import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { SharedComponentsCoreModule } from '@bp/shared/components/core';
import { SharedFeaturesSvgIconsModule } from '@bp/shared/features/svg-icons';
import { SharedFeaturesTooltipModule } from '@bp/shared/features/tooltip';
import { SharedPipesModule } from '@bp/shared/pipes';

import {
	NotificationsHubBellBtnComponent as NotificationsHubBellButtonComponent, NotificationsHubBodyComponent,
	NotificationsHubComponent, NotificationsHubHeaderComponent,
	NotificationsHubNotificationItemComponent
} from './components';

@NgModule({

	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		RouterModule,
		CommonModule,
		MatButtonModule,
		MatIconModule,
		MatListModule,
		MatProgressBarModule,
		MatSidenavModule,
		MatToolbarModule,

		SharedComponentsCoreModule,
		SharedFeaturesTooltipModule,
		SharedFeaturesSvgIconsModule,
		SharedPipesModule,
	],
	exports: [
		NotificationsHubBellButtonComponent,
		NotificationsHubComponent,
	],
	declarations: [
		NotificationsHubBellButtonComponent,
		NotificationsHubBodyComponent,
		NotificationsHubComponent,
		NotificationsHubHeaderComponent,
		NotificationsHubNotificationItemComponent,
	],
})
export class AdminsSharedFeaturesNotificationsHubModule {
}
