import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { SharedComponentsControlsModule } from '@bp/shared/components/controls';
import { SharedComponentsCoreModule } from '@bp/shared/components/core';
import { SharedFeaturesTextMaskModule } from '@bp/shared/features/text-mask';

import { SharedDomainsIdentityModule } from '@bp/shared-domains-identity';

import {
	IdentitySessionIsAboutToExpireDialogComponent,
	OtpCodeInputComponent,
	OtpGenerateCodeButtonComponent,
	OtpProviderMessageComponent
} from './components';

const EXPOSED_MODULES = [ SharedDomainsIdentityModule ];

const EXPOSED_COMPONENTS = [ OtpCodeInputComponent, OtpGenerateCodeButtonComponent, OtpProviderMessageComponent ];

@NgModule({
	imports: [
		CommonModule,
		MatDialogModule,
		MatButtonModule,
		MatProgressBarModule,
		ReactiveFormsModule,
		SharedComponentsCoreModule,
		SharedComponentsControlsModule,
		SharedFeaturesTextMaskModule,
		EXPOSED_MODULES,
	],
	exports: [ EXPOSED_MODULES, EXPOSED_COMPONENTS ],
	declarations: [ EXPOSED_COMPONENTS, IdentitySessionIsAboutToExpireDialogComponent ],
})
export class AdminsSharedDomainsIdentityModule {}
