import { ToastrService } from 'ngx-toastr';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

import { FormBaseComponent } from '@bp/shared/components/core';
import { ensureFormGroupConfig } from '@bp/shared/typings';
import { Validators } from '@bp/shared/features/validation';

import type { ISendResetPasswordLinkApiRequest } from '@bp/admins-shared/domains/identity';

@Component({
	selector: 'bp-send-reset-password-link-form',
	templateUrl: './send-reset-password-link-form.component.html',
	styleUrls: [ './send-reset-password-link-form.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SendResetPasswordLinkFormComponent extends FormBaseComponent<ISendResetPasswordLinkApiRequest> {

	@Output() readonly login = new EventEmitter();

	override onSubmitShowInvalidInputsToast = false;

	constructor(
		fb: UntypedFormBuilder,
		cdr: ChangeDetectorRef,
		toaster: ToastrService,
	) {
		super(fb, cdr, toaster);

		this.form = this._formBuilder.group(ensureFormGroupConfig<ISendResetPasswordLinkApiRequest>({
			userName: [ '', [ Validators.email ]],
		}));

	}
}
