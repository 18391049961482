import { Enumeration } from '@bp/shared/models/core/enum';

export class FieldViewType extends Enumeration {
	// General
	static text = new FieldViewType();

	static textarea = new FieldViewType();

	static boolean = new FieldViewType();

	static booleanCircle = new FieldViewType();

	static currency = new FieldViewType();

	static percent = new FieldViewType();

	static moment = new FieldViewType();

	static momentFromNow = new FieldViewType();

	static momentSplitIntoTwoRows = new FieldViewType();

	static email = new FieldViewType();

	static chip = new FieldViewType();

	static link = new FieldViewType();

	static thumbnail = new FieldViewType();

	static image = new FieldViewType();

	// Special
	static currencyCode = new FieldViewType();

	static country = new FieldViewType();

	static paymentOptionBrand = new FieldViewType();

	static status = new FieldViewType();
}
