import { isInteger, isNil, isString } from 'lodash-es';

import { formatCurrency } from '@angular/common';
import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

import type { CryptoCurrencyCode, CurrencyCode, ICurrency } from '@bp/shared/models/currencies';
import { CryptoCurrency, currencyFactory } from '@bp/shared/models/currencies';

export const CRYPTO_CURRENCY_DIGITS = 12;
export const CRYPTO_CURRENCY_DIGITS_INFO = `1.0-${ CRYPTO_CURRENCY_DIGITS }`;

@Pipe({
	name: 'bpCurrency',
})
export class BpCurrencyPipe implements PipeTransform {

	/**
	 * If currencyCode is null no currency symbol gets rendered
	 */
	transform(
		value: number | string | null,
		currencyOrCurrencyCode?: CryptoCurrencyCode | CurrencyCode | ICurrency | null,
	): string {
		if (isNil(value))
			return '';

		if (isString(value) && Number.isNaN(Number(value)))
			return value;

		const currency = currencyOrCurrencyCode
			? currencyFactory(currencyOrCurrencyCode)
			: undefined;

		const isCrypto = currency instanceof CryptoCurrency;

		return formatCurrency(
			Number(value),
			'en',
			currency?.symbol ?? '',
			'USD',
			isInteger(value)
				? '1.0-0'
				: (isCrypto
					? CRYPTO_CURRENCY_DIGITS_INFO
					: undefined),
		);
	}

}
