
import type { IValidatorFunc } from '@bp/shared/features/validation';

import type { MetadataEntity } from '../metadata-entity';

import { $$classMetadataPropertyMetadataControlInjector } from './class-metadata-property-metadata-control-injector';

// eslint-disable-next-line @typescript-eslint/naming-convention
export function Validator(validator: IValidatorFunc) {
	return function(model: MetadataEntity, property: string): void {
		$$classMetadataPropertyMetadataControlInjector(
			model,
			property,
			{ validator },
		);
	};
}
