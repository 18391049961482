import type { Moment } from 'moment';

import { Default, Label, Mapper, View } from './decorators';
import { EntityBase } from './entity-base';
import { FieldViewType } from './enums';
import { momentMapper } from './mappers';

export abstract class FirebaseEntity extends EntityBase {

	@Label('Author')
	@Default(null)
	authorUid!: string | null;

	@Mapper(momentMapper)
	@View(FieldViewType.moment, () => 'LLL')
	@Default(null)
	createdAt!: Moment | null;

	@Mapper(momentMapper)
	@View(FieldViewType.moment, () => 'LLL')
	@Default(null)
	updatedAt!: Moment | null;

	@Default(null)
	updatedBy!: string | null;

}
