import m from 'moment';
import type { Observable } from 'rxjs';
import { BehaviorSubject, from, fromEvent, interval } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeAll, subscribeOn, throttleTime } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { BpScheduler, observeInsideNgZone } from '@bp/shared/rxjs';

import { StorageService } from './storage';

@Injectable({
	providedIn: 'root',
})
export class UserIdleService {

	/**
	 * The amount of seconds to consider the user is away
	 */
	readonly awayInDuration = 15 * 60;

	/**
	 * The amount of seconds to consider the user is active
	 */
	readonly activeDuration = 3 * 60;

	private readonly _lastActiveTimestampKey = 'LAST_ACTIVE_TIMESTAMP';

	get lastActiveTimestamp(): number {
		return this._storageService.get(this._lastActiveTimestampKey);
	}

	set lastActiveTimestamp(value: number) {
		this._storageService.set(this._lastActiveTimestampKey, value);
	}

	private readonly _idle$ = new BehaviorSubject(0);

	/**
	 * Emits each 10 secs idle time in seconds
	 */
	idle$ = this._idle$.asObservable();

	away$ = this._idle$.pipe(
		map(idle => idle >= this.awayInDuration),
		distinctUntilChanged(),
		observeInsideNgZone(),
	);

	/**
	 * Emits when the user is considered away
	 */
	onAway$ = <Observable<void>> <unknown> this.away$.pipe(
		filter(isAway => !!isAway),
	);

	active$ = this._idle$.pipe(
		map(idle => idle <= this.activeDuration),
		distinctUntilChanged(),
		observeInsideNgZone(),
	);

	/**
	 * Emits when the user is considered active
	 */
	onActive$ = <Observable<void>> <unknown> this.active$.pipe(
		filter(isActive => !!isActive),
	);

	/**
	 * Idle time in seconds
	 */
	get idle(): number {
		return this._idle$.value;
	}

	private readonly _trackedUserEvents: (keyof WindowEventMap)[] = [
		'click',
		'mousemove',
		'mouseenter',
		'keydown',
		'scroll',
		'wheel',
		'touchmove',
		'touchstart',
	];

	constructor(private readonly _storageService: StorageService) { }

	init(): void {
		this._whenUserEventOnAnotherTabOccursResetIdleTimer();

		this._whenUserEventOccursSaveTimestampAndResetIdleTimer();

		this._startIdleTimer();
	}

	private _startIdleTimer(): void {
		interval(30 * 1000, BpScheduler.asyncOutside)
			.pipe(map(() => m().unix() - this.lastActiveTimestamp))
			.subscribe(idle => void this._idle$.next(idle));
	}

	private _whenUserEventOccursSaveTimestampAndResetIdleTimer(): void {
		from(this._trackedUserEvents.map(trackingEvent => fromEvent(window, trackingEvent)))
			.pipe(
				mergeAll(),
				throttleTime(1000),
				subscribeOn(BpScheduler.outside),
			)
			.subscribe(() => {
				this.lastActiveTimestamp = m()
					.unix();

				this._resetIdleCounter();
			});
	}

	private _whenUserEventOnAnotherTabOccursResetIdleTimer(): void {
		fromEvent<StorageEvent>(window, 'storage')
			.pipe(
				filter(
					storageEvent => storageEvent.key === this._storageService.deriveKey(this._lastActiveTimestampKey),
				),
				subscribeOn(BpScheduler.outside),
			)
			.subscribe(() => void this._resetIdleCounter());
	}

	private _resetIdleCounter(): void {
		this._idle$.next(0);
	}
}
