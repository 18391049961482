import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'bp-boxed-items',
	templateUrl: './boxed-items.component.html',
	styleUrls: [ './boxed-items.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoxedItemsComponent {

	@Input() items?: { displayName?: string }[];

}
