<div class="h-100">
	<div class="text-center">
		This extra step verifies it's really you trying to reset the password for
		<strong> {{ (identityFacade.incompleteIdentity$ | async)?.email }}</strong>
	</div>

	<ng-container [ngSwitch]="currentWay$ | async">
		<bp-otp-verification-form
			*ngSwitchCase="ResetPasswordVerificationWay.Otp"
			@fadeIn
			[pending]="introFacade.pending$ | async"
			[error]="introFacade.error$ | async"
			(tryAnotherWay)="currentWay$.next(ResetPasswordVerificationWay.ProvideSecurityQuestionAnswer)"
			(submitted)="identityFacade.resetPasswordOtpVerification($event)"
		>
		</bp-otp-verification-form>

		<bp-provide-security-question-answer-form
			*ngSwitchCase="ResetPasswordVerificationWay.ProvideSecurityQuestionAnswer"
			@fadeIn
			[hasAnotherWay]="hasAuthApp$ | async"
			[pending]="introFacade.pending$ | async"
			[error]="introFacade.error$ | async"
			[identitySecurityQuestions]="identityFacade.identitySecurityQuestions$ | async"
			(tryAnotherWay)="currentWay$.next(ResetPasswordVerificationWay.Otp)"
			(submitted)="identityFacade.verifySecurityQuestionAnswer($event)"
		>
		</bp-provide-security-question-answer-form>
	</ng-container>
</div>
