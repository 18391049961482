import { SlidingStepRoute } from '@bp/shared/features/sliding-step-routes';

// The order of props is used for creating the transition animation between steps
export class IntroPageStep extends SlidingStepRoute {

	static login = new IntroPageStep('Log In');

	static otpOnLogin = new IntroPageStep('2-Step Verification');

	// #region SECTION Signup Via Invite

	static acceptInvite = new IntroPageStep('Create Account');

	static createAccount = new IntroPageStep('Create Account');

	// #endregion !SECTION

	// #region deprecated, remove after accepting invite implementation by backend

	static emailVerification = new IntroPageStep('Email Verification');

	static createPassword = new IntroPageStep();

	// #endregion !deprecated

	// #region SECTION Continue Signup

	static setSecurityQuestionsAnswers = new IntroPageStep('Security Questions');

	static registerAuthenticatorApp = new IntroPageStep('Authenticator Connection');

	// #endregion !SECTION

	// #region SECTION reset password

	static sendResetPasswordLink = new IntroPageStep('Reset Password');

	static resetPasswordLinkSent = new IntroPageStep('Email Sent!');

	static resetPasswordVerification = new IntroPageStep('2-Step Verification');

	static resetPassword = new IntroPageStep('Reset Password');

	// #endregion !SECTION

	// #region SECTION reset authenticator app

	static sendResetAuthenticatorAppLink = new IntroPageStep('Reset Authenticator App');

	static resetAuthenticatorAppLinkSent = new IntroPageStep('Email Sent!');

	static resetAuthenticatorAppVerification = new IntroPageStep('Identity Verification');

	static resetAuthenticatorApp = new IntroPageStep('Reset Authenticator App');

	// #endregion !SECTION

	static sessionExpired = new IntroPageStep('Your Session Has Expired');

}
