import { range } from 'lodash-es';
import Lottie, { AnimationItem } from 'lottie-web-light';
import { Observable, lastValueFrom } from 'rxjs';
import { first } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostBinding, Input } from '@angular/core';

import { Destroyable, takeUntilDestroyed } from '@bp/shared/models/common';
import { fromViewportIntersection, ZoneService } from '@bp/shared/rxjs';
import { MediaService } from '@bp/shared/features/media';
import { attrBoolValue } from '@bp/shared/utilities';
import { OnChanges, SimpleChanges } from '@bp/shared/models/core';

const LOTTIE_ANIMATIONS_ASSETS_DIR = '/assets/lottie-animations';

@Component({
	selector: 'bp-lottie-player',
	templateUrl: './lottie-player.component.html',
	styleUrls: [ './lottie-player.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LottiePlayerComponent extends Destroyable implements OnDestroy, OnChanges {

	@Input()
	@HostBinding('class')
	animationName!: string;

	@Input() relatedToScroll: boolean | '' = false;

	@Input() looped: boolean | '' = false;

	@Input() eager: boolean | '' = false;

	@HostBinding('class.loaded')
	loaded = false;

	private readonly _$host = this._hostRef.nativeElement;

	private _animationItem?: AnimationItem;

	constructor(
		private readonly _hostRef: ElementRef<HTMLElement>,
		private readonly _http: HttpClient,
		private readonly _mediaService: MediaService,
		private readonly _cdr: ChangeDetectorRef,
		private readonly _zoneService: ZoneService,
	) {
		super();
	}

	async ngOnChanges(_changes: SimpleChanges<this>): Promise<void> {
		this.relatedToScroll = attrBoolValue(this.relatedToScroll);

		this.looped = attrBoolValue(this.looped);

		this.eager = attrBoolValue(this.eager);

		this._animationItem?.destroy();

		this._animationItem = this.eager
			? await this._createLottieAnimation()
			: await this._createLottieAnimationWhenHostIsAboutToEnterViewport();

		if (this.relatedToScroll)
			this._playAnimationOnScrollInsideViewport();
		else
			this._playAnimationWhenFullyInsideViewport();

		this._markAsLoadedOnLottieDOMLoad();
	}

	override ngOnDestroy(): void {
		super.ngOnDestroy();

		// some transition animations can be applied to the host so we cleanup
		// when most certainly the animation is not needed anymore
		setTimeout(() => this._animationItem?.destroy(), 1000);
	}

	private _markAsLoadedOnLottieDOMLoad(): void {
		this._animationItem!.addEventListener('DOMLoaded', () => {
			this.loaded = true;

			this._cdr.markForCheck();
		});
	}

	private async _createLottieAnimationWhenHostIsAboutToEnterViewport(): Promise<AnimationItem> {
		await lastValueFrom(this._observeHostIsAboutToEnterViewport()
			.pipe(first(entry => entry.isIntersecting)));

		return this._createLottieAnimation();
	}

	private async _createLottieAnimation(): Promise<AnimationItem> {
		const animationData = await this._loadAnimationDataAccordingToDPR();

		return this._zoneService.runOutsideAngular(() => Lottie.loadAnimation({
			animationData,
			container: this._$host,
			renderer: 'svg',
			loop: !this.relatedToScroll && !!this.looped,
			autoplay: false,
		}));
	}

	private async _loadAnimationDataAccordingToDPR(): Promise<Record<string, unknown>> {
		const sceneFolderSource = `${ LOTTIE_ANIMATIONS_ASSETS_DIR }/${ this.animationName }`;
		const dataFileSource = `${ sceneFolderSource }/data.json`;
		const imageFolderSource = `${ sceneFolderSource }/images${ this._mediaService.isHighDPR ? '@2x' : '' }/`;

		const animationDataText = await lastValueFrom(this._http.get(dataFileSource, { responseType: 'text' }));

		return JSON.parse(animationDataText.replace(/images\//ug, imageFolderSource));
	}

	private _playAnimationOnScrollInsideViewport(): void {
		this._observeHostScrollingInsideViewport()
			.pipe(takeUntilDestroyed(this))
			.subscribe(({ boundingClientRect, intersectionRatio }) => {
				if (boundingClientRect.top > 0)
					// 50 hardcoded value due to stupid animations provided by Ben, should be totalFrames
					this._animationItem!.goToAndStop(intersectionRatio * 50, true);
			});
	}

	private _playAnimationWhenFullyInsideViewport(): void {
		this._observeHostEnterViewport()
			.pipe(takeUntilDestroyed(this))
			.subscribe(({ boundingClientRect, intersectionRatio, rootBounds }) => {
				if (intersectionRatio >= 0.9)
					this._animationItem!.play();

				if (rootBounds && boundingClientRect.top > rootBounds.height)
					this._animationItem!.goToAndStop(0); // Reset when the host gets in the viewport from the top
			});
	}

	private _observeHostScrollingInsideViewport(): Observable<IntersectionObserverEntry> {
		return fromViewportIntersection(
			this._$host,
			{
				rootMargin: '0px 0px -25% 0px',
				threshold: range(0, 1, 0.01),
			},
		);
	}

	private _observeHostIsAboutToEnterViewport(): Observable<IntersectionObserverEntry> {
		return fromViewportIntersection(
			this._$host,
			{
				rootMargin: '0px 0px 75% 0px',
				threshold: range(0, 1, 0.01),
			},
		);
	}

	private _observeHostEnterViewport(): Observable<IntersectionObserverEntry> {
		return fromViewportIntersection(
			this._$host,
			{
				threshold: range(0, 1, 0.01),
			},
		);
	}
}
