/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import type { ActionReducer } from '@ngrx/store';
import { INIT } from '@ngrx/store';

import type { Dictionary } from '@bp/shared/typings';
import type { StorageService } from '@bp/shared/services';

export function appStateRehydratorMetaReducerFactory(storageService: StorageService) {
	return function(reducer: ActionReducer<any>): ActionReducer<any> {
		return function(state, action) {
			const newState = reducer(state, action);

			return action.type === INIT
				? { ...newState, ...extractStateFromStorage(storageService) }
				: newState;
		};
	};
}

function extractStateFromStorage(storageService: StorageService): Object {
	return storageService
		.keys()
		// eslint-disable-next-line unicorn/prefer-object-from-entries
		.reduce(
			(state: Dictionary<any>, storageKey) => {
				const stateKeys = storageKey.split('.');

				let currentStateRef = state;

				stateKeys.forEach((key, index) => {
					if (index === stateKeys.length - 1)
						// eslint-disable-next-line no-return-assign
						return currentStateRef[key] = storageService.get(storageKey);

					currentStateRef[key] = currentStateRef[key] || {};

					currentStateRef = currentStateRef[key];
				});

				return state;
			},
			{},
		);
}
