<mat-card>
	<a routerLink="login">
		<bp-svg-icon name="logo-short-text"> </bp-svg-icon>
	</a>

	<h1>{{ outlet?.activatedRouteData?.['step']?.titleDisplayName }}</h1>

	<mat-card-content>
		<mat-progress-bar
			*ngIf="introFacade.pending$ | async"
			class="progress-bar-top-sticked"
			@slide
			color="primary"
			mode="indeterminate"
		>
		</mat-progress-bar>

		<bp-sliding-step-routes [steps]="introPageSteps">
			<router-outlet #outlet="outlet" (activate)="introFacade.resetError()"></router-outlet>
		</bp-sliding-step-routes>
	</mat-card-content>
</mat-card>

<bp-intro-shell-page-footer></bp-intro-shell-page-footer>
