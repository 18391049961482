import { Moment } from 'moment';

import { Action, createReducer, on } from '@ngrx/store';

import {
	IdentityState,
	IDENTITY_STATE_KEY,
	composeIdentityReducer,
	IDENTITY_INITIAL_STATE
} from '@bp/shared-domains-identity';

import { Identity, SecurityQuestion, OtpProvider } from '../models';

import {
	createAccountFailure,
	createAccountSuccess,
	loadAllSecurityQuestionsFailure,
	loadAllSecurityQuestionsSuccess,
	loadAuthenticatorAppKeyFailure,
	loadAuthenticatorAppKeySuccess,
	loadIdentitySecurityQuestionsFailure,
	loadIdentitySecurityQuestionsSuccess,
	refreshTokenSuccess,
	registerAuthenticatorFailure,
	registerAuthenticatorSuccess,
	resetAuthenticatorAppFailure,
	resetAuthenticatorAppSuccess,
	resetPasswordFailure,
	resetPasswordOtpVerificationFailure,
	resetPasswordOtpVerificationSuccess,
	resetPasswordSuccess,
	sendResetAuthenticatorAppLinkFailure,
	sendResetAuthenticatorAppLinkSuccess,
	sendResetPasswordLinkFailure,
	sendResetPasswordLinkSuccess,
	setSecurityQuestionsAnswersFailure,
	setSecurityQuestionsAnswersSuccess,
	acceptInviteFailure,
	acceptInviteSuccess,
	loginOtpVerificationFailure,
	loginOtpVerificationSuccess,
	verifySecurityQuestionsAnswersFailure,
	verifySecurityQuestionsAnswersSuccess,
	resourceAccessOtpVerificationSuccess,
	resourceAccessOtpVerificationFailure,
	IDENTITY_API_ACTIONS,
	generateLoginOtpSuccess,
	generateResourceAccessOtpSuccess,
	generateLoginOtpFailure,
	generateResourceAccessOtpFailure,
	verifyEmailSuccess,
	createPasswordSuccess,
	verifyEmailFailure,
	createPasswordFailure
} from './identity-api.actions';
import {
	IDENTITY_ACTIONS,
	acceptInvite,
	createAccount,
	loadAllSecurityQuestions,
	setSecurityQuestionsAnswers,
	loadAuthenticatorAppKey,
	registerAuthenticatorApp,
	resetPassword,
	resetPasswordOtpVerification,
	verifySecurityQuestionsAnswers,
	loginOtpVerification,
	resetAuthenticatorApp,
	sendResetAuthenticatorAppLink,
	sendResetPasswordLink,
	resourceAccessOtpVerification,
	generateLoginOtp,
	generateResourceAccessOtp,
	identityEffectsInit,
	verifyEmail,
	createPassword
} from './identity.actions';
import { setIncompleteIdentity, setIncompleteIdentityBasedOnLoginQueryParams } from './incomplete-identity.actions';

export const FEATURE_STATE_KEY = '[domain]:identity';

export interface IState extends IdentityState<Identity> {

	incompleteIdentity: Identity | null;

	allSecurityQuestions: SecurityQuestion[] | null;

	identitySecurityQuestions: SecurityQuestion[] | null;

	authenticatorKey: string | null;

	otpProvider: OtpProvider | null;

	otpExpiresAt: Moment | null;

}

export const initialState: IState = {
	...IDENTITY_INITIAL_STATE,

	incompleteIdentity: null,

	allSecurityQuestions: null,

	identitySecurityQuestions: null,

	authenticatorKey: null,

	otpProvider: null,

	otpExpiresAt: null,
};

const identityReducer = createReducer(
	initialState,

	...composeIdentityReducer(initialState, IDENTITY_ACTIONS),

	on(
		refreshTokenSuccess,
		resourceAccessOtpVerificationSuccess,
		(state, { result }): IState => ({
			...state,
			user: result,
		}),
	),

	on(
		setIncompleteIdentityBasedOnLoginQueryParams,
		setIncompleteIdentity,
		(state, { identity }): IState => ({
			...state,
			incompleteIdentity: identity,
		}),
	),

	on(
		acceptInvite,
		createAccount,
		verifyEmail,
		createPassword,
		resetPassword,
		resetAuthenticatorApp,
		resetPasswordOtpVerification,
		sendResetAuthenticatorAppLink,
		sendResetPasswordLink,
		loadAllSecurityQuestions,
		setSecurityQuestionsAnswers,
		loadIdentitySecurityQuestionsSuccess,
		verifySecurityQuestionsAnswers,
		loadAuthenticatorAppKey,
		registerAuthenticatorApp,
		loginOtpVerification,
		resourceAccessOtpVerification,
		generateLoginOtp,
		generateResourceAccessOtp,
		(state): IState => ({
			...state,
			error: null,
			pending: true,
		}),
	),

	on(
		acceptInviteSuccess,
		createAccountSuccess,
		verifyEmailSuccess,
		createPasswordSuccess,
		resetPasswordSuccess,
		resetPasswordOtpVerificationSuccess,
		resetAuthenticatorAppSuccess,
		setSecurityQuestionsAnswersSuccess,
		verifySecurityQuestionsAnswersSuccess,
		registerAuthenticatorSuccess,
		loginOtpVerificationSuccess,
		(state, { result }): IState => ({
			...state,
			incompleteIdentity: result.isIncomplete ? result : null,
			user: result.isIncomplete ? null : result,
		}),
	),

	on(
		acceptInviteSuccess,
		acceptInviteFailure,
		createAccountSuccess,
		createAccountFailure,
		verifyEmailSuccess,
		verifyEmailFailure,
		createPasswordSuccess,
		createPasswordFailure,
		resetPasswordSuccess,
		resetPasswordFailure,
		resetPasswordOtpVerificationSuccess,
		resetPasswordOtpVerificationFailure,
		resetAuthenticatorAppSuccess,
		resetAuthenticatorAppFailure,
		loadAllSecurityQuestionsSuccess,
		loadAllSecurityQuestionsFailure,
		setSecurityQuestionsAnswersSuccess,
		setSecurityQuestionsAnswersFailure,
		loadIdentitySecurityQuestionsSuccess,
		loadIdentitySecurityQuestionsFailure,
		verifySecurityQuestionsAnswersSuccess,
		verifySecurityQuestionsAnswersFailure,
		loadAuthenticatorAppKeySuccess,
		loadAuthenticatorAppKeyFailure,
		registerAuthenticatorSuccess,
		registerAuthenticatorFailure,
		loginOtpVerificationSuccess,
		loginOtpVerificationFailure,
		resourceAccessOtpVerificationSuccess,
		resourceAccessOtpVerificationFailure,
		sendResetPasswordLinkSuccess,
		sendResetPasswordLinkFailure,
		sendResetAuthenticatorAppLinkSuccess,
		sendResetAuthenticatorAppLinkFailure,
		generateLoginOtpSuccess,
		generateLoginOtpFailure,
		generateResourceAccessOtpSuccess,
		generateResourceAccessOtpFailure,
		(state): IState => ({
			...state,
			pending: false,
		}),
	),

	on(
		acceptInviteFailure,
		createAccountFailure,
		verifyEmailFailure,
		createPasswordFailure,
		resetPasswordFailure,
		resetPasswordOtpVerificationFailure,
		resetAuthenticatorAppFailure,
		loadAllSecurityQuestionsFailure,
		setSecurityQuestionsAnswersFailure,
		loadIdentitySecurityQuestionsFailure,
		verifySecurityQuestionsAnswersFailure,
		loadAuthenticatorAppKeyFailure,
		registerAuthenticatorFailure,
		loginOtpVerificationFailure,
		resourceAccessOtpVerificationFailure,
		sendResetPasswordLinkFailure,
		sendResetAuthenticatorAppLinkFailure,
		generateLoginOtpFailure,
		generateResourceAccessOtpFailure,
		(state, { error }): IState => ({
			...state,
			error,
		}),
	),

	on(
		loadAllSecurityQuestionsSuccess,
		(state, { result }): IState => ({
			...state,
			allSecurityQuestions: result,
		}),
	),

	on(
		loadIdentitySecurityQuestionsSuccess,
		(state, { result }): IState => ({
			...state,
			identitySecurityQuestions: result,
		}),
	),

	on(
		loadAuthenticatorAppKeySuccess,
		(state, { result }): IState => ({
			...state,
			authenticatorKey: result,
		}),
	),

	on(
		IDENTITY_API_ACTIONS.loginSuccess,
		(state, { result }): IState => ({
			...state,
			otpProvider: result.otpProvider,
		}),
	),

	on(
		identityEffectsInit,
		(state): IState => ({
			...state,
			otpProvider: state[IDENTITY_STATE_KEY]?.otpProvider ?? null,
		}),
	),

	on(
		IDENTITY_API_ACTIONS.loginSuccess,
		generateLoginOtpSuccess,
		generateResourceAccessOtpSuccess,
		(state, { result }): IState => ({
			...state,
			otpExpiresAt: result.otpExpiresAt,
		}),
	),

	on(
		loginOtpVerificationSuccess,
		resourceAccessOtpVerificationSuccess,
		(state): IState => ({
			...state,
			otpExpiresAt: null,
		}),
	),
);

export function reducer(state: IState | undefined, action: Action): IState {
	return identityReducer(state, action);
}
