/* eslint-disable @typescript-eslint/promise-function-async */
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';

import { NgModule } from '@angular/core';
import type { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';

import { TelemetryService } from '@bp/shared/services';

import {
	identityHasAccessGuardConfig,
	IdentityHasAccessGuard,
	IdentityLoggedInGuard,
	BridgerAdminFeature
} from '@bp/shared-domains-identity';

// App routing module is for lazy routes

const routes: Routes = [
	{
		path: '',
		pathMatch: 'full',
		redirectTo: '/psps',
	},

	{
		path: 'psps',
		data: {
			...identityHasAccessGuardConfig(BridgerAdminFeature.bridgerPsps),
		},
		canActivate: [ IdentityHasAccessGuard ],
		runGuardsAndResolvers: 'always',
		loadChildren: () => import('@bp/bridger-admin/sections/bridger-psps').then(m => m.BridgerPspsModule),
	},

	{
		path: 'merchants',
		loadChildren: () => import('@bp/bridger-admin/sections/merchants').then(m => m.MerchantsModule),
		canActivate: [ IdentityLoggedInGuard ],
	},
];

@NgModule({
	imports: [
		QuicklinkModule,
		RouterModule.forRoot(routes, {
			initialNavigation: 'enabledNonBlocking',
			paramsInheritanceStrategy: 'always',
			onSameUrlNavigation: 'reload',
			scrollPositionRestoration: 'enabled',
			anchorScrolling: 'enabled',
			scrollOffset: [ 0, 150 ],
			canceledNavigationResolution: 'replace',
			preloadingStrategy: QuicklinkStrategy,
			errorHandler: TelemetryService.routerErrorHandler,
		}),
	],
	exports: [ RouterModule, QuicklinkModule ],
})
export class AppRoutingModule {}
