import { createFeatureSelector, createSelector } from '@ngrx/store';

import type { IState } from './layout.reducer';
import { FEATURE_KEY } from './layout.reducer';

const getLayoutState = createFeatureSelector<IState>(FEATURE_KEY);

export const getShowSidenav = createSelector(
	getLayoutState,
	it => it.showSidenav,
);

export const getHasBackdrop = createSelector(
	getLayoutState,
	it => it.hasBackdrop,
);

export const getHasFullpageBackdrop = createSelector(
	getLayoutState,
	it => it.hasFullpageBackdrop,
);
