import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';

import { BpError } from '@bp/shared/models/core';
import { BridgerPsp } from '@bp/shared/domains/bridger-psps/core';

import { loadAllFailure, loadAllSuccess } from './bridger-psps-shared-api.actions';
import { loadAll, resetState } from './bridger-psps-shared.actions';

export const FEATURE_KEY = '[domain]:bridger-psps-shared';

export interface IState {

	all: BridgerPsp[] | null;

	pending: boolean;

	error: BpError | null;

}

export const initialState: IState = {
	all: null,
	pending: false,
	error: null,
};

const providersReducer = createReducer(

	initialState,

	on(resetState, (): IState => initialState),

	on(loadAll, (state): IState => ({
		...state,
		pending: true,
	})),

	on(loadAllSuccess, (state, { result }): IState => ({
		...state,
		all: result.records,
		pending: false,
		error: null,
	})),

	on(loadAllFailure, (state, { error }): IState => ({
		...state,
		error,
		pending: false,
	})),

);

export function reducer(state: IState | undefined, action: Action): IState {
	return providersReducer(state, action);
}
