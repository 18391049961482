import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { LottiePlayerComponent } from './components';

const EXPOSED = [
	LottiePlayerComponent,
];

@NgModule({
	imports: [ CommonModule, HttpClientModule ],
	declarations: EXPOSED,
	exports: EXPOSED,
})
export class SharedFeaturesLottiePlayerModule { }
