import { Moment } from 'moment';

import { createAction, props } from '@ngrx/store';

import { Identity } from '../models';

export const SCOPE = '[Domain][Incomplete Identity]';

export const incompleteIdentityEffectsInit = createAction(
	`${ SCOPE } Incomplete Identity Effects Init`,
);

export const setIncompleteIdentityBasedOnLoginQueryParams = createAction(
	`${ SCOPE } Set Incomplete Identity Based On Login Query Params`,
	props<{ identity: Identity | null }>(),
);

export const setIncompleteIdentity = createAction(
	`${ SCOPE } Set Incomplete Identity`,
	props<{ identity: Identity | null }>(),
);

export const startIncompleteIdentitySessionExpiryTimer = createAction(
	`${ SCOPE } Start Incomplete Identity Session Expiry Timer`,
	props<{ expiresAt: Moment }>(),
);

export const stopIncompleteIdentitySessionExpiryTimer = createAction(
	`${ SCOPE } Stop Incomplete Identity Session Expiry Timer`,
);

export const incompleteIdentitySessionExpired = createAction(
	`${ SCOPE } Incomplete Identity Session Expired`,
);
