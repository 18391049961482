<span class="frontend-version">
	<ng-container *ngIf="environmentService.isRemoteServer">
		v{{
			environmentService.isProduction && !environmentService.isBackoffice
				? appVersion.release
				: appVersion.releaseAndShortSHA + (environmentService.isBackoffice ? '' : '-' + environmentService.name)
		}}
	</ng-container>

	<ng-container *ngIf="environmentService.isLocalServer"> v0.0.0-localhost </ng-container>
</span>

<span *ngIf="environmentService.backendVersion$ | async as backendVersion" class="backend-version">
	/ v{{ backendVersion }}
</span>
