import {
	camelCase, forIn, forOwn, isBoolean, isEmpty, isNil, isNumber, isString, kebabCase,
	lowerCase, upperFirst, startCase
} from 'lodash-es';

export type GetEnumerationLiterals<T extends typeof Enumeration> = Exclude<
{
	[ K in keyof T ]-?: T[ K ] extends (Function | null | undefined) ? never : K
}[ keyof T ],
'prototype'
>;

/*
 * We use static this in this context because we want to use
 * The inherited static this scope,
 */
export class Enumeration {

	static parseHook?: (value: any) => Enumeration | null;

	// eslint-disable-next-line @typescript-eslint/prefer-readonly
	private static _list: any[];

	private static _isValue(v: any): boolean {
		return isNumber(v) || isBoolean(v);
	}

	static getList<T extends typeof Enumeration>(this: T): InstanceType<T>[] {
		if (isNil(this._list)) {
			const list: T[] = [];

			forIn(this, (it, key) => {
				if (it instanceof Enumeration && Number.isNaN(Number(key)) && this._shouldList(it))
					list.push(<any> it);
			});

			this._list = list;
		}

		return this._list;
	}

	static find<T extends typeof Enumeration>(this: T, value: number | string): InstanceType<T> | null {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
		return (<any> this)[value] || null;
	}

	static parse<T extends typeof Enumeration>(this: T, value: any): InstanceType<T> | null {
		if (isNil(value))
			return null;

		if (value instanceof this.prototype.constructor)
			return <InstanceType<T>> value;

		const customParserResult = this.parseHook?.(value);

		if (customParserResult)
			return <InstanceType<T>> customParserResult;

		return this.find(this._isValue(value) ? value : camelCase(value));
	}

	static parseStrict<T extends typeof Enumeration>(this: T, value: any): InstanceType<T> {
		const result = this.parse(value);

		if (!result)
			throw new Error(`Enum type \`${ this.name }\` does not contain value \`${ value }\``);

		return result;
	}

	static isInstance<T extends typeof Enumeration>(this: T, value: any): value is T {
		return value instanceof this;
	}

	protected static _shouldList(_value: Enumeration): boolean {
		return true;
	}

	get displayName(): string {
		return this._displayName ?? this.name;
	}

	protected _titleDisplayName!: string;

	get titleDisplayName(): string {
		if (isEmpty(this._titleDisplayName)) {
			return (this._titleDisplayName = this._hasCustomDisplayName
				? this.displayName
				: startCase(this.displayName)
			);
		}

		return this._titleDisplayName;
	}

	get name(): string {
		return this.value.toString();
	}

	protected _kebabName!: string;

	get kebabName(): string {
		if (isEmpty(this._kebabName))
			return (this._kebabName = kebabCase(this.name));

		return this._kebabName;
	}

	protected _value!: boolean | number | string;

	get value(): boolean | number | string {
		if (isEmpty(this._value))
			return (this._value = this._getValueName());

		return this._value;
	}

	cssClass!: string;

	protected _displayName?: string;

	private readonly _id = `enum_${ this._getUniqId() }`;

	private readonly _hasCustomDisplayName: boolean;

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	constructor(displayName?: any, ..._args: any[]) {
		this._assertDisplayName(displayName);

		this._displayName = displayName;

		this._hasCustomDisplayName = !isEmpty(displayName);

		/*
		 * Schedule a microtask at the end of the current event loop
		 * which means that the constructor will have all the enumerations attached to it by the time
		 * the callback is fired and we are able to find by the id of the enum its name amidst the static properties
		 * PS we can't use queryMicrotask since it fires the microtask after the dom is rendered.
		 * and we need the enums to be inited before any components are rendered
		 */
		void Promise
			.resolve()
			.then(() => void this._init());
	}

	private _assertDisplayName(displayName: any): asserts displayName is string | undefined {
		if (!isNil(displayName) && !isString(displayName))
			throw new Error('Invalid displayName argument type, must be string or undefined');
	}

	valueOf() {
		return this.value;
	}

	toString(): string | undefined {
		return this.name;
	}

	toJSON() {
		return this.value;
	}

	protected _init(): void {
		this.cssClass = this._getCssClass();

		this._displayName = this._displayName ?? upperFirst(lowerCase(this.name));
	}

	private _getUniqId(): string {
		return Math
			.random()
			.toString(36)
			.slice(2, 10);
	}

	private _getCssClass(): string {

		/*
		 * TODO Angular CLI mangles the names of class constructors which is used for generating cssClass, check
		 * somewhere later
		 * return `${kebabCase(this.constructor.name)}-${kebabCase(this.name)}`;
		 */
		return kebabCase(this.name);
	}

	private _getValueName(): string {
		let result = '';

		forOwn(this.constructor, (it, key) => {
			if (it instanceof Enumeration && it._id === this._id && Number.isNaN(Number(key))) {
				result = key;

				return false;
			}

			return true;
		});

		return result;
	}
}
