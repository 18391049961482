import { ToastrModule } from 'ngx-toastr';

import { CommonModule } from '@angular/common';
import type { ModuleWithProviders } from '@angular/core';
import { NgModule } from '@angular/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { A11yModule } from '@angular/cdk/a11y';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { SharedDirectivesModule } from '@bp/shared/directives';
import { SharedFeaturesSvgIconsModule } from '@bp/shared/features/svg-icons';
import { SharedFeaturesTooltipModule } from '@bp/shared/features/tooltip';
import { SharedPipesModule } from '@bp/shared/pipes';
import { SharedFeaturesLottiePlayerModule } from '@bp/shared/features/lottie-player';

import { AlertComponent, AlertMessagesComponent, ErrorMessagesComponent } from './alert';
import { BurgerBtnComponent as BurgerButtonComponent, PendingBtnComponent as PendingButtonComponent, PendingIconBtnComponent as PendingIconButtonComponent } from './buttons';
import { CopyComponent } from './copy';
import { CountryComponent } from './country';
import { DatepickerCalendarHeaderComponent } from './datepicker-calendar-header';
import { FilterComponent, FilterControlDirective } from './filter';
import { HintComponent } from './hint';
import { ImgComponent, ImgErrorDirective } from './img';
import { CursorPageAdaptorDirective, PaginatorComponent } from './paginator';
import { PaymentCardBrandComponent } from './payment-card-brand';
import { StatusBarComponent, StatusBarContainerDirective } from './status-bar';
import { ToastComponent, TOAST_ANIMATION_CONFIG } from './toast';
import { AppVersionComponent } from './app-version';
import { AttentionDrawingDotComponent } from './attention-drawing-dot';
import { StatusBadgeComponent } from './status-badge';
import { FooterMockLinksComponent } from './footer-mock-links';
import { PlusMinusBtnComponent } from './plus-minus-btn';
import { SpinningLogoComponent } from './spinning-logo';
import { PictureComponent } from './picture';

const EXPOSED_MODULES = [
	MatMomentDateModule,
	A11yModule,
];

const EXPOSED_COMPONENTS = [
	AlertComponent,
	AlertMessagesComponent,
	ErrorMessagesComponent,
	PaginatorComponent,
	StatusBarComponent,
	FilterControlDirective,
	FilterComponent,
	CopyComponent,
	CountryComponent,
	PaymentCardBrandComponent,
	ImgComponent,
	ImgErrorDirective,
	AppVersionComponent,
	ToastComponent,
	DatepickerCalendarHeaderComponent,
	HintComponent,
	PendingIconButtonComponent,
	PendingButtonComponent,
	BurgerButtonComponent,
	AttentionDrawingDotComponent,
	StatusBadgeComponent,
	FooterMockLinksComponent,
	PlusMinusBtnComponent,
	SpinningLogoComponent,
	PictureComponent,
];

const EXPOSED_DIRECTIVES = [
	StatusBarContainerDirective,
	CursorPageAdaptorDirective,
];

const EXPOSED = [
	EXPOSED_COMPONENTS,
	EXPOSED_DIRECTIVES,
];

@NgModule({
	imports: [
		EXPOSED_MODULES,

		CommonModule,
		RouterModule,
		ToastrModule,
		MatSelectModule,

		MatButtonModule,
		MatIconModule,
		MatProgressSpinnerModule,
		MatProgressBarModule,
		MatRippleModule,

		SharedPipesModule,
		SharedDirectivesModule,
		SharedFeaturesSvgIconsModule,
		SharedFeaturesTooltipModule,
		SharedFeaturesLottiePlayerModule,
	],
	exports: [ EXPOSED, EXPOSED_MODULES ],
	declarations: EXPOSED,
})
export class SharedComponentsCoreModule {
	static forRoot(): ModuleWithProviders<SharedComponentsCoreModule> {
		return {
			ngModule: SharedComponentsCoreModule,
			providers: [
				ToastrModule.forRoot({
					toastComponent: ToastComponent,
					timeOut: 5000,
					preventDuplicates: true,
					includeTitleDuplicates: true,
					closeButton: true,
					resetTimeoutOnDuplicate: true,
					maxOpened: 5,
					progressBar: true,
					onActivateTick: true,
					easeTime: TOAST_ANIMATION_CONFIG.params.timing * 1000,
				}).providers!,
				{ provide: MAT_DATE_LOCALE, useValue: navigator.language || navigator.languages[0] },
			],
		};
	}
}
