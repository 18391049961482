import m from 'moment';
import { ToastrService } from 'ngx-toastr';
import type { Subscription } from 'rxjs';
import { firstValueFrom, lastValueFrom, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ApplicationRef, Injectable } from '@angular/core';
import type { UpdateAvailableEvent } from '@angular/service-worker';
import { SwUpdate } from '@angular/service-worker';

import { AsyncFlashSubject, ZoneService } from '@bp/shared/rxjs';

import { TelemetryService } from './telemetry';

/**
 * @description
 * 1. Checks for available ServiceWorker updates once instantiated.
 * 2. Re-checks every 5 mins.
 * 3. Whenever an update is available, it activates the update and shows a notification to update
 * 4. Logs each time PWA gets installed
 *
 */
@Injectable({
	providedIn: 'root',
})
export class ProgressiveWebAppService {

	private readonly _newVersionIsAvailable$ = new AsyncFlashSubject<true>();

	newVersionIsAvailable$ = this._newVersionIsAvailable$.asObservable();

	private readonly _checkNewVersionIntervalOnStartup = 5000;

	private readonly _checkNewVersionIntervalOnStartupTimeout = 1000 * 30;

	private readonly _checkNewVersionInterval = 1000 * 60 * 5; // Each 5 mins

	constructor(
		private readonly _app: ApplicationRef,
		private readonly _swUpdateService: SwUpdate,
		private readonly _toaster: ToastrService,
		private readonly _zoneService: ZoneService,
	) {
		this._logPWAInstalledEvent();

		this._logPWADisplayMode();

		this._forceReloadAppOnUnrecoverableStateError();
	}

	async listenForNewVersion(): Promise<void> {
		if (!this._swUpdateService.isEnabled)
			return;

		await this._whenOnStartupNewVersionAvailableForceAppReload();

		await this._whenUpdateAvailableActivateIt();

		this._newVersionIsAvailable$.complete(true);
	}

	reloadApp(): void {
		window.location.reload();
	}

	whenNewVersionAvailableReloadApp(): void {
		if (!this._swUpdateService.isEnabled)
			return;

		void this._zoneService.runOutsideAngular(async () => {
			await this._whenUpdateAvailableActivateIt();

			this.reloadApp();
		});
	}

	private async _whenOnStartupNewVersionAvailableForceAppReload(): Promise<void> {
		await this._whenAppIsStable();

		const activated = await this._whenNewVersionAvailableDuringStartupActivateIt();

		activated && this.reloadApp();
	}

	private async _whenUpdateAvailableActivateIt(): Promise<void> {
		const intervalCheckingSubscription = this._inIntervalCheckForUpdate(this._checkNewVersionInterval);

		await firstValueFrom(this._swUpdateService.available);

		intervalCheckingSubscription.unsubscribe();

		this._log('A new version is available, activating...');

		await this._swUpdateService.activateUpdate();

		this._log('The new version has been activated');
	}

	private _forceReloadAppOnUnrecoverableStateError(): void {
		this._swUpdateService.unrecoverable
			.subscribe(event => {
				TelemetryService.captureMessage(
					`An error occurred that we cannot recover from:\n${ event.reason }\n\n`,
				);

				this.reloadApp();
			});
	}

	private async _whenAppIsStable(): Promise<void> {
		await firstValueFrom(this._app.isStable);
	}

	private async _whenNewVersionAvailableDuringStartupActivateIt(): Promise<boolean> {
		this._log('Check a new version is available during startup activate it');

		const intervalCheckingSubscription = this._inIntervalCheckForUpdate(this._checkNewVersionIntervalOnStartup);

		const updateEvent: UpdateAvailableEvent | null = await lastValueFrom(
			this._swUpdateService.available
				.pipe(takeUntil(timer(this._checkNewVersionIntervalOnStartupTimeout))),
			{ defaultValue: null },
		);

		intervalCheckingSubscription.unsubscribe();

		if (!updateEvent) {
			this._log('No new version is available during startup');

			return false;
		}

		this._showNewVersionIsAvailableToast();

		await lastValueFrom(timer(3000)); // Time to read the message

		this._log('Activating the new version');

		await this._swUpdateService.activateUpdate();

		this._log('The new version has been activated');

		return true;
	}

	private _showNewVersionIsAvailableToast(): void {
		this._log('Show new version is available toast');

		this._toaster.info(
			'A new version is available. The page will be reloaded in a moment.',
			undefined,
			{ disableTimeOut: true, tapToDismiss: false },
		);
	}

	private _inIntervalCheckForUpdate(interval: number): Subscription {
		this._log(`Initiate check for update each ${ interval } ms`);

		return timer(0, interval)
			.subscribe(() => void this._checkForUpdate());
	}

	private async _checkForUpdate(): Promise<void> {
		this._log('Checking for update...');

		await this._swUpdateService.checkForUpdate();
	}

	private _log(message: string) {
		console.log(`%c[PWA][${ m().format('LLL') }]: ${ message }`, 'color:#fd720c;');
	}

	private _logPWAInstalledEvent() {
		window.addEventListener('appinstalled', () => void TelemetryService.captureMessage('PWA got installed'));
	}

	private _logPWADisplayMode() {
		if (window.matchMedia('(display-mode: standalone)').matches)
			TelemetryService.captureMessage('PWA is started as standalone');
	}
}
