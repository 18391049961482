import { createAction, props } from '@ngrx/store';

import type { Notification } from '../models';

export const close = createAction(
	'[Feature][Notifications hub] Close',
);

export const open = createAction(
	'[Feature][Notifications hub] Open',
);

export const updateItems = createAction(
	'[Feature][Notifications hub] Update Notifications',
	props<{ notifications: Notification[] }>(),
);

export const removeAllItems = createAction(
	'[Feature][Notifications hub] Remove All Notifications',
);

export const removeItem = createAction(
	'[Feature][Notifications hub] Remove Notification',
	props<{ notification: Notification }>(),
);

export const markAllItemsAsRead = createAction(
	'[Feature][Notifications hub] Mark All Notifications As Read',
);

export const setHasUnreadItems = createAction(
	'[Feature][Notifications hub] Set Has Unread Notifications',
	props<{ hasUnread: boolean }>(),
);

export const setHasImportantItems = createAction(
	'[Feature][Notifications hub] Set Has Important Notifications',
	props<{ hasImportant: boolean }>(),
);

export const setItemReadState = createAction(
	'[Feature][Notifications hub] Set Notification Read State',
	props<{ notification: Notification; isRead: boolean }>(),
);
