<div
	*ngIf="show"
	@slide
	(@slide.done)="onSlideAnimationEnd()"
	class="alert"
	[class.alert-with-border]="withBorder"
	[ngClass]="'alert-' + type"
>
	<button *ngIf="closable" type="button" class="btn-icon-close" mat-icon-button (click)="close()">
		<bp-svg-icon name="close"></bp-svg-icon>
	</button>

	<bp-error-messages *ngIf="errors" class="messages" [src]="errors" [showType]="showType"> </bp-error-messages>

	<bp-alert-messages *ngIf="messages" class="messages" [src]="messages"> </bp-alert-messages>

	<ng-content></ng-content>
</div>
