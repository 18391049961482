
import { Observable } from 'rxjs';
import { compact, isObject, isString, map, isNil } from 'lodash-es';

import { IValidationErrors, ValidationError, ValidationErrorTemplateVariables } from '../models';

export abstract class ErrorsTextsProviderBaseService {

	abstract renderFirstErrorTextAndObserveChanges(
		validationErrors: IValidationErrors,
		controlName?: number | string | null,
	): Observable<string>;

	protected _renderValidationErrorsAsTexts(
		validationErrors: IValidationErrors,
		controlName?: number | string | null,
	): string[] {

		/*
		 * In case if we have an error for the control but don't have
		 * predefined error msg for the error we get [undefined], thus we compact it
		 */
		return compact(
			map(validationErrors, (validationError, validatorName) => this._renderErrorText(
				validatorName,
				controlName,
				validationError,
			)),
		);
	}

	 protected _renderErrorText(
		validatorName: string,
		controlName?: number | string | null,
		validationError?: ValidationError,
	): string | null {
		if (isString(validationError))
			return validationError;

		const errorText = this._fetchErrorTextWithMasks(validatorName, controlName);

		return errorText && isObject(validationError)
			? this._substituteMasksIfAny(validationError, errorText)
			: errorText;
	}

	protected abstract _fetchErrorTextWithMasks(
		validatorName: string,
		controlName?: number | string | null,
	): string | null;

	protected _substituteMasksIfAny(
		validationErrorTemplateVariable: ValidationErrorTemplateVariables,
		errorTextWithMasks: string,
	): string {
		const masks = errorTextWithMasks.match(/\{\{\w+\}\}/gu);

		return masks
			? masks
				.map(mask => ({
					mask,
					maskValue: validationErrorTemplateVariable[
					<keyof ValidationErrorTemplateVariables>mask.replace(/\{\{|\}\}/ug, '')
					],
				}))
				.reduce(
					(errorText, { mask, maskValue }) => isNil(maskValue)
						? errorText
						: errorText.replace(mask, maskValue.toString()),
					errorTextWithMasks,
				)
			: errorTextWithMasks;
	}

}
