import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { MockUserEmail } from '@bp/shared-domains-identity';

import type { ILoginApiRequest } from '@bp/admins-shared/domains/identity';
import { IdentityFacade } from '@bp/admins-shared/domains/identity';

import { IntroPageStep } from '../models';

import { changeIntroPageStep } from './intro.actions';
import { getIntroPageStep } from './intro.selectors';

@Injectable({
	providedIn: 'root',
})
export class IntroFacade {
	step$ = this._store$.select(getIntroPageStep);

	pending$ = this._identityFacade.pending$;

	error$ = this._identityFacade.error$;

	constructor(private readonly _store$: Store, private readonly _identityFacade: IdentityFacade) {}

	login(credentials: ILoginApiRequest): void {
		this._identityFacade.login(credentials);
	}

	loginAsMockBackendUser(mockUserEmail: MockUserEmail): void {
		this.login({
			userName: mockUserEmail,
			password: 'demodemo',
		});
	}

	setStep(step: IntroPageStep): void {
		this._store$.dispatch(changeIntroPageStep({ step }));
	}

	gotoSendResetPasswordLinkPage(): void {
		this.setStep(IntroPageStep.sendResetPasswordLink);
	}

	gotoSendResetAuthenticatorAppLinkPage(): void {
		this.setStep(IntroPageStep.sendResetAuthenticatorAppLink);
	}

	gotoLoginPage(): void {
		this.setStep(IntroPageStep.login);
	}

	resetError(): void {
		this._identityFacade.resetError();
	}
}
