import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import { PickBy } from '@bp/shared/typings';

import { IdentityFacade } from '../state';
import { IIdentity, Permission } from '../models';

@Injectable({
	providedIn: 'root',
})
export class FeaturePermissionsService {
	private get _featurePermissions(): PickBy<IIdentity, 'featurePermissions'> | undefined {
		return this._identityFacade.user?.featurePermissions;
	}

	private get _user$(): Observable<IIdentity | null> {
		return this._identityFacade.user$;
	}

	constructor(private readonly _identityFacade: IdentityFacade<IIdentity>) {}

	hasAccess(permission: Permission): boolean {
		return !!this._featurePermissions?.has(permission);
	}

	hasAccess$(permission: Permission): Observable<boolean> {
		return this._user$.pipe(map(v => (v ? this.hasAccess(permission) : false)));
	}
}
