import { ILocalStorageServiceConfig } from 'angular-2-local-storage';

import { StorageService } from './storage.service';

export class SessionStorageService extends StorageService {

	constructor(config: Omit<ILocalStorageServiceConfig, 'storageType'>) {
		super({
			...config,
			storageType: 'sessionStorage',
		});
	}

}
