import type { NonFunctionProperties, NonFunctionPropertyNames, Typify } from '@bp/shared/typings';
import { hasIn, bpQueueMicrotask } from '@bp/shared/utilities';

import { PropertyMetadata } from './property-metadata';

type MetadataHost<TMetadataHostClass> = { getClassMetadata: () => ClassMetadata<TMetadataHostClass> };

export class ClassMetadata<TMetadataHostClass> {

	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	readonly properties: Typify<NonFunctionProperties<TMetadataHostClass>, PropertyMetadata> = <any>{};

	readonly values: PropertyMetadata[] = [];

	readonly keys: string[] = [];

	readonly defaultSortProperty?: PropertyMetadata;

	constructor(private readonly _metadataHost: MetadataHost<TMetadataHostClass>) {
		this._initReadonlyPropertiesAfterMetadataHasBeenBuiltAndSealInstance();
	}

	add(property: string, metadata: Partial<PropertyMetadata>): void {
		// @ts-expect-error we need to assign to the property index by string here but its readonly by nature
		const propertyMetadata = this.properties[property] = new PropertyMetadata({
			...this._getPropertyMetadataLookingUpAncestors(<any>property),
			...metadata,
			property,
		});

		this._trySetDefaultTableSortProperty(propertyMetadata);
	}

	get(propertyName: NonFunctionPropertyNames<TMetadataHostClass>): PropertyMetadata | null {
		return <PropertyMetadata | null> this.properties[propertyName] ?? null;
	}

	has(propertyName: NonFunctionPropertyNames<TMetadataHostClass>): boolean {
		return !!(<PropertyMetadata | null> this.properties[propertyName]);
	}

	private _getPropertyMetadataLookingUpAncestors(property: string): PropertyMetadata | null {
		return this.get(<any>property)
			?? this._getPrototypeClassMetadata()?._getPropertyMetadataLookingUpAncestors(property)
			?? null;
	}

	private _getPrototypeClassMetadata(): ClassMetadata<TMetadataHostClass> | undefined {
		const prototype = <MetadataHost<TMetadataHostClass>> Object.getPrototypeOf(this._metadataHost);

		return hasIn(prototype, 'getClassMetadata')
			? prototype.getClassMetadata()
			: undefined;
	}

	private _initReadonlyPropertiesAfterMetadataHasBeenBuiltAndSealInstance(): void {
		bpQueueMicrotask(() => {
			// @ts-expect-error we need to init the property here but ts readonly by nature
			this.properties = this._mergePrototypeAndClassPropertiesMetadata();

			// @ts-expect-error we need to init the property here but ts readonly by nature
			this.keys = Object.keys(this.properties);

			// @ts-expect-error we need to init the property here but ts readonly by nature
		 	this.values = Object.values(this.properties);

			// @ts-expect-error we need to init the property here but ts readonly by nature
			 this.defaultSortProperty ??= this._getPrototypeClassMetadata()?.defaultSortProperty;

			 Object.seal(this);
		});
	}

	private _mergePrototypeAndClassPropertiesMetadata(
	): Typify<NonFunctionProperties<TMetadataHostClass>, PropertyMetadata> {
		return {
			...this._getPrototypeClassMetadata()?._mergePrototypeAndClassPropertiesMetadata(),
			...this.properties,
		};
	}

	private _trySetDefaultTableSortProperty(propertyMetadata: PropertyMetadata): void {
		if (!propertyMetadata.table?.defaultSortField
			|| propertyMetadata.property === this.defaultSortProperty?.property)
			return;

		if (this.defaultSortProperty)
			throw new Error(`Only one property can be marked as the default sort field. Existing: ${ this.defaultSortProperty.property }; Attempting: ${ propertyMetadata.property }`);

		// @ts-expect-error we need to init the property here but ts readonly by nature
		this.defaultSortProperty = propertyMetadata;
	}
}
