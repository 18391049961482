import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { SharedComponentsCoreModule } from '@bp/shared/components/core';
import { SharedFeaturesTooltipModule } from '@bp/shared/features/tooltip';

import { PspThumbnailComponent } from './psp-thumbnail.component';

const EXPOSED = [ PspThumbnailComponent ];

@NgModule({
	imports: [
		CommonModule,
		SharedComponentsCoreModule,
		SharedFeaturesTooltipModule,
		MatIconModule,
	],
	exports: EXPOSED,
	declarations: EXPOSED,
})
export class PspThumbnailModule { }
