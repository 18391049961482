import { ToastrService } from 'ngx-toastr';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

import { FormBaseComponent } from '@bp/shared/components/core';
import { Validators } from '@bp/shared/features/validation';
import type { FormGroupConfig } from '@bp/shared/typings';
import { takeUntilDestroyed } from '@bp/shared/models/common';

import type { IResetPasswordApiRequest } from '@bp/admins-shared/domains/identity';

@Component({
	selector: 'bp-reset-password-form',
	templateUrl: './reset-password-form.component.html',
	styleUrls: [ './reset-password-form.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetPasswordFormComponent extends FormBaseComponent<IResetPasswordApiRequest> {

	@Input() email?: string;

	@Input() expiredPassword?: boolean;

	@Output() readonly login = new EventEmitter();

	override onSubmitShowInvalidInputsToast = false;

	constructor(
		fb: UntypedFormBuilder,
		cdr: ChangeDetectorRef,
		toaster: ToastrService,
	) {
		super(fb, cdr, toaster);

		this.form = this._formBuilder.group(<FormGroupConfig<IResetPasswordApiRequest>> {
			email: [ this.email, []],
			password: [ '', [ Validators.required, Validators.password() ]],
			confirmPassword: [ '', [ Validators.required, Validators.confirmPassword() ]],
		});

		this._onPasswordChangeRevalidateConfirmNewPassword();
	}

	private _onPasswordChangeRevalidateConfirmNewPassword(): void {
		this.controls!.password!.valueChanges
			.pipe(takeUntilDestroyed(this))
			.subscribe(() => void this.controls!.confirmPassword!.updateValueAndValidity());
	}

}
