/* eslint-disable max-classes-per-file */
import { CommonFeatureActions, FeatureAction, FeatureActionSymbol } from '../feature-actions';

export class TransactionsFeatureActions extends CommonFeatureActions {
	@FeatureActionSymbol
	refund!: FeatureAction;

	@FeatureActionSymbol
	checkStatus!: FeatureAction;

	@FeatureActionSymbol
	resendNotification!: FeatureAction;

	@FeatureActionSymbol
	chargeback!: FeatureAction;

	@FeatureActionSymbol
	void!: FeatureAction;

	@FeatureActionSymbol
	capture!: FeatureAction;
}

export class PayoutsFeatureActions extends CommonFeatureActions {
	@FeatureActionSymbol
	refund!: FeatureAction;

	@FeatureActionSymbol
	checkStatus!: FeatureAction;

	@FeatureActionSymbol
	resendNotification!: FeatureAction;

	@FeatureActionSymbol
	chargeback!: FeatureAction;
}

export class ReportsFeatureActions extends CommonFeatureActions {
	@FeatureActionSymbol
	preview!: FeatureAction;

	@FeatureActionSymbol
	export!: FeatureAction;
}

export class MembersFeatureActions extends CommonFeatureActions {
	@FeatureActionSymbol
	invite!: FeatureAction;

	@FeatureActionSymbol
	edit!: FeatureAction;
}
