import { uniq } from 'lodash-es';
import type { Moment } from 'moment';

import { Validators } from '@bp/shared/features/validation';
import type { PaymentCardBrand, PspPaymentOptionType } from '@bp/shared/models/business';
import type { ICurrency } from '@bp/shared/models/currencies';
import type { DTO } from '@bp/shared/models/metadata';
import {
	Control, Default, FieldControlType, FieldViewType, FirebaseEntity, Hint, Label, MapFromDTO,
	Mapper, Required, Table, Unserializable, Validator, View, ViewEmptyValue
} from '@bp/shared/models/metadata';
import type { NonFunctionPropertyNames } from '@bp/shared/typings';
import { isInstanceOf } from '@bp/shared/utilities';

import { FirebasePspIndustry } from './firebase-psp-industry';
import { FirebasePspLocation } from './firebase-psp-location';
import type { FirebasePspPaymentOptions } from './payment-options';
import { FirebasePspPaymentOptionCreditCard, firebasePspPaymentOptionsFactory } from './payment-options';
import { FirebasePspEnvironment } from './firebase-psp-environment';

export type FirebasePspKeys = NonFunctionPropertyNames<FirebasePsp>;

export type FirebasePspsByPaymentOptionTypeMap = Map<PspPaymentOptionType, FirebasePsp[]>;

export type FirebasePspByPspNameMap = Map<string, FirebasePsp>;

/**
 * Class defining an integrated provider into the bridgerpay system, where we keep general settings of this provider and
 * its information
 */
export class FirebasePsp extends FirebaseEntity {

	@Default(null)
	merchantPspId!: string | null;

	@Required()
	@Default(null)
	override name!: string;

	@Hint(
		'For matching the PSP with the internal system psp enum',
		'This field is used for matching this PSP with the corresponding internal system psp enum value',
	)
	@Required()
	@Validator(Validators.pascalCase)
	@Label('Backend Psp Enum Value')
	@Table({ ellipsis: true })
	@Default(null)
	internalName!: string | null;

	@MapFromDTO()
	connected!: boolean;

	@Label('Publicly Visible')
	@Default(false)
	@Hint('Visibility of the PSP on the promo website')
	@View(
		FieldViewType.booleanCircle,
		(publiclyVisible: boolean) => publiclyVisible ? 'Publicly Visible' : 'Not Publicly Visible',
	)
	@Table({ sortable: true })
	publiclyVisible!: boolean;

	@Hint('Shown on the popular section of the promo website')
	@Default(false)
	@Control(FieldControlType.switch)
	@View(FieldViewType.boolean)
	popular!: boolean;

	@Required()
	@Control(FieldControlType.textarea)
	description!: string;

	@Required()
	@Validator(Validators.url)
	@View(FieldViewType.link)
	websiteUrl!: string;

	@Required()
	@Label('Thumbnail Logo')
	@View(FieldViewType.thumbnail)
	@Table()
	logoUrl!: string;

	@Required()
	@Label('Logo')
	@View(FieldViewType.image)
	fullLogoUrl!: string;

	@Validator(Validators.requiredArray)
	@Mapper(firebasePspPaymentOptionsFactory)
	@Default([])
	@Table()
	paymentOptions!: FirebasePspPaymentOptions[];

	@Table({ sortable: true })
	override updatedBy!: string;

	@Label('Updated')
	@View(FieldViewType.momentFromNow)
	@Table({ sortable: true, defaultSortField: true })
	override updatedAt!: Moment | null;

	@Required()
	@Control(FieldControlType.chip, {
		list: FirebasePspLocation.getList(),
		placeholder: 'Add location...',
	})
	@View(FieldViewType.chip)
	@Mapper(FirebasePspLocation)
	@Default([])
	locations!: FirebasePspLocation[];

	@Required()
	@Control(FieldControlType.chip, {
		list: FirebasePspIndustry.getList(),
		placeholder: 'Add industry...',
	})
	@View(FieldViewType.chip)
	@Mapper(FirebasePspIndustry)
	@Default([])
	industries!: FirebasePspIndustry[];

	@Default([])
	@Control(FieldControlType.chip, {
		list: FirebasePspEnvironment.getList(),
		placeholder: 'Add environment...',
	})
	@Mapper(FirebasePspEnvironment)
	@ViewEmptyValue('non-integrated')
	@Table({ sortable: true })
	environments!: FirebasePspEnvironment[];

	@Unserializable()
	readonly lowerCaseName!: string;

	@Unserializable()
	readonly lowerCaseInternalName!: string | null;

	@View(FieldViewType.chip)
	@Unserializable()
	readonly paymentOptionsTypes: PspPaymentOptionType[];

	@Unserializable()
	readonly paymentOptionByTypeMap: Map<PspPaymentOptionType, FirebasePspPaymentOptions>;

	/** Used for filtering this psp */
	@Unserializable()
	readonly paymentOptionsCurrencies: ICurrency[] = [];

	/** Used for filtering this psp */
	@Unserializable()
	readonly paymentCardBrands: PaymentCardBrand[] = [];

	constructor(dto?: DTO<FirebasePsp>) {
		super(dto);

		if (this.id) {
			this.lowerCaseName = this.name.toLowerCase();

			this.lowerCaseInternalName = this.internalName?.toLowerCase() ?? null;
		}

		this.paymentOptionsTypes = this.paymentOptions.map(option => option.type);

		this.paymentOptionByTypeMap = new Map(this.paymentOptions.map(option => [ option.type, option ]));

		this.paymentOptionsCurrencies = this._aggregateCurrencies();

		this.paymentCardBrands = this._extractPaymentCardBrands();

		this.environments = this.environments
			.sort((a, b) => FirebasePspEnvironment.getList().indexOf(a) - FirebasePspEnvironment.getList().indexOf(b));
	}

	private _aggregateCurrencies(): ICurrency[] {
		return uniq(
			this.paymentOptions.flatMap(option => <ICurrency[]> option.currencies),
		);
	}

	private _extractPaymentCardBrands(): PaymentCardBrand[] {
		return this.paymentOptions
			.filter(isInstanceOf(FirebasePspPaymentOptionCreditCard))
			.flatMap(option => option.brands);
	}

	override toString(): string {
		return this.internalName ?? '';
	}

}
