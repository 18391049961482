import { assign } from 'lodash-es';

import type { SortDirection } from '@angular/material/sort';

import type { FieldViewType } from './enums';

export class PropertyMetadataTable {

	/**
	 * Overrides view type set on the property view metadata
	 */
	readonly viewType: FieldViewType | null = null;

	readonly viewFormatter: ((propertyValue: any) => any) | null = null;

	readonly viewEmptyValue: number | string | null = null;

	/**
	 * The header for this column is spanned with the next column header
	 */
	readonly headless: boolean = false;

	/**
	 * The column is always present on the table, can't be unselected under the show more btn
	 */
	readonly alwaysShown: boolean = false;

	/**
	 * By default the column is not shown, but can be selected under the show more button
	 */
	readonly optional: boolean = false;

	readonly sortable: boolean = false;

	readonly defaultSortField: boolean = false;

	readonly defaultSortDir: SortDirection = 'desc';

	/**
	 * Ellipsis the content and adds a tooltip
	 */
	readonly ellipsis: boolean = false;

	constructor(data?: Partial<PropertyMetadataTable>) {
		assign(this, data);
	}

}
