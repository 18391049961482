import type { MetadataEntity } from '../metadata-entity';

import { Property } from './property-metadata.decorator';

/**
 * On construction a value for the property is taken from DTO[alias]
 * On deserialization the json will contain an alias property with a value equal the instance property value
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export function Alias(alias: string) {
	return function(model: MetadataEntity, property: string): void {
		Property({ alias })(model, property);
	};
}
