<bp-layout [@routeHitAnimations]="shouldAnimateOutlet" (@routeHitAnimations.done)="shouldAnimateOutlet = false">
	<bp-svg-payment-card-brands-icons-definitions></bp-svg-payment-card-brands-icons-definitions>

	<bp-right-drawers-orchestrator></bp-right-drawers-orchestrator>

	<bp-notifications-hub></bp-notifications-hub>

	<bp-modal-outlet>
		<router-outlet name="modal"></router-outlet>
	</bp-modal-outlet>

	<bp-menu></bp-menu>

	<bp-toolbar
		*ngIf="identityFacade.userIsLoggedIn$ | async"
		[userName]="identityFacade.user!.email"
		(logout)="identityFacade.confirmLogout()"
		(clickMenu)="layoutFacade.openSidenav()"
	>
		<div class="ng-content-toolbar-right-section">
			<bp-notifications-hub-bell-btn></bp-notifications-hub-bell-btn>
		</div>
	</bp-toolbar>

	<router-outlet (activate)="shouldAnimateOutlet = true"></router-outlet>

	<bp-footer></bp-footer>
</bp-layout>
