import { ChangeDetectionStrategy, Component } from '@angular/core';

import { LayoutFacade } from '../../state';

@Component({
	selector: 'bp-sidenav',
	templateUrl: './sidenav.component.html',
	styleUrls: [ './sidenav.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavComponent {

	constructor(public layoutFacade: LayoutFacade) { }

}
