import type { MetadataEntity } from '../metadata-entity';
import type { PropertyMapper } from '../property-metadata';

import { Property } from './property-metadata.decorator';

/**
 * The mapper function is gonna be invoked only if the DTO's property's value is not null or undefined
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export function Mapper(mapper: PropertyMapper) {
	return function propertyMapper(model: MetadataEntity, property: string): void {
		Property({ mapper })(model, property);
	};
}
