import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { toggleableStateKeptInSessionStorageFactory, TRUE } from './toggleable-state-kept-in-session-storage-factory';

const MOCKED_BACKEND_IS_ACTIVE_STORAGE_KEY = 'mocked-backend-is-active';
const DEMO_MODE_IS_ACTIVE_STORAGE_KEY = 'demo-mode-is-active';

export class MockedBackendState extends toggleableStateKeptInSessionStorageFactory(MOCKED_BACKEND_IS_ACTIVE_STORAGE_KEY) {

	private static readonly _isApiMockPluginsIniting$ = new BehaviorSubject(false);

	static isApiMockPluginsIniting$ = MockedBackendState._isApiMockPluginsIniting$.asObservable();

	static isNoApiMockPluginsIniting$ = MockedBackendState._isApiMockPluginsIniting$.pipe(map(v => !v));

	static get isDemoMode(): boolean {
		return this.isActive && sessionStorage.getItem(DEMO_MODE_IS_ACTIVE_STORAGE_KEY) === TRUE;
	}

	static get isActiveAndNotDemoMode(): boolean {
		return this.isActive && !this.isDemoMode;
	}

	static get isApiMockPluginsIniting(): boolean {
		return this._isApiMockPluginsIniting$.value;
	}

	static apiMockPluginsInitStarted(): void {
		this._isApiMockPluginsIniting$.next(true);
	}

	static apiMockPluginsInitFinished(): void {
		this._isApiMockPluginsIniting$.next(false);
	}

	static reloadInDemoMode(): void {
		if (MockedBackendState.isDemoMode)
			return;

		sessionStorage.setItem(DEMO_MODE_IS_ACTIVE_STORAGE_KEY, TRUE);

		this.setAndReloadPage(true);
	}

	static reloadInMockMode(): void {
		if (MockedBackendState.isActive)
			return;

		this.setAndReloadPage(true);
	}

	static reloadInNormalMode(): void {
		if (!MockedBackendState.isDemoMode)
			return;

		sessionStorage.removeItem(DEMO_MODE_IS_ACTIVE_STORAGE_KEY);

		this.setAndReloadPage(false);
	}
}
