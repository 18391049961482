import type { Action, ActionReducer } from '@ngrx/store';

import { IDENTITY_ACTIONS } from './identity.actions';

/**
 * This reducer on removed user clears all the apps state
 */
export function resetAppStateOnRemovedIdentityMetaReducer<T, V extends Action = Action>(
	reducer: ActionReducer<T, V>,
): ActionReducer<T, V> {
	return (state, action: V): T => reducer(
		action.type === IDENTITY_ACTIONS.removeIdentity.type ? undefined : state,
		action,
	);
}
