import { Enumeration } from '@bp/shared/models/core/enum';

export class BridgerPspPaymentOptionCreditCardSecurityProtocol extends Enumeration {

	static twoDSecure = new BridgerPspPaymentOptionCreditCardSecurityProtocol('2D');

	static threeDSecure = new BridgerPspPaymentOptionCreditCardSecurityProtocol('3D');

	static threeDSecureV2 = new BridgerPspPaymentOptionCreditCardSecurityProtocol('3Dv2');

}
