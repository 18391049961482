import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import type { CountryCode } from '@bp/shared/models/countries';
import { Countries, Country } from '@bp/shared/models/countries';
import { attrBoolValue } from '@bp/shared/utilities';

@Component({
	selector: 'bp-country',
	templateUrl: './country.component.html',
	styleUrls: [ './country.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountryComponent implements OnInit {

	@Input()
	get src(): Country | CountryCode | Object | string {
		return this._src;
	}

	set src(value: Country | CountryCode | Object | string) {
		this._src = value;

		this.country = this._parseCountrySource();

		this.countryIsWorldwide = this.country === Countries.worldwide;
	}

	private _src!: Country | CountryCode | Object | string;

	@Input() compact!: boolean | '';

	@Input() hideTooltip!: boolean | '';

	@Input() round!: boolean | '';

	@Input() worldwide!: boolean | '';

	country!: Country | null;

	countryIsWorldwide!: boolean;

	ngOnInit() {
		this.worldwide = attrBoolValue(this.worldwide);

		this.compact = attrBoolValue(this.compact);

		this.hideTooltip = attrBoolValue(this.hideTooltip);

		this.round = attrBoolValue(this.round);
	}

	private _parseCountrySource(): Country | null {
		return this.src instanceof Country
			? this.src
			: Countries.findByCodeString(<string> this.src.valueOf());
	}
}
