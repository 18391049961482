import { createAction, props } from '@ngrx/store';

import { IDENTITY_STATE_SCOPE as SCOPE } from '@bp/shared-domains-identity';

import type { IdentityPreferences } from '../../models';

export const updateIdentityPreferences = createAction(
	`${ SCOPE } Update Identity Preferences`,
	props<{ identityPreferences: IdentityPreferences }>(),
);

export const localStorageIdentityPreferencesChanged = createAction(
	`${ SCOPE } Local Storage Identity Preferences Changed`,
	props<{ identityPreferences: IdentityPreferences }>(),
);
