import { timer } from 'rxjs';
import { map, takeWhile } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { BaseDialogComponent } from '@bp/shared/components/dialogs';
import { takeUntilDestroyed } from '@bp/shared/models/common';

const TIMEOUT_BEFORE_LOGOUT = 15;

@Component({
	selector: 'bp-identity-session-is-about-to-expire-dialog',
	templateUrl: './identity-session-is-about-to-expire-dialog.component.html',
	styleUrls: [ './identity-session-is-about-to-expire-dialog.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IdentitySessionIsAboutToExpireDialogComponent extends BaseDialogComponent {

	countDown$ = timer(0, 1000)
		.pipe(
			map(passed => TIMEOUT_BEFORE_LOGOUT - passed),
			takeWhile(it => it >= 0),
		);

	constructor(public dialog: MatDialogRef<IdentitySessionIsAboutToExpireDialogComponent>) {
		super(dialog, null);

		this.countDown$
			.pipe(takeUntilDestroyed(this))
			.subscribe({
				// One more second to give a last chance to continue
				complete: () => setTimeout(() => void this.dialog.close(), 1000),
			});
	}

	canCloseWithTrueOnEnter(): boolean {
		return true;
	}
}
