export const TRUE = 'true';

export type ToggleableStateKeptInSessionCtor = (new () => Object) & {
	isActive: boolean;
	toggle: () => void;
	toggleAndReloadPage: () => void;
	set: (state: boolean) => void;
	setAndReloadPage: (state: boolean) => void;
};

export function toggleableStateKeptInSessionStorageFactory(sessionStorageKey: string): ToggleableStateKeptInSessionCtor {

	class ToggleableStateKeptInSessionStorage {

		protected static get _storage(): Storage {
			return sessionStorage;
		}

		static get isActive(): boolean {
			return this._storage.getItem(sessionStorageKey) === TRUE;
		}

		static toggle(): void {
			this._toggle(null, false);
		}

		static toggleAndReloadPage(): void {
			this._toggle(null, true);
		}

		static set(state: boolean): void {
			this._toggle(state, false);
		}

		static setAndReloadPage(state: boolean): void {
			this._toggle(state, true);
		}

		private static _toggle(state: boolean | null, reloadPage: boolean): void {
			const nextIsActiveState = state ?? !this.isActive;

			if (nextIsActiveState === this.isActive)
				return;

			nextIsActiveState
				? this._storage.setItem(sessionStorageKey, TRUE)
				: this._storage.setItem(sessionStorageKey, 'false');

			reloadPage && window.location.reload();
		}

	}

	return ToggleableStateKeptInSessionStorage;
}
