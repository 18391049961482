<form *ngIf="form" [formGroup]="form" (ngSubmit)="submit()">
	<bp-alert [errors]="errors" type="danger"> </bp-alert>

	<div class="inputs-wrapper">
		<bp-input label="Email" nativeAutocomplete="email" formControlName="userName" required></bp-input>

		<bp-password-input
			required
			label="Password"
			nativeAutocomplete="current-password"
			formControlName="password"
		></bp-password-input>
	</div>

	<button type="submit" class="bp-round-button mt-auto" mat-flat-button color="primary" [disabled]="form.disabled">
		Login
	</button>

	<ng-container *ngIf="showOAuthProviders">
		<div *ngIf="googleOAuthButton.enabled" class="or-separator mat-body-2 text-center mat-hint">OR</div>

		<bp-google-oauth-button
			#googleOAuthButton
			class="mb-2 flex-shrink-0"
			(identityChange)="googleIdentityChange.emit($event)"
		></bp-google-oauth-button>
	</ng-container>

	<div class="aux-buttons">
		<button type="button" class="link" [disabled]="form.disabled" (click)="sendResetPasswordLink.emit()">
			Forgot password?
		</button>
	</div>
</form>
