import {
	distinctUntilChanged,
	exhaustMap,
	filter,
	first,
	map,
	skip,
	switchMap,
	tap,
	withLatestFrom
} from 'rxjs/operators';

import type { MatDialog } from '@angular/material/dialog';
import type { Router } from '@angular/router';

import type { Actions } from '@ngrx/effects';
import { createEffect, ofType } from '@ngrx/effects';

import { LogoutConfirmDialogComponent } from '@bp/shared/components/dialogs';
import { apiResult } from '@bp/shared/models/common';
import { isEqual } from '@bp/shared/utilities';
import { RouterService } from '@bp/shared/services';

import { IIdentity, PERMISSION_BASED_REDIRECTION_ON_NO_ACCESS_TOKEN } from '../models';
import type { IIdentityApiService } from '../services/identity-api.service.interface';

import type { IdentityActions } from './identity.actions';
import type { IdentityFacade } from './identity.facade';

export abstract class IdentityEffects<TIdentity extends IIdentity, TLoginPayload = undefined> {
	get actions(): IdentityActions<TIdentity, TLoginPayload> {
		return this._identityFacade.actions;
	}

	login$ = createEffect(() => this._actions$.pipe(
		ofType(this.actions.login),
		exhaustMap(({ payload }) => this._identityApiService
			.login(payload)
			.pipe(apiResult(this.actions.api.loginSuccess, this.actions.api.loginFailure))),
	));

	onLoginSuccessSetIdentity$ = createEffect(
		() => this._actions$.pipe(
			ofType(this.actions.api.loginSuccess),
			tap(({ result }) => void this._identityFacade.setIdentity(result)),
		),
		{ dispatch: false },
	);

	onLogoutConfirmIdentityLogout$ = createEffect(() => this._actions$.pipe(
		ofType(this.actions.confirmLogout),
		exhaustMap(() => this._dialog
			.open<LogoutConfirmDialogComponent, undefined, boolean>(LogoutConfirmDialogComponent)
			.afterClosed()),
		map(result => (result ? this.actions.logout() : this.actions.dismissLogoutConfirmation())),
	));

	whenIdentityAuthorizedNavigateToApp$ = createEffect(() => this._identityFacade.userLoggedIn$.pipe(
		switchMap(() => this._identityFacade.userPresent$.pipe(first())),
		withLatestFrom(this._identityFacade.urlForRedirectionAfterLogin$),
		map(([ , urlForRedirectionAfterLogin ]) => this.actions.navigateToApp({ urlForRedirectionAfterLogin })),
	));

	navigateToApp$ = createEffect(
		() => this._actions$.pipe(
			ofType(this.actions.navigateToApp),
			tap(
				({ urlForRedirectionAfterLogin }) => void this._router.navigateByUrl(
					urlForRedirectionAfterLogin ? urlForRedirectionAfterLogin : '/',
					{
						state: PERMISSION_BASED_REDIRECTION_ON_NO_ACCESS_TOKEN,
					},
				),
			),
		),
		{ dispatch: false },
	);

	onPermissionsChangeRunRouteGuards = this._identityFacade.userPresent$
		.pipe(
			map(user => user.featurePermissions),
			distinctUntilChanged(isEqual),
			skip(1), // skip the first initial change
			filter(() => !this._routerService.isNavigationInProcess),
		)
		.subscribe(() => void this._router.navigateByUrl(this._router.url));

	constructor(
		protected readonly _identityFacade: IdentityFacade<TIdentity, TLoginPayload>,
		protected readonly _identityApiService: IIdentityApiService<TIdentity, TLoginPayload>,
		protected readonly _actions$: Actions,
		protected readonly _dialog: MatDialog,
		protected readonly _router: Router,
		protected readonly _routerService: RouterService,
	) {}
}
