import { escapeRegExp } from 'lodash-es';

/**
 * Case insensitive search for the substring inside the search term
 */
export function searchIgnoringCase(target: string, search: string): boolean {
	if (!target || !search)
		return false;

	return target.search(new RegExp(escapeRegExp(search), 'ui')) !== -1;
}

/**
 * Case insensitive exact match to the search term
 */
export function matchIgnoringCase(target: string, search: string): boolean {
	if (!target || !search)
		return false;

	return target.search(new RegExp(`^${ escapeRegExp(search) }$`, 'ui')) !== -1;
}
