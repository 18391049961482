import { createAction, props } from '@ngrx/store';

import { ErrorActionPayload, ResultActionPayload } from '@bp/shared/models/common';
import { TELEMETRY_HIDDEN_MARK } from '@bp/shared/models/core';

import { composeIdentityApiActions, IDENTITY_STATE_API_SCOPE as API_SCOPE } from '@bp/shared-domains-identity';

import { GenerateOtpApiResponse, Identity, SecurityQuestion } from '../models';

export const IDENTITY_API_ACTIONS = composeIdentityApiActions<Identity>();

export const refreshTokenSuccess = createAction(
	`${ API_SCOPE } Refresh Access Token Success`,
	props<ResultActionPayload<Identity>>(),
);

export const refreshTokenFailure = createAction(
	`${ API_SCOPE } Refresh Access Token Failure`,
	props<ErrorActionPayload>(),
);

export const generateLoginOtpSuccess = createAction(
	`${ API_SCOPE } Generate Login OTP Success`,
	props<ResultActionPayload<GenerateOtpApiResponse>>(),
);

export const generateLoginOtpFailure = createAction(
	`${ API_SCOPE } Generate Login OTP Failure`,
	props<ErrorActionPayload>(),
);

export const loginOtpVerificationSuccess = createAction(
	`${ API_SCOPE } Login OTP Verification Success`,
	props<ResultActionPayload<Identity>>(),
);

export const loginOtpVerificationFailure = createAction(
	`${ API_SCOPE } Login OTP Verification Failure`,
	props<ErrorActionPayload>(),
);

export const generateResourceAccessOtpSuccess = createAction(
	`${ API_SCOPE } Generate Resource Access OTP Success`,
	props<ResultActionPayload<GenerateOtpApiResponse>>(),
);

export const generateResourceAccessOtpFailure = createAction(
	`${ API_SCOPE } Generate Resource Access OTP Failure`,
	props<ErrorActionPayload>(),
);

export const resourceAccessOtpVerificationSuccess = createAction(
	`${ API_SCOPE } Resource Access OTP Verification Success`,
	props<ResultActionPayload<Identity>>(),
);

export const resourceAccessOtpVerificationFailure = createAction(
	`${ API_SCOPE } Resource Access OTP Verification Failure`,
	props<ErrorActionPayload>(),
);

// #region SECTION Signup via link

export const acceptInviteSuccess = createAction(
	`${ API_SCOPE } Accept Invite Success`,
	props<ResultActionPayload<Identity>>(),
);

export const acceptInviteFailure = createAction(
	`${ API_SCOPE } Accept Invite Failure`,
	props<ErrorActionPayload>(),
);

export const createAccountSuccess = createAction(
	`${ API_SCOPE } Create Account Success`,
	props<ResultActionPayload<Identity>>(),
);

export const createAccountFailure = createAction(
	`${ API_SCOPE } Create Account Failure`,
	props<ErrorActionPayload>(),
);

// #endregion !SECTION

// #region deprecated, remove after accepting invite implementation by backend

export const verifyEmailSuccess = createAction(
	`${ API_SCOPE } Verify Email Success`,
	props<ResultActionPayload<Identity>>(),
);

export const verifyEmailFailure = createAction(
	`${ API_SCOPE } Verify Email Failure`,
	props<ErrorActionPayload>(),
);

export const createPasswordSuccess = createAction(
	`${ API_SCOPE } Create Password Success`,
	props<ResultActionPayload<Identity>>(),
);

export const createPasswordFailure = createAction(
	`${ API_SCOPE } Create Password Failure`,
	props<ErrorActionPayload>(),
);

// #endregion !deprecated

// #region SECTION Continue Signup

export const loadAllSecurityQuestionsSuccess = createAction(
	`${ API_SCOPE } Load All Security Questions Success`,
	props<ResultActionPayload<SecurityQuestion[]>>(),
);

export const loadAllSecurityQuestionsFailure = createAction(
	`${ API_SCOPE } Load All Security Questions Failure`,
	props<ErrorActionPayload>(),
);

export const setSecurityQuestionsAnswersSuccess = createAction(
	`${ API_SCOPE } Set Security Questions Answers Success`,
	props<ResultActionPayload<Identity>>(),
);

export const setSecurityQuestionsAnswersFailure = createAction(
	`${ API_SCOPE } Set Security Questions Answers Failure`,
	props<ErrorActionPayload>(),
);

export const loadAuthenticatorAppKeySuccess = createAction(
	`${ API_SCOPE } Load Authenticator App Key Success ${ TELEMETRY_HIDDEN_MARK }`,
	props<ResultActionPayload<string>>(),
);

export const loadAuthenticatorAppKeyFailure = createAction(
	`${ API_SCOPE } Load Authenticator App Key Failure`,
	props<ErrorActionPayload>(),
);

export const registerAuthenticatorSuccess = createAction(
	`${ API_SCOPE } Register Authenticator Success`,
	props<ResultActionPayload<Identity>>(),
);

export const registerAuthenticatorFailure = createAction(
	`${ API_SCOPE } Register Authenticator Failure`,
	props<ErrorActionPayload>(),
);

// #endregion !SECTION

// #region SECTION Reset

export const loadIdentitySecurityQuestionsSuccess = createAction(
	`${ API_SCOPE } Load Identity Security Questions Success`,
	props<ResultActionPayload<SecurityQuestion[]>>(),
);

export const loadIdentitySecurityQuestionsFailure = createAction(
	`${ API_SCOPE } Load Identity Security Questions Failure`,
	props<ErrorActionPayload>(),
);

export const verifySecurityQuestionsAnswersSuccess = createAction(
	`${ API_SCOPE } Verify Security Questions Answers Success`,
	props<ResultActionPayload<Identity>>(),
);

export const verifySecurityQuestionsAnswersFailure = createAction(
	`${ API_SCOPE } Verify Security Questions Answers Failure`,
	props<ErrorActionPayload>(),
);

// #region SECTION Reset Password

export const sendResetPasswordLinkSuccess = createAction(`${ API_SCOPE } Send Reset Password Link Success`);

export const sendResetPasswordLinkFailure = createAction(
	`${ API_SCOPE } Send Reset Password Link Failure`,
	props<ErrorActionPayload>(),
);

export const resetPasswordOtpVerificationSuccess = createAction(
	`${ API_SCOPE } Reset Password Otp Verification Success`,
	props<ResultActionPayload<Identity>>(),
);

export const resetPasswordOtpVerificationFailure = createAction(
	`${ API_SCOPE } Reset Password Otp Verification Failure`,
	props<ErrorActionPayload>(),
);

export const resetPasswordSuccess = createAction(
	`${ API_SCOPE } Reset Password Success`,
	props<ResultActionPayload<Identity>>(),
);

export const resetPasswordFailure = createAction(`${ API_SCOPE } Reset Password Failure`, props<ErrorActionPayload>());

// #endregion !SECTION

// #region SECTION Reset Authenticator App

export const sendResetAuthenticatorAppLinkSuccess = createAction(
	`${ API_SCOPE } Send Reset Authenticator App Link Success`,
);

export const sendResetAuthenticatorAppLinkFailure = createAction(
	`${ API_SCOPE } Send Reset Authenticator App Link Failure`,
	props<ErrorActionPayload>(),
);

export const resetAuthenticatorAppSuccess = createAction(
	`${ API_SCOPE } Reset Authenticator App Success`,
	props<ResultActionPayload<Identity>>(),
);

export const resetAuthenticatorAppFailure = createAction(
	`${ API_SCOPE } Reset Authenticator App Failure`,
	props<ErrorActionPayload>(),
);

// #endregion !SECTION

// #endregion !SECTION
