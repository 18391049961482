import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

import { Permission } from '../models';

import { HasAccessBaseDirective } from './has-access-base.directive';

@Directive({
	selector: '[bpCanEditGuard]',
	exportAs: 'canEditGuard',
	host: {
		'[class.can-edit]': 'hasAccess',
	},
})
export class CanEditGuardDirective extends HasAccessBaseDirective {
	@Input()
	set bpCanEditGuard(permission: Permission) {
		this._observeUserAccessToPermission(permission);
	}

	@Output() readonly bpCanEditGuardClick = new EventEmitter<void>();

	@HostListener('click')
	onClick(): void {
		this._hasAccess$.value && this.bpCanEditGuardClick.emit();
	}
}
