import { Enumeration } from '@bp/shared/models/core/enum';

export class FirebasePspEnvironment extends Enumeration {

	static staging = new FirebasePspEnvironment();

	static sandbox = new FirebasePspEnvironment();

	static production = new FirebasePspEnvironment();

}
