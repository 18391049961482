import type { OnDestroy, Type } from '@angular/core';
import { Injectable, InjectionToken } from '@angular/core';

import { HttpConfigService } from './http';
import { MockedBackendState } from './state-keepers';

export const API_MOCK_SERVER_FACTORY = new InjectionToken('api_mock');

export interface IApiMockPlugin {

	init: (urlPrefix: string) => void;

	shutdown: () => void;

}

type ApiMockPluginModuleImport = () => Promise<{ default: Type<IApiMockPlugin> }>;

@Injectable({ providedIn: 'root' })
export class ApiMockPluginsManagerService implements OnDestroy {

	private _lastLoadedApiMockPlugin?: IApiMockPlugin;

	private _initingApiMockPluginsCounter = 0;

	constructor(
		private readonly _httpConfig: HttpConfigService,
	) { }

	async register(apiMockPluginImportPromise: ApiMockPluginModuleImport): Promise<void> {
		if (!MockedBackendState.isActive)
			return;

		this._initingApiMockPluginsCounter++;

		MockedBackendState.apiMockPluginsInitStarted();

		const { default: apiMockPlugin } = await apiMockPluginImportPromise();

		this._lastLoadedApiMockPlugin = new apiMockPlugin();

		this._lastLoadedApiMockPlugin.init(this._httpConfig.backendBaseSegment);

		this._initingApiMockPluginsCounter--;

		if (this._initingApiMockPluginsCounter === 0)
			MockedBackendState.apiMockPluginsInitFinished();
	}

	ngOnDestroy(): void {
		this._lastLoadedApiMockPlugin?.shutdown();
	}

}
