import type { Observable } from 'rxjs';
import { filter, map } from 'rxjs';

import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';

import type { DTO } from '@bp/shared/models/metadata';
import { filterPresent } from '@bp/shared/rxjs';

import { IdentityFacade as IdentityBaseFacade } from '@bp/shared-domains-identity';

import { Identity } from '../models';
import type {
	ILoginApiRequest,
	CreateAccountApiRequest,
	ISetSecurityQuestionsAnswersApiRequest,
	IRegisterAuthenticatorAppApiRequest,
	ISendResetPasswordLinkApiRequest,
	IResetPasswordApiRequest,
	IResetPasswordOtpVerificationApiRequest,
	ISecurityQuestionAnswerApiRequest,
	ILoginOtpVerificationApiRequest,
	IResourceAccessOtpVerificationApiRequest
} from '../models';

import {
	createAccount,
	IDENTITY_ACTIONS,
	loadAllSecurityQuestions,
	loadAuthenticatorAppKey,
	loadIdentitySecurityQuestions,
	registerAuthenticatorApp,
	resetAuthenticatorApp,
	resetPassword,
	resetPasswordOtpVerification,
	sendResetAuthenticatorAppLink,
	sendResetPasswordLink,
	setSecurityQuestionsAnswers,
	showIdentitySessionIsAboutToExpireDialog,
	loginOtpVerification,
	verifySecurityQuestionsAnswers,
	resourceAccessOtpVerification,
	refreshAccessToken,
	generateLoginOtp,
	generateResourceAccessOtp,
	acceptInvite,
	verifyEmail,
	createPassword
} from './identity.actions';
import {
	IDENTITY_SELECTORS,
	selectAllSecurityQuestions,
	selectAuthenticatorKey,
	selectIdentitySecurityQuestions,
	selectIncompleteIdentity,
	selectOtpExpiresAt,
	selectOtpProvider
} from './identity.selectors';
import {
	createAccountSuccess,
	registerAuthenticatorSuccess,
	resetAuthenticatorAppSuccess,
	resetPasswordSuccess,
	setSecurityQuestionsAnswersSuccess,
	loginOtpVerificationSuccess,
	resourceAccessOtpVerificationSuccess,
	refreshTokenSuccess,
	acceptInviteSuccess,
	verifyEmailSuccess,
	createPasswordSuccess
} from './identity-api.actions';
import { setIncompleteIdentity } from './incomplete-identity.actions';

@Injectable({ providedIn: 'root' })
export class IdentityFacade extends IdentityBaseFacade<Identity, ILoginApiRequest> {
	readonly actions = IDENTITY_ACTIONS;

	readonly selectors = IDENTITY_SELECTORS;

	/**
	 * Happens when user got inside the platform from the intro pages
	 */
	override readonly userLoggedIn$: Observable<Identity> = this._actions$.pipe(
		ofType(
			this.actions.api.loginSuccess,
			acceptInviteSuccess,
			createAccountSuccess,
			verifyEmailSuccess,
			createPasswordSuccess,
			setSecurityQuestionsAnswersSuccess,
			registerAuthenticatorSuccess,
			resetPasswordSuccess,
			loginOtpVerificationSuccess,
			resetAuthenticatorAppSuccess,
		),
		filter(({ result }) => !result.isIncomplete),
		map(({ result }) => result),
	);

	incompleteIdentity$ = this._store$.select(selectIncompleteIdentity);

	incompleteIdentity!: Identity | null;

	allSecurityQuestions$ = this._store$.select(selectAllSecurityQuestions);

	identitySecurityQuestions$ = this._store$.select(selectIdentitySecurityQuestions);

	authenticatorKey$ = this._store$.select(selectAuthenticatorKey);

	otpProvider$ = this._store$.select(selectOtpProvider);

	otpExpiresAt$ = this._store$.select(selectOtpExpiresAt).pipe(filterPresent);

	resourceAccessOtpVerificationSuccess$ = this._actions$.pipe(
		ofType(resourceAccessOtpVerificationSuccess),
		map(({ result }) => result),
	);

	refreshTokenSuccess$ = this._actions$.pipe(
		ofType(refreshTokenSuccess),
		map(({ result }) => result),
	);

	constructor(store$: Store, actions$: Actions) {
		super(store$, actions$);

		this._updateIncompleteIdentityPropertyOnStateChange();
	}

	readonly factory = (dto: DTO<Identity>): Identity => new Identity(dto);

	showIdentitySessionIsAboutToExpireDialog(): void {
		this._store$.dispatch(showIdentitySessionIsAboutToExpireDialog());
	}

	setIncompleteIdentity(identity: Identity): void {
		this._store$.dispatch(setIncompleteIdentity({ identity }));
	}

	generateLoginOtp(): void {
		this._store$.dispatch(generateLoginOtp());
	}

	loginOtpVerification(payload: ILoginOtpVerificationApiRequest): void {
		this._store$.dispatch(loginOtpVerification(payload));
	}

	generateResourceAccessOtp(resourceName: string): void {
		this._store$.dispatch(generateResourceAccessOtp({ resourceName }));
	}

	resourceAccessOtpVerification(payload: IResourceAccessOtpVerificationApiRequest): void {
		this._store$.dispatch(resourceAccessOtpVerification(payload));
	}

	refreshAccessToken(): void {
		this._store$.dispatch(refreshAccessToken());
	}

	// #region SECTION Signup Via Invite

	acceptInvite(): void {
		this._store$.dispatch(acceptInvite());
	}

	createAccount(payload: CreateAccountApiRequest): void {
		this._store$.dispatch(createAccount(payload));
	}

	// #endregion !SECTION

	// #region deprecated, remove after accepting invite implementation by backend

	verifyEmail(): void {
		this._store$.dispatch(verifyEmail());
	}

	createPassword(payload: CreateAccountApiRequest): void {
		this._store$.dispatch(createPassword(payload));
	}

	// #endregion !deprecated

	// #region SECTION Continue Signup

	loadAllSecurityQuestions(): void {
		this._store$.dispatch(loadAllSecurityQuestions());
	}

	setSecurityQuestionsAnswers(payload: ISetSecurityQuestionsAnswersApiRequest): void {
		this._store$.dispatch(setSecurityQuestionsAnswers(payload));
	}

	loadAuthenticatorAppKey(): void {
		this._store$.dispatch(loadAuthenticatorAppKey());
	}

	registerAuthenticatorApp(payload: IRegisterAuthenticatorAppApiRequest): void {
		this._store$.dispatch(registerAuthenticatorApp(payload));
	}

	// #endregion !SECTION

	// #region SECTION reset

	loadIdentitySecurityQuestions(): void {
		this._store$.dispatch(loadIdentitySecurityQuestions());
	}

	verifySecurityQuestionAnswer(payload: ISecurityQuestionAnswerApiRequest): void {
		this._store$.dispatch(verifySecurityQuestionsAnswers({ answers: [ payload ]}));
	}

	// #region SECTION reset password

	sendResetPasswordLink(payload: ISendResetPasswordLinkApiRequest): void {
		this._store$.dispatch(sendResetPasswordLink(payload));
	}

	resetPasswordOtpVerification(payload: IResetPasswordOtpVerificationApiRequest): void {
		this._store$.dispatch(resetPasswordOtpVerification(payload));
	}

	resetPassword(payload: IResetPasswordApiRequest): void {
		this._store$.dispatch(resetPassword(payload));
	}

	// #endregion !SECTION

	// #region SECTION reset authenticator app

	sendResetAuthenticatorAppLink(): void {
		this._store$.dispatch(sendResetAuthenticatorAppLink());
	}

	resetAuthenticatorApp(payload: IRegisterAuthenticatorAppApiRequest): void {
		this._store$.dispatch(resetAuthenticatorApp(payload));
	}

	// #endregion !SECTION

	// #endregion !SECTION

	private _updateIncompleteIdentityPropertyOnStateChange(): void {
		this.incompleteIdentity$.subscribe(incompleteIdentity => (this.incompleteIdentity = incompleteIdentity));
	}
}
