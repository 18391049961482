import { combineLatest } from 'rxjs';

import type { OnDestroy, OnInit } from '@angular/core';
import { Directive, Input } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { Destroyable, takeUntilDestroyed } from '@bp/shared/models/common';
import { Enumeration } from '@bp/shared/models/core/enum';
import { OptionalBehaviorSubject } from '@bp/shared/rxjs';

import type { IRightDrawerTabPageComponent } from '../models';
import { IRightDrawerActiveTab } from '../models';

@Directive({
	selector: 'router-outlet[bpActiveTabSetterOnRouteChange]',
})
export class ActiveTabSetterOnRouteChangeDirective extends Destroyable implements OnInit, OnDestroy {

	private readonly _rightDrawerActiveTab$ = new OptionalBehaviorSubject<IRightDrawerActiveTab>();

	@Input() bpActiveTabSetterOnRouteChange!: IRightDrawerActiveTab;

	constructor(private readonly _routerOutlet: RouterOutlet) {
		super();

		this._setActiveTabOnRouteActivation();
	}

	ngOnInit(): void {
		this._rightDrawerActiveTab$.next(this.bpActiveTabSetterOnRouteChange);

		this._rightDrawerActiveTab$.complete();
	}

	override ngOnDestroy(): void {
		super.ngOnDestroy();

		this._rightDrawerActiveTab$.value!.setActiveTab(null);
	}

	private _setActiveTabOnRouteActivation(): void {
		combineLatest([
			this._routerOutlet.activateEvents,
			this._rightDrawerActiveTab$,
		])
			.pipe(takeUntilDestroyed(this))
			.subscribe(([ component, rightDrawerActiveTab ]: [IRightDrawerTabPageComponent, IRightDrawerActiveTab]) => {
				this._assertTabPropertyIsPresent(component);

				rightDrawerActiveTab.setActiveTab(component.tab);
			});
	}

	private _assertTabPropertyIsPresent(component: IRightDrawerTabPageComponent): void {
		if (component.tab instanceof Enumeration)
			return;

		throw new Error('Right drawer tab page component must contain the readonly `tab` property of the Enumeration type');
	}
}
