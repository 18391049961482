import { PaymentOptionType } from '@bp/shared/models/business';
import type { DTO } from '@bp/shared/models/metadata';
import { isPresent } from '@bp/shared/utilities';

import { BridgerPspPaymentOptionApmSummary } from './bridger-psp-payment-option-apm-summary';
import { BridgerPspPaymentOptionCreditCardSummary } from './bridger-psp-payment-option-credit-card-summary';
import { BridgerPspPaymentOptionCryptoSummary } from './bridger-psp-payment-option-crypto-summary';
import { BridgerPspPaymentOptionVoucherSummary } from './bridger-psp-payment-option-voucher-summary';

export type BridgerPspPaymentOptionsSummary = BridgerPspPaymentOptionApmSummary
| BridgerPspPaymentOptionCreditCardSummary
| BridgerPspPaymentOptionCryptoSummary
| BridgerPspPaymentOptionVoucherSummary;

const bridgerPspPaymentOptionsSummaryConstructors = [
	BridgerPspPaymentOptionCreditCardSummary,
	BridgerPspPaymentOptionApmSummary,
	BridgerPspPaymentOptionVoucherSummary,
	BridgerPspPaymentOptionCryptoSummary,
];

export function bridgerPspPaymentOptionsSummaryFactory(dtos: DTO<BridgerPspPaymentOptionsSummary>[]): BridgerPspPaymentOptionsSummary[] {
	return dtos
		.map(dto => dto.type && bridgerPspPaymentOptionSummaryFactory(dto.type, <any> dto))
		.filter(isPresent);
}

export function bridgerPspPaymentOptionSummaryFactory<T extends BridgerPspPaymentOptionsSummary = BridgerPspPaymentOptionsSummary>(
	type: PaymentOptionType | string,
	dto?: DTO<T>,
): T {
	const paymentOptionType = PaymentOptionType.parseStrict(type);

	const targetPaymentOptionConstructor = bridgerPspPaymentOptionsSummaryConstructors.find(
		paymentOption => paymentOption.type === paymentOptionType,
	);

	if (targetPaymentOptionConstructor)
		return (<T> new targetPaymentOptionConstructor(<any> dto));

	throw new Error(`No appropriate constructor for ${ paymentOptionType }`);
}
