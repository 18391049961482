import { Inject, Injectable, Optional } from '@angular/core';
import type { CanActivate, Data, Route, RouterStateSnapshot } from '@angular/router';
import { Router, ActivatedRouteSnapshot } from '@angular/router';

import type { PickBy } from '@bp/shared/typings';
import { isEmpty } from '@bp/shared/utilities';

import { Permission, Feature, PERMISSION_BASED_REDIRECTION_ON_NO_ACCESS_TOKEN } from '../models';
import { FeaturePermissionsService, PermissionBasedNavigationService } from '../services';

import { IdentityLoggedInGuard } from './identity-logged-in.guard';
import { IDENTITY_GET_ACCESS_DIALOG_GUARDS, IIdentityGetAccessDialogGuard } from './identity-get-access-dialog-guard';

export type IdentityHasAccessGuardConfig = {
	permission: Permission;
	permissionBasedRedirectionOnNoAccess?: boolean;
	dontRequireRunGuardsAlways?: boolean;
};

export function identityHasAccessGuardConfig(
	permissionOrConfig: IdentityHasAccessGuardConfig | PickBy<IdentityHasAccessGuardConfig, 'permission'>,
): IdentityHasAccessGuardConfig {

	return Feature.isValid(permissionOrConfig)
		? {
			permission: permissionOrConfig,
		}
		: permissionOrConfig;
}

@Injectable({
	providedIn: 'root',
})
export class IdentityHasAccessGuard implements CanActivate {
	constructor(
		private readonly _router: Router,
		private readonly _identityLoggedInGuard: IdentityLoggedInGuard,
		private readonly _featurePermissionsService: FeaturePermissionsService,
		private readonly _permissionBasedNavigationService: PermissionBasedNavigationService,
		@Inject(IDENTITY_GET_ACCESS_DIALOG_GUARDS)
		@Optional()
		private readonly _identityGetAccessDialogGuards?: IIdentityGetAccessDialogGuard[],
	) {}

	async canActivate(route: ActivatedRouteSnapshot | Route, state: RouterStateSnapshot): Promise<boolean> {
		const userIsLoggedIn = await this._userIsLoggedIn(<ActivatedRouteSnapshot>route, state);

		if (!userIsLoggedIn)
			return false;

		const { data } = route;

		this._assertIdentityHasAccessGuardConfig(data);

		this._assertRouteIsSetupProperly(route, data);

		const hasAccess = this._featurePermissionsService.hasAccess(data.permission);

		if (!hasAccess) {
			this._navigateOffRoute(data);

			return false;
		}

		return this._checkGetAccessDialogs(data.permission);
	}

	private _navigateOffRoute({ permissionBasedRedirectionOnNoAccess }: IdentityHasAccessGuardConfig): void {
		permissionBasedRedirectionOnNoAccess = this._router.getCurrentNavigation()?.extras.state === PERMISSION_BASED_REDIRECTION_ON_NO_ACCESS_TOKEN || permissionBasedRedirectionOnNoAccess;

		if (permissionBasedRedirectionOnNoAccess) {
			const isSuccess = this._permissionBasedNavigationService.tryNavigate();

			if (isSuccess)
				return;
		}

		this._navigateToForbiddenPage();
	}

	private async _checkGetAccessDialogs(permission: Permission): Promise<boolean> {
		if (isEmpty(this._identityGetAccessDialogGuards))
			return true;

		const dialogsGetAccessRequests = this._identityGetAccessDialogGuards.map(
			async dialogGuard => dialogGuard.getAccess(permission),
		);

		const getAccessResults = await Promise.all(dialogsGetAccessRequests);

		return getAccessResults.every(Boolean);
	}

	private _assertRouteIsSetupProperly(
		route: ActivatedRouteSnapshot | Route,
		{ dontRequireRunGuardsAlways }: IdentityHasAccessGuardConfig,
	): void {
		const runGuardsAndResolvers
			= route instanceof ActivatedRouteSnapshot
				? route.routeConfig?.runGuardsAndResolvers
				: route.runGuardsAndResolvers;

		if (dontRequireRunGuardsAlways || runGuardsAndResolvers === 'always')
			return;

		// Since the permission to the route can change dynamically, we need to make sure that we run the guards always

		throw new Error(
			'Route guarded with `IdentityHasAccessGuard` must always be configured with `runGuardsAndResolvers: always`',
		);
	}

	private _navigateToForbiddenPage(): void {
		void this._router.navigate([ 'forbidden' ], { replaceUrl: false, skipLocationChange: true });
	}

	private async _userIsLoggedIn(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		return this._identityLoggedInGuard.canActivate(route, state);
	}

	private _assertIdentityHasAccessGuardConfig(data: Data | undefined): asserts data is IdentityHasAccessGuardConfig {
		const { permission } = <IdentityHasAccessGuardConfig>data;

		if (Feature.isValid(permission))
			return;

		throw new Error(
			'`IdentityHasAccessGuard` must always come with the `permission` property declared on the route config data property',
		);
	}
}
