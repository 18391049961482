import moment from 'moment';

import { Injectable } from '@angular/core';

import type { Timezone } from '@bp/shared/models/common';
import { Timezones } from '@bp/shared/models/common';
import { OptionalBehaviorSubject } from '@bp/shared/rxjs';

@Injectable({
	providedIn: 'root',
})
export class MomentTimezoneService {

	private readonly _timezone$ = new OptionalBehaviorSubject<Timezone>();

	readonly timezone$ = this._timezone$.asObservable();

	setTimezone(timezone: Timezone): void {
		moment.tz.setDefault(timezone.name);

		this._timezone$.next(timezone);
	}

	setGuessedTimezone(): void {
		this.setTimezone(Timezones.guessed);
	}

}
