import { Component, ChangeDetectionStrategy, ViewChild, Input } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';

import { FormFieldControlComponent } from '@bp/shared/components/core';
import { InputTextMaskConfig } from '@bp/shared/features/text-mask';
import { FADE } from '@bp/shared/animations';

import { InputComponent } from '../input';

@Component({
	selector: 'bp-password-input',
	templateUrl: './password-input.component.html',
	styleUrls: [ './password-input.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: PasswordInputComponent,
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: PasswordInputComponent,
			multi: true,
		},
	],
	animations: [ FADE ],
})
export class PasswordInputComponent extends FormFieldControlComponent<string | null> {

	@Input() mask?: InputTextMaskConfig | null;

	@Input() maxLength?: number;

	@ViewChild(InputComponent, { static: true }) input!: InputComponent;

	isPasswordVisible = false;

	focus(): void {
		this.input.focus();
	}

}
