import { Enumeration } from '@bp/shared/models/core/enum';

export class BridgerPspScope extends Enumeration {

	static merchantAdmin = new BridgerPspScope('Merchant Admin');

	static promo = new BridgerPspScope();

	static notouchWizard = new BridgerPspScope('No-touch Wizard');

}
