import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import type { Notification } from '../models';

import {
	close, markAllItemsAsRead, open, removeAllItems, removeItem, setItemReadState
} from './notifications-hub.actions';
import {
	getHasImportantNotifications, getHasNonRemovableNotifications, getHasNotImportantNotifications,
	getHasPendingNotifications, getHasRemovableNotifications, getHasUnreadNotifications, getIsOpened,
	getNotifications
} from './notifications-hub.selectors';

@Injectable({ providedIn: 'root' })
export class NotificationsHubFacade {

	notifications$ = this._store$.select(getNotifications);

	hasUnread$ = this._store$.select(getHasUnreadNotifications);

	hasImportant$ = this._store$.select(getHasImportantNotifications);

	hasNotImportant$ = this._store$.select(getHasNotImportantNotifications);

	hasRemovable$ = this._store$.select(getHasRemovableNotifications);

	hasNonRemovable$ = this._store$.select(getHasNonRemovableNotifications);

	hasPendings$ = this._store$.select(getHasPendingNotifications);

	isOpened$ = this._store$.select(getIsOpened);

	notifications!: Notification[];

	isOpened = false;

	constructor(private readonly _store$: Store) {
		this._setNotificationsPropertyOnStateChange();

		this._setIsOpenedPropertyOnStateChange();
	}

	open() {
		this._store$.dispatch(open());
	}

	close() {
		this._store$.dispatch(close());
	}

	setReadState(notification: Notification, isRead: boolean): void {
		this._store$.dispatch(setItemReadState({ notification, isRead }));
	}

	markAllAsRead(): void {
		this._store$.dispatch(markAllItemsAsRead());
	}

	remove(notification: Notification): void {
		this._store$.dispatch(removeItem({ notification }));
	}

	removeAll(): void {
		this._store$.dispatch(removeAllItems());
	}

	private _setNotificationsPropertyOnStateChange() {
		this.notifications$
			.subscribe(v => (this.notifications = v));
	}

	private _setIsOpenedPropertyOnStateChange() {
		this.isOpened$
			.subscribe(v => (this.isOpened = v));
	}
}
