import type { OnChanges, OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { PaymentCardBrand } from '@bp/shared/models/business';
import { attrBoolValue } from '@bp/shared/utilities';

@Component({
	selector: 'bp-payment-card-brand',
	templateUrl: './payment-card-brand.component.html',
	styleUrls: [ './payment-card-brand.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentCardBrandComponent implements OnChanges, OnInit {

	@Input() src!: PaymentCardBrand | string;

	@Input() noTooltip?: boolean | '';

	brand!: PaymentCardBrand | null;

	ngOnChanges(): void {
		this.brand = this.src instanceof PaymentCardBrand
			? this.src
			: PaymentCardBrand.parse(this.src);
	}

	ngOnInit(): void {
		this.noTooltip = attrBoolValue(this.noTooltip);
	}
}
