import type { MetadataEntity } from '../metadata-entity';
import type { PropertyMetadata } from '../property-metadata';

// eslint-disable-next-line @typescript-eslint/naming-convention
export function Property(config: Omit<Partial<PropertyMetadata>, 'property'>) {
	return function(model: MetadataEntity, property: string): void {
		model
			.classMetadata
			.add(property, config);
	};
}
