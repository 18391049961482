import { DescribableEnumeration } from '@bp/shared/models/core';

export class BridgerPspProcessingHistory extends DescribableEnumeration {

	static lessThan3Months = new BridgerPspProcessingHistory('<3 months');

	static from3To6Months = new BridgerPspProcessingHistory('3 - 6 months');

	static moreThan6Months = new BridgerPspProcessingHistory('6 months or more');

}
