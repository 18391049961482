import { Action } from '@ngrx/store';

import { MetadataEntity } from '@bp/shared/models/metadata';
import { TELEMETRY_HIDDEN_MARK } from '@bp/shared/models/core';

export function actionTransformer(action: Action): Action {
	if (action.type.includes(TELEMETRY_HIDDEN_MARK)) {
		return {
			type: action.type.replace(TELEMETRY_HIDDEN_MARK, ''),
		};
	}

	return sanitizeObject(action);
}

export function actionSanitizer(action: Action): Action | null {
	return actionTransformer(action);
}

export function stateSanitizer(state: Object): Object {
	return sanitizeObject(state);
}

const sanitizedProperties = new Set([ 'jwt', 'accessToken', 'password', 'currentPassword', 'newPassword', 'confirmPassword' ]);

function sanitizeObject<T extends Object>(object: T): T {
	return JSON.parse(JSON.stringify(object, function(this: any, key, value) {

		const isSecretProperty = this instanceof MetadataEntity && this.classMetadata.get(<any>key)?.isSecret;

		if (isSecretProperty || sanitizedProperties.has(key))
			return '**REDACTED**';

		return value;
	}));
}
