/* eslint-disable @typescript-eslint/naming-convention */
export const CRYPTO_CURRENCY_CODES_AND_NAMES = {
	'1INCH': [ '1inch' ],
	AAVE: [ 'Aave' ],
	ADA: [ 'Cardano', '₳' ],
	AKRO: [ 'Akropolis' ],
	ALPHA: [ 'Alpha Finance Lab' ],
	AMP: [ 'Amp' ],
	AUDIO: [ 'Audius' ],
	AXS: [ 'Axie Infinity' ],
	BADGER: [ 'Badger DAO' ],
	BAL: [ 'Balancer' ],
	BAND: [ 'Band Protocol' ],
	BAT: [ 'Basic Attention Token', '⟁' ],
	BCH: [ 'Bitcoin Cash', 'Ƀ' ],
	BNB: [ 'Binance Coin' ],
	BTC: [ 'Bitcoin', '₿' ],
	BUSD: [ 'Binance USD' ],
	BZRX: [ 'bZx Protocol' ],
	CAKE: [ 'PancakeSwap' ],
	CEL: [ 'Celsius' ],
	CHR: [ 'Chromia' ],
	CHZ: [ 'Chiliz' ],
	COMP: [ 'Compound' ],
	CRV: [ 'Curve DAO Token' ],
	DAI: [ 'Dai', '◈' ],
	DASH: [ 'DASH' ],
	DOGE: [ 'Dogecoin', 'Ɖ' ],
	ENJ: [ 'Enjin Coin' ],
	ETH: [ 'Ethereum', 'Ξ' ],
	FET: [ 'Fetch.ai' ],
	FTM: [ 'Fantom' ],
	FTT: [ 'FTX Token' ],
	GRT: [ 'The Graph' ],
	GUSD: [ 'Gemini Dollar' ],
	HOT: [ 'Holo' ],
	IOST: [ 'IOSToken' ],
	LEO: [ 'UNUS SED LEO' ],
	LINK: [ 'Chainlink' ],
	LRC: [ 'Loopring' ],
	LTC: [ 'Litecoin', 'Ł' ],
	MANA: [ 'Decentraland' ],
	MATIC: [ 'Polygon' ],
	MKR: [ 'Maker', 'Μ' ],
	NEM: [ 'NEM' ],
	NEO: [ 'NEO' ],
	'NU-ETH': [ 'NuCypher' ],
	OCEAN: [ 'Ocean Protocol' ],
	OMG: [ 'OmiseGO' ],
	PAX: [ 'Paxos Standard Token' ],
	QNT: [ 'Quant' ],
	RARI: [ 'Rarible' ],
	REEF: [ 'Reef' ],
	REN: [ 'Ren' ],
	REV: [ 'Revain' ],
	RSR: [ 'Reserve Rights' ],
	SAND: [ 'The Sandbox' ],
	SHIB: [ 'SHIBA INU' ],
	SNX: [ 'Synthetix' ],
	SRM: [ 'Serum' ],
	SUSHI: [ 'SushiSwap' ],
	SXP: [ 'Swipe' ],
	TEL: [ 'Telcoin' ],
	TRX: [ 'TRX' ],
	TUSD: [ 'TrueUSD' ],
	UNI: [ 'Uniswap' ],
	USDC: [ 'USD Coin' ],
	USDP: [ 'Pax Dollar' ],
	USDT: [ 'Tether', '₮' ],
	'USDT-TRC20': [ 'Tether (Tron)', '₮' ],
	'USDT-ERC20': [ 'Tether (Ethereum)', '₮' ],
	'USDT-BEP20': [ 'Tether (Binance)', '₮' ],
	UST: [ 'TerraUSD' ],
	VIU: [ 'Viuly' ],
	XLM: [ 'Stellar', '🚀' ],
	XMR: [ 'Monero', 'ɱ' ],
	XRP: [ 'Ripple', '✕' ],
	YFI: [ 'yearn.finance' ],
	ZEC: [ 'Zcash', 'ⓩ' ],
	ZRX: [ '0x' ],
};
