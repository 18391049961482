import { ChangeDetectionStrategy, Component, ContentChild } from '@angular/core';

import { LayoutFacade } from '../../state';
import { SidenavComponent } from '../sidenav';

@Component({
	selector: 'bp-layout',
	templateUrl: './layout.component.html',
	styleUrls: [ './layout.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent {

	@ContentChild(SidenavComponent) readonly sidenav!: SidenavComponent;

	constructor(public layoutFacade: LayoutFacade) { }

}
