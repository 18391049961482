/// <reference path="../../../../../../typings.d.ts" />

import { isString } from 'lodash-es';

import { getCurrencySymbol, getNumberOfCurrencyDigits } from '@angular/common';

import { PrimitiveConstructable } from '@bp/shared/models/core';

import type { ICurrency } from '../currency.interface';

import type { CurrencyCode } from './currency-codes';
import { CURRENCIES_CODES } from './currency-codes';

let intlGetDisplayName: Intl.DisplayNames | undefined;

function tryGetCurrencyNameInEnglish(this: void, iso: string): string | null {
	try {
		intlGetDisplayName = intlGetDisplayName ?? new Intl.DisplayNames([ 'en' ], { type: 'currency' });

		return intlGetDisplayName.of(iso) ?? null;
	} catch {

		return null;
	}
}

export class Currency extends PrimitiveConstructable<CurrencyCode> implements ICurrency {

	static readonly list: Currency[] = CURRENCIES_CODES.map(it => new Currency(it));

	static fromCodes(codes: CurrencyCode[]): Currency[] {
		return codes.map(v => new Currency(v));
	}

	readonly code!: CurrencyCode;

	/**
	 * '{symbol} {code}'
	 */
	readonly displayName!: string;

	readonly symbol!: string;

	readonly thousandsSeparatorSymbol!: string;

	readonly allowDecimal!: boolean;

	readonly decimalSeparatorSymbol!: string;

	readonly decimalLimit!: number;

	constructor(dtoOrCode: CurrencyCode | { code: CurrencyCode }) {
		super(isString(dtoOrCode)
			? <CurrencyCode> dtoOrCode.toUpperCase()
			: dtoOrCode.code);

		if (this._isCached())
			return this;

		this.code = this._id;

		this._whenCurrencyCodeCodeInvalidThrowNotFoundError();

		this.symbol = getCurrencySymbol(this.code, 'narrow');

		this.displayName = this._buildDisplayName();

		this._setLocaleNumberSymbols();

		this.decimalLimit = getNumberOfCurrencyDigits(this.code);

		this.allowDecimal = this.decimalLimit !== 0;

		this._cacheAndFreezeInstance();
	}

	private _buildDisplayName(): string {
		return tryGetCurrencyNameInEnglish(this.code)
			?? (this.code === this.symbol ? this.code : `${ this.code } ${ this.symbol }`);
	}

	protected _getScope(): string {
		return 'currency';
	}

	private _whenCurrencyCodeCodeInvalidThrowNotFoundError(): void {
		if (!CURRENCIES_CODES.includes(this.code))
			throw new Error(`Such currency doesn't exist - "${ this._id }"`);
	}

	private _setLocaleNumberSymbols(): void {
		const localCurrencyString = 1000.1
			.toLocaleString();

		// @ts-expect-error must stay readonly
		this.thousandsSeparatorSymbol = localCurrencyString.slice(1, 2);

		// @ts-expect-error must stay readonly
		this.decimalSeparatorSymbol = localCurrencyString.slice(-2, -1);
	}
}

Object.freeze(Currency.list);
