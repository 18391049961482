import { ChangeDetectionStrategy, Component } from '@angular/core';

import { EnvironmentService, MockedBackendState } from '@bp/shared/services';

import { MockUserEmail } from '@bp/shared-domains-identity';

import { IntroFacade } from '../../state/intro.facade';

@Component({
	selector: 'bp-login-page',
	templateUrl: './login-page.component.html',
	styleUrls: [ './login-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginPageComponent {
	constructor(
		public readonly environment: EnvironmentService,
		public readonly introFacade: IntroFacade,
	) {
		this._loginDemoUserInDemoMode();
	}

	private _loginDemoUserInDemoMode(): void {
		if (MockedBackendState.isDemoMode)
			this.introFacade.loginAsMockBackendUser(MockUserEmail.Demo);
	}
}
