import { get, isObject } from 'lodash-es';

import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({
	name: 'bpDisplayValue',
})
export class DisplayValuePipe implements PipeTransform {
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	transform(value?: any, displayValuePropertyName: string = 'displayName'): string {
		return isObject(value)
			? get(value, displayValuePropertyName) ?? get(value, 'name') ?? value.toString()
			: value;
	}
}
