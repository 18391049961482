import { countries } from 'countries-list';
import { isString } from 'lodash-es';

import { Country } from './country';
import type { CountryCode } from './country-codes';

export class Countries {

	static list = Countries._buildCountries();

	private static readonly _countriesByCodeMap = new Map(Countries.list.map(country => [ country.code, country ]));

	static worldwide = Countries.get('ALL')!;

	private static readonly _lowerCaseCountryNames = Countries.list.map(v => v.lowerCaseName);

	private static _buildCountries(): Country[] {
		return [
			...Object
				.entries(countries)
				.map(([ code, country ]) => new Country({
					code: <CountryCode> code,
					name: country.name,
					displayName: country.name === country.native
						? country.name
						: `${ country.name } (${ country.native })`,
					currency: country.currency,
					dialCode: country.phone,
				}))
				.sort((a, b) => a.displayName.localeCompare(b.displayName)),
			new Country({ code: 'ALL', name: 'Worldwide' }), // ALL backend value for worldwide
		];
	}

	static findByName(countryName: string | null): Country | null {
		const lowerCaseCountryName = countryName?.toLowerCase();

		return lowerCaseCountryName
			? Countries.list.find(v => v.lowerCaseName === lowerCaseCountryName) ?? null
			: null;
	}

	static get(code: CountryCode): Country {
		return Countries._countriesByCodeMap.get(code)!;
	}

	static findByCodeString(code: unknown): Country | null {
		return isString(code)
			? Countries._countriesByCodeMap.get(<CountryCode> code.toUpperCase()) ?? null
			: null;
	}

	static findByDialCode(dialCode: string): Country | undefined {
		return Countries.list.find(v => v.dialCode === dialCode);
	}

	static fromCodes(codes: CountryCode[]): Country[] {
		return codes.map(code => Countries.get(code));
	}

	static includes(countryName: string): boolean {
		return Countries._lowerCaseCountryNames.includes(countryName.toLowerCase());
	}

	static includesCode(code?: string | null): code is CountryCode {
		return !!code && !!Countries.findByCodeString(code);
	}

}
