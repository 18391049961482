import { Moment } from 'moment';

import { booleanMapper, Default, DTO, Entity, FieldViewType, MapFromDTO, Mapper, Table, Unserializable, View } from '@bp/shared/models/metadata';
import { PspPaymentOptionType } from '@bp/shared/models/business';

import { BridgerPspIndustry } from '../bridger-psp-industry';
import { BridgerPspLicense } from '../bridger-psp-license';
import { BridgerPspLocation } from '../bridger-psp-location';
import { BridgerPspMonthlyTurnoverVolume } from '../bridger-psp-monthly-turnover-volume';
import { BridgerPspProcessingHistory } from '../bridger-psp-processing-history';
import { BridgerPspLegalEntityType } from '../bridger-psp-legal-entity-type';

import { BridgerPspPaymentOptionsSummary, bridgerPspPaymentOptionsSummaryFactory } from './payment-options';

export class BridgerPspSummary extends Entity {

	@Default(null)
	internalName!: string | null;

	@MapFromDTO()
	description!: string;

	@MapFromDTO()
	websiteUrl!: string;

	@MapFromDTO()
	backofficeUrl!: string;

	/** thumbnail logo */
	@MapFromDTO()
	logoUrl!: string;

	@MapFromDTO()
	fullLogoUrl!: string;

	@MapFromDTO()
	instructionGuide!: string | null;

	@MapFromDTO()
	instructionGuideUrl!: string | null;

	@MapFromDTO()
	instructionGuideVideoUrl!: string | null;

	@Mapper(booleanMapper)
	@Default(false)
	popular!: boolean;

	@Mapper(BridgerPspLocation)
	locations!: BridgerPspLocation[];

	@Mapper(BridgerPspIndustry)
	industries!: BridgerPspIndustry[];

	@Mapper(bridgerPspPaymentOptionsSummaryFactory)
	@Default([])
	paymentOptions!: BridgerPspPaymentOptionsSummary[];

	@Mapper(BridgerPspLocation)
	@Default([])
	legalEntityLocations!: BridgerPspLocation[];

	@Mapper(BridgerPspLicense)
	@Default([])
	licenses!: BridgerPspLicense[];

	@Mapper(BridgerPspLegalEntityType)
	@Default([])
	legalEntityTypes!: BridgerPspLegalEntityType[];

	@Mapper(BridgerPspProcessingHistory)
	@Default([])
	processingHistories!: BridgerPspProcessingHistory[];

	@Mapper(BridgerPspMonthlyTurnoverVolume)
	@Default([])
	monthlyTurnoverVolumes!: BridgerPspMonthlyTurnoverVolume[];

	@Table({ sortable: true, defaultSortField: true })
	override modified!: Moment;

	@View(FieldViewType.chip)
	@Unserializable()
	readonly paymentOptionsTypes: PspPaymentOptionType[];

	constructor(dto?: DTO<BridgerPspSummary>) {
		super(dto);

		this.paymentOptionsTypes = this.paymentOptions.map(option => option.type);

		this._tryDeserializeInstructionGuideFromBase64();
	}

	toJSON(): DTO<BridgerPspSummary> {
		const dto = <DTO<BridgerPspSummary>>{ ...this };

		this._serializeInstructionGuideToBase64(dto);

		return dto;
	}

	private _tryDeserializeInstructionGuideFromBase64(): void {
		if (this.instructionGuide === null)
			return;

		try {
			this.instructionGuide = atob(this.instructionGuide);
		} catch {
			// means that the instruction guide is not base64 encoded
			// so we do nothing
		}
	}

	/**
	 * WAF on backend blocks saving the object due to anchor tags in the instruction guide,
	 * so we force to save the instruction guide as base64 string
	 */
	private _serializeInstructionGuideToBase64(dto: DTO<BridgerPspSummary>): void {
		if (dto.instructionGuide)
			dto.instructionGuide = btoa(dto.instructionGuide);
	}
}
