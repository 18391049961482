import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ApiMockPluginsManagerService } from '@bp/shared/services';

import { LOGIN_ROUTE_PATHNAME_TOKEN, IdentityFacade as IdentityBaseFacade } from '@bp/shared-domains-identity';

import { AdminsSharedDomainsIdentityModule } from './admins-shared-domains-identity.module';
import { HttpResponseAccessGuardInterceptor } from './services';
import { IdentityEffects } from './state/identity.effects';
import { FEATURE_STATE_KEY as IDENTITY_FEATURE_STATE_KEY, reducer as IDENTITY_REDUCER } from './state/identity.reducer';
import {
	FEATURE_STATE_KEY as IDENTITY_PREFERENCES_FEATURE_STATE_KEY,
	reducer as IDENTITY_PREFERENCES_REDUCER
} from './state/preferences/identity-preferences.reducer';
import { resetAppStateOnRemovedIdentityMetaReducer } from './state/reset-app-state-on-removed-identity.meta-reducer';
import { IdentityPreferencesEffects } from './state/preferences/identity-preferences.effects';
import { IdentityFacade } from './state';
import { LOGIN_ROUTE_PATHNAME } from './models';
import { IncompleteIdentityEffects } from './state/incomplete-identity.effects';
import { identityStateRehydratorMetaReducer } from './state/identity-state-rehydrator.meta-reducer';
import { identityPreferencesStateRehydratorMetaReducer } from './state/preferences/identity-preferences-state-rehydrator.meta-reducer';

@NgModule({
	imports: [
		AdminsSharedDomainsIdentityModule,
		StoreModule.forFeature(IDENTITY_FEATURE_STATE_KEY, IDENTITY_REDUCER, {
			metaReducers: [ identityStateRehydratorMetaReducer, resetAppStateOnRemovedIdentityMetaReducer ],
		}),
		StoreModule.forFeature(IDENTITY_PREFERENCES_FEATURE_STATE_KEY, IDENTITY_PREFERENCES_REDUCER, {
			metaReducers: [ identityPreferencesStateRehydratorMetaReducer ],
		}),
		EffectsModule.forFeature([ IdentityEffects, IdentityPreferencesEffects, IncompleteIdentityEffects ]),
	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: HttpResponseAccessGuardInterceptor, multi: true },
		{ provide: LOGIN_ROUTE_PATHNAME_TOKEN, useValue: LOGIN_ROUTE_PATHNAME },
		{ provide: IdentityBaseFacade, useExisting: IdentityFacade },
	],
})
export class AdminsSharedDomainsIdentityRootModule {
	constructor(private readonly _apiMocksManagerService: ApiMockPluginsManagerService) {
		void this._apiMocksManagerService.register(async () => import('./api-mock-plugin/identity-api-mock-plugin'));
	}
}
