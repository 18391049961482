import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import type { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';

import { SharedComponentsCoreModule } from '@bp/shared/components/core';

import { NON_REDIRECTED_URLS_AFTER_LOGIN_TOKEN } from '@bp/shared-domains-identity';

import { ErrorShellComponent } from './components';
import { ForbiddenPageComponent, GeneralErrorPageComponent, NotFoundPageComponent } from './pages';

export const ERROR_ROUTE_PATH = 'error';

export const FORBIDDEN_ROUTE_PATH = 'forbidden';

const routes: Routes = [
	{
		path: ERROR_ROUTE_PATH,
		component: GeneralErrorPageComponent,
		data: {
			title: 'Error',
		},
	},
	{
		path: FORBIDDEN_ROUTE_PATH,
		component: ForbiddenPageComponent,
		data: {
			title: 'Access Forbidden',
		},
	},
	{
		path: '**',
		component: NotFoundPageComponent,
		data: {
			title: '404 – not found',
		},
	},
];

@NgModule({
	imports: [ CommonModule, MatIconModule, SharedComponentsCoreModule, RouterModule.forChild(routes) ],
	declarations: [ GeneralErrorPageComponent, NotFoundPageComponent, ErrorShellComponent, ForbiddenPageComponent ],
	exports: [ RouterModule ],
	providers: [
		{
			provide: NON_REDIRECTED_URLS_AFTER_LOGIN_TOKEN,
			multi: true,
			useValue: [ `/${ ERROR_ROUTE_PATH }`, `/${ FORBIDDEN_ROUTE_PATH }` ],
		},
	],
})
export class AdminsSharedPagesErrorsModule {}
