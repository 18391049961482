<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<!-- eslint-disable @angular-eslint/template/cyclomatic-complexity -->
<bp-input
	*ngIf="isControlTypeInputLike"
	[textarea]="controlMetadata.type === FieldControlType.textarea"
	[number]="controlMetadata.type === FieldControlType.number"
	[formControl]="control"
	[color]="color"
	[name]="metadata.property"
	[label]="controlLabel"
	[hint]="metadata.hint"
	[pending]="pending"
	[required]="controlMetadata.required"
	[nativeAutocomplete]="controlMetadata.nativeAutocomplete"
	[placeholder]="controlMetadata.placeholder"
	[appearance]="appearance"
	[floatLabel]="floatLabel"
	[maxLength]="getControlOptions(FieldControlType.input).maxLength"
	[mask]="$$textMask"
	[isSecret]="controlMetadata.isSecret"
>
</bp-input>

<bp-input-chips
	*ngIf="isControlTypeInputChipLike"
	[number]="controlMetadata.type === FieldControlType.numberChips"
	[formControl]="control"
	[color]="color"
	[name]="metadata.property"
	[label]="controlLabel"
	[hint]="metadata.hint"
	[pending]="pending"
	[required]="controlMetadata.required"
	[nativeAutocomplete]="controlMetadata.nativeAutocomplete"
	[placeholder]="controlMetadata.placeholder"
	[appearance]="appearance"
	[floatLabel]="floatLabel"
>
</bp-input-chips>

<bp-chips
	*ngIf="isControlTypeChipLike"
	[items]="$$items"
	[formControl]="control"
	[color]="color"
	[name]="metadata.property"
	[label]="controlLabel"
	[hint]="metadata.hint"
	[pending]="pending"
	[required]="controlMetadata.required"
	[nativeAutocomplete]="controlMetadata.nativeAutocomplete"
	[placeholder]="controlMetadata.placeholder"
	[appearance]="appearance"
	[floatLabel]="floatLabel"
>
	<ng-container *ngIf="controlMetadata.type === FieldControlType.brandChips">
		<div *cdkPortal="let brand; let isMatOption = isMatOption" class="flex-align-items-center">
			<bp-payment-card-brand [class.mr-2]="isMatOption" [src]="brand"></bp-payment-card-brand>
			{{ isMatOption ? brand.displayName : '' }}
		</div>
	</ng-container>

	<ng-container *ngIf="controlMetadata.type === FieldControlType.countryChips">
		<bp-country
			*cdkPortal="let country; let isMatOption = isMatOption"
			[class.mr-2]="isMatOption"
			[src]="country"
			[compact]="!isMatOption"
		></bp-country>
	</ng-container>
</bp-chips>

<ng-container [ngSwitch]="controlMetadata.type">
	<ng-container *ngSwitchCase="FieldControlType.date">
		<bp-datepicker
			*ngIf="getControlOptions(FieldControlType.date) as controlOptions"
			[formControl]="control"
			[color]="color"
			[name]="metadata.property"
			[label]="metadata.label"
			[hint]="metadata.hint"
			[required]="controlMetadata.required"
			[nativeAutocomplete]="controlMetadata.nativeAutocomplete"
			[placeholder]="controlMetadata.placeholder"
			[appearance]="appearance"
			[floatLabel]="floatLabel"
			[min]="controlOptions.min"
			[max]="controlOptions.max"
			[startView]="controlOptions.startView"
			[startAt]="controlOptions.startAt"
		>
		</bp-datepicker>
	</ng-container>

	<ng-container *ngSwitchCase="FieldControlType.country">
		<bp-country-selector
			*ngIf="getControlOptions(FieldControlType.country) as controlOptions"
			[countries]="controlMetadata.list"
			[hasWorldwide]="!!controlOptions.hasWorldwide"
			[formControl]="control"
			[color]="color"
			[name]="metadata.property"
			[label]="controlLabel"
			[hint]="metadata.hint"
			[required]="controlMetadata.required"
			[nativeAutocomplete]="controlMetadata.nativeAutocomplete"
			[placeholder]="controlMetadata.placeholder"
			[appearance]="appearance"
			[floatLabel]="floatLabel"
		>
		</bp-country-selector>
	</ng-container>

	<bp-select-field
		*ngSwitchCase="FieldControlType.select"
		[items]="$$items"
		[formControl]="control"
		[color]="color"
		[name]="metadata.property"
		[label]="controlLabel"
		[hint]="metadata.hint"
		[required]="controlMetadata.required"
		[nativeAutocomplete]="controlMetadata.nativeAutocomplete"
		[placeholder]="controlMetadata.placeholder"
		[appearance]="appearance"
		[floatLabel]="floatLabel"
	>
	</bp-select-field>

	<ng-container *ngSwitchCase="FieldControlType.autocomplete">
		<bp-autocomplete
			*ngIf="getControlOptions(FieldControlType.autocomplete) as controlOptions"
			[items]="$$items"
			[formControl]="control"
			[color]="color"
			[name]="metadata.property"
			[label]="controlLabel"
			[hint]="metadata.hint"
			[required]="controlMetadata.required"
			[nativeAutocomplete]="controlMetadata.nativeAutocomplete"
			[placeholder]="controlMetadata.placeholder"
			[appearance]="appearance"
			[floatLabel]="floatLabel"
			[pending]="pending"
			[suggestedItem]="controlOptions.suggestedItem"
			[suggestedItemTooltip]="controlOptions.suggestedItemTooltip"
			[suggestedItemButtonTextPrefix]="controlOptions.suggestedItemButtonTextPrefix"
		>
		</bp-autocomplete>
	</ng-container>

	<bp-button-toggle
		*ngSwitchCase="FieldControlType.buttonToggle"
		[items]="$$items"
		[formControl]="control"
		[color]="color"
		[name]="metadata.property"
		[label]="controlLabel"
		[hint]="metadata.hint"
		[required]="controlMetadata.required"
		[nativeAutocomplete]="controlMetadata.nativeAutocomplete"
		[placeholder]="controlMetadata.placeholder"
	>
	</bp-button-toggle>

	<div *ngSwitchCase="FieldControlType.switch" class="mat-form-field mat-form-field-switch">
		<mat-slide-toggle
			[formControl]="control"
			[name]="metadata.property"
			[color]="color ?? 'primary'"
			[required]="controlMetadata.required"
			(click)="control.markAsTouched()"
		>
			{{ controlLabel | bpTranslate | async }} <bp-hint *ngIf="metadata.hint" [msg]="metadata.hint"></bp-hint>
		</mat-slide-toggle>
		<mat-error [bpFieldError]="control" [bpFieldErrorAnimate]="true"></mat-error>
	</div>

	<div *ngSwitchCase="FieldControlType.checkbox" class="mat-form-field mat-form-field-checkbox">
		<mat-checkbox
			[formControl]="control"
			[name]="metadata.property"
			[color]="color ?? 'primary'"
			[required]="controlMetadata.required"
			(click)="control.markAsTouched()"
		>
			{{ controlLabel | bpTranslate | async }} <bp-hint *ngIf="metadata.hint" [msg]="metadata.hint"></bp-hint>
		</mat-checkbox>

		<mat-error [bpFieldError]="control" [bpFieldErrorAnimate]="true"></mat-error>
	</div>

	<ng-container *ngSwitchCase="FieldControlType.phone">
		<bp-phone-input
			*ngIf="getControlOptions(FieldControlType.phone) as controlOptions"
			[initialDialCodeCountry]="controlOptions.initialDialCodeCountry"
			[formControl]="control"
			[color]="color"
			[name]="metadata.property"
			[label]="controlLabel"
			[hint]="metadata.hint"
			[required]="controlMetadata.required"
			[placeholder]="controlMetadata.placeholder"
			[appearance]="appearance"
			[floatLabel]="floatLabel"
			[nativeAutocomplete]="controlMetadata.nativeAutocomplete"
		>
		</bp-phone-input>
	</ng-container>

	<ng-container *ngSwitchCase="FieldControlType.password">
		<bp-password-input
			*ngIf="getControlOptions(FieldControlType.password) as controlOptions"
			[formControl]="control"
			[name]="metadata.property"
			[label]="controlLabel"
			[hint]="metadata.hint"
			[required]="controlMetadata.required"
			[placeholder]="controlMetadata.placeholder"
			[appearance]="appearance"
			[floatLabel]="floatLabel"
			[nativeAutocomplete]="controlMetadata.nativeAutocomplete"
			[maxLength]="controlOptions.maxLength"
			[mask]="$$textMask"
		>
		</bp-password-input>
	</ng-container>
</ng-container>
