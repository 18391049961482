<bp-reset-password-form
	class="h-100"
	[email]="(identityFacade.incompleteIdentity$ | async)?.email"
	[expiredPassword]="(isResetExpiredPassword$ | async) === true"
	[pending]="introFacade.pending$ | async"
	[error]="introFacade.error$ | async"
	(login)="introFacade.gotoLoginPage()"
	(submitted)="identityFacade.resetPassword($event)"
>
</bp-reset-password-form>
