import { ChangeDetectionStrategy, Component } from '@angular/core';

import { PaymentCardBrand } from '@bp/shared/models/business';

@Component({
	selector: 'bp-svg-payment-card-brands-icons-definitions',
	templateUrl: './svg-payment-card-brands-icons-definitions.component.html',
	styles: [ ':host { display: none }' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SvgPaymentCardBrandsIconsDefinitionsComponent {

	// eslint-disable-next-line @typescript-eslint/naming-convention
	PaymentCardBrand = PaymentCardBrand;

}
