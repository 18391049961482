import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IErrorMessage } from '@bp/shared/models/core';

@Component({
	selector: 'bp-error-messages',
	templateUrl: './error-messages.component.html',
	styleUrls: [ './error-messages.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorMessagesComponent {

	@Input('src')
	errors!: IErrorMessage[];

	@Input() showType = true;

}
