import type m from 'moment';
import type { Observable } from 'rxjs';
import { timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

import { momentMapper } from '@bp/shared/models/metadata';

import { MomentBasePipe } from './moment-base.pipe';

@Pipe({
	name: 'momentFromNow',
})
export class MomentFromNowPipe extends MomentBasePipe implements PipeTransform {
	transform(momentInput: m.MomentInput, withoutSuffix?: 'withoutSuffix'): Observable<string> {
		 return this._momentTimezoneService.timezone$.pipe(
			switchMap(() => this._momentFromNowUpdatedEach30Seconds(momentInput, withoutSuffix)),
		);
	}

	private _momentFromNowUpdatedEach30Seconds(
		momentInput: m.MomentInput,
		withoutSuffix: string | undefined,
	): Observable<string> {
		const moment = momentMapper(momentInput);

		return timer(0, 1000 * 30)
			.pipe(map(() => moment.fromNow(!!withoutSuffix)));
	}
}
