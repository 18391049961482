import { BehaviorSubject } from 'rxjs';

import { Injectable } from '@angular/core';

import { takeFirstTruthy } from '@bp/shared/rxjs';

import { EnvironmentService } from '../environment.service';
import { LocalBackendState } from '../state-keepers';

export const CORRELATION_ID_HEADER = 'x-correlation-id';

export const CONTENT_TYPE_HEADER = 'Content-Type';

@Injectable({ providedIn: 'root' })
export class HttpConfigService {

	private readonly _backendEndpointBaseSegment = this._environment.api === 'relative'
		? '/api'
		: (this._environment.api.includes('api')
			? this._environment.api
			: `${ this._environment.api }/api`);

	private readonly _backendEndpointVersionSegment = this._environment.apiVersion
		? `/${ this._environment.apiVersion }`
		: '';

	private readonly _localBackendHost = `http://localhost:5000/api${ this._backendEndpointVersionSegment }`;

	get backendBaseSegment(): string {
		return LocalBackendState.isActive
			? this._localBackendHost
			: `${ this._backendEndpointBaseSegment }${ this._backendEndpointVersionSegment }`;
	}

	readonly headers: Record<string, string | null> = {
		[CONTENT_TYPE_HEADER]: 'application/json',
		'json-naming-strategy': 'camelcase',

		/*
		 * All the api calls should bypass the service worker since due to cloudlflare we sometimes have the 302
		 * response code which if handled by the browser redirects the page, but with the service worker used as proxy for the api calls
		 * it doesn't happen
		 */
		'ngsw-bypass': '',
		// For cloudflare access https://developers.cloudflare.com/access/faq/
		credentials: 'same-origin',
	};

	private readonly _authorized$ = new BehaviorSubject(false);

	readonly hasAuthorizationToken$ = this._authorized$.pipe(takeFirstTruthy);

	constructor(
		private readonly _environment: EnvironmentService,
	) {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
		(<any> window).BP_HTTP_CONFIG = this;
	}

	setAuthorizationHeader(token: string): void {
		this.headers['Authorization'] = `Bearer ${ token }`;

		this._authorized$.next(true);
	}

	removeAuthorizationHeader(): void {
		this.headers['Authorization'] = '';

		this._authorized$.next(false);
	}

	setHttpHeader(key: string, value: string): void {
		this.headers[key] = value;
	}

	setAcceptLanguageHeader(lang: string): void {
		this.setHttpHeader('Accept-Language', lang);
	}

}
