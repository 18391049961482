import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { OtpProvider } from '../../models';
import { IdentityFacade } from '../../state';

@Component({
	selector: 'bp-otp-provider-message[resourceName]',
	templateUrl: './otp-provider-message.component.html',
	styleUrls: [ './otp-provider-message.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OtpProviderMessageComponent {

	@Input() resourceName!: string | 'login';

	// eslint-disable-next-line @typescript-eslint/naming-convention
	OtpProvider = OtpProvider;

	constructor(public readonly identityFacade: IdentityFacade) { }

}
