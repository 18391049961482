export enum ResponseStatusCode {
	UnknownError = 0,
	Ok = 200,
	Created = 201,
	NoContent = 204,
	Redirect = 302,
	SeeOther = 303,
	BadRequest = 400,
	Unauthorized = 401,
	TransactionDeclined = 402,
	Forbidden = 403,
	NotFound = 404,
	Timeout = 408,
	Conflict = 409,
	RateLimited = 429,
	InternalServerError = 500,
	GatewayTimeout = 504,
}

export const RESPONSE_STATUS_CODE_MESSAGES = {
	[ResponseStatusCode.BadRequest]: 'Bad request',
	[ResponseStatusCode.Unauthorized]: 'Unauthorized',
	[ResponseStatusCode.TransactionDeclined]: 'Transaction declined',
	[ResponseStatusCode.Forbidden]: 'Access forbidden',
	[ResponseStatusCode.NotFound]: 'Not found',
	[ResponseStatusCode.Timeout]: 'The operation has timed out',
	[ResponseStatusCode.RateLimited]: 'You are being rate limited',
	get [ResponseStatusCode.GatewayTimeout](): string {
		return this[ResponseStatusCode.Timeout];
	},
	[ResponseStatusCode.InternalServerError]: 'Something is wrong!',
};
