<bp-input
	[formControl]="internalControl"
	[type]="isPasswordVisible ? 'text' : 'password'"
	[nativeAutocomplete]="nativeAutocomplete"
	[label]="label"
	[required]="!!required"
	[mask]="mask"
	[maxLength]="maxLength"
	[placeholder]="placeholder"
	[name]="name"
	[hint]="hint"
	[appearance]="appearance"
	[floatLabel]="floatLabel"
	[hideClearButton]="hideClearButton"
	[color]="color"
	[hideErrorText]="hideErrorText"
	[pending]="pending"
>
	<button
		*ngIf="internalControl.value && !internalControl.disabled"
		@fade
		bpInputSuffix
		mat-icon-button
		type="button"
		(click)="isPasswordVisible = !isPasswordVisible"
	>
		<mat-icon>
			{{ isPasswordVisible ? 'visibility_off' : 'visibility' }}
		</mat-icon>
	</button>
</bp-input>
