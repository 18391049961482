<form *ngIf="form" [formGroup]="form" (ngSubmit)="submit()">
	<bp-alert [errors]="errors" type="danger"> </bp-alert>

	<!-- to kick in the browsers save password feature with upon login -->
	<input type="text" name="email" [value]="email" hidden />

	<bp-input
		type="password"
		label="Password"
		nativeAutocomplete="new-password"
		formControlName="password"
		required
	></bp-input>

	<bp-input
		class="mb-4"
		type="password"
		label="Confirm Password"
		nativeAutocomplete="new-password"
		formControlName="confirmPassword"
		required
	></bp-input>

	<button type="submit" class="bp-round-button mt-auto" mat-flat-button [disabled]="pending$ | async" color="primary">
		Create
	</button>

	<div class="aux-buttons">
		<button class="link" type="button" (click)="login.emit()">Login</button>
	</div>
</form>
