import { take } from 'lodash-es';

import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({
	name: 'take',
})
export class TakePipe implements PipeTransform {
	transform<T>(array: T[] | null | undefined, size: number): T[] | null {
		return array ? take(array, size) : null;
	}
}
