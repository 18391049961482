<bp-alert [errors]="(introFacade.error$ | async)?.messages" type="danger"> </bp-alert>

<div>You will be sent an email with instructions on how to reset your authenticator app.</div>

<button
	class="bp-round-button mt-auto"
	type="submit"
	mat-flat-button
	color="primary"
	[disabled]="introFacade.pending$ | async"
	(click)="identityFacade.sendResetAuthenticatorAppLink()"
>
	Send Email
</button>

<div class="aux-buttons">
	<button class="link" type="button" (click)="introFacade.gotoLoginPage()">Login</button>
</div>
