import type { MetadataEntity } from '../metadata-entity';

import { Property } from './property-metadata.decorator';

/**
 * Map the property value from the constructor data parameter
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export function MapFromDTO() {
	return function(model: MetadataEntity, property: string): void {
		Property({})(model, property);
	};
}
