<ng-container *ngIf="hasSeparator">
	<hr *bpDelayedRender="0; stagger: renderStagger; allStagger: true" @fadeInSemiSlow />
</ng-container>

<ng-container *ngIf="heading">
	<h2 *bpDelayedRender="0; stagger: renderStagger; allStagger: true" @fadeInSemiSlow class="mat-h2">{{ heading }}</h2>
</ng-container>

<ng-container *ngIf="propertiesMetadata; else sectionSchemeTpl">
	<ng-container *ngFor="let rowPropsMd of propertiesMetadata | chunk: 2">
		<div *bpDelayedRender="0; stagger: renderStagger; allStagger: true" @fadeInSemiSlow class="row">
			<ng-container *ngFor="let propertyMetadata of rowPropsMd | takePresent">
				<ng-template
					[ngTemplateOutlet]="propertyMetadataViewTpl"
					[ngTemplateOutletContext]="{ $implicit: propertyMetadata }"
				>
				</ng-template>
			</ng-container>
		</div>
	</ng-container>
</ng-container>

<ng-template #sectionSchemeTpl>
	<ng-container *ngFor="let rowCellsDefs of sectionScheme | takePresent">
		<div *bpDelayedRender="0; stagger: renderStagger; allStagger: true" @fadeInSemiSlow class="row">
			<ng-template
				*ngFor="let propertyName of rowCellsDefs | takePresent"
				[ngTemplateOutlet]="propertyMetadataViewTpl"
				[ngTemplateOutletContext]="{ $implicit: getPropertyMetadata(propertyName) }"
			>
			</ng-template>
		</div>
	</ng-container>
</ng-template>

<ng-template #propertyMetadataViewTpl let-propertyMetadata>
	<bp-property-metadata-view
		[metadata]="propertyMetadata"
		[value]="get(entity, propertyMetadata.property)"
		[customValueView]="customValueViewPerProperty.get(propertyMetadata.property)"
	>
	</bp-property-metadata-view>
</ng-template>
