import type { ModuleWithProviders } from '@angular/core';
import { NgModule } from '@angular/core';

import { ChunkPipe } from './chunk.pipe';
import { BpCurrencyPipe } from './currency.pipe';
import { EntriesPipe } from './entries.pipe';
import { IsPresentPipe } from './is-present.pipe';
import { JoinPipe } from './join.pipe';
import { MomentFormatPipe, MomentFromNowPipe, MomentIsSameOrAfterNowPipe, MomentToNowPipe } from './moment';
import { PropertiesMetadataColspanPipe } from './properties-metadata-colspan.pipe';
import { RangePipe } from './range.pipe';
import { SafePipe } from './safe.pipe';
import { ShallowFilterPipe } from './shallow-filter.pipe';
import { TitleCasePipe } from './title-case.pipe';
import { SumByPipe } from './sum-by.pipe';
import { TakePresentPipe } from './take-present.pipe';
import { TakePipe } from './take.pipe';
import { ToKeyValuePairsPipe } from './to-key-value-pairs.pipe';
import { UpperFirstPipe } from './upper-first.pipe';
import { TranslatePipe } from './translate';
import { KebabCasePipe } from './kebab-case.pipe';
import { ValueOfPipe } from './value-of.pipe';
import { DisplayValuePipe } from './display-value.pipe';
import { DividePipe } from './divide.pipe';
import { SubtractPercentPipe } from './subtract-percent.pipe';
import { EnsureFormGroupPipe } from './ensure-form-group.pipe';
import { IsBooleanPipe } from './is-boolean.pipe';
import { IsNumberPipe } from './is-number.pipe';
import { WrapSubstringWithTagPipe } from './wrap-substring-with-tag.pipe';
import { InRangePipe } from './in-range.pipe';
import { BpTruncatePipe } from './truncate.pipe';

const EXPOSED = [
	UpperFirstPipe,
	IsPresentPipe,
	ToKeyValuePairsPipe,
	MomentFormatPipe,
	MomentFromNowPipe,
	MomentToNowPipe,
	MomentIsSameOrAfterNowPipe,
	SafePipe,
	ChunkPipe,
	EntriesPipe,
	TitleCasePipe,
	TakePipe,
	TakePresentPipe,
	PropertiesMetadataColspanPipe,
	BpCurrencyPipe,
	SumByPipe,
	ShallowFilterPipe,
	RangePipe,
	JoinPipe,
	TranslatePipe,
	KebabCasePipe,
	ValueOfPipe,
	DisplayValuePipe,
	DividePipe,
	SubtractPercentPipe,
	EnsureFormGroupPipe,
	IsBooleanPipe,
	IsNumberPipe,
	WrapSubstringWithTagPipe,
	InRangePipe,
	BpTruncatePipe,
];

@NgModule({
	exports: EXPOSED,
	declarations: EXPOSED,
})
export class SharedPipesModule {

	static forRoot(): ModuleWithProviders<SharedPipesModule> {
		return {
			ngModule: SharedPipesModule,
			providers: [
				BpCurrencyPipe,
				DisplayValuePipe,
			],
		};
	}

}
