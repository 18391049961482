<mat-toolbar class="flex-align-items-center" color="primary">
	<h2>Notifications</h2>

	<div class="ml-auto flex-align-items-center">
		<ng-container *ngIf="notificationsHubFacade.hasNotImportant$ | async">
			<button
				*ngIf="notificationsHubFacade.hasUnread$ | async; else clearAllTpl"
				@fadeIn
				mat-button
				class="font-weight-normal"
				(click)="notificationsHubFacade.markAllAsRead()"
			>
				Mark all as read
			</button>

			<ng-template #clearAllTpl>
				<button
					*ngIf="notificationsHubFacade.hasRemovable$ | async"
					@fadeIn
					mat-button
					class="font-weight-normal"
					(click)="notificationsHubFacade.removeAll()"
				>
					Clear all
				</button>
			</ng-template>
		</ng-container>

		<button mat-icon-button (click)="notificationsHubFacade.close()">
			<mat-icon> close </mat-icon>
		</button>
	</div>
</mat-toolbar>
