import { NgModule } from '@angular/core';
import type { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';

import { MerchantAdminFeature, IdentityLoggedOutGuard } from '@bp/shared-domains-identity';

import {
	incompleteIdentityHasAccessGuardConfig,
	IncompleteIdentityHasAccessGuard
} from '@bp/admins-shared/domains/identity';

import { IntroPageStep } from './models';
import {
	CreateAccountPageComponent,
	AcceptInvitePageComponent,
	IntroShellPageComponent,
	LoginPageComponent,
	OtpOnLoginPageComponent,
	RegisterAuthenticatorAppPageComponent,
	ResetAuthenticatorAppLinkSentPageComponent,
	ResetAuthenticatorAppPageComponent,
	ResetAuthenticatorAppVerificationPageComponent,
	ResetPasswordLinkSentPageComponent,
	ResetPasswordPageComponent,
	ResetPasswordVerificationPageComponent,
	SendResetAuthenticatorAppLinkPageComponent,
	SendResetPasswordLinkPageComponent,
	SessionExpiredPageComponent,
	SetSecurityQuestionsAnswersPageComponent,
	EmailVerificationPageComponent,
	CreatePasswordPageComponent
} from './pages';

const routes: Routes = [
	{
		path: 'intro',
		component: IntroShellPageComponent,
		data: {
			fullscreen: true,
		},
		canActivate: [ IdentityLoggedOutGuard ],
		children: [
			{
				path: IntroPageStep.login.kebabName,
				component: LoginPageComponent,
				data: {
					step: IntroPageStep.login,
					title: IntroPageStep.login.titleDisplayName,
				},
			},
			{
				path: IntroPageStep.otpOnLogin.kebabName,
				component: OtpOnLoginPageComponent,
				canActivate: [ IncompleteIdentityHasAccessGuard ],
				data: {
					step: IntroPageStep.otpOnLogin,
					title: IntroPageStep.otpOnLogin.titleDisplayName,
					...incompleteIdentityHasAccessGuardConfig(MerchantAdminFeature.otpVerify),
				},
			},

			// #region SECTION Signup Via Invite
			{
				path: IntroPageStep.acceptInvite.kebabName,
				component: AcceptInvitePageComponent,
				canActivate: [ IncompleteIdentityHasAccessGuard ],
				data: {
					step: IntroPageStep.acceptInvite,
					title: IntroPageStep.acceptInvite.titleDisplayName,
					...incompleteIdentityHasAccessGuardConfig(MerchantAdminFeature.confirmationEmail),
				},
			},
			{
				path: IntroPageStep.createAccount.kebabName,
				component: CreateAccountPageComponent,
				canActivate: [ IncompleteIdentityHasAccessGuard ],
				data: {
					step: IntroPageStep.createAccount,
					title: IntroPageStep.createAccount.titleDisplayName,
					...incompleteIdentityHasAccessGuardConfig(MerchantAdminFeature.createAccount),
				},
			},

			// #region deprecated, remove after accepting invite implementation by backend

			{
				path: IntroPageStep.emailVerification.kebabName,
				component: EmailVerificationPageComponent,
				canActivate: [ IncompleteIdentityHasAccessGuard ],
				data: {
					step: IntroPageStep.emailVerification,
					title: IntroPageStep.emailVerification.titleDisplayName,
					...incompleteIdentityHasAccessGuardConfig(MerchantAdminFeature.confirmationEmail),
				},
			},
			{
				path: IntroPageStep.createPassword.kebabName,
				component: CreatePasswordPageComponent,
				canActivate: [ IncompleteIdentityHasAccessGuard ],
				data: {
					step: IntroPageStep.createPassword,
					title: IntroPageStep.createPassword.titleDisplayName,
					...incompleteIdentityHasAccessGuardConfig(MerchantAdminFeature.setPasswordOnLogin),
				},
			},

			// #endregion !deprecated

			{
				path: IntroPageStep.setSecurityQuestionsAnswers.kebabName,
				component: SetSecurityQuestionsAnswersPageComponent,
				canActivate: [ IncompleteIdentityHasAccessGuard ],
				data: {
					step: IntroPageStep.setSecurityQuestionsAnswers,
					title: IntroPageStep.setSecurityQuestionsAnswers.titleDisplayName,
					...incompleteIdentityHasAccessGuardConfig(MerchantAdminFeature.setSecurityQuestionsAnswers),
				},
			},
			{
				path: IntroPageStep.registerAuthenticatorApp.kebabName,
				component: RegisterAuthenticatorAppPageComponent,
				canActivate: [ IncompleteIdentityHasAccessGuard ],
				data: {
					step: IntroPageStep.registerAuthenticatorApp,
					title: IntroPageStep.registerAuthenticatorApp.titleDisplayName,
					...incompleteIdentityHasAccessGuardConfig(MerchantAdminFeature.registerAuthenticator),
				},
			},
			// #endregion !SECTION

			// #region SECTION reset password
			{
				path: IntroPageStep.sendResetPasswordLink.kebabName,
				component: SendResetPasswordLinkPageComponent,
				data: {
					step: IntroPageStep.sendResetPasswordLink,
					title: IntroPageStep.sendResetPasswordLink.titleDisplayName,
				},
			},
			{
				path: IntroPageStep.resetPasswordLinkSent.kebabName,
				component: ResetPasswordLinkSentPageComponent,
				data: {
					step: IntroPageStep.resetPasswordLinkSent,
					title: IntroPageStep.resetPasswordLinkSent.titleDisplayName,
				},
			},
			{
				path: IntroPageStep.resetPasswordVerification.kebabName,
				component: ResetPasswordVerificationPageComponent,
				canActivate: [ IncompleteIdentityHasAccessGuard ],
				data: {
					step: IntroPageStep.resetPasswordVerification,
					title: IntroPageStep.resetPasswordVerification.titleDisplayName,
					...incompleteIdentityHasAccessGuardConfig([
						MerchantAdminFeature.verifyAnswerBeforeResetPassword,
						MerchantAdminFeature.otpResetPasswordVerification,
					]),
				},
			},
			{
				path: IntroPageStep.resetPassword.kebabName,
				component: ResetPasswordPageComponent,
				canActivate: [ IncompleteIdentityHasAccessGuard ],
				data: {
					step: IntroPageStep.resetPassword,
					title: IntroPageStep.resetPassword.titleDisplayName,
					...incompleteIdentityHasAccessGuardConfig([
						MerchantAdminFeature.resetPassword,
						MerchantAdminFeature.resetExpiredPassword,
					]),
				},
			},
			// #endregion !SECTION

			// #region SECTION reset authenticator app
			{
				path: IntroPageStep.sendResetAuthenticatorAppLink.kebabName,
				component: SendResetAuthenticatorAppLinkPageComponent,
				data: {
					step: IntroPageStep.sendResetAuthenticatorAppLink,
					title: IntroPageStep.sendResetAuthenticatorAppLink.titleDisplayName,
				},
			},
			{
				path: IntroPageStep.resetAuthenticatorAppLinkSent.kebabName,
				component: ResetAuthenticatorAppLinkSentPageComponent,
				data: {
					step: IntroPageStep.resetAuthenticatorAppLinkSent,
					title: IntroPageStep.resetAuthenticatorAppLinkSent.titleDisplayName,
				},
			},
			{
				path: IntroPageStep.resetAuthenticatorAppVerification.kebabName,
				component: ResetAuthenticatorAppVerificationPageComponent,
				canActivate: [ IncompleteIdentityHasAccessGuard ],
				data: {
					step: IntroPageStep.resetAuthenticatorAppVerification,
					title: IntroPageStep.resetAuthenticatorAppVerification.titleDisplayName,
					...incompleteIdentityHasAccessGuardConfig(
						MerchantAdminFeature.verifyAnswerBeforeResetAuthenticator,
					),
				},
			},
			{
				path: IntroPageStep.resetAuthenticatorApp.kebabName,
				component: ResetAuthenticatorAppPageComponent,
				canActivate: [ IncompleteIdentityHasAccessGuard ],
				data: {
					step: IntroPageStep.resetAuthenticatorApp,
					title: IntroPageStep.resetAuthenticatorApp.titleDisplayName,
					...incompleteIdentityHasAccessGuardConfig(MerchantAdminFeature.resetAuthenticator),
				},
			},
			// #endregion !SECTION

			{
				path: IntroPageStep.sessionExpired.kebabName,
				component: SessionExpiredPageComponent,
				data: {
					step: IntroPageStep.sessionExpired,
					title: IntroPageStep.sessionExpired.titleDisplayName,
				},
			},

			{
				path: '**',
				redirectTo: IntroPageStep.login.kebabName,
			},
		],
	},
];

@NgModule({
	imports: [ RouterModule.forChild(routes) ],
	exports: [ RouterModule ],
})
export class AdminsSharedPagesIntroRoutingModule {}
