<span class="flag-wrapper" [bpTooltip]="compact && country && !hideTooltip ? country?.name : ''">
	<bp-svg-icon
		*ngIf="worldwide || countryIsWorldwide; else defaultTpl"
		name="earth"
		class="flag"
		[ngClass]="{ round: round }"
	>
	</bp-svg-icon>

	<ng-template #defaultTpl>
		<span
			class="flag flag-icon flag-icon-{{ country?.lowerCaseCode || '' }}"
			[ngClass]="{ 'flag-icon-squared round': round }"
		></span>
	</ng-template>
</span>

<span *ngIf="!compact" class="capitalize country-name">
	{{ country?.name }}
</span>
