import { Feature } from '../feature';
import { CommonFeatureActions, EditAndAccessFeatureActions } from '../feature-actions';

export class BridgerAdminFeature<T extends CommonFeatureActions = CommonFeatureActions> extends Feature<T> {
	static changePassword = new BridgerAdminFeature(CommonFeatureActions);

	static bridgerPsps = new BridgerAdminFeature(EditAndAccessFeatureActions);

	static bridgerPspSystemDetails = new BridgerAdminFeature(EditAndAccessFeatureActions);
}
