import { PaymentCardBrand, PspPaymentOptionType } from '@bp/shared/models/business';
import { Currency } from '@bp/shared/models/currencies';
import type { DTO } from '@bp/shared/models/metadata';
import {
	FieldViewType, View, Hint, Control, Default, FieldControlType, Mapper, Required
} from '@bp/shared/models/metadata';

import { BridgerPspPaymentOptionCreditCardSummary } from '../summaries';

import { BridgerPspPaymentOptionBase } from './bridger-psp-payment-option-base';
import {
	 BridgerPspPaymentOptionCreditCardSecurityProtocol
} from './bridger-psp-payment-option-credit-card-security-protocol';

export class BridgerPspPaymentOptionCreditCard extends BridgerPspPaymentOptionBase implements BridgerPspPaymentOptionCreditCardSummary {

	static override readonly type = PspPaymentOptionType.creditCard;

	readonly type = BridgerPspPaymentOptionCreditCard.type;

	@Control(
		FieldControlType.brandChips,
		{
			list: PaymentCardBrand.getList(),
		},
	)
	@Mapper(PaymentCardBrand)
	@Default([])
	@Required()
	brands!: PaymentCardBrand[];

	@Control(
		FieldControlType.chip,
		{
			list: Currency.list,
			placeholder: 'Add currency...',
		},
	)
	@Mapper(Currency)
	@Default([])
	@Required()
	currencies!: Currency[];

	@Control(
		FieldControlType.chip,
		{
			list: BridgerPspPaymentOptionCreditCardSecurityProtocol.getList(),
			placeholder: 'Add security protocol...',
		},
	)
	@View(FieldViewType.chip)
	@Mapper(BridgerPspPaymentOptionCreditCardSecurityProtocol)
	@Default([ BridgerPspPaymentOptionCreditCardSecurityProtocol.twoDSecure ])
	@Hint('Just a visual hint what security protocols this psp has')
	securityProtocols!: BridgerPspPaymentOptionCreditCardSecurityProtocol[];

	constructor(dto: DTO<BridgerPspPaymentOptionCreditCard>) {
		super(dto);
	}

}
