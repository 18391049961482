import type { MetadataEntity } from '../metadata-entity';
import { PropertyMetadataTable } from '../property-metadata-table';

/**
 * Properties marked by this decorator will be used for constructing columns for mat-table by the TableHostComponent
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export function Table(config: Partial<PropertyMetadataTable> = {}) {
	return function(model: MetadataEntity, property: string): void {
		const propertyMd = model.classMetadata.get(<any>property);
		const currentTableConfig = propertyMd ? propertyMd.table : {};

		model.classMetadata
			.add(property, {
				table: new PropertyMetadataTable({
					...currentTableConfig,
					...config,
				}),
			});
	};
}
