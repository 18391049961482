import { isNumber } from 'lodash-es';
import { BehaviorSubject, EMPTY, of, Subject } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';

import { Directive, Input, Output } from '@angular/core';

import { ControlComponent } from '@bp/shared/components/core';
import { FirebaseService } from '@bp/shared/services';
import { takeUntilDestroyed } from '@bp/shared/models/common';

// Base component class which provides handy methods and props to prevent concurrency on firestore upload.
@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class FirestoreUploadControlComponent extends ControlComponent<string> {

	@Input() bucketPath!: string;

	@Output('busy') readonly busy$ = new Subject();

	isPerformUploading$ = new BehaviorSubject<boolean>(false);

	canUpload$ = this._firebase.uploadProgress$.pipe(
		startWith(null),
		map(progress => progress === null),
	);

	uploadProgress$ = this.isPerformUploading$.pipe(
		switchMap(yes => yes ? this._firebase.uploadProgress$ : of(null)),
	);

	uploadedDownloadUrl$ = this.isPerformUploading$.pipe(
		switchMap(yes => yes ? this._firebase.uploadedDownloadUrl$ : EMPTY),
	);

	uploadError$ = this.isPerformUploading$.pipe(
		switchMap(yes => yes ? this._firebase.uploadError$ : EMPTY),
	);

	constructor(
		protected _firebase: FirebaseService,
	) {
		super();

		this.isPerformUploading$
			.pipe(
				switchMap(yes => yes ? this._firebase.uploadProgress$ : EMPTY),
				takeUntilDestroyed(this),
			)
			.subscribe(v => void this.busy$.next(isNumber(v)));

		this.isPerformUploading$
			.pipe(
				switchMap(yes => yes ? this._firebase.uploadedDownloadUrl$ : EMPTY),
				takeUntilDestroyed(this),
			)
			.subscribe(v => {
				this.isPerformUploading$.next(false);

				this.setValue(v);
			});

		this._firebase.uploadError$
			.pipe(takeUntilDestroyed(this))
			.subscribe(() => void this.isPerformUploading$.next(false));
	}

}
