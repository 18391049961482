import { isArray, isMap, isObject, mapValues, snakeCase } from 'lodash-es';
import type { Observable } from 'rxjs';
import { concat } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { HttpContext, HttpContextToken, HttpHandler, HttpInterceptor, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { transformMapToObject } from '@bp/shared/utilities';
import { takeFirstTruthy } from '@bp/shared/rxjs';
import { SORT_FIELD } from '@bp/shared/models/common';

import { MockedBackendState } from '../state-keepers';

import { CONTENT_TYPE_HEADER, HttpConfigService } from './http-config.service';

export const BYPASS_AUTHORIZATION_HEADER_CHECK = new HttpContextToken<boolean>(() => true);

export function bypassAuthorizationHeaderCheck(): HttpContext {
	return new HttpContext().set(BYPASS_AUTHORIZATION_HEADER_CHECK, true);
}

@Injectable()
export class HttpRequestInterceptorService implements HttpInterceptor {

	constructor(
		private readonly _httpConfig: HttpConfigService,
	) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
		return this._checkIfShouldWaitForAuthorizationHeader(request)
			? this._httpConfig.hasAuthorizationToken$.pipe(
				switchMap(() => this._enhanceRequest(request, next)),
			)
			: this._enhanceRequest(request, next);
	}

	private _checkIfShouldWaitForAuthorizationHeader(request: HttpRequest<any>): boolean {
		return !(/.*\.json$/u).test(request.url)
			&& !request.url.includes('auth')
			&& !request.context.get(BYPASS_AUTHORIZATION_HEADER_CHECK);
	}

	private _enhanceRequest(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
		return concat(
			MockedBackendState.isNoApiMockPluginsIniting$.pipe(takeFirstTruthy),
			next.handle(request.clone({
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				body: this._normalizeBody(request.body),
				params: this._normalizeParams(request.params),
				url: this._shouldPrependBackendBaseSegmentToHttpRequestUrl(request)
					? `${ this._httpConfig.backendBaseSegment }/${ request.url }`
					: request.url,
				setHeaders: {
					...(request.url.startsWith('http') ? {} : this._httpConfig.headers),
					[CONTENT_TYPE_HEADER]: request.headers.get(CONTENT_TYPE_HEADER) ?? this._httpConfig.headers[CONTENT_TYPE_HEADER] ?? '',
				},
			})),
		);
	}

	private _normalizeParams(params: HttpParams): HttpParams {
		if (params.has(SORT_FIELD))
			params = params.set(SORT_FIELD, snakeCase(params.get(SORT_FIELD)!));

		return params;
	}

	private _normalizeBody(body: any): any {
		if (!isObject(body) || isArray(body))
			return body;

		if (isMap(body))
			return transformMapToObject(body);

		return mapValues(body, v => isMap(v) ? transformMapToObject(v) : v);
	}

	private _shouldPrependBackendBaseSegmentToHttpRequestUrl(request: HttpRequest<any>): boolean {
		return !request.url.startsWith('http')
			&& !request.url.includes('assets') // All assets is relative to the origin
			&& !request.url.startsWith('/');
	}
}
