import { Control, DTO, FieldControlType, MetadataEntity, Required } from '@bp/shared/models/metadata';

export class CreateAccountApiRequest extends MetadataEntity {
	@Required()
	@Control(
		FieldControlType.input,
		{
			nativeAutocomplete: 'given-name',
		},
	)
	firstName!: string;

	@Required()
	@Control(
		FieldControlType.input,
		{
			nativeAutocomplete: 'family-name',
		},
	)
	lastName!: string;

	@Required()
	password!: string;

	@Required()
	confirmPassword!: string;

	constructor(dto?: DTO<CreateAccountApiRequest>) {
		super(dto);
	}

}
