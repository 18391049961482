import { isFunction, isNil } from 'lodash-es';

import { isEmpty } from '@bp/shared/utilities';

import { MaskPipe } from './mask-pipe';
import type { TextMask, TextMaskConfig } from './text-mask.config';

export class TextMaskPipe extends MaskPipe {

	constructor(config: TextMaskConfig) {
		super(config);
	}

	protected _transformBody(rawValue: string): TextMask | undefined {
		const resolvedMask = isFunction(this.mask) ? this.mask(rawValue) : this.mask;

		if (isNil(resolvedMask) && this.prefixLength === 0 && this.suffixLength === 0)
			return;

		this.formatChars = resolvedMask
			? <string[]> [
				...resolvedMask.filter(char => !(char instanceof RegExp)),
				...this.config.placeholderChar,
			]
			: [];

		if (this.prefixLength === 0
			&& this.suffixLength === 0
			|| rawValue === ''
			|| (rawValue.startsWith(this.prefix[0]) && rawValue.length === 1))
			return resolvedMask ?? [ null ];

		const refinedValue = resolvedMask
			? undefined
			: this._removePrefixAndSuffix(rawValue);

		const maskFromRefinedValue = isEmpty(refinedValue)
			? [ null ]
			: [ ...refinedValue ]
				.map(() => /./u);

		return resolvedMask ?? maskFromRefinedValue;
	}
}
