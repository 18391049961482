import { ChangeDetectionStrategy, Component } from '@angular/core';

import { BridgerAdminFeature } from '@bp/shared-domains-identity';

@Component({
	selector: 'bp-menu',
	templateUrl: './menu.component.html',
	styleUrls: [ './menu.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	Feature = BridgerAdminFeature;
}
