import { Inject, Injectable, InjectionToken } from '@angular/core';

import { OptionalBehaviorSubject } from '@bp/shared/rxjs';
import type { IEnvironment, Platform } from '@bp/shared/typings';
import { getLocationHostDomain } from '@bp/shared/utilities';

export const PLATFORM = new InjectionToken<Platform>('PLATFORM');

@Injectable({
	providedIn: 'root',
})
export class EnvironmentService implements IEnvironment {

	private static _environment: IEnvironment;

	private static _hostDomain: string;

	private static _cashierLoaderUrl: string;

	static init(environment: IEnvironment): void {
		EnvironmentService._environment = environment;

		EnvironmentService._hostDomain = getLocationHostDomain();

		EnvironmentService._setCashierLoaderUrlAccordingToHostDomain();
	}

	private static _setCashierLoaderUrlAccordingToHostDomain(): void {
		EnvironmentService._cashierLoaderUrl = EnvironmentService._environment.cashierLoaderUrl
			.replace('{{hostDomain}}', EnvironmentService._hostDomain);
	}

	private readonly _environment = EnvironmentService._environment;

	readonly api = this._environment.api;

	readonly apiVersion = this._environment.apiVersion;

	readonly appVersion = this._environment.appVersion;

	readonly hostDomain = EnvironmentService._hostDomain;

	readonly cashierLoaderUrl = EnvironmentService._cashierLoaderUrl;

	readonly merchantAdminUrl = this._environment.merchantAdminUrl;

	readonly wizardUrl = this._environment.wizardUrl;

	readonly name = this._environment.name;

	readonly isLocalServer = this._environment.isLocalServer;

	readonly isRemoteServer = this._environment.isRemoteServer;

	readonly isProduction = this._environment.name === 'production';

	readonly isNotProduction = !this.isProduction;

	readonly isStaging = this._environment.name === 'staging';

	readonly isStagingOrLocal = this.isStaging || this.isLocalServer;

	readonly isBackoffice = location.hostname.includes('backoffice');

	private readonly _backendVersion$ = new OptionalBehaviorSubject<string>();

	readonly backendVersion$ = this._backendVersion$.asObservable();

	// #region SECTION Third Party Keys

	readonly sentry = this._environment.sentry;

	readonly intercom = this._environment.intercom;

	readonly survicate = this._environment.survicate;

	readonly logrocket = this._environment.logrocket;

	readonly googleTagAnalyticsId = this._environment.googleTagAnalyticsId;

	readonly googleTagTransportUrl = this._environment.googleTagTransportUrl;

	readonly livestormToken = this._environment.livestormToken;

	readonly oauthGoogleClientId = this._environment.oauthGoogleClientId;

	// #endregion !SECTION

	constructor(@Inject(PLATFORM) public readonly platform: Platform) { }

	setBackendVersion(version: string): void {
		if (version === this._backendVersion$.value)
			return;

		this._backendVersion$.next(version);
	}

}
