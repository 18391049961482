import { isEmpty, isString } from 'lodash-es';
import { merge, Subject, Subscription } from 'rxjs';
import { auditTime, debounceTime, filter, switchMap } from 'rxjs/operators';

import { OnInit, Directive, ElementRef, Input, HostBinding, inject, InjectFlags } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, FormGroupDirective } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { FloatLabelType, MatFormFieldAppearance } from '@angular/material/form-field';

import { OptionalBehaviorSubject } from '@bp/shared/rxjs';
import { attrBoolValue, isPresent, bpQueueMicrotask, uuid } from '@bp/shared/utilities';
import { takeUntilDestroyed } from '@bp/shared/models/common';
import { OnChanges, SimpleChanges } from '@bp/shared/models/core';

import { ControlComponent } from './control.component';
import { FormFieldAppearance, FORM_FIELD_DEFAULT_OPTIONS } from './form-field-default-options';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class FormFieldControlComponent<TControlValue, TInternalControlValue = TControlValue>
	extends ControlComponent<TControlValue>
	implements OnChanges, OnInit {

	@Input() formControl?: UntypedFormControl;

	@Input() formControlName!: string;

	@Input() appearance?: FormFieldAppearance;

	@Input() floatLabel?: FloatLabelType;

	@Input() color?: ThemePalette;

	@Input() name!: string;

	@Input() placeholder!: string;

	@Input() label?: string | null;

	@Input() hint?: string | null;

	@Input() nativeAutocomplete?: boolean | string = true;

	@Input() required?: boolean | '' | null;

	@Input() hideClearButton?: boolean | '' | null;

	@Input() disabled?: boolean | null;

	@Input() throttle?: number | '';

	@Input() debounce?: number | '';

	@Input() hideErrorText?: boolean | '' | null;

	@Input() pending?: boolean | null;

	defaultOptions = inject(FORM_FIELD_DEFAULT_OPTIONS, InjectFlags.Optional);

	protected _host = <ElementRef<HTMLElement>>inject(ElementRef);

	get $$appearance(): FormFieldAppearance | null {
		return this.appearance ?? this.defaultOptions?.appearance ?? null;
	}

	get matAppearance(): MatFormFieldAppearance {
		if (this.isRoundAppearance)
			return 'outline';

		return <MatFormFieldAppearance> (this.$$appearance ?? 'standard');
	}

	get $$floatLabel(): FloatLabelType {
		return this.floatLabel ?? this.defaultOptions?.floatLabel ?? 'auto';
	}

	@HostBinding('class.form-field-appearance-round')
	get isRoundAppearance(): boolean {
		return !!this.$$appearance?.startsWith('round');
	}

	@HostBinding('class.form-field-appearance-round-lg')
	get isRoundLargeAppearance(): boolean {
		return this.$$appearance === 'round-lg';
	}

	@HostBinding('class.form-field-compact')
	get isCompact(): boolean {
		return !!this.defaultOptions?.compact;
	}

	get externalControl(): UntypedFormControl | null {
		return this.formControl ?? <UntypedFormControl | undefined> this.form?.controls[this.formControlName] ?? null;
	}

	get form(): UntypedFormGroup | undefined {
		return this._formGroupDirective?.form;
	}

	internalControl = new UntypedFormControl(undefined, {
		updateOn: this.form?.updateOn,
	});

	externalControl$ = new OptionalBehaviorSubject<UntypedFormControl | null>();

	$host = this._host.nativeElement;

	get isFocused(): boolean {
		return this.$host === document.activeElement || this.$host.contains(document.activeElement);
	}

	private readonly _dummyNativeAutocompleteValue = `value-to-disable-native-autocomplete-${ uuid() }`;

	get $$attrAutocompleteValue(): string {
		return isString(this.nativeAutocomplete)
			? this.nativeAutocomplete
			: (this.nativeAutocomplete ? this.name : this._dummyNativeAutocompleteValue);
	}

	get $$name(): string {
		return this.nativeAutocomplete ? this.name : this.$$attrAutocompleteValue;
	}

	private readonly _formGroupDirective = inject(FormGroupDirective, InjectFlags.Optional);

	protected _onWriteValue$ = new Subject<void>();

	private _updateSubscription = Subscription.EMPTY;

	private _throttleTime = 0;

	private _debounceTime = 0;

	private readonly _defaultThrottleTime = 200;

	private readonly _defaultDebounceTime = 400;

	ngOnChanges({ formControl, formControlName, throttle, debounce, value }: SimpleChanges<this>): void {
		if (formControl || formControlName)
			this.externalControl$.next(this.externalControl);

		if (value)
			this.writeValue(this.value);

		if (throttle)
			this._throttleTime = this.throttle === '' ? this._defaultThrottleTime : this.throttle!;

		if (debounce)
			this._debounceTime = this.debounce === '' ? this._defaultDebounceTime : this.debounce!;

		if (throttle || debounce)
			this._listenToInternalControlValueChanges();
	}

	ngOnInit(): void {
		this.required = attrBoolValue(this.required);

		this.hideClearButton = attrBoolValue(this.hideClearButton);

		this.hideErrorText = attrBoolValue(this.hideErrorText) || this.defaultOptions?.hideErrorText;

		this._listenToInternalControlValueChanges();

		this._reflectExternalControlOnInternal();
	}

	// #region Implementation of the ControlValueAccessor interface
	override writeValue(value: TControlValue | null): void {
		bpQueueMicrotask(() => {
			this._setIncomingValue(value);

			this._setIncomingValueToInternalControl(value);

			this._onWriteValue$.next();
		});
	}

	setDisabledState?(isDisabled: boolean): void {
		if (isDisabled)
			this.internalControl.disable({ emitEvent: false });
		else
			this.internalControl.enable({ emitEvent: false });

		this._cdr.detectChanges();
	}
	// #endregion Implementation of the ControlValueAccessor interface

	abstract focus(): void;

	$$eraseInternalControlValue(): void {
		this.internalControl.markAsDirty();

		this.internalControl.setValue(null);
	}

	protected _setIncomingValue(value: TControlValue | null): void {
		this.setValue(value!, { emitChange: false });
	}

	protected _setIncomingValueToInternalControl<U = TControlValue>(value: U | null): void {
		this.internalControl.setValue(value, { emitEvent: false });

		this._cdr.markForCheck();
	}

	protected _onInternalControlValueChange(v: TInternalControlValue): void {
		this.setValue(<TControlValue><unknown>v);
	}

	private _listenToInternalControlValueChanges(): void {
		this._updateSubscription.unsubscribe();

		this._updateSubscription = (
			this._debounceTime
				? this.internalControl.valueChanges.pipe(debounceTime(this._debounceTime))
				: (this._throttleTime
					? this.internalControl.valueChanges.pipe(auditTime(this._throttleTime))
					: this.internalControl.valueChanges)
		)
			.pipe(
				takeUntilDestroyed(this),
				filter(() => this.internalControl.dirty),
			)
			.subscribe(v => {
				this.externalControl?.markAsDirty();

				this._onInternalControlValueChange(v);
			});
	}

	protected _reflectExternalControlOnInternal(): void {
		this.externalControl$
			.pipe(
				filter(isPresent),
				switchMap(externalControl => merge(
					externalControl.statusChanges,
					this._onWriteValue$,
				)),
				takeUntilDestroyed(this),
			)
			.subscribe(() => {
				this._reflectExternalControlStateOnInternalControl();

				this.internalControl.updateValueAndValidity({ onlySelf: true, emitEvent: false });

				const errors = {
					...this.externalControl?.errors,
					...this.internalControl.errors,
				};

				this.internalControl.setErrors(isEmpty(errors) ? null : errors);

				this._cdr.markForCheck();
			});
	}

	private _reflectExternalControlStateOnInternalControl(): void {
		if (this.externalControl?.dirty && this.internalControl.pristine)
			this.internalControl.markAsDirty();

		if (this.externalControl?.pristine && this.internalControl.dirty)
			this.internalControl.markAsPristine();

		if (this.externalControl?.touched && this.internalControl.untouched)
			this.internalControl.markAsTouched();

		if (this.externalControl?.untouched && this.internalControl.touched)
			this.internalControl.markAsUntouched();

	}
}
