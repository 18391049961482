/* eslint-disable unicorn/prefer-query-selector */
/* eslint-disable unicorn/prefer-spread */
import { forIn, forOwn, isBoolean, isObject, isString } from 'lodash-es';

import { Dimensions, Position, Size } from '@bp/shared/models/core';
import type { Dictionary } from '@bp/shared/typings';

export class $ {

	private static _$canvas?: HTMLCanvasElement;

	static addClass($element: Element, ...classes: string[]) {
		for (const cls of classes)
			$.setClass($element, cls, true);
	}

	static removeClass($element: Element, ...classes: string[]) {
		for (const cls of classes)
			$.setClass($element, cls, false);
	}

	static setClass($element: Element, cls: string, isAdd: boolean) {
		if (!cls)
			return;

		isAdd ? $element.classList.add(cls) : $element.classList.remove(cls);
	}

	static containsClass($element: Element, cls: string) {
		return $element.classList.contains(cls);
	}

	static setAttribute($element: Element, name: string, value: string, isAdd: boolean): void;
	static setAttribute($element: Element, name: string, isAdd: boolean): void;
	static setAttribute($element: Element, name: string, valueOrIsAdd: boolean | string, isAdd?: boolean): void {
		const value = isString(valueOrIsAdd) ? valueOrIsAdd : name;

		isAdd = isBoolean(valueOrIsAdd) ? valueOrIsAdd : isAdd;

		isAdd ? $element.setAttribute(name, value) : $element.removeAttribute(name);
	}

	// Searching methods
	static siblings(element: Element): Element[] {
		return element.parentNode
			? <Element[]> Array.from(element.parentNode.childNodes)
				.filter(child => child !== element)
			: [];
	}

	static filter(element: Element, selector: string): Element[] {
		return Array.from(element.querySelectorAll(selector));
	}

	static find(selector: string): Element[];
	static find(target: Element, selector?: string): Element[];
	static find(targetOrSelector: Element | string, selector?: string): Element[] {
		if (targetOrSelector instanceof Element) {
			return selector
				? Array.from(targetOrSelector.querySelectorAll(selector))
				: [];
		}

		return Array.from(document.querySelectorAll(targetOrSelector));
	}

	static findSingle<T = Element>(target: Element | string, selector?: string): T | null {
		if (target instanceof Element)
			return selector ? <T> <unknown> target.querySelector(selector) : null;

		return <T> <unknown> document.querySelector(target);
	}

	static closest(target: Element, selector: string): Element | null {
		while (target !== document.documentElement) {
			if (!target.parentElement)
				return null;

			target = target.parentElement;

			if (target.matches(selector))
				return target;
		}

		return null;
	}

	static hasParent(target: Element, parent: Element | string): boolean {
		while (target !== document.documentElement) {
			if (!target.parentElement)
				return false;

			target = target.parentElement;

			if (isString(parent) ? target.matches(parent) : target === parent)
				return true;
		}

		return false;
	}

	static is(element: Element, selector: ':hidden' | ':visible'): boolean {
		switch (selector) {
			case ':visible':
				return $.isVisible(element);

			case ':hidden':
				return !$.isVisible(element);

			default:
				throw new Error('Wrong selector has been put in \'IS\' function');
		}
	}

	static isVisible(element: Element): boolean {
		// First check if elem is hidden through css as this is not very costly
		const style = getComputedStyle(element);

		return style.display !== 'none'
			&& style.display !== ''
			&& style.visibility !== 'hidden'
			&& element.getAttribute('type') !== 'hidden'
			&& style.opacity !== '0';
	}

	static css(element: HTMLElement, styleName: string, styleValue: any): void;
	static css(element: HTMLElement, stylesDictionary: Record<string, any>): void;
	static css(element: HTMLElement, ...styles: any[]): void {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const dictionary = isObject(styles[0]) ? styles[0] : { [styles[0]]: styles[1] };

		forIn(dictionary, (value, style) => (element.style[<number> <unknown> style] = value));
	}

	static attr(element: HTMLElement, attributeName: string, attributeValue: any): void;
	static attr(element: HTMLElement, attributesDictionary: Record<string, any>): void;
	static attr(element: HTMLElement, ...attributes: any[]): void {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const dictionary = isObject(attributes[0]) ? attributes[0] : { [attributes[0]]: attributes[1] };

		forIn(dictionary, (value, attribute) => void element.setAttribute(attribute, value));
	}

	static parseCss(cssValue: string): number {
		return Math.ceil(Number.parseFloat(cssValue));
	}

	/**
	 * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
	 *
	 * @param {String} text The text to be rendered.
	 * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
	 *
	 * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
	 */
	static getTextWidth(text: string, font: string): number | null {
		// Re-use canvas object for better performance
		const canvas = $._$canvas ?? ($._$canvas = document.createElement('canvas'));
		const context = canvas.getContext('2d');

		if (context) {
			context.font = font;
			const metrics = context.measureText(text);

			return metrics.width;
		}

		return null;
	}

	/**
	 * Gets size of element without paddings and borders
	 */
	static innerSize(element: Element): Size {
		const style = getComputedStyle(element);

		return new Size(
			element.clientWidth - Number.parseInt(style.paddingLeft || '0') - Number.parseInt(style.paddingRight || '0'),
			element.clientHeight - Number.parseInt(style.paddingTop || '0') - Number.parseInt(style.paddingBottom || '0'),
		);
	}

	/**
	 * Gets size of element including paddings, borders, and margins
	 */
	static outerSize(element: Element): Size {
		const style = getComputedStyle(element);
		const { width, height } = element.getBoundingClientRect();

		return new Size(
			width + Number.parseInt(style.marginLeft || '0') + Number.parseInt(style.marginRight || '0'),
			height + Number.parseInt(style.marginTop || '0') + Number.parseInt(style.marginBottom || '0'),
		);
	}

	/**
	 * Get the element coordinates relative to the document
	 */
	static offset(element: Element): Dimensions {
		const { width, height, top, left } = element.getBoundingClientRect();

		return new Dimensions({
			width,
			height,
			top: top + window.scrollY,
			left: left + window.scrollX,
		});
	}

	/**
	 * Get the hidden element coordinates relative to the document
	 */
	static offsetHidden(element: HTMLElement): Dimensions {
		let offset: Dimensions;

		if (element.offsetWidth)
			offset = $.offset(element);
		else {
			element.style.visibility = 'hidden';

			element.style.display = '';

			offset = $.offset(element);

			element.style.display = 'none';

			element.style.visibility = '';
		}

		return offset;
	}

	/**
	 * Get the current coordinates of the element relative to the offset parent.
	 */
	static position(element: HTMLElement): Position {
		// Get correct offsets
		const offset = $.offset(element);
		const parentOffset = element.offsetParent instanceof Element ? $.offset(element.offsetParent) : { left: 0, top: 0 };
		const elementStyles = getComputedStyle(element);

		/*
		 * Subtract element margins
		 * note: when an element has margin: auto the offsetLeft and marginLeft
		 * are the same in Safari causing offset.left to incorrectly be 0
		 */
		offset.top -= Number.parseFloat(elementStyles.marginTop || '0');

		offset.left -= Number.parseFloat(elementStyles.marginLeft || '0');

		if (element.offsetParent) {
			const parentStyles = getComputedStyle(element.offsetParent);

			// Add offsetParent borders
			parentOffset.top += Number.parseFloat(parentStyles.borderTopWidth || '0');

			parentOffset.left += Number.parseFloat(parentStyles.borderLeftWidth || '0');
		}

		// Subtract the two offsets
		return new Position({
			top: offset.top - parentOffset.top,
			left: offset.left - parentOffset.left,
		});
	}

	static scroll(target: Element | Window, x: number, y: number) {
		if (target instanceof Window)
			target.scroll(x, y);
		else {
			target.scrollLeft = x;

			target.scrollTop = y;
		}
	}

	/**
	 * Gets scroll container for the @prop the target element.
	 */
	static getScrollContainer(target: Element): HTMLElement | Window {
		const scrollValues = new Set([ 'scroll', 'auto' ]);
		let parent = target.parentElement;

		while (parent) {
			const { overflow, overflowY, overflowX } = getComputedStyle(parent);

			if (scrollValues.has(overflow)
				|| scrollValues.has(overflowY)
				|| scrollValues.has(overflowX))
				return parent;

			parent = parent.parentElement;
		}

		return window;
	}

	/**
	 * Cleans targetId from '#' and checks on whitespaces
	 * @param  {string} targetId
	 * @return {string}
	 */
	static sanitizeTargetId(targetId: string): string {
		if ((/\s+/ug).test(targetId))
			throw new Error(`At sanitizeTargetId('${ targetId }') target argument has not allowed whitespaces`);

		return targetId.replace(/#/u, ''); // Remove first matched hash symbol
	}

	/**
	 * Gets HTMLElement by targetId if it's presented at the dom and has bounding client rect,
	 * which means target element doesn't have 'display:none' style.
	 * @param  {string}      targetId string which may represent Id of element or it's name
	 * @return {HTMLElement}
	 */
	static getTarget(targetId: string): HTMLElement | null {
		targetId = $.sanitizeTargetId(targetId);
		const target = targetId
			? document.getElementById(targetId) || document.getElementsByName(targetId)[0]
			: null;

		if (target?.getBoundingClientRect)
			return target;

		return null;
	}

	/**
	 * Create Image element with specified url string
	 */
	static createImage(source: string) {
		const img = new HTMLImageElement();

		img.src = source;

		return img;
	}

	/**
	 * Returns content of the meta-tag in head.
	 */
	static getMeta(name: string) {
		return $
			.findSingle(document.head, `meta[name=${ name }]`)
			?.getAttribute('content') ?? null;
	}

	static dispatchEvent($element: HTMLElement, eventName: string, bubbles = false, cancelable = false) {
		const event = new Event(eventName, { bubbles, cancelable });

		$element.dispatchEvent(event);
	}

	static buildAsyncScriptElement({
		code,
		src,
		data,
	}: {
		code?: string;
		src?: string;
		data?: Dictionary<string>;
	}): HTMLScriptElement {
		const $script = document.createElement('script');

		$script.type = 'text/javascript';

		$script.async = true;

		data && forOwn(data, (value, key) => ($script.dataset[key] = value));

		if (src)
			$script.src = src;
		else if (code) {
			try {
				$script.append(document.createTextNode(code));
			} catch {
				$script.text = code;
			}
		}

		return $script;
	}
}
