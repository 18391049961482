<bp-svg-shared-filters-definitions></bp-svg-shared-filters-definitions>
<bp-svg-shared-icons-definitions></bp-svg-shared-icons-definitions>
<bp-svg-admin-shared-icons-definitions></bp-svg-admin-shared-icons-definitions>

<mat-sidenav-container
	[hasBackdrop]="(layoutFacade.hasBackdrop$ | async) || (layoutFacade.hasFullpageBackdrop$ | async)"
	[class.fullscreen]="layoutFacade.fullscreen$ | async"
	[class.fullpage-backdrop]="layoutFacade.hasFullpageBackdrop$ | async"
	(backdropClick)="layoutFacade.closeFloatOutlets()"
>
	<ng-content></ng-content>
</mat-sidenav-container>
