<ng-template
	*ngIf="messages.length === 1; else listTpl"
	[ngTemplateOutlet]="msgTpl"
	[ngTemplateOutletContext]="{ $implicit: messages[0] }"
>
</ng-template>

<ng-template #listTpl>
	<ul>
		<li *ngFor="let m of messages">
			<ng-container *ngTemplateOutlet="msgTpl; context: { $implicit: m }"></ng-container>
		</li>
	</ul>
</ng-template>

<ng-template #msgTpl let-msg>
	<ng-template
		*ngIf="tpl; else defaultMsgViewTpl"
		[ngTemplateOutlet]="tpl"
		[ngTemplateOutletContext]="{ $implicit: msg }"
	>
	</ng-template>

	<ng-template #defaultMsgViewTpl>
		{{ msg }}
	</ng-template>
</ng-template>
