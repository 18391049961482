import { Directive, Input, TemplateRef } from '@angular/core';

import { NonFunctionPropertyNames } from '@bp/shared/typings';

@Directive({
	selector: '[bpCustomValueView]',
})
export class PropertyMetadataCustomValueViewDirective<TEntity> {

	@Input('bpCustomValueView') propertyName!: NonFunctionPropertyNames<TEntity>;

	@Input('bpCustomValueViewShowLabel') showLabel = true;

	constructor(public tpl: TemplateRef<any>) { }

}
