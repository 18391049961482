import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ROUTE_HIT_ANIMATIONS } from '@bp/shared/animations';

import { IdentityFacade } from '@bp/admins-shared/domains/identity';
import { LayoutFacade } from '@bp/admins-shared/features/layout';

@Component({
	selector: 'bp-root',
	templateUrl: './root.component.html',
	styleUrls: [ './root.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ ROUTE_HIT_ANIMATIONS ],
})
export class RootComponent {

	shouldAnimateOutlet!: boolean;

	constructor(
		public layoutFacade: LayoutFacade,
		public identityFacade: IdentityFacade,
	) { }

}
