<mat-form-field
	[color]="color"
	[appearance]="matAppearance"
	[hideRequiredMarker]="defaultOptions?.hideRequiredMarker"
	[floatLabel]="$$floatLabel"
	[class.form-field-pending]="pending"
>
	<mat-label *ngIf="label">
		{{ label }}
		<span *ngIf="required" class="mat-placeholder-required mat-form-field-required-marker"> * </span>
	</mat-label>

	<mat-chip-list #chipList [formControl]="externalControl!">
		<mat-chip
			*ngFor="let v of value"
			#chip
			@fade
			[color]="color"
			[bpTooltip]="v.description"
			[removable]="true"
			(removed)="remove(v)"
		>
			<ng-template
				*ngIf="portal; else defaultTpl"
				[ngTemplateOutlet]="portal!.templateRef"
				[ngTemplateOutletContext]="{ $implicit: v }"
			>
			</ng-template>

			<ng-template #defaultTpl>
				{{ getDisplayValue(v) }}
			</ng-template>
			<mat-icon matChipRemove>cancel</mat-icon>
		</mat-chip>

		<input
			#input
			[formControl]="internalControl"
			[attr.name]="name"
			[placeholder]="placeholder"
			[required]="!!required"
			[matAutocomplete]="autocomplete"
			[matChipInputFor]="chipList"
			[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
			(matChipInputTokenEnd)="add($event)"
		/>
	</mat-chip-list>

	<mat-autocomplete #autocomplete="matAutocomplete" (optionSelected)="selected($event)">
		<div *ngIf="autocomplete.isOpen" @fadeInListStaggered>
			<mat-option *ngIf="!pending && !(filtered$ | async)?.length" disabled>No items</mat-option>

			<div *ngFor="let chip of filtered$ | async">
				<mat-option
					*bpDelayedRender="0; allStagger: true"
					class="chip-autocomplete-option"
					[class.has-description]="chip.description"
					[value]="chip"
				>
					<div>
						<ng-template
							*ngIf="portal; else defaultTpl"
							[ngTemplateOutlet]="portal!.templateRef"
							[ngTemplateOutletContext]="{ $implicit: chip, isMatOption: true }"
						>
						</ng-template>

						<ng-template #defaultTpl>
							{{ getDisplayValue(chip) }}
						</ng-template>
					</div>

					<div *ngIf="chip.description" class="description mat-caption">
						{{ chip.description }}
					</div>
				</mat-option>
			</div>
		</div>
		<!-- the mat option below is a trick not to render all the options on render of this component, mat-autocomplete needs options to be present to start render them in dom -->
		<mat-option style="display: none" disabled></mat-option>
	</mat-autocomplete>

	<mat-hint *ngIf="hint">{{ hint }}</mat-hint>

	<mat-error [bpFieldError]="name" [bpFieldErrorSuppress]="hideErrorText"> </mat-error>
</mat-form-field>
