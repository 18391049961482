import type { IAppVersion } from '@bp/shared/typings';

export class AppVersion implements IAppVersion {

	readonly major: number;

	readonly minor: number;

	readonly patch: number;

	readonly release: string;

	readonly shortSHA: string;

	readonly releaseAndShortSHA: string;

	constructor(release: string, shortSHA: string) {
		const [ major, minor, patch ] = release
			.split('.')
			.map(Number);

		this.major = major;

		this.minor = minor;

		this.patch = patch;

		this.release = release;

		this.shortSHA = shortSHA;

		this.releaseAndShortSHA = `${ this.release }-${ this.shortSHA }`;
	}

}
