import type { PspPaymentOptionType } from '@bp/shared/models/business';
import { MetadataEntity } from '@bp/shared/models/metadata';

export abstract class FirebasePspPaymentOptionBase extends MetadataEntity {

	static readonly type: PspPaymentOptionType;

	abstract readonly type: PspPaymentOptionType;

}
