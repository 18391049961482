import type { Action, ActionReducer } from '@ngrx/store';

import { ConsoleLoggingState } from '@bp/shared/services';

const IS_DARK_BROWSER_THEME = matchMedia('(prefers-color-scheme: dark)').matches;
const LOG_STYLE_COLOR = IS_DARK_BROWSER_THEME ? '#F9FE25' : '#2578ea';

const LOG_STYLE = `color: ${ LOG_STYLE_COLOR }; font-weight: 100;`;
const getLogNamespaceStyle = (index: number): string => `color: hsl(281deg 83% ${ 58 + (index ? index + 5 : 0) }%); font-weight: bold;`;
const LOG_API_NAMESPACE_STYLE = 'color: #21E269; font-weight: bold;';
const LOG_FEATURE_NAME_STYLE = 'color: #8A63F7; font-weight: bold;';

const getLogBackgroundStyle = (c: string): string => `color: ${ c }; background: black; border: 1px solid ${ c }; border-radius: 5px; padding: 2px 5px;`;
const ERROR_LOG_STYLE = `${ LOG_STYLE }${ getLogBackgroundStyle('#ff3636') }`;

export function consoleLoggerMetaReducer<T, V extends Action = Action>(reducer: ActionReducer<T, V>): ActionReducer<T, V> {
	return (state, action: V): T => {
		const result = reducer(state, action);

		if (!ConsoleLoggingState.isActive)
			return result;

		if (action.type === '@ngrx/store-devtools/recompute')
			return result;

		const isErrorActionType = action.type.includes('Reset Error')
			? false
			: (/(([Ff])ailure)|(([Ee])rror)|ROUTE_ERROR/u).test(action.type);
		const defaultLogStyle = isErrorActionType ? ERROR_LOG_STYLE : LOG_STYLE;

		consoleGroupCollapsedWithColors<V>(action, isErrorActionType, defaultLogStyle);

		console.groupCollapsed('%cTrace', `${ getLogBackgroundStyle('#23b3ec') }`);

		console.trace();

		console.groupEnd();

		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
		console.log('%czone', defaultLogStyle, (<any> window).Zone?.current?.name);

		console.log('%cprev state', defaultLogStyle, state);

		console.log('%caction', defaultLogStyle, action);

		console.log('%cnext state', defaultLogStyle, result);

		console.groupEnd();

		return result;
	};
}

function consoleGroupCollapsedWithColors<V extends Action = Action>(
	action: V,
	isErrorActionType: boolean,
	defaultLogStyle: string,
): void {
	const firstPrefixBracketIndex = action.type.search(/\]/u);
	const hasPrefix = firstPrefixBracketIndex > 0;

	if (!hasPrefix || isErrorActionType) {
		console.groupCollapsed(`%c${ action.type }`, defaultLogStyle);

		return;
	}

	const formattedActionType = injectLogStylingDirective(action.type);
	const lastPrefixBracketIndex = formattedActionType.search(/(\])(?!.*\])/u);

	if (lastPrefixBracketIndex === -1) {
		console.groupCollapsed(formattedActionType, LOG_FEATURE_NAME_STYLE, defaultLogStyle);

		return;
	}

	console.groupCollapsed(
		formattedActionType,
		...getLogActionTypeStyles(formattedActionType),
		defaultLogStyle,
	);
}

function getLogActionTypeStyles(formattedActionType: string): string[] {
	const isApiAction = formattedActionType.search(/api/ui) > 0;

	return [ ...formattedActionType.matchAll(/\[/ug) ]
		.map((v, index, array) => {
			if (index === 0 && isApiAction)
				return LOG_API_NAMESPACE_STYLE;

			if (index + 1 === array.length)
				return LOG_FEATURE_NAME_STYLE;

			return getLogNamespaceStyle(isApiAction ? index - 1 : index);
		});
}

function injectLogStylingDirective(value: string): string {
	return `%c${ value.replace(/(\[.*?\])/ug, '$1%c') }`;
}
