import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatPseudoCheckboxModule, MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';

import { SharedComponentsControlsModule } from '@bp/shared/components/controls';
import { SharedComponentsCoreModule } from '@bp/shared/components/core';
import { SharedDirectivesModule } from '@bp/shared/directives';
import { SharedFeaturesTooltipModule } from '@bp/shared/features/tooltip';
import { SharedFeaturesValidationModule } from '@bp/shared/features/validation';
import { SharedPipesModule } from '@bp/shared/pipes';
import { ApiMockPluginsManagerService } from '@bp/shared/services';

import { PspsAddingModule, PspSelectorComponent, PspThumbnailModule } from './components';
import { BridgerPspsImportService } from './services';

const EXPOSED_COMPONENTS = [
	PspSelectorComponent,
];

@NgModule({
	imports: [
		CommonModule,
		MatAutocompleteModule,
		MatChipsModule,
		MatIconModule,
		MatPseudoCheckboxModule,
		MatRippleModule,
		MatSelectModule,
		ReactiveFormsModule,
		ScrollingModule,

		SharedDirectivesModule,
		SharedPipesModule,
		SharedComponentsCoreModule,
		SharedComponentsControlsModule,
		SharedFeaturesTooltipModule,
		SharedFeaturesValidationModule,

		PspsAddingModule,
		PspThumbnailModule,
	],
	exports: [ EXPOSED_COMPONENTS, PspsAddingModule, PspThumbnailModule ],
	declarations: EXPOSED_COMPONENTS,
})
export class AdminsSharedBridgerPspsModule {

	static isMockRegistered = false;

	constructor(
		private readonly _importS: BridgerPspsImportService,
		private readonly _apiMocksManagerService: ApiMockPluginsManagerService,
	) {

		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
		(<any>window).bridgerPspsImportService = this._importS;

		if (!AdminsSharedBridgerPspsModule.isMockRegistered) {
			void this._apiMocksManagerService.register(async () => import('./api-mock-plugin/admins-shared-bridger-psps-api-mock-plugin'));

			AdminsSharedBridgerPspsModule.isMockRegistered = true;
		}
	}

}
