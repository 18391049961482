import { DescribableEnumeration } from '@bp/shared/models/core';

export class PspPaymentOptionType extends DescribableEnumeration {

	static creditCard = new PspPaymentOptionType('Credit Card');

	static apm = new PspPaymentOptionType('APM');

	static crypto = new PspPaymentOptionType();

	static voucher = new PspPaymentOptionType();

	static isPspBased(type?: PspPaymentOptionType | null): boolean {
		return [
			PspPaymentOptionType.creditCard,
			PspPaymentOptionType.apm,
			PspPaymentOptionType.crypto,
			PspPaymentOptionType.voucher,
		].includes(type!);
	}

	constructor(displayName?: string, description?: string) {
		super(displayName, description);
	}

}
