import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IntroPageStep } from '../models';

import type { IState } from './intro.reducer';
import { FEATURE_KEY } from './intro.reducer';

export const selectPageState = createFeatureSelector<IState>(FEATURE_KEY);

export const getIntroError = createSelector(
	selectPageState,
	s => s.error ?? null,
);

export const getIntroPending = createSelector(
	selectPageState,
	s => s.pending,
);

export const getIntroPageStep = createSelector(
	selectPageState,
	s => IntroPageStep.parse(s.step),
);
