// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
export const requestIdleCallback: typeof window.requestIdleCallback = window.requestIdleCallback || function(handler) {
	const startTime = Date.now();

	return setTimeout(
		() => void handler({
			didTimeout: false,
			timeRemaining: () => Math.max(0, 50 - (Date.now() - startTime)),
		}),
		1,
	);
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
export const cancelIdleCallback: typeof window.cancelIdleCallback = window.cancelIdleCallback || function(id) {
	clearTimeout(id);
};
