import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { mapTo } from '@bp/shared/rxjs';
import { DTO } from '@bp/shared/models/metadata';

import { IIdentityApiService } from '@bp/shared-domains-identity';

import {
	ILoginApiRequest,
	IChangePasswordApiRequest,
	ISetSecurityQuestionsAnswersApiRequest,
	IRegisterAuthenticatorAppApiRequest,
	ISendResetPasswordLinkApiRequest,
	IResetPasswordOtpVerificationApiRequest,
	IResetPasswordApiRequest,
	CreateAccountApiRequest,
	IVerifySecurityQuestionsAnswersApiRequest,
	ILoginOtpVerificationApiRequest,
	IIdentitySessionApiResponse,
	Identity,
	IResourceAccessOtpVerificationApiRequest,
	IResourceAccessOtpVerificationApiResponse,
	GenerateOtpApiResponse,
	SecurityQuestion
} from '../models';
import { IDENTITY_API_AUTH_NAMESPACE } from '../constants';

@Injectable({
	providedIn: 'root',
})
export class IdentityApiService implements IIdentityApiService<Identity, ILoginApiRequest> {
	private readonly _namespace = IDENTITY_API_AUTH_NAMESPACE;

	constructor(private readonly _http: HttpClient) {}

	factory = ({ accessToken: { token, otpExpiresAt }, refreshToken }: IIdentitySessionApiResponse): Identity => new Identity(token, refreshToken, otpExpiresAt);

	login(credentials: ILoginApiRequest): Observable<Identity> {
		return this._http
			.post<IIdentitySessionApiResponse>(`${ this._namespace }/login`, credentials)
			.pipe(map(this.factory));
	}

	loginOtpVerification({ code }: ILoginOtpVerificationApiRequest): Observable<Identity> {
		return this._http
			.post<IIdentitySessionApiResponse>(`${ this._namespace }/otp/verify/${ code }`, null)
			.pipe(map(this.factory));
	}

	generateLoginOtp(): Observable<GenerateOtpApiResponse> {
		return this.generateResourceAccessOtp('login');
	}

	resourceAccessOtpVerification({
		resourceName,
		code,
	}: IResourceAccessOtpVerificationApiRequest): Observable<Identity> {
		return this._http
			.post<IResourceAccessOtpVerificationApiResponse>(
			`${ this._namespace }/otp/${ resourceName }/verify/${ code }`,
			null,
		)
			.pipe(map(({ token }) => new Identity(token)));
	}

	generateResourceAccessOtp(resourceName: string): Observable<GenerateOtpApiResponse> {
		return this._http
			.post<void>(`${ this._namespace }/otp/${ resourceName }/generate`, null)
			.pipe(mapTo(GenerateOtpApiResponse));
	}

	changePassword(request: IChangePasswordApiRequest): Observable<Identity> {
		return this._http
			.post<IIdentitySessionApiResponse>(`${ this._namespace }/change-password`, request)
			.pipe(map(this.factory));
	}

	// #region SECTION Signup Via Invite

	acceptInvite(): Observable<Identity> {
		return this._http
			.post<IIdentitySessionApiResponse>(`${ this._namespace }/accept-invite`, null)
			.pipe(map(this.factory));
	}

	createAccount(request: CreateAccountApiRequest): Observable<Identity> {
		return this._http
			.post<IIdentitySessionApiResponse>(`${ this._namespace }/create-account`, request)
			.pipe(map(this.factory));
	}

	// #endregion !SECTION

	// #region deprecated, remove after accepting invite implementation by backend

	verifyEmail(): Observable<Identity> {
		return this._http
			.post<IIdentitySessionApiResponse>(`${ this._namespace }/verify-email`, null)
			.pipe(map(this.factory));
	}

	createPassword({ password }: CreateAccountApiRequest): Observable<Identity> {
		return this._http
			.post<IIdentitySessionApiResponse>(`${ this._namespace }/create-password`, { password })
			.pipe(map(this.factory));
	}

	// #endregion !deprecated

	// #region SECTION Continue Signup

	getAllSecurityQuestions(): Observable<SecurityQuestion[]> {
		return this._http.get<DTO<SecurityQuestion>[]>(`${ this._namespace }/all-security-questions`)
			.pipe(map(questions => questions.map(question => new SecurityQuestion(question))));
	}

	setSecurityQuestionsAnswers({ answers }: ISetSecurityQuestionsAnswersApiRequest): Observable<Identity> {
		return this._http
			.post<IIdentitySessionApiResponse>(`${ this._namespace }/set-security-questions-answers`, answers)
			.pipe(map(this.factory));
	}

	getAuthenticatorAppKey(): Observable<string> {
		return this._http.get(`${ this._namespace }/otp/qr-code-key`, { responseType: 'text' });
	}

	registerAuthenticatorApp(request: IRegisterAuthenticatorAppApiRequest): Observable<Identity> {
		return this._http
			.post<IIdentitySessionApiResponse>(`${ this._namespace }/otp/register-authenticator`, request)
			.pipe(map(this.factory));
	}

	// #endregion !SECTION

	// #region SECTION reset

	getIdentitySecurityQuestions(): Observable<SecurityQuestion[]> {
		return this._http.get<DTO<SecurityQuestion>[]>(`${ this._namespace }/security-questions`)
			.pipe(map(questions => questions.map(question => new SecurityQuestion(question))));
	}

	verifySecurityQuestionsAnswers({ answers }: IVerifySecurityQuestionsAnswersApiRequest): Observable<Identity> {
		return this._http
			.post<IIdentitySessionApiResponse>(`${ this._namespace }/verify-security-questions-answers`, answers)
			.pipe(map(this.factory));
	}

	// #region SECTION reset password

	sendResetPasswordLink(request: ISendResetPasswordLinkApiRequest): Observable<void> {
		return this._http.post<void>(`${ this._namespace }/reset-password-link`, request);
	}

	resetPasswordOtpVerification({ code }: IResetPasswordOtpVerificationApiRequest): Observable<Identity> {
		return this._http
			.post<IIdentitySessionApiResponse>(`${ this._namespace }/otp/reset-password/verify/${ code }`, null)
			.pipe(map(this.factory));
	}

	resetPassword(request: IResetPasswordApiRequest): Observable<Identity> {
		return this._http
			.post<IIdentitySessionApiResponse>(`${ this._namespace }/reset-password`, request)
			.pipe(map(this.factory));
	}

	// #endregion !SECTION

	// #region SECTION reset authenticator app

	sendResetAuthenticatorAppLink(): Observable<void> {
		return this._http.post<void>(`${ this._namespace }/otp/reset-authenticator-link`, null);
	}

	resetAuthenticatorApp(request: IRegisterAuthenticatorAppApiRequest): Observable<Identity> {
		return this._http
			.post<IIdentitySessionApiResponse>(`${ this._namespace }/otp/reset-authenticator`, request)
			.pipe(map(this.factory));
	}

	// #endregion !SECTION

	// #endregion !SECTION

	refreshToken(identity: Identity): Observable<Identity> {
		return this._http
			.post<IIdentitySessionApiResponse>(`${ this._namespace }/refresh-token`, {
			refreshToken: identity.refreshToken,
			accessToken: identity.jwt,
		})
			.pipe(map(this.factory));
	}
}
