<!-- eslint-disable @angular-eslint/template/cyclomatic-complexity -->

<mat-form-field
	[color]="color"
	[appearance]="matAppearance"
	[hideRequiredMarker]="defaultOptions?.hideRequiredMarker"
	[floatLabel]="useIncomingSecretValueAsPlaceholder ? 'always' : $$floatLabel"
	[class.incoming-secret-value-as-placeholder]="useIncomingSecretValueAsPlaceholder"
	[class.form-field-pending]="pending || internalControl.pending"
	[class.error-hidden]="hideErrorText"
	[class.has-prefix]="!!prefix"
	[class.has-suffix]="!!suffix || !hideClearButton"
>
	<mat-label *ngIf="label || customLabel">
		<span [ngSwitch]="!!customLabel">
			<span *ngSwitchDefault>{{ label | bpTranslate | async }}</span>
			<ng-content *ngSwitchCase="true" select="bp-input-label"> </ng-content>
		</span>
	</mat-label>

	<ng-content></ng-content>

	<textarea
		*ngIf="textarea; else inputTpl"
		matInput
		[attr.maxlength]="maxLength"
		[attr.data-private]="shouldAddSecretAttribute"
		[name]="$$name"
		[formControl]="internalControl"
		[placeholder]="
			useIncomingSecretValueAsPlaceholder ? $$incomingSecretValue : $any(placeholder | bpTranslate | async)
		"
		[required]="!!required"
		[disabled]="!!disabled"
		(blur)="onInputBlur()"
	>
	</textarea>

	<ng-template #inputTpl>
		<input
			*ngIf="autocomplete; else defaultInputTpl"
			matInput
			[attr.enterkeyhint]="nativeAutocomplete ? null : 'go'"
			[attr.inputmode]="inputmode"
			[attr.maxlength]="maxLength"
			[attr.data-private]="shouldAddSecretAttribute"
			[autocomplete]="$$attrAutocompleteValue"
			[name]="$$name"
			[type]="$$inputType"
			[formControl]="internalControl"
			[placeholder]="
				useIncomingSecretValueAsPlaceholder ? $$incomingSecretValue : $any(placeholder | bpTranslate | async)
			"
			[required]="!!required"
			[disabled]="!!disabled"
			[value]="value"
			[matAutocomplete]="autocomplete"
			(blur)="onInputBlur()"
		/>

		<ng-template #defaultInputTpl>
			<input
				matInput
				[attr.enterkeyhint]="nativeAutocomplete ? null : 'go'"
				[attr.inputmode]="inputmode"
				[attr.maxlength]="maxLength"
				[attr.data-private]="shouldAddSecretAttribute"
				[autocomplete]="$$attrAutocompleteValue"
				[name]="$$name"
				[type]="$$inputType"
				[formControl]="internalControl"
				[required]="!!required"
				[disabled]="!!disabled"
				[placeholder]="
					useIncomingSecretValueAsPlaceholder
						? $$incomingSecretValue
						: $any(placeholder | bpTranslate | async)
				"
				[bpTextMaskPlaceholderFromMask]="!useIncomingSecretValueAsPlaceholder"
				[bpTextMask]="$$mask"
				(blur)="onInputBlur()"
			/>
		</ng-template>
	</ng-template>

	<mat-hint *ngIf="hint || customHint" class="display-contents">
		<span [ngSwitch]="!!customHint" class="display-contents">
			<span *ngSwitchDefault>{{ hint | bpTranslate | async }}</span>
			<ng-content *ngSwitchCase="true" select="bp-input-hint, [bpInputHint]"> </ng-content>
		</span>
	</mat-hint>

	<span *ngIf="prefix" matPrefix class="display-contents">
		<ng-content select="bp-input-prefix, [bpInputPrefix]"></ng-content>
	</span>

	<span *ngIf="hasSearchIcon" matPrefix class="display-contents">
		<mat-icon class="material-icons-round search-icon">search</mat-icon>
	</span>

	<span *ngIf="suffix || !hideClearButton" matSuffix class="display-contents">
		<ng-content select="bp-input-suffix, [bpInputSuffix]"></ng-content>

		<div *ngIf="!hideClearButton" class="clear-button-wrapper">
			<div [class.show]="!!internalControl.value && !internalControl.disabled">
				<button type="button" tabindex="-1" mat-icon-button (click)="$$eraseInternalControlValue()">
					<mat-icon>close</mat-icon>
				</button>
			</div>
		</div>
	</span>

	<mat-error [bpFieldError]="name" [bpFieldErrorSuppress]="hideErrorText"> </mat-error>
</mat-form-field>
