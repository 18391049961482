import type { Scope } from '@sentry/angular';
import { configureScope } from '@sentry/angular';

import type { Action, MetaReducer } from '@ngrx/store';

type AnyAction = Action & Record<string, any>;

export type SentryMetaReducerOptions = {

	/**
	 * Transforms the state before attaching it to an event.
	 * Use this to remove any private data before sending it to Sentry.
	 * Return null to not attach the state.
	 */
	stateTransformer?: <S>(state: S | undefined) => (S & any) | null;

	/**
	 * Transforms the action before sending it as a breadcrumb.
	 * Use this to remove any private data before sending it to Sentry.
	 * Return null to not send the breadcrumb.
	 */
	actionTransformer: (action: AnyAction) => AnyAction | null;

	/**
	 * Called on every state update, configure the Sentry Scope with the redux state.
	 */
	configureScopeWithState?: <S>(scope: Scope, state: S) => void;
};

const ACTION_BREADCRUMB_CATEGORY = 'redux.action';
const ACTION_BREADCRUMB_TYPE = 'info';

export function createSentryMetaReducer(): MetaReducer {
	return reducer => (state, action) => {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const newState = reducer(state, action);

		configureScope(scope => {

			/* Action breadcrumbs */

			scope.addBreadcrumb({
				category: ACTION_BREADCRUMB_CATEGORY,
				data: {
					type: action.type,
				},
				type: ACTION_BREADCRUMB_TYPE,
			});
		});

		return newState;
	};
}
