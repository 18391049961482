import { isString } from 'lodash-es';

import { PspPaymentOptionType } from '@bp/shared/models/business';
import { IPageQueryParamsDTO, mixinPageQueryParams, QueryParamsBase, StringifyQuery } from '@bp/shared/models/common';
import { ISortQueryParamsDTO, mixinSortQueryParams } from '@bp/shared/models/metadata';

import { BridgerPspLocation } from '../bridger-psp-location';
import { BridgerPspLicense } from '../bridger-psp-license';
import { BridgerPspProcessingHistory } from '../bridger-psp-processing-history';
import { BridgerPspMonthlyTurnoverVolume } from '../bridger-psp-monthly-turnover-volume';
import { BridgerPspLegalEntityType } from '../bridger-psp-legal-entity-type';
import { BridgerPspIndustry } from '../bridger-psp-industry';

import { BridgerPspSummary } from './bridger-psp-summary';
import { BNPL_PSEUDO_PAYMENT_OPTION } from './payment-options';

interface IBridgerPspSummaryQueryParams {

	name?: string;

	pspName?: string;

	locations: BridgerPspLocation[];

	industries: BridgerPspIndustry[];

	paymentOptionType?: PspPaymentOptionType;

	legalEntityLocation?: BridgerPspLocation;

	license?: BridgerPspLicense;

	processingHistory?: BridgerPspProcessingHistory;

	monthlyTurnoverVolume?: BridgerPspMonthlyTurnoverVolume;

	legalEntityType?: BridgerPspLegalEntityType;

	supportsBnpl?: boolean;

}

export type BridgerPspSummaryQueryParamsDTO = IPageQueryParamsDTO & ISortQueryParamsDTO & StringifyQuery<IBridgerPspSummaryQueryParams>;

export class BridgerPspSummaryQueryParamsBase extends QueryParamsBase<BridgerPspSummaryQueryParamsDTO> {}

export class BridgerPspSummaryQueryParams extends mixinPageQueryParams(
	mixinSortQueryParams(BridgerPspSummaryQueryParamsBase, BridgerPspSummary),
) {

	name?: string;

	pspName?: string;

	locations: BridgerPspLocation[];

	industries: BridgerPspIndustry[];

	paymentOptionType?: PspPaymentOptionType;

	legalEntityLocation?: BridgerPspLocation;

	license?: BridgerPspLicense;

	processingHistory?: BridgerPspProcessingHistory;

	monthlyTurnoverVolume?: BridgerPspMonthlyTurnoverVolume;

	legalEntityType?: BridgerPspLegalEntityType;

	supportsBnpl?: boolean;

	constructor(dto?: BridgerPspSummaryQueryParamsDTO) {
		super(dto);

		this.name = dto?.name;

		this.pspName = dto?.pspName;

		this.locations = isString(dto?.locations)
			? dto?.locations
				.split(',')
				.map(v => BridgerPspLocation.parse(v))
				.filter((v): v is BridgerPspLocation => !!v) ?? []
			: dto?.locations ?? [];

		this.industries = isString(dto?.industries)
			? dto?.industries
				.split(',')
				.map(v => BridgerPspIndustry.parse(v))
				.filter((v): v is BridgerPspIndustry => !!v) ?? []
			: dto?.industries ?? [];

		this.paymentOptionType = PspPaymentOptionType.parse(dto?.paymentOptionType) ?? undefined;

		if (dto?.paymentOptionType && dto.paymentOptionType === BNPL_PSEUDO_PAYMENT_OPTION.name)
			this.supportsBnpl = true;

		this.legalEntityLocation = BridgerPspLocation.parse(dto?.legalEntityLocation) ?? undefined;

		this.license = BridgerPspLicense.parse(dto?.license) ?? undefined;

		this.processingHistory = BridgerPspProcessingHistory.parse(dto?.processingHistory) ?? undefined;

		this.monthlyTurnoverVolume = BridgerPspMonthlyTurnoverVolume.parse(dto?.monthlyTurnoverVolume) ?? undefined;

		this.legalEntityType = BridgerPspLegalEntityType.parse(dto?.legalEntityType) ?? undefined;
	}

}
