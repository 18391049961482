import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { GoogleTagDirective } from './directives';
import { DISPATCH_EVENT_OBSERVERS } from './models';
import { LinkedInAnalyticsService } from './services';

const EXPOSED = [
	GoogleTagDirective,
];

@NgModule({
	imports: [
		CommonModule,
	],
	declarations: EXPOSED,
	exports: EXPOSED,
})
export class SharedFeaturesAnalyticsModule {
	static forRoot(): ModuleWithProviders<SharedFeaturesAnalyticsModule> {
		return {
			ngModule: SharedFeaturesAnalyticsModule,
			providers: [
				{ provide: DISPATCH_EVENT_OBSERVERS, useClass: LinkedInAnalyticsService, multi: true },
			],
		};
	}
}
