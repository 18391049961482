
import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';

import {
	closeSidenav, hideBackdrop, hideFullpageBackdrop, openSidenav, showBackdrop, showFullpageBackdrop
} from './layout.actions';

export const FEATURE_KEY = '[feature]:layout';

export interface IState {

	showSidenav: boolean;

	hasBackdrop: boolean;

	hasFullpageBackdrop: boolean;

}

export const initialState: IState = {
	showSidenav: false,
	hasBackdrop: false,
	hasFullpageBackdrop: false,
};

const layoutReducer = createReducer(
	initialState,

	on(openSidenav, closeSidenav, (state, action): IState => ({
		...state,
		showSidenav: action.type === openSidenav.type,
	})),

	on(showBackdrop, hideBackdrop, (state, { type }): IState => ({
		...state,
		hasBackdrop: type === showBackdrop.type,
	})),

	on(showFullpageBackdrop, hideFullpageBackdrop, (state, { type }): IState => ({
		...state,
		hasFullpageBackdrop: type === showFullpageBackdrop.type,
	})),
);

export function reducer(state: IState | undefined, action: Action): IState {
	return layoutReducer(state, action);
}
