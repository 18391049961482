import { DescribableEnumeration } from '@bp/shared/models/core';

export class FirebasePspLocation extends DescribableEnumeration {

	static worldwide = new FirebasePspLocation();

	static northAmerica = new FirebasePspLocation();

	static apac = new FirebasePspLocation('APAC', 'Asia Pacific Accreditation Cooperation');

	static china = new FirebasePspLocation();

	static cis = new FirebasePspLocation('CIS', 'Commonwealth of Independent States');

	static eu = new FirebasePspLocation('EU', 'European Union');

	static gcc = new FirebasePspLocation('GCC', 'Gulf Cooperation Council');

	static india = new FirebasePspLocation();

	static latam = new FirebasePspLocation('LATAM', 'Latin America');

	static middleEast = new FirebasePspLocation();

	static sea = new FirebasePspLocation('SEA', 'South East Asia');

	static africa = new FirebasePspLocation();

	static japan = new FirebasePspLocation();

	static canada = new FirebasePspLocation();

	static australia = new FirebasePspLocation();

	static uk = new FirebasePspLocation('UK', 'United Kingdom');

	constructor(
		displayName?: string,
		public override description?: string,
	) {
		super(displayName, description);
	}

}
