import { difference, partition, without, isEmpty } from 'lodash-es';
import { BehaviorSubject, fromEvent } from 'rxjs';
import { distinctUntilChanged, filter, map, skipWhile, switchMap } from 'rxjs/operators';

import type { AfterViewInit } from '@angular/core';
import { forwardRef, ChangeDetectionStrategy, Component, QueryList, ViewChildren, ChangeDetectorRef } from '@angular/core';
import type { UrlSegmentGroup } from '@angular/router';
import { GuardsCheckEnd, Router } from '@angular/router';

import { Destroyable, takeUntilDestroyed } from '@bp/shared/models/common';
import { isInstanceOf, bpQueueMicrotask, listenToQueryListChanges, UrlHelper } from '@bp/shared/utilities';
import { filterPresent } from '@bp/shared/rxjs';
import { RouterService } from '@bp/shared/services';

import { LayoutFacade } from '../../state';
import type { RightDrawerRouteGroup } from '../right-drawer/right-drawer.component';
import { RightDrawerComponent } from '../right-drawer/right-drawer.component';
import { isRightDrawerOutlet } from '../../utilities';

@Component({
	selector: 'bp-right-drawers-orchestrator',
	templateUrl: './right-drawers-orchestrator.component.html',
	styleUrls: [ './right-drawers-orchestrator.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RightDrawersOrchestratorComponent extends Destroyable implements AfterViewInit {

	private readonly _drawersNames$ = new BehaviorSubject<string[]>([]);

	drawersNames$ = this._drawersNames$.asObservable();

	get openedDrawersNames(): string[] {
		return this._drawersNames$.value;
	}

	// eslint-disable-next-line @angular-eslint/no-forward-ref
	@ViewChildren(forwardRef(() => RightDrawerComponent)) drawersQueryList?: QueryList<RightDrawerComponent>;

	private readonly _drawers$ = new BehaviorSubject<RightDrawerComponent[]>([]);

	drawers$ = this._drawers$.asObservable();

	get drawers(): RightDrawerComponent[] {
		return this._drawers$.value;
	}

	constructor(
		private readonly _router: Router,
		private readonly _layoutFacade: LayoutFacade,
		private readonly _cdr: ChangeDetectorRef,
		private readonly _routerService: RouterService,
	) {
		super();

		this._whenIndexedDrawersAppearToggleFullscreenModeOnNonActive();

		this._onInitialNavigationTryOpenDrawerFromUrl();

		this._onNavigationTryOpenDrawerFromUrl();

		this._onEscapeCloseActiveDrawer();

		this._onLayoutRequestCloseAllDrawers();
	}

	ngAfterViewInit(): void {
		this._whenDrawersChangeSetLayoutActiveRightDrawer();

		this._whenDrawersQueryListChangeSetDrawers();
	}

	private _whenDrawersQueryListChangeSetDrawers(): void {
		listenToQueryListChanges(this.drawersQueryList!)
			.pipe(takeUntilDestroyed(this))
			.subscribe(drawers => void this._drawers$.next(drawers));
	}

	private _whenDrawersChangeSetLayoutActiveRightDrawer(): void {
		this.drawers$
			.pipe(
				map(() => this.getActiveDrawer()),
				distinctUntilChanged(),
				skipWhile((v, index) => index === 0 && v === null),
				takeUntilDestroyed(this),
			)
			.subscribe(activeDrawer => void this._layoutFacade.setActiveRightDrawer(activeDrawer));
	}

	getActiveDrawer(): RightDrawerComponent | null {
		return this.drawersQueryList?.last ?? null;
	}

	async onBackdropClick(): Promise<void> {
		const closed = await this._closeActiveDrawer();

		closed && this._activateBackdropDrawer();
	}

	onActiveDrawerClosedStart(): void {
		this._routerService.markAsNavigationInProcess();

		this._activateBackdropDrawer();
	}

	onActiveDrawerClosed(drawer: string): void {
		void this._router.navigateByUrl(UrlHelper.buildUrlExcludingOutlet(drawer, this._router));
	}

	registerRightDrawer(newRightDrawer: RightDrawerComponent): void {
		void this._whenGroupRootDrawerClosesTryCloseRestGroupDrawers(newRightDrawer);
	}

	private _whenGroupRootDrawerClosesTryCloseRestGroupDrawers(drawer: RightDrawerComponent): void {
		let groupDrawersCanCloseHandlersIds: (() => Promise<boolean>)[] = [];

		drawer.routerOutletActivatedComponent$
			.pipe(
				filterPresent,
				takeUntilDestroyed(drawer),
				switchMap(() => drawer.drawerRouteConfig$),
			)
			.subscribe(drawerRouteConfig => {
				const rootDrawersGroups = drawerRouteConfig
					.drawersGroups
					?.filter(({ groupRoot }) => groupRoot) ?? [];

				groupDrawersCanCloseHandlersIds.forEach(
					canCloseHandler => void drawer.unregisterCanCloseHandler(canCloseHandler),
				);

				groupDrawersCanCloseHandlersIds = rootDrawersGroups
					.map(rootDrawerGroup => this._registerGroupRootDrawerCanCloseHandler(drawer, rootDrawerGroup));
			});

	}

	private _registerGroupRootDrawerCanCloseHandler(
		drawer: RightDrawerComponent,
		rootDrawerGroup: RightDrawerRouteGroup,
	): () => Promise<boolean> {
		return drawer.registerCanCloseHandler(async () => {
			const sameGroupDrawers = without(this.drawers, drawer)
				.filter(otherDrawer => !!otherDrawer
					.drawerRouteConfig
					.drawersGroups
					?.some(({ groupName }) => groupName === rootDrawerGroup.groupName));

			if (isEmpty(sameGroupDrawers))
				return Promise.resolve(true);

			const sameGroupDrawersCloseResults = await Promise.all(
				sameGroupDrawers.map(async sameGroupDrawer => sameGroupDrawer.close()),
			);

			return Promise.resolve(sameGroupDrawersCloseResults.every(Boolean));
		});
	}

	private async _closeActiveDrawer(): Promise<boolean> {
		return this.getActiveDrawer()!.close();
	}

	private _activateBackdropDrawer(): void {
		const backdropDrawer = this.drawers.at(-2);

		backdropDrawer?.setFullscreen(false);

		backdropDrawer?.focus();
	}

	private _onInitialNavigationTryOpenDrawerFromUrl(): void {
		const currentNavigation = this._router.getCurrentNavigation();

		if (!this._router.navigated && currentNavigation) {
			const rootChildren = currentNavigation.finalUrl!.root.children;

			this._stackDrawers(this._extractDrawersOutletNames(rootChildren));
		}
	}

	private _onNavigationTryOpenDrawerFromUrl(): void {
		this._router.events
			.pipe(
				filter(isInstanceOf(GuardsCheckEnd)),
				filter(routeEvent => routeEvent.shouldActivate),
				takeUntilDestroyed(this),
			)
			.subscribe(guardsCheckEndEvent => {
				const parsed = this._router.parseUrl(guardsCheckEndEvent.url);

				this._stackDrawers(this._extractDrawersOutletNames(parsed.root.children));
			});
	}

	private _stackDrawers(drawers: string[]): void {
		const orderedDrawers = this._restoreOrderOfIndexedDrawers(drawers);
		const toAdd = difference(orderedDrawers, this.openedDrawersNames);
		const toRemove = difference(this.openedDrawersNames, orderedDrawers);

		this._drawersNames$.next([
			...this.openedDrawersNames.filter(v => !toRemove.includes(v)),
			...toAdd,
		]);
	}

	private _restoreOrderOfIndexedDrawers(drawerNames: string[]): string[] {
		const drawers = drawerNames.map(name => ({
			name,
			index: RightDrawerComponent.extractDrawerIndex(name),
		}));

		const [ indexedDrawers, simpleDrawers ] = partition(drawers, v => v.index !== null);

		indexedDrawers.sort((a, b) => a.index! - b.index!);

		return [
			...indexedDrawers,
			...simpleDrawers,
		]
			.map(v => v.name);
	}

	private _extractDrawersOutletNames(urlSegmentGroupsByOutletName: Record<string, UrlSegmentGroup>): string[] {
		const drawers = [];

		for (const outlet of Object.keys(urlSegmentGroupsByOutletName)) {
			if (isRightDrawerOutlet(outlet))
				drawers.push(outlet);

			if (urlSegmentGroupsByOutletName[outlet].hasChildren())
				drawers.push(...this._extractDrawersOutletNames(urlSegmentGroupsByOutletName[outlet].children));
		}

		return drawers;
	}

	private _onEscapeCloseActiveDrawer(): void {
		fromEvent<KeyboardEvent>(window, 'keydown')
			.pipe(
				filter(it => this.drawers.length > 0 && it.key === 'Escape'),
				takeUntilDestroyed(this),
			)
			.subscribe(() => void this._closeActiveDrawer());
	}

	private _onLayoutRequestCloseAllDrawers(): void {
		this._layoutFacade.closeFloatOutlets$
			.pipe(takeUntilDestroyed(this))
			.subscribe(() => void this.drawers.forEach(
				drawer => void drawer.close({ skipCanCloseCheck: true }),
			));
	}

	private _whenIndexedDrawersAppearToggleFullscreenModeOnNonActive(): void {
		this._onIndexedDrawersChange(indexedDrawers => {
			indexedDrawers.forEach(
				(drawer, index) => {
					void drawer.setFullscreen(index !== indexedDrawers.length - 1);

					void drawer.setBackdrop(
						!!indexedDrawers[index + 1]?.drawerRouteConfig?.drawerOccupiesMostOfSpace,
					);
				},
			);

			bpQueueMicrotask(() => void this._cdr.detectChanges());
		});
	}

	private _onIndexedDrawersChange(callback: (indexedDrawers: RightDrawerComponent[]) => void): void {
		this.drawers$
			.pipe(
				map(drawers => drawers.filter(v => v.isIndexed)),
				takeUntilDestroyed(this),
			)
			.subscribe(callback);
	}
}
