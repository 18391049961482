import { createAction } from '@ngrx/store';

export const openSidenav = createAction(
	'[Feature][Layout] Open Sidenav',
);

export const closeSidenav = createAction(
	'[Feature][Layout] Close Sidenav',
);

export const showBackdrop = createAction(
	'[Feature][Layout] Show Backdrop',
);

export const hideBackdrop = createAction(
	'[Feature][Layout] Hide Backdrop',
);

export const showFullpageBackdrop = createAction(
	'[Feature][Layout] Show Fullpage Backdrop',
);

export const hideFullpageBackdrop = createAction(
	'[Feature][Layout] Hide Fullpage Backdrop',
);

export const closeFloatOutlets = createAction(
	'[Feature][Layout] Close All Float Outlets',
);
