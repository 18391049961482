import { Enumeration } from '@bp/shared/models/core/enum';

export class FirebasePspIndustry extends Enumeration {

	static eCommerce = new FirebasePspIndustry('eCommerce');

	static financialServices = new FirebasePspIndustry('Financial Services');

	static gaming = new FirebasePspIndustry();

	static iGaming = new FirebasePspIndustry('iGaming');

	static marketplace = new FirebasePspIndustry();

	static travel = new FirebasePspIndustry();

}
