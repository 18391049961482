import { filter } from 'rxjs/operators';

import { Directive, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Destroyable, takeUntilDestroyed } from '@bp/shared/models/common';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class BaseDialogComponent<T = any> extends Destroyable {

	constructor(
		public dialogRef: MatDialogRef<any>,
		@Optional() @Inject(MAT_DIALOG_DATA) public data: T,
	) {
		super();

		this._stopEscapeKeydownEventPropagation();

		this._submitOnEnter();
	}

	abstract canCloseWithTrueOnEnter(): boolean;

	private _stopEscapeKeydownEventPropagation(): void {
		this.dialogRef
			.keydownEvents()
			.pipe(takeUntilDestroyed(this))
			.subscribe(event => (event.key === 'Escape') && void event.stopPropagation());
	}

	private _submitOnEnter(): void {
		this.dialogRef
			.keydownEvents()
			.pipe(
				filter(event => event.key === 'Enter' && this.canCloseWithTrueOnEnter()),
				takeUntilDestroyed(this),
			)
			.subscribe(() => void this.dialogRef.close(true));
	}

}
