import { assign, get, has, isArray, isString, lowerCase, isNil } from 'lodash-es';

import { HttpErrorResponse } from '@angular/common/http';

import { DeepPartial } from '@bp/shared/typings';

import { ResponseStatusCode, RESPONSE_STATUS_CODE_MESSAGES } from './api';

type BpErrorInput = DeepPartial<BpError> | Error | HttpErrorResponse | IBpHttpErrorResponse | string | unknown;

// TODO refactor
export class BpError extends Error {

	static get notFound(): BpError {
		return new BpError({ status: ResponseStatusCode.NotFound });
	}

	private static _parseErrorInput(errorInput: BpErrorInput): Partial<BpError> {
		const error: Partial<BpError> = {};

		if (errorInput instanceof Error) {
			assign(error, errorInput);

			error.messages = [{ message: errorInput.message }];
		} else if (isString(errorInput))
			error.messages = [{ message: errorInput }];
		 else if (errorInput instanceof HttpErrorResponse) {
			error.requestUrl = errorInput.url;

			error.status = this._parseStatus(errorInput);

			error.statusText = get(RESPONSE_STATUS_CODE_MESSAGES, error.status);

			if (!isNil(errorInput.error))
				error.messages = this._extractMessagesFromBpHttpErrorResponse(<IBpHttpErrorResponse>errorInput.error);
		} else if (has(errorInput, 'response')) {
			const errorResponse = <IBpHttpErrorResponse> errorInput;

			error.status = errorResponse.response.code;

			error.statusText = get(RESPONSE_STATUS_CODE_MESSAGES, error.status);

			error.messages = this._extractMessagesFromBpHttpErrorResponse(errorResponse);
		} else
			assign(error, errorInput);

		error.messages = error.messages?.map(message => ({
			...message,
			type: message.type === 'validation' ? undefined : message.type,
 		})) ?? [];

		error.message ??= error.messages[0]?.message ?? error.statusText;

		error.message ??= 'Unknown Error';

		return error;
	}

	private static _extractMessagesFromBpHttpErrorResponse(error: Partial<IBpHttpErrorResponse>): IErrorMessage[] {
		return error.result
			? (isArray(error.result)
				? error.result
				: [ error.result ]
			)
			: (isNil(error.response) || isNil(error.response.message)
				? []
				: [{ message: lowerCase(error.response.message) }]
			);
	}

	private static _parseStatus(httpErrorResponse: HttpErrorResponse): ResponseStatusCode {
		if (httpErrorResponse.status >= 500)
			return ResponseStatusCode.InternalServerError;

		return httpErrorResponse.status === 0 || httpErrorResponse.statusText === 'Unknown Error'
			? ResponseStatusCode.UnknownError
			: httpErrorResponse.status;
	}

	messages: IErrorMessage[] = [];

	/**
	 * Error message, or first message from the error messages array, or the status text
	 */
	override message!: string;

	status?: ResponseStatusCode;

	statusText?: string;

	requestUrl?: string | null;

	get isForbidden(): boolean {
		return this.status === ResponseStatusCode.Forbidden;
	}

	get isInternalServerError(): boolean {
		return this.status === ResponseStatusCode.InternalServerError;
	}

	constructor(errorInput: BpErrorInput, options?: ErrorOptions) {
		if (errorInput instanceof BpError)
			return errorInput;

		const error = BpError._parseErrorInput(errorInput);

		super(error.message, options);

		assign(this, error);
	}

}

export interface IBpHttpErrorResponse {

	response: {
		status: string;
		code: number;
		message: string;
	};

	result?: IErrorMessage | IErrorMessage[];
}

export interface IErrorMessage {

	message: string;

	type?: string;

	field?: string | null;

}
