/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { difference, isArray, isEmpty, isNil, isString, uniq, without } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CdkPortal } from '@angular/cdk/portal';
import {
	ChangeDetectionStrategy, Component, ContentChild, ElementRef, Input,
	ViewChild
} from '@angular/core';
import type { ValidationErrors, ValidatorFn } from '@angular/forms';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import type { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatAutocomplete } from '@angular/material/autocomplete';
import type { MatChipInputEvent } from '@angular/material/chips';

import { FADE, FADE_IN_LIST_STAGGERED } from '@bp/shared/animations';
import { FormFieldControlComponent } from '@bp/shared/components/core';
import type { IDescribable, OnChanges, SimpleChanges } from '@bp/shared/models/core';
import { isPresent, bpQueueMicrotask, matchIgnoringCase, searchIgnoringCase } from '@bp/shared/utilities';

// eslint-disable-next-line @typescript-eslint/consistent-indexed-object-style
export interface IChipControlItem extends IDescribable {
	[ prop: string ]: any;
}

@Component({
	selector: 'bp-chips',
	templateUrl: './chips.component.html',
	styleUrls: [ './chips.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE, FADE_IN_LIST_STAGGERED ],
	host: {
		'(focusin)': 'onTouched()',
	},
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: ChipsControlComponent,
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: ChipsControlComponent,
			multi: true,
		},
	],
})
export class ChipsControlComponent
	extends FormFieldControlComponent<IChipControlItem[] | null, IChipControlItem | string>
	implements OnChanges {

	@Input()
	get items(): IChipControlItem[] | undefined {
		return this._items;
	}

	set items(value: IChipControlItem[] | undefined) {
		this._items = value ?? [];
	}

	private _items: IChipControlItem[] = [];

	@Input() itemDisplayPropertyName?: string;

	@Input() filterListFn?: (item: any, search: string) => boolean;

	@ViewChild('autocomplete', { static: true }) autocomplete!: MatAutocomplete;

	@ViewChild('input', { static: true }) inputRef!: ElementRef;

	@ContentChild(CdkPortal) portal?: CdkPortal;

	get $input(): HTMLInputElement {
		return this.inputRef.nativeElement;
	}

	separatorKeysCodes: number[] = [ ENTER, COMMA ];

	override throttle = 0;

	filtered$ = new BehaviorSubject<IChipControlItem[]>([]);

	override ngOnChanges(changes: SimpleChanges<this>) {
		super.ngOnChanges(changes);

		if (changes.items)
			this._updateFilteredExcludingSelectedChips();
	}

	focus(): void {
		this.$input.focus();
	}

	// #region Implementation of the ControlValueAccessor interface
	override writeValue(value: IChipControlItem[] | null): void {
		this._assertValueFitsChipControlValueType(value);

		bpQueueMicrotask(() => {
			this._setIncomingValue(value);

			this._updateFilteredExcludingSelectedChips();
		});
	}
	// #endregion Implementation of the ControlValueAccessor interface

	protected override _validator: ValidatorFn | null = (): ValidationErrors | null => null;

	getDisplayValue(item: any) {
		return (this.itemDisplayPropertyName && item[this.itemDisplayPropertyName])
			?? item?.displayName
			?? item?.name
			?? item.toString();
	}

	protected override _onInternalControlValueChange(searchTermOrSelectedItem: IChipControlItem | string) {
		if (isEmpty(this._items) || !isString(searchTermOrSelectedItem))
			return;

		this._filterAvailableItems(searchTermOrSelectedItem);
	}

	private _filterAvailableItems(searchTerm: string) {
		const trimmedSearchTerm = searchTerm.trim();

		const filtered = trimmedSearchTerm.length > 1
			? this._items.filter(item => this._filterItem(item, trimmedSearchTerm))
			: this._items;

		this.filtered$.next(
			this._excludeSelectedChipsFromCollection(filtered),
		);
	}

	add({ value }: MatChipInputEvent): void {
		if (!value)
			return;

		let foundChips = value.split(/,|\s/u)
			.map(searchTerm => searchTerm.trim())
			.filter(isPresent)
			.map(searchTerm => this._items.find(item => this._matchItem(item, searchTerm)))
			.filter(isPresent);

		foundChips = this._excludeSelectedChipsFromCollection(foundChips);

		this.select(...foundChips);

		this._resetInput();
	}

	remove(item: IChipControlItem): void {
		this.setValue(without(this._getSelectedChips(), item));

		this._updateFilteredExcludingSelectedChips();
	}

	selected({ option: { value } }: MatAutocompleteSelectedEvent): void {
		this.select(value);
	}

	select(...value: IChipControlItem[]) {
		this.setValue(uniq([ ...this._getSelectedChips(), ...value ]));

		this._updateFilteredExcludingSelectedChips();
	}

	override setValue(value: IChipControlItem[] | null, options?: { emitChange: boolean }) {
		this._resetInput();

		super.setValue(isEmpty(value) ? null : value, options);
	}

	private _resetInput() {
		this.$input.value = '';

		this.internalControl.setValue(null, { emitEvent: false });
	}

	private _getSelectedChips() {
		return this.value || [];
	}

	private _excludeSelectedChipsFromCollection(items: IChipControlItem[]): IChipControlItem[] {
		return difference(items, this._getSelectedChips());
	}

	private _updateFilteredExcludingSelectedChips() {
		this.filtered$.next(
			this._excludeSelectedChipsFromCollection(this._items),
		);
	}

	private _matchItem(item: any, searchTerm: string): boolean {
		return matchIgnoringCase(item.toString(), searchTerm)
			|| matchIgnoringCase(this.getDisplayValue(item), searchTerm);
	}

	private _filterItem(item: any, searchTerm: string): boolean {
		return this.filterListFn
			? this.filterListFn(item, searchTerm)
			: searchIgnoringCase(item.toString(), searchTerm)
			|| searchIgnoringCase(this.getDisplayValue(item), searchTerm);
	}

	private _isIncorrectValueType(value: unknown): boolean {
		return !isNil(value) && !isArray(value);
	}

	private _assertValueFitsChipControlValueType(value: unknown): never | void {
		if (this._isIncorrectValueType(value))
			throw new Error(`Incorrect incoming value ${ value } (not Array or Nil)`);
	}
}
