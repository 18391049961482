import { createAction, props } from '@ngrx/store';

import type { IntroPageStep } from '../models';

const ACTIONS_SCOPE = '[Page][Intro]';

export const changeIntroPageStep = createAction(
	`${ ACTIONS_SCOPE } Change Intro Page Step`,
	props<{ step: IntroPageStep }>(),
);
