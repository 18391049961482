<div *ngIf="!notification.isImportant" class="actions">
	<button
		*ngIf="!notification.isNonRemovable"
		class="btn-remove reset-default-decoration"
		mat-icon-button
		type="button"
		bpTooltip="Clear notification"
		(click)="notificationsHubFacade.remove(notification)"
	>
		<mat-icon>close</mat-icon>
	</button>

	<button
		type="button"
		class="btn-read-state reset-default-decoration"
		[bpTooltip]="notification.isRead ? 'Mark as unread' : 'Mark as read'"
		[class.read]="notification.isRead"
		(click)="notificationsHubFacade.setReadState(notification, !notification.isRead); $event.stopPropagation()"
	></button>
</div>

<div class="logo">
	<img *ngIf="notification.logoUrl; else defaultLogoTpl" class="logo" [src]="notification.logoUrl" alt="" />

	<ng-template #defaultLogoTpl>
		<bp-svg-icon name="logo" class="bp-icon-big"></bp-svg-icon>
	</ng-template>
</div>

<div class="content">
	<div class="text">{{ notification.text }}</div>
	<div class="mat-caption">
		{{ notification.createdAt | momentFromNow | async }}
	</div>
</div>

<div *ngIf="notification.button as btn" class="buttons mt-2">
	<button
		*ngIf="btn!.onClick && !btn.href && !btn.routerLink"
		class="bp-button-sm"
		color="primary"
		mat-stroked-button
		(click)="btn!.onClick!()"
	>
		{{ btn!.text }}
	</button>

	<a
		*ngIf="btn!.href"
		[href]="btn!.href"
		class="bp-button-sm"
		color="primary"
		mat-stroked-button
		bpTargetBlank
		(click)="btn!.onClick && btn!.onClick!()"
	>
		{{ btn!.text }}
	</a>

	<a
		*ngIf="btn!.routerLink"
		[routerLink]="btn!.routerLink"
		class="bp-button-sm"
		color="primary"
		mat-stroked-button
		(click)="btn!.onClick && btn!.onClick!()"
	>
		{{ btn!.text }}
	</a>
</div>
