import { LocalStorageService } from 'angular-2-local-storage';

type DyingStorageItem<T> = {
	value: T;
	aliveUntil: number;
};

export class StorageService extends LocalStorageService {

	setIfDifferentFromStored(object: Object | null, path: string): void {
		const serializedToStoreObject = JSON.stringify(object);
		const serializedStoredObject = this.get(path);

		if (serializedToStoreObject !== serializedStoredObject)
			this.set(path, object);
	}

	setDying<T>(key: string, value: T, timeToLiveInSeconds: number): boolean {
		return this.set(key, <DyingStorageItem<T>>{
			value,
			aliveUntil: Date.now() + 1000 * timeToLiveInSeconds,
		});
	}

	getAlive<T>(key: string): T | undefined {
		const storedDyingItem = this.get<DyingStorageItem<T> | undefined>(key);

		if (!storedDyingItem)
			return undefined;

		if (storedDyingItem.aliveUntil > Date.now())
			return storedDyingItem.value;

		this.remove(key);

		return undefined;
	}

}
