<button
	mat-icon-button
	bpTooltip="Notifications"
	(click)="notificationsHubFacade.isOpened ? notificationsHubFacade.close() : notificationsHubFacade.open()"
>
	<bp-attention-drawing-dot
		*ngIf="(notificationsHubFacade.hasUnread$ | async) || (notificationsHubFacade.hasImportant$ | async)"
		@fade
	></bp-attention-drawing-dot>
	<mat-icon>notifications</mat-icon>
</button>
