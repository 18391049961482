import { Observable, of, timer, merge, EMPTY, fromEvent } from 'rxjs';
import { delay, exhaustMap, filter, first, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { Actions, concatLatestFrom, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { apiResult } from '@bp/shared/models/common';
import {
	HttpConfigService,
	StorageService,
	UserIdleService,
	MockedBackendState,
	EnvironmentService,
	RouterService
} from '@bp/shared/services';
import { filterPresent } from '@bp/shared/rxjs';

import {
	IdentityEffects as IdentityBaseEffects,
	IDENTITY_STATE_KEY,
	NON_REDIRECTED_URLS_AFTER_LOGIN_TOKEN,
	getMockUsersCredentials,
	MockUserEmail
} from '@bp/shared-domains-identity';

import { LayoutFacade } from '@bp/admins-shared/features/layout';

import { IdentitySessionIsAboutToExpireDialogComponent } from '../components';
import type { ILoginApiRequest } from '../models';
import { LOGIN_ROUTE_PATHNAME, Identity } from '../models';
import { IdentityApiService } from '../services';
import { tryCreateIdentityBasedOnLoginQueryParams } from '../utils';

import {
	generateLoginOtpFailure,
	generateLoginOtpSuccess,
	generateResourceAccessOtpFailure,
	generateResourceAccessOtpSuccess,
	refreshTokenFailure,
	refreshTokenSuccess,
	resourceAccessOtpVerificationFailure,
	resourceAccessOtpVerificationSuccess
} from './identity-api.actions';
import {
	generateLoginOtp,
	generateResourceAccessOtp,
	identityEffectsInit,
	localStorageIdentityChanged,
	refreshAccessToken,
	resourceAccessOtpVerification,
	sessionExpired,
	setIdentityBasedOnLoginQueryParams,
	showIdentitySessionIsAboutToExpireDialog,
	startIdentitySessionExpiryTimer,
	stopIdentitySessionExpiryTimer
} from './identity.actions';
import { IdentityFacade } from './identity.facade';
import { FEATURE_STATE_KEY } from './identity.reducer';

const IDENTITY_PATH_IN_STATE = `${ FEATURE_STATE_KEY }.${ IDENTITY_STATE_KEY }`;

@Injectable()
export class IdentityEffects extends IdentityBaseEffects<Identity, ILoginApiRequest> implements OnInitEffects {
	onDemoUserLoginReloadInDemoMode$ = createEffect(
		() => this._actions$.pipe(
			ofType(this.actions.login),
			filter(
				({ payload }) => this._environment.isNotProduction
						&& payload.userName === MockUserEmail.Demo
						&& payload.password === 'awesomedemo',
			),
			tap(() => void MockedBackendState.reloadInDemoMode()),
		),
		{ dispatch: false },
	);

	onMockUserLoginReloadInMockMode$ = createEffect(
		() => this._actions$.pipe(
			ofType(this.actions.api.loginSuccess),
			filter(
				({ result }) => this._environment.isNotProduction && !!getMockUsersCredentials()[<MockUserEmail>result.email],
			),
			tap(() => void MockedBackendState.reloadInMockMode()),
		),
		{ dispatch: false },
	);

	trySetIdentityBasedOnLoginQueryParams$ = createEffect(() => this._actions$.pipe(
		ofType(identityEffectsInit),
		mergeMap(() => {
			const identity = tryCreateIdentityBasedOnLoginQueryParams();

			if (window.location.pathname === LOGIN_ROUTE_PATHNAME && identity)
				return of(setIdentityBasedOnLoginQueryParams({ identity }));

			return EMPTY;
		}),
	));

	override onLoginSuccessSetIdentity$ = createEffect(
		() => this._actions$.pipe(
			ofType(this.actions.api.loginSuccess),
			tap(({ result }) => {
				if (result.isIncomplete)
					this._identityFacade.setIncompleteIdentity(result);
				else
					this._identityFacade.setIdentity(result);
			}),
		),
		{ dispatch: false },
	);

	onLogout$ = createEffect(() => this._actions$.pipe(
		ofType(this.actions.logout),
		tap(() => {
			if (!!this._identityFacade.user || !this._router.url.includes(LOGIN_ROUTE_PATHNAME)) {
				MockedBackendState.reloadInNormalMode();

				this._identityFacade.removeIdentity();

				this._layoutFacade.closeFloatOutlets();

				this._identityFacade.saveUrlForRedirectionAfterLogin(
					this._nonRedirectedUrlsAfterLogin.flat().includes(this._router.url) ? '/' : this._router.url,
				);

				void this._router.navigateByUrl(LOGIN_ROUTE_PATHNAME);
			}
		}),
		delay(100), /// wait for the logout happening on any other open tab
		map(() => this.actions.logoutComplete()),
	));

	whenIdentityChangedToggleIdentitySessionExpiryTimer$ = createEffect(() => this._identityFacade.user$.pipe(
		map(identity => identity
			? startIdentitySessionExpiryTimer({
				expiresAt: identity.sessionExpiresAt,
					  })
			: stopIdentitySessionExpiryTimer()),
	));

	userSessionExpiryTimer$ = createEffect(() => this._actions$.pipe(
		ofType(startIdentitySessionExpiryTimer, stopIdentitySessionExpiryTimer),
		switchMap(action => action.type === startIdentitySessionExpiryTimer.type
			? timer(action.expiresAt.toDate()).pipe(map(() => sessionExpired()))
			: EMPTY),
	));

	whenSessionExpiredRefreshTokenOrLogout$ = createEffect(() => this._actions$.pipe(
		ofType(sessionExpired),
		map(() => (this._identityFacade.user!.refreshToken ? refreshAccessToken() : this.actions.logout())),
	));

	refreshIdentitySessionToken$ = createEffect(() => this._actions$.pipe(
		ofType(refreshAccessToken),
		concatLatestFrom(() => this._identityFacade.userPresent$),
		exhaustMap(([ , identity ]) => this._identityApiService
			.refreshToken(identity)
			.pipe(apiResult(refreshTokenSuccess, refreshTokenFailure))),
	));

	whenUserAwayShowSessionIsAboutToExpireDialog = this._identityFacade.user$
		.pipe(switchMap(identity => (identity ? this._userIdleService.onAway$ : EMPTY)))
		.subscribe(() => void this._identityFacade.showIdentitySessionIsAboutToExpireDialog());

	whenExpireDialogExpiresLogoutUser$ = createEffect(
		() => this._actions$.pipe(
			ofType(showIdentitySessionIsAboutToExpireDialog),
			exhaustMap(() => this._showIdentitySessionIsAboutToExpireDialogAndObserveContinueWorkingResult()),
			tap(keepWorking => !keepWorking && void this._identityFacade.logout()),
		),
		{ dispatch: false },
	);

	// #region SECTION Open tabs synchronization

	storeIdentityToLocalStorageOnChange = this._identityFacade.user$.subscribe(
		identity => void this._storageService.setIfDifferentFromStored(identity, IDENTITY_PATH_IN_STATE),
	);

	/**
	 * When on one tab the user has been changed on
	 * all the other tabs the user will be updated accordingly
	 */
	reflectLocalStorageUserChange$ = createEffect(() => fromEvent<StorageEvent>(window, 'storage').pipe(
		filter(event => event.key === this._storageService.deriveKey(IDENTITY_PATH_IN_STATE)),
		map(event => <Partial<Identity> | null>(event.newValue && JSON.parse(event.newValue)) ?? null),
		map(localStorageIdentity => (localStorageIdentity ? new Identity(localStorageIdentity) : null)),
		filter(
			localStorageIdentity => localStorageIdentity?.modified?.unix() !== this._identityFacade.user?.modified?.unix(),
		),
		map(localStorageIdentity => localStorageIdentityChanged({ identity: localStorageIdentity })),
	));

	handleLocalStorageUserChange$ = createEffect(
		() => this._actions$.pipe(
			ofType(localStorageIdentityChanged),
			tap(({ identity }) => identity ? void this._identityFacade.setIdentity(identity) : void this._identityFacade.logout()),
		),
		{ dispatch: false },
	);

	navigateToAppOnLoginFromDifferentTab$ = createEffect(() => this._actions$.pipe(
		ofType(localStorageIdentityChanged),
		filter(({ identity }) => !!identity && this._router.url.includes(LOGIN_ROUTE_PATHNAME)),
		withLatestFrom(this._identityFacade.urlForRedirectionAfterLogin$),
		map(([ , urlForRedirectionAfterLogin ]) => this.actions.navigateToApp({ urlForRedirectionAfterLogin })),
	));

	// #endregion !SECTION Open tabs synchronization

	whenUserChangeSetAuthorizedHeader = merge(
		this._identityFacade.user$,
		this._identityFacade.incompleteIdentity$.pipe(filterPresent),
	)
		.pipe(map(identity => identity?.jwt))
		.subscribe(jwt => jwt ? void this._httpConfig.setAuthorizationHeader(jwt) : void this._httpConfig.removeAuthorizationHeader());

	whenIdentitySetBasedOnLoginQueryParamsLoginSuccessfully$ = createEffect(() => this._actions$.pipe(
		ofType(setIdentityBasedOnLoginQueryParams),
		// eslint-disable-next-line rxjs/no-unsafe-switchmap
		switchMap(payload => {
			void this._identityFacade.logout(); // logout from any other open tab

			return this._actions$.pipe(
				ofType(this.actions.logoutComplete),
				// eslint-disable-next-line rxjs/no-unsafe-first
				first(),
				map(() => payload),
			);
		}),
		map(({ identity }) => this.actions.api.loginSuccess({ result: identity })),
	));

	generateLoginOtp$ = createEffect(() => this._actions$.pipe(
		ofType(generateLoginOtp),
		exhaustMap(() => this._identityApiService
			.generateLoginOtp()
			.pipe(apiResult(generateLoginOtpSuccess, generateLoginOtpFailure))),
	));

	generateResourceAccessOtp$ = createEffect(() => this._actions$.pipe(
		ofType(generateResourceAccessOtp),
		exhaustMap(({ resourceName }) => this._identityApiService
			.generateResourceAccessOtp(resourceName)
			.pipe(apiResult(generateResourceAccessOtpSuccess, generateResourceAccessOtpFailure))),
	));

	resourceAccessOtpVerification$ = createEffect(() => this._actions$.pipe(
		ofType(resourceAccessOtpVerification),
		exhaustMap(payload => this._identityApiService
			.resourceAccessOtpVerification(payload)
			.pipe(apiResult(resourceAccessOtpVerificationSuccess, resourceAccessOtpVerificationFailure))),
	));

	constructor(
		protected override readonly _identityFacade: IdentityFacade,
		protected override readonly _identityApiService: IdentityApiService,
		@Inject(NON_REDIRECTED_URLS_AFTER_LOGIN_TOKEN) private readonly _nonRedirectedUrlsAfterLogin: string[][],
		private readonly _storageService: StorageService,
		private readonly _userIdleService: UserIdleService,
		private readonly _layoutFacade: LayoutFacade,
		private readonly _httpConfig: HttpConfigService,
		private readonly _environment: EnvironmentService,
		router: Router,
		actions$: Actions,
		dialog: MatDialog,
		routerService: RouterService,
	) {
		super(_identityFacade, _identityApiService, actions$, dialog, router, routerService);
	}

	ngrxOnInitEffects(): Action {
		return identityEffectsInit();
	}

	private _showIdentitySessionIsAboutToExpireDialogAndObserveContinueWorkingResult(): Observable<boolean> {
		return this._dialog
			.open<IdentitySessionIsAboutToExpireDialogComponent, undefined, boolean>(
			IdentitySessionIsAboutToExpireDialogComponent,
			{
				disableClose: true,
			},
		)
			.afterClosed()
			.pipe(map(keepWorking => !!keepWorking));
	}
}
