import m from 'moment';

import { JwtToken } from '@bp/shared/utilities';

import { IdentityJwtPayloadDTO } from './identity-jwt-payload';
import { BridgerAdminFeature, MerchantAdminFeature } from './permissions';

export type IMockIdentityJwtPayload = IdentityJwtPayloadDTO;

export function getNeverExpiringEpoch(): number {
	return m().add(1, 'week')
		.unix();
}

export enum MockUserEmail {
	Demo = 'demo@demo.com',

	Free = 'free-mock@mock.com',

	Pro = 'pro-mock@mock.com',

	ProEmailOtp = 'pro-email-otp@mock.com',

	Enterprise = 'enterprise-mock@mock.com',

	EnterpriseUnpaid = 'enterprise-unpaid-mock@mock.com',
}

export const getFreeMockUserCredentials = (): IMockIdentityJwtPayload => ({
	userIdentityId: '6cefe991-a0d9-4169-b361-8529a5b8f27f',
	userFullName: 'Free User',
	userEmail: MockUserEmail.Free,
	exp: getNeverExpiringEpoch(),
	permissions: [
		`${ MerchantAdminFeature.dashboard }`,
		`${ MerchantAdminFeature.reports }.restricted`,
		`${ MerchantAdminFeature.reports.getActionClaim('preview') }`,
		`${ MerchantAdminFeature.reports.getActionClaim('export') }`,
		`${ MerchantAdminFeature.cashiers }`,
		`${ MerchantAdminFeature.cashiers.getActionClaim('create') }`,
		`${ MerchantAdminFeature.cashiers.getActionClaim('edit') }`,
		`${ MerchantAdminFeature.transactions }`,
		`${ MerchantAdminFeature.transactions.getActionClaim('refund') }.paywall`,
		`${ MerchantAdminFeature.transactions.getActionClaim('capture') }.restricted`,
		`${ MerchantAdminFeature.transactions.getActionClaim('void') }`,
		`${ MerchantAdminFeature.paymentRoutes }`,
		`${ MerchantAdminFeature.paymentRoutes.getActionClaim('create') }`,
		`${ MerchantAdminFeature.paymentRoutes.getActionClaim('edit') }`,
		`${ MerchantAdminFeature.paymentRouteCredentials }`,
		`${ MerchantAdminFeature.paymentRouteCredentials.getActionClaim('create') }`,
		`${ MerchantAdminFeature.paymentRouteCredentials.getActionClaim('edit') }`,
		`${ MerchantAdminFeature.paymentRouteRules }.paywall`,
		`${ MerchantAdminFeature.paymentRouteRules.getActionClaim('create') }`,
		`${ MerchantAdminFeature.paymentRouteRules.getActionClaim('edit') }`,
		`${ MerchantAdminFeature.virtualTerminal }`,
		`${ MerchantAdminFeature.payouts }.paywall`,
		`${ MerchantAdminFeature.creditCardTokens }.paywall`,
		`${ MerchantAdminFeature.creditCardTokens.getActionClaim('edit') }`,
		`${ MerchantAdminFeature.psps }`,
		`${ MerchantAdminFeature.psps.getActionClaim('create') }.paywall`,
		`${ MerchantAdminFeature.psps.getActionClaim('edit') }`,
		`${ MerchantAdminFeature.pspCredentials }`,
		`${ MerchantAdminFeature.pspCredentials.getActionClaim('create') }`,
		`${ MerchantAdminFeature.pspCredentials.getActionClaim('edit') }`,
		`${ MerchantAdminFeature.members }`,
		`${ MerchantAdminFeature.members.getActionClaim('invite') }.paywall`,
		`${ MerchantAdminFeature.members.getActionClaim('edit') }`,

		`${ BridgerAdminFeature.bridgerPsps }`,
		`${ BridgerAdminFeature.bridgerPsps.getActionClaim('create') }`,
		`${ BridgerAdminFeature.bridgerPsps.getActionClaim('edit') }`,
		`${ BridgerAdminFeature.bridgerPspSystemDetails }`,
		`${ BridgerAdminFeature.bridgerPspSystemDetails.getActionClaim('edit') }`,
	],
});

export const getProMockUserCredentials = (): IMockIdentityJwtPayload => ({
	...getFreeMockUserCredentials(),
	userFullName: 'Pro User',
	userEmail: MockUserEmail.Pro,
	permissions: getFreeMockUserCredentials().permissions!.map(permission => permission.replace('paywall', 'access')),
});

export const getProEmailOtpMockUserCredentials = (): IMockIdentityJwtPayload => ({
	...getProMockUserCredentials(),
	userFullName: 'Pro Email Otp User',
	userEmail: MockUserEmail.ProEmailOtp,
	otpProvider: 'email',
});

export const getEnterpriseMockUserCredentials = (): IMockIdentityJwtPayload => ({
	...getProMockUserCredentials(),
	userFullName: 'Enterprise User',
	userEmail: MockUserEmail.Enterprise,
});

export const getEnterpriseUnpaidMockUserCredentials = (): IMockIdentityJwtPayload => ({
	...getProMockUserCredentials(),
	userFullName: 'Enterprise Unpaid User',
	userEmail: MockUserEmail.Enterprise,
	permissions: [
		MerchantAdminFeature.transactions.claim,
		MerchantAdminFeature.enterpriseSubscriptionCheckout.claim,
	],
});

export const getDemoMockUserCredentials = (): IMockIdentityJwtPayload => ({
	userIdentityId: 'e2ffd316-9a72-42cc-bb0b-05409435359a',
	userFullName: 'Demo User',
	userEmail: MockUserEmail.Demo,
	exp: getNeverExpiringEpoch(),
	permissions: [
		`${ MerchantAdminFeature.dashboard }`,
		`${ MerchantAdminFeature.reports }`,
		`${ MerchantAdminFeature.reports.getActionClaim('preview') }`,
		`${ MerchantAdminFeature.reports.getActionClaim('export') }`,
		`${ MerchantAdminFeature.cashiers }`,
		`${ MerchantAdminFeature.cashiers.getActionClaim('create') }`,
		`${ MerchantAdminFeature.cashiers.getActionClaim('edit') }`,
		`${ MerchantAdminFeature.transactions }`,
		`${ MerchantAdminFeature.transactions.getActionClaim('refund') }`,
		`${ MerchantAdminFeature.transactions.getActionClaim('capture') }`,
		`${ MerchantAdminFeature.transactions.getActionClaim('void') }`,
		`${ MerchantAdminFeature.paymentRoutes }`,
		`${ MerchantAdminFeature.paymentRoutes.getActionClaim('create') }`,
		`${ MerchantAdminFeature.paymentRoutes.getActionClaim('edit') }`,
		`${ MerchantAdminFeature.paymentRouteCredentials }`,
		`${ MerchantAdminFeature.paymentRouteCredentials.getActionClaim('create') }`,
		`${ MerchantAdminFeature.paymentRouteCredentials.getActionClaim('edit') }`,
		`${ MerchantAdminFeature.paymentRouteRules }`,
		`${ MerchantAdminFeature.paymentRouteRules.getActionClaim('create') }`,
		`${ MerchantAdminFeature.paymentRouteRules.getActionClaim('edit') }`,
		`${ MerchantAdminFeature.virtualTerminal }`,
		`${ MerchantAdminFeature.payouts }`,
		`${ MerchantAdminFeature.creditCardTokens }`,
		`${ MerchantAdminFeature.creditCardTokens.getActionClaim('edit') }`,
		`${ MerchantAdminFeature.psps }`,
		`${ MerchantAdminFeature.psps.getActionClaim('create') }`,
		`${ MerchantAdminFeature.psps.getActionClaim('edit') }`,
		`${ MerchantAdminFeature.pspCredentials }`,
		`${ MerchantAdminFeature.pspCredentials.getActionClaim('create') }`,
		`${ MerchantAdminFeature.pspCredentials.getActionClaim('edit') }`,
		`${ MerchantAdminFeature.members }`,
		`${ MerchantAdminFeature.members.getActionClaim('invite') }`,
		`${ MerchantAdminFeature.members.getActionClaim('edit') }`,
	],
});

export const getMockUsersCredentials = () => ({
	[MockUserEmail.Free]: getFreeMockUserCredentials(),
	[MockUserEmail.Pro]: getProMockUserCredentials(),
	[MockUserEmail.ProEmailOtp]: getProEmailOtpMockUserCredentials(),
	[MockUserEmail.Enterprise]: getEnterpriseMockUserCredentials(),
	[MockUserEmail.EnterpriseUnpaid]: getEnterpriseUnpaidMockUserCredentials(),
	[MockUserEmail.Demo]: getDemoMockUserCredentials(),
});

// eslint-disable-next-line @typescript-eslint/naming-convention
export function getIdentityJwtPayload({ Authorization }: Record<string, string>): IMockIdentityJwtPayload {
	return JwtToken.decode<IMockIdentityJwtPayload>(Authorization.replace('Bearer ', ''));
}
