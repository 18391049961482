import type { Subscriber, Subscription, SubscriptionLike } from 'rxjs';
import { ObjectUnsubscribedError, Subject } from 'rxjs';

export class OptionalBehaviorSubject<T> extends Subject<T> {

	private readonly _hasInitValue: boolean;

	private _hasNextValue: boolean | undefined;

	constructor(private _value?: T) {
		super();

		this._hasInitValue = arguments.length === 1;
	}

	get value(): T | undefined {
		return this.getValue();
	}

	// eslint-disable-next-line @typescript-eslint/naming-convention
	// @ts-expect-error  RxJs 7 made this protected.
	protected override _subscribe(subscriber: Subscriber<T>): Subscription {
		// @ts-expect-error  RxJs 7 made this protected.
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call
		const subscription: Subscription = super._subscribe(subscriber);

		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		if (subscription && !(<SubscriptionLike> subscription).closed && (this._hasNextValue || this._hasInitValue))
			subscriber.next(this._value);

		return subscription;
	}

	getValue(): T | undefined {
		if (this.hasError)
			throw this.thrownError;

		if (this.closed)
			throw new ObjectUnsubscribedError();

		return this._value;
	}

	override next(value: T): void {
		this._hasNextValue = true;

		super.next(this._value = value);
	}
}
