import { camelCase, get } from 'lodash-es';

import type { Feature } from './feature';
import type { FeatureAction } from './feature-actions';

export class FeaturePermission<TFeature extends Feature> {
	readonly permission!: FeatureAction | TFeature;

	readonly featureName: string;

	readonly resourceName: string;

	readonly isRequiredOTP: boolean = false;

	readonly isBehindPaywall: boolean = false;

	/**
	 * @argument permissionDTO a string defined by the back looks like `featureName.access` or `featureName.actionName`
	 * or `featureName.access.restricted` or `featureName.actionName.restricted`
	 */
	constructor(
		private readonly _permissionName: string,
		private readonly _featureParser: (featureName: string) => TFeature,
	) {
		const [ featureName, featureActionName, restrictionLiteral ] = this._permissionName.split('.');

		const feature = this._featureParser(camelCase(featureName));
		const action = <FeatureAction>get(feature.actions, camelCase(featureActionName));

		this.permission = action === feature.actions.access ? feature : action;

		this.featureName = featureName;

		this.resourceName = `${ featureName }.${ featureActionName }`;

		this.isRequiredOTP = restrictionLiteral === 'restricted';

		this.isBehindPaywall = restrictionLiteral === 'paywall';
	}
}
