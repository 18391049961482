/// <reference path="../../../../../typings.d.ts" />
import { FirebaseError, getApp, getApps, initializeApp } from 'firebase/app';
import * as auth from 'firebase/auth';
import * as firestore from 'firebase/firestore';
import * as functions from 'firebase/functions';
import * as performance from 'firebase/performance';
import * as storage from 'firebase/storage';
import { identity, isEmpty, last, noop, snakeCase, take } from 'lodash-es';
import m from 'moment';
import type { MonoTypeOperatorFunction } from 'rxjs';
import { firstValueFrom, lastValueFrom, defer, from, Observable, Subject, throwError } from 'rxjs';
import { catchError, concatMap, finalize, map, subscribeOn } from 'rxjs/operators';

import { Inject, Injectable, InjectionToken } from '@angular/core';

import type { IPageQueryParams } from '@bp/shared/models/common';
import { BpError } from '@bp/shared/models/core';
import { RecordsPage, ISortQueryParams } from '@bp/shared/models/common';
import type { DTO, FirebaseEntity } from '@bp/shared/models/metadata';
import { AsyncVoidSubject, BpScheduler, observeInsideNgZone, ZoneService } from '@bp/shared/rxjs';
import type { Dictionary } from '@bp/shared/typings';
import { toPlainObject } from '@bp/shared/utilities';

import { FB_FUNCTIONS_REGION } from '@bp/firebase-functions';

import { EnvironmentService } from './environment.service';
import { TelemetryService } from './telemetry';

export const FIREBASE_APP_CONFIG = new InjectionToken('FIREBASE_APP_CONFIG');

export type FirebaseAppConfig = {
	appId: string;
	enablePersistence?: boolean;
	hasAuth?: boolean;
};

export type FirestoreQueryComposer<T> = (firestoreQuery: firestore.Query<DTO<T>>) => firestore.Query<DTO<T>>;

@Injectable({ providedIn: 'root' })
export class FirebaseService {

	uploadProgress$ = new Subject<number | null>();

	uploadedDownloadUrl$ = new Subject<string>();

	uploadError$ = new Subject<string>();

	init$ = new AsyncVoidSubject();

	private readonly _defaultSortField = 'updatedAt';

	private _queryDocumentSnapshotsById: Dictionary<any> = {};

	protected _uploadTask?: storage.UploadTask;

	private readonly _auth$ = this.init$.pipe(map(() => auth.getAuth()));

	private readonly _firestore$ = this.init$.pipe(map(() => firestore.getFirestore()));

	private readonly _storage$ = this.init$.pipe(map(() => storage.getStorage()));

	private readonly _functions$ = this.init$
		.pipe(map(() => functions.getFunctions(undefined, FB_FUNCTIONS_REGION)));

	constructor(
		protected _telemetry: TelemetryService,
		@Inject(FIREBASE_APP_CONFIG) protected _firebaseConfig: FirebaseAppConfig,
		private readonly _zoneService: ZoneService,
		private readonly _environment: EnvironmentService,
	) {
		void this._zoneService.runOutsideAngular(async () => {
			if (isEmpty(getApps())) {
				initializeApp({
					apiKey: 'AIzaSyCE0HJJUq4otCVdCbdBINJApcVmj3h-isU',
					authDomain: 'web-hosting-213618.firebaseapp.com',
					databaseURL: 'https://web-hosting-213618.firebaseio.com',
					projectId: 'web-hosting-213618',
					storageBucket: 'web-hosting-213618.appspot.com',
					messagingSenderId: '977741303368',
					appId: this._firebaseConfig.appId,
				});
			}

			if (this._environment.isRemoteServer)
				performance.getPerformance();

			// Note we MUST wait for persistence to be enabled before allowing any other usage of firestore, otherwise it stuck forever.
			if (this._environment.isRemoteServer && this._firebaseConfig.enablePersistence)
				await this._enableFirestorePersistence();

			if (this._firebaseConfig.hasAuth)
				void this.auth();

			this.init$.complete();
		});
	}

	async auth(): Promise<auth.Auth> {
		return firstValueFrom(this._auth$);
	}

	async getCurrentUser(): Promise<auth.User | null> {
		const authInstance = await this.auth();

		return authInstance.currentUser;
	}

	private async _storage(): Promise<storage.FirebaseStorage> {
		return firstValueFrom(this._storage$);
	}

	private async _functions(): Promise<functions.Functions> {
		return firstValueFrom(this._functions$);
	}

	private async _firestore(): Promise<firestore.Firestore> {
		return firstValueFrom(this._firestore$);
	}

	private async _enableFirestorePersistence(): Promise<void> {
		const firestoreInstance = firestore.initializeFirestore(getApp(), {
			cacheSizeBytes: firestore.CACHE_SIZE_UNLIMITED,
		});

		return firestore.enableMultiTabIndexedDbPersistence(firestoreInstance);
	}

	async generateNewDocumentId(collectionPath: string): Promise<string> {
		return lastValueFrom(
			defer(async () => this.getCollectionRef(collectionPath))
				.pipe(
					map(collectionReference => firestore.doc(collectionReference).id),
					this._subscribeOutsideAndRethrowAsBpError(),
				),
		);
	}

	async getCollectionRef<T = firestore.DocumentData>(collectionPath: string): Promise<firestore.CollectionReference<T>> {
		return lastValueFrom(
			defer(async () => this._firestore())
				.pipe(
					map(firestoreInstance => <firestore.CollectionReference<T>>firestore.collection(firestoreInstance, collectionPath)),
					this._subscribeOutsideAndRethrowAsBpError(),
				),
		);
	}

	async getDocumentRef(documentPath: string): Promise<firestore.DocumentReference> {
		return lastValueFrom(
			defer(async () => this._firestore())
				.pipe(
					map(firestoreInstance => firestore.doc(firestoreInstance, documentPath)),
					this._subscribeOutsideAndRethrowAsBpError(),
				),
		);
	}

	async callFunction(name: string, options?: functions.HttpsCallableOptions): Promise<functions.HttpsCallable> {
		return lastValueFrom(
			defer(async () => this._functions())
				.pipe(
					map(functionsInstance => functions.httpsCallable(functionsInstance, name, options)),
					this._subscribeOutsideAndRethrowAsBpError(),
				),
		);
	}

	async batch(): Promise<firestore.WriteBatch> {
		return lastValueFrom(
			defer(async () => this._firestore())
				.pipe(
					map(firestoreInstance => firestore.writeBatch(firestoreInstance)),
					this._subscribeOutsideAndRethrowAsBpError(),
				),
		);
	}

	firestoreQueryComposer<T>(
		composer: (firestoreQuery: firestore.Query<DTO<T>>) => firestore.Query<DTO<T>>,
	): (firestoreQuery: firestore.Query<DTO<T>>) => firestore.Query<DTO<T>> {
		return composer;
	}

	/**
	 * @returns a hot stream
	 */
	listenToQueriedRecordsPageChanges<T>(
		collectionPath: string,
		{ page, limit, authorUid, search, sortField, sortDir }: IPageQueryParams & Partial<ISortQueryParams> & {
			search?: string;
			authorUid?: string;
		},
		factory: ((data: DTO<T>) => T) | null,
		firestoreQueryComposer: FirestoreQueryComposer<T> = identity,
	): Observable<RecordsPage<T>> {
		return from(this.getCollectionRef(collectionPath))
			.pipe(
				concatMap(collection => {
					let query = firestore.query<DTO<T>>(
						<firestore.CollectionReference<DTO<T>>>collection,
						firestore.orderBy(
							sortField ?? this._defaultSortField,
							(<firestore.OrderByDirection | undefined> sortDir) ?? 'desc',
						),
					);

					if (limit !== -1) {
						query = firestore.query<DTO<T>>(
							query,
							firestore.limit(limit),
						);
					}

					if (authorUid) {
						query = firestore.query<DTO<T>>(
							query,
							firestore.where('authorUid', '==', authorUid),
						);
					}

					query = firestoreQueryComposer(query);

					if (search) {
						// 10 is cause array-contains-any support up to 10 comparison values only.
						const searchTerms = take(
							search
								.toLowerCase()
								.split(/\s|-/u)
								.filter(v => !!v),
							10,
						);

						if (searchTerms.length > 0) {
							query = firestore.query(
								query,
								firestore.where('searchTerms', 'array-contains-any', searchTerms),
							);
						}
					}

					if (page && this._queryDocumentSnapshotsById[page]) {
						query = firestore.query(
							query,
							firestore.startAfter(this._queryDocumentSnapshotsById[page]),
						);
					}

					return new Observable<RecordsPage<T>>(observer => {
						const unsubscribe = firestore.onSnapshot(
							query,
							({ docs }) => {
								const lastDocument = last(docs);
								const nextPageCursor = docs.length === limit && lastDocument
									? lastDocument.id
									: null;

								if (nextPageCursor)
									this._queryDocumentSnapshotsById[nextPageCursor] = lastDocument;

								observer.next(new RecordsPage({
									nextPageCursor,
									firstPage: !page,
									records: docs.map(v => factory
										? factory(v.data())
										: <T> v.data()),
								}));
							},
							error => void observer.error(error),
						);

						return () => void this._zoneService.runOutsideAngular(unsubscribe);
					});
				}),
				this._subscribeOutsideButObserveInsideAngularAndRethrowAsBprror(),
			);
	}

	/**
	 * @returns a hot stream
	 */
	listenToCollectionChanges<T>(
		collectionPath: string,
		factory: (data: DTO<T>) => T,
		firestoreQueryComposer: (firestoreQuery: firestore.Query) => firestore.Query<DTO<T>> = identity,
	): Observable<T[]> {
		return from(this.getCollectionRef(collectionPath))
			.pipe(
				concatMap(collection => new Observable<T[]>(observer => {
					const unsubscribe = firestore.onSnapshot(
						firestoreQueryComposer(collection),
						snapshot => void observer.next(snapshot.docs.map(v => factory(v.data()))),
						error => void observer.error(error),
					);

					return () => void this._zoneService.runOutsideAngular(unsubscribe);
				})),
				this._subscribeOutsideButObserveInsideAngularAndRethrowAsBprror(),
			);
	}

	/**
	 * @returns a cold stream
	 */
	getCollection<T>(
		collectionPath: string,
		factory: (dto: DTO<T>) => T,
		firestoreQueryComposer: FirestoreQueryComposer<T> = identity,
	): Observable<T[]> {
		return from(this.getCollectionRef<T>(collectionPath))
			.pipe(
				concatMap(async collection => firestore.getDocs(firestoreQueryComposer(collection))),
				map(snapshot => snapshot.docs.map(v => factory(v.data()))),
				this._subscribeOutsideButObserveInsideAngularAndRethrowAsBprror(),
			);
	}

	listenToDocumentChanges<T>(
		documentPath: string,
		factory: (dto: DTO<T>) => T,
	): Observable<T | null> {
		return from(this.getDocumentRef(documentPath))
			.pipe(
				concatMap(document => new Observable<T | null>(observer => {
					const unsubscribe = firestore.onSnapshot(
						document,
						snapshot => {
							const dto = <DTO<T> | undefined> snapshot.data();

							observer.next(dto ? factory(dto) : null);
						},
						error => void observer.error(error),
					);

					return () => void this._zoneService.runOutsideAngular(unsubscribe);
				})),
				this._subscribeOutsideButObserveInsideAngularAndRethrowAsBprror(),
			);
	}

	/**
	 * @return a cold stream
	 */
	getDocument<T>(
		documentPath: string,
		factory: (dto: DTO<T>) => T,
	): Observable<T | null> {
		return from(this.getDocumentRef(documentPath))
			.pipe(
				concatMap(async document => firestore.getDoc(document)),
				map(snapshot => snapshot.data()),
				map(dto => dto ? factory(<DTO<T>> dto) : null),
				this._subscribeOutsideButObserveInsideAngularAndRethrowAsBprror(),
			);
	}

	/**
	 * @return a hot stream
	 */
	listenToQueriedDocumentChanges<T>(
		collectionPath: string,
		factory: (data: DTO<T>) => T,
		firestoreQueryComposer: (firestoreQuery: firestore.Query) => firestore.Query<DTO<T> | null> = identity,
	): Observable<T | null> {
		return from(this.getCollectionRef(collectionPath))
			.pipe(
				concatMap(collection => new Observable<T | null>(observer => {
					const unsubscribe = firestore.onSnapshot(
						firestore.query(
							firestoreQueryComposer(collection),
							firestore.limit(1),
						),
						snapshot => {
							const document = snapshot.docs[0]?.data();

							void observer.next(document ? factory(document) : null);
						},
						error => void observer.error(error),
					);

					return () => void this._zoneService.runOutsideAngular(unsubscribe);
				})),
				this._subscribeOutsideButObserveInsideAngularAndRethrowAsBprror(),
			);
	}

	/**
	 * Deletes the document referred to by this `documentPath`.
	 *
	 * @return A cold stream completed once the document has been successfully
	 * deleted from the backend (Note that it won't complete while you're
	 * offline).
	 */
	delete(documentPath: string): Observable<void> {
		return from(this.getDocumentRef(documentPath))
			.pipe(
				concatMap(async document => firestore.deleteDoc(document)),
				this._subscribeOutsideButObserveInsideAngularAndRethrowAsBprror(),
			);
	}

	/**
	 * Writes to the document referred to by this `documentPath`. If the
	 * document does not yet exist, it will be created,
	 * otherwise the provided data is merged into an existing document.
	 * @return A cold stream completed once the data has been successfully written
	 * to the backend (Note that it won't complete while you're offline).
	 */
	set(documentPath: string, body: Object): Observable<void> {
		return from(this.getDocumentRef(documentPath))
			.pipe(
				concatMap(async document => firestore.setDoc(
					document,
					// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
					toPlainObject(body),
					{ merge: true },
				)),
				this._subscribeOutsideButObserveInsideAngularAndRethrowAsBprror(),
			);
	}

	/**
	 * Writes to the document referred to by this `documentPath`. If the
	 * document does not yet exist, it will be created,
	 * otherwise the provided data is merged into an existing document.
	 * @return A cold stream completed once the data has been successfully written
	 * to the backend (Note that it won't complete while you're offline).
	 */
	save<T extends FirebaseEntity>(
		collectionPath: string,
		entity: T,
		factory: (data: DTO<T>) => T,
	): Observable<T> {
		return from(this.getCurrentUser())
			.pipe(concatMap(async currentUser => {
				const isAdding = !entity.id;
				const entityId = entity.id ?? await this.generateNewDocumentId(collectionPath);

				const patch: DTO<FirebaseEntity> = isAdding
					? {
						authorUid: currentUser!.uid,
						createdAt: m(),
						updatedAt: m(),
					}
					: { updatedAt: m() };

				patch.id = entityId;

				const patchedEntity = factory({
					...<DTO<T>> <unknown> entity,
					...patch,
				});

				await lastValueFrom(this.set(`${ collectionPath }/${ entityId }`, patchedEntity));

				return patchedEntity;
			}));
	}

	/**
	 * Upload file to a specific folder path in firebase storage
	 * @param path A relative path to initialize the reference with,
	 * for example path/to/image.jpg. If not passed, the returned
	 * reference points to the bucket root.
	 */
	async upload(file: File, path: string): Promise<void> {
		const startProgressValue = 25;

		this.uploadProgress$.next(startProgressValue);

		return this._zoneService.runOutsideAngular(async () => {
			this._uploadTask?.cancel();

			const uploadFileRef = await this._getFileRef(file.name, path);

			this._uploadTask = storage.uploadBytesResumable(uploadFileRef, file);

			this._uploadTask.on(
				'state_changed',
				snapshot => {
					const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

					progress > startProgressValue && this._zoneService.runInAngularZone(() => void this.uploadProgress$.next(progress));
				},
				error => void this._zoneService.runInAngularZone(() => {
					this.uploadError$.next(error.message);

					this._telemetry.captureError(error);
				}),
				() => void storage.getDownloadURL(this._uploadTask!.snapshot.ref)
					.then(downloadURL => void this._zoneService.runInAngularZone(() => {
						this.uploadedDownloadUrl$.next(downloadURL);

						this.uploadProgress$.next(null);
					})),
			);
		});
	}

	// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
	async postFnCall<T, U = void>(firebaseFunctionName: string, body: T): Promise<U> {
		const result = await (await this.callFunction(firebaseFunctionName))(body);

		return <U>result.data;
	}

	onAuthStateChange(): Observable<auth.User | null> {
		return from(this.auth())
			.pipe(
				concatMap(authInstance => new Observable<auth.User | null>(observer => authInstance
					.onAuthStateChanged(
						user => void observer.next(user),
						error => void observer.error(this._mapToBpError(error)),
					))),
				this._subscribeOutsideButObserveInsideAngularAndRethrowAsBprror(),
			);
	}

	private async _getFileRef(fileName: string, path: string): Promise<storage.StorageReference> {
		const storageInstance = await this._storage();

		const fileReference = storage.ref(
			storage.ref(storageInstance, path),
			this._snakeCaseFileName(fileName),
		);

		const existFileMetadata = < { name: string } | undefined> await storage.getMetadata(fileReference)
			.catch(() => { /* Swallow 404 error since the empty var would be there is no file found */ });

		return existFileMetadata
			? this._getFileRef(this._increaseFileNameCounter(existFileMetadata.name), path)
			: fileReference;
	}

	private _increaseFileNameCounter(name: string): string {
		const fileName = this._getFilenameWithoutExtension(name);
		const counterRegexp = /_(?<counter>\d+)$/u;
		const fileCounter = counterRegexp.exec(fileName)?.groups?.['counter'];
		const nextFileCounter = Number(fileCounter ?? 0) + 1;

		return name.replace(
			fileName,
			fileCounter
				? fileName.replace(counterRegexp, `_${ nextFileCounter }`)
				: `${ fileName }_${ nextFileCounter }`,
		);
	}

	private _snakeCaseFileName(name: string): string {
		const fileName = this._getFilenameWithoutExtension(name);

		return name.replace(fileName, snakeCase(fileName));
	}

	private _getFilenameWithoutExtension(name: string): string {
		if (!name)
			return '';

		return (/(?<filename>.+?)(?<fileExtension>\.[^.]+$|$)/u).exec(name)?.groups?.['filename'] ?? '';
	}

	private _subscribeOutsideAndRethrowAsBpError<T>(): MonoTypeOperatorFunction<T> {
		return (source$: Observable<T>) => source$.pipe(
			subscribeOn(BpScheduler.outside),
			catchError(this._throwAsBpError),
		);
	}

	private _subscribeOutsideButObserveInsideAngularAndRethrowAsBprror<T>(): MonoTypeOperatorFunction<T> {
		return (source$: Observable<T>) => new Observable(observer => {

			/**
			 * We need this http request macrotask to tell angular that there is a pending request to firebase,
			 * since we run all the calls to firebase functionality outside the angular to prevent
			 * firing unnecessary change detections inside angular, firebase schedules a lot of macrotasks during
			 * its lifecycle. E.g. scully relies on counting of macrotasks to decide when to remove the placeholder root
			 * component
			 */
			const zone = Zone.current;
			const fakeXMLHttpRequestTask = zone.scheduleMacroTask('XMLHttpRequest', noop, {}, noop, noop);

			const subscription = source$
				.pipe(
					subscribeOn(BpScheduler.outside),
					catchError(this._throwAsBpError),
					observeInsideNgZone(),
					finalize(() => zone.cancelTask(fakeXMLHttpRequestTask)),
				)
				.subscribe(observer);

			return () => void subscription.unsubscribe();
		});
	}

	private readonly _throwAsBpError = (firebaseError: FirebaseError): Observable<never> => throwError(
		() => this._mapToBpError(firebaseError),
	);

	private readonly _mapToBpError = (error: Error | FirebaseError): BpError => new BpError({
		messages: [
			{
				type: error instanceof FirebaseError ? error.code : undefined,
				message: error.message,
			},
		],
	});

}
