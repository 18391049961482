import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTabsModule } from '@angular/material/tabs';

import { SharedComponentsControlsModule } from '@bp/shared/components/controls';
import { SharedComponentsCoreModule } from '@bp/shared/components/core';
import { SharedDirectivesModule } from '@bp/shared/directives';
import { SharedPipesModule } from '@bp/shared/pipes';

import { AdminsSharedCoreComponentsModule } from '@bp/admins-shared/core/components';

import {
	BridgerPspsFilterComponent, BridgerPspsMultipickerModule,
	PaymentOptionTypeToggleGroupComponent
} from './components';
import { PspsAddingComponent } from './psps-adding.component';

@NgModule({
	imports: [
		CommonModule,
		MatButtonToggleModule,
		MatTabsModule,
		MatButtonModule,
		MatBadgeModule,

		SharedPipesModule,
		SharedDirectivesModule,
		SharedComponentsCoreModule,
		SharedComponentsControlsModule,
		AdminsSharedCoreComponentsModule,

		BridgerPspsMultipickerModule,
	],
	exports: [ PspsAddingComponent ],
	declarations: [
		PaymentOptionTypeToggleGroupComponent,
		PspsAddingComponent,
		BridgerPspsFilterComponent,
	],
})
export class PspsAddingModule { }
