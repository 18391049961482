import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'bp-hint',
	templateUrl: './hint.component.html',
	styleUrls: [ './hint.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,

})
export class HintComponent {

	@Input() msg?: string;

	@Input()
	size: 'lg' | 'md' | 'sm' = 'sm';

}
