import type { Moment } from 'moment';

import { createAction, props } from '@ngrx/store';

import { TELEMETRY_HIDDEN_MARK } from '@bp/shared/models/core';

import type { IdentityActions } from '@bp/shared-domains-identity';
import { composeIdentityActions, IDENTITY_STATE_SCOPE as SCOPE } from '@bp/shared-domains-identity';

import type {
	CreateAccountApiRequest,
	Identity,
	ILoginApiRequest,
	IRegisterAuthenticatorAppApiRequest,
	IResetPasswordApiRequest,
	IResetPasswordOtpVerificationApiRequest,
	IResourceAccessOtpVerificationApiRequest,
	ISendResetPasswordLinkApiRequest,
	ISetSecurityQuestionsAnswersApiRequest,
	ILoginOtpVerificationApiRequest,
	IVerifySecurityQuestionsAnswersApiRequest
} from '../models';

import { IDENTITY_API_ACTIONS } from './identity-api.actions';

export const IDENTITY_ACTIONS: IdentityActions<Identity, ILoginApiRequest> = {
	...composeIdentityActions(),

	api: IDENTITY_API_ACTIONS,
};

export const identityEffectsInit = createAction(`${ SCOPE } Effects Init`);

export const localStorageIdentityChanged = createAction(
	`${ SCOPE } Local Storage Identity Changed`,
	props<{ identity: Identity | null }>(),
);

export const startIdentitySessionExpiryTimer = createAction(
	`${ SCOPE } Start Identity Session Expiry Timer`,
	props<{ expiresAt: Moment }>(),
);

export const stopIdentitySessionExpiryTimer = createAction(`${ SCOPE } Stop Identity Session Expiry Timer`);

export const showIdentitySessionIsAboutToExpireDialog = createAction(
	`${ SCOPE } Show Identity Session Is About To Expire Dialog`,
);

export const refreshAccessToken = createAction(`${ SCOPE } Refresh Access Token`);

export const sessionExpired = createAction(`${ SCOPE } Session Expired`);

export const setIdentityBasedOnLoginQueryParams = createAction(
	`${ SCOPE } Set Identity Based On Login Query Params`,
	props<{ identity: Identity }>(),
);

export const generateLoginOtp = createAction(`${ SCOPE } Generate Login OTP`);

export const loginOtpVerification = createAction(
	`${ SCOPE } Login OTP Verification ${ TELEMETRY_HIDDEN_MARK }`,
	props<ILoginOtpVerificationApiRequest>(),
);

export const generateResourceAccessOtp = createAction(
	`${ SCOPE } Generate Resource Access OTP`,
	props<{ resourceName: string }>(),
);

export const resourceAccessOtpVerification = createAction(
	`${ SCOPE } Resource Access Otp Verification ${ TELEMETRY_HIDDEN_MARK }`,
	props<IResourceAccessOtpVerificationApiRequest>(),
);

// #region SECTION Signup via link

export const acceptInvite = createAction(`${ SCOPE } Accept Invite`);

export const createAccount = createAction(
	`${ SCOPE } Create Account ${ TELEMETRY_HIDDEN_MARK }`,
	props<CreateAccountApiRequest>(),
);

// #endregion !SECTION

// #region deprecated, remove after accepting invite implementation by backend

export const verifyEmail = createAction(
	`${ SCOPE } Verify Email`,
);

export const createPassword = createAction(
	`${ SCOPE } Create Password ${ TELEMETRY_HIDDEN_MARK }`,
	props<CreateAccountApiRequest>(),
);

// #endregion !deprecated

// #region SECTION Continue Signup

export const loadAllSecurityQuestions = createAction(`${ SCOPE } Load All Security Questions`);

export const setSecurityQuestionsAnswers = createAction(
	`${ SCOPE } Set Security Questions Answers ${ TELEMETRY_HIDDEN_MARK }`,
	props<ISetSecurityQuestionsAnswersApiRequest>(),
);

export const loadAuthenticatorAppKey = createAction(`${ SCOPE } Load Authenticator App Key`);

export const registerAuthenticatorApp = createAction(
	`${ SCOPE } Register Authenticator App ${ TELEMETRY_HIDDEN_MARK }`,
	props<IRegisterAuthenticatorAppApiRequest>(),
);

// #endregion !SECTION

// #region SECTION Reset

export const loadIdentitySecurityQuestions = createAction(`${ SCOPE } Load Identity Security Questions`);

export const verifySecurityQuestionsAnswers = createAction(
	`${ SCOPE } Verify Security Questions Answers ${ TELEMETRY_HIDDEN_MARK }`,
	props<IVerifySecurityQuestionsAnswersApiRequest>(),
);

// #region SECTION Reset Password

export const sendResetPasswordLink = createAction(
	`${ SCOPE } Send Reset Password Link`,
	props<ISendResetPasswordLinkApiRequest>(),
);

export const resetPasswordOtpVerification = createAction(
	`${ SCOPE } Reset Password Otp Verification`,
	props<IResetPasswordOtpVerificationApiRequest>(),
);

export const resetPassword = createAction(`${ SCOPE } Reset Password`, props<IResetPasswordApiRequest>());

// #endregion !SECTION

// #region SECTION Reset Authenticator App

export const sendResetAuthenticatorAppLink = createAction(`${ SCOPE } Send Reset Authenticator App Link`);

export const resetAuthenticatorApp = createAction(
	`${ SCOPE } Reset Authenticator App`,
	props<IRegisterAuthenticatorAppApiRequest>(),
);

// #endregion !SECTION

// #endregion !SECTION
