import * as Sentry from '@sentry/angular';
import { noop } from 'lodash-es';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import type { ModuleWithProviders } from '@angular/core';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { Router } from '@angular/router';

import { APP_STORAGE_PREFIX } from '@bp/shared/models/core';
import { ZoneService } from '@bp/shared/rxjs';

import { HttpRequestInterceptorService, HttpResponseInterceptorService } from './http';
import { MockedBackendState } from './state-keepers';
import { AppErrorHandler } from './telemetry';
import { StorageService, SessionStorageService, LocalStorageService } from './storage';
import { WindowService } from './window.service';

@NgModule()
export class SharedServicesModule {

	static forRoot(): ModuleWithProviders<SharedServicesModule> {
		return {
			ngModule: SharedServicesModule,
			providers: [
				{
					provide: StorageService,
					useFactory: () => new StorageService({
						prefix: APP_STORAGE_PREFIX,
						storageType: MockedBackendState.isActive ? 'sessionStorage' : 'localStorage',
					}),
				},
				{
					provide: SessionStorageService,
					useFactory: () => new SessionStorageService({
						prefix: APP_STORAGE_PREFIX,
					}),
				},
				{
					provide: LocalStorageService,
					useFactory: () => new LocalStorageService({
						prefix: APP_STORAGE_PREFIX,
					}),
				},
				{ provide: HTTP_INTERCEPTORS, useClass: HttpResponseInterceptorService, multi: true },
				{ provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptorService, multi: true },
				{ provide: ErrorHandler, useClass: AppErrorHandler },
				{
					provide: Sentry.TraceService,
					deps: [ Router ],
				},
				{
					provide: APP_INITIALIZER,
					useFactory: () => noop,
					deps: [ Sentry.TraceService, ZoneService, WindowService ],
					multi: true,
				},
			],
		};
	}

}
