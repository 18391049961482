import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

import { FADE_IN } from '@bp/shared/animations';
import { EnvironmentService, MockedBackendState, LocalBackendState, ProgressiveWebAppService } from '@bp/shared/services';

import { LayoutFacade } from '../../state';

@Component({
	selector: 'bp-toolbar',
	templateUrl: './toolbar.component.html',
	styleUrls: [ './toolbar.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE_IN ],
})
export class ToolbarComponent {

	// eslint-disable-next-line @typescript-eslint/naming-convention
	MockedBackendState = MockedBackendState;

	// eslint-disable-next-line @typescript-eslint/naming-convention
	LocalBackendState = LocalBackendState;

	@HostBinding('class.bp-toolbar') toolbar = true;

	@Input() userName!: string;

	@Output() readonly logout = new EventEmitter();

	constructor(
		public layoutFacade: LayoutFacade,
		public environment: EnvironmentService,
		public pwaService: ProgressiveWebAppService,
	) { }

}
