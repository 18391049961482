import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import type { IdentityPreferences } from '../../models';

import { updateIdentityPreferences } from './identity-preferences.actions';
import { selectIdentityPreferences } from './identity-preferences.selectors';

@Injectable({ providedIn: 'root' })
export class IdentityPreferencesFacade {

	identityPreferences$ = this._store$.select(selectIdentityPreferences);

	identityPreferences!: IdentityPreferences;

	constructor(private readonly _store$: Store) {

		this._updateIdentityPreferencesPropertyOnStateChange();

	}

	update(identityPreferences: IdentityPreferences): void {
		this._store$.dispatch(updateIdentityPreferences({ identityPreferences }));
	}

	private _updateIdentityPreferencesPropertyOnStateChange(): void {
		this.identityPreferences$
			.subscribe(identityPreferences => (this.identityPreferences = identityPreferences));
	}

}
