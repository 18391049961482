import { get, set } from 'lodash-es';

import type { Action, ActionReducer } from '@ngrx/store';
import { INIT } from '@ngrx/store';

const APP_STATE = '[BP]:APP_STATE';

export function hmrAppStateRestorerMetaReducer<T, V extends Action = Action>(
	reducer: ActionReducer<T, V>,
): ActionReducer<T, V> {
	return (state, action: V): T => {
		const newState = reducer(state, action);

		if (action.type === INIT)
			return get(window, APP_STATE) || newState;

		set(window, APP_STATE, newState);

		return newState;
	};
}
