<h1 class="mat-display-3">
	<ng-content select=".ng-content-title"></ng-content>
</h1>

<h1 class="error-code">
	<ng-content select=".ng-content-title-error-code"></ng-content>
</h1>

<h3 class="description mb-5">
	<ng-content select=".ng-content-description"></ng-content>
</h3>

<div class="error-image">
	<ng-content select=".ng-content-error-image"></ng-content>
</div>
