import { QuicklinkModule } from 'ngx-quicklink';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { SharedComponentsCoreModule } from '@bp/shared/components/core';
import { SharedDirectivesModule } from '@bp/shared/directives';
import { SharedFeaturesSvgIconsModule } from '@bp/shared/features/svg-icons';
import { SharedFeaturesTooltipModule } from '@bp/shared/features/tooltip';

import {
	EditableViewCardComponent, EditableViewListCardComponent, FooterComponent, LayoutComponent, NavGroupComponent, NavItemComponent,
	RightDrawerComponent, RightDrawersOrchestratorComponent, SidenavComponent, SvgAdminSharedIconsDefinitionsComponent, ToolbarComponent
} from './components';
import { ActiveTabSetterOnRouteChangeDirective, RightDrawerNavBarDirective } from './directives';

const COMPONENTS = [
	LayoutComponent,
	NavItemComponent,
	NavGroupComponent,
	SidenavComponent,
	RightDrawerComponent,
	RightDrawersOrchestratorComponent,
	ToolbarComponent,
	FooterComponent,
	SvgAdminSharedIconsDefinitionsComponent,
	EditableViewCardComponent,
	EditableViewListCardComponent,

	ActiveTabSetterOnRouteChangeDirective,
	RightDrawerNavBarDirective,
];

@NgModule({
	imports: [
		QuicklinkModule,
		CommonModule,
		RouterModule,
		StoreModule,
		EffectsModule,
		MatSidenavModule,
		MatListModule,
		MatToolbarModule,
		MatIconModule,
		MatMenuModule,
		MatButtonModule,

		SharedFeaturesSvgIconsModule,
		SharedFeaturesTooltipModule,
		SharedComponentsCoreModule,
		SharedDirectivesModule,
	],
	declarations: COMPONENTS,
	exports: COMPONENTS,
})
export class AdminsSharedFeaturesLayoutModule { }
