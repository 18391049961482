import { ToastrService } from 'ngx-toastr';

import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

import { FormBaseComponent } from '@bp/shared/components/core';
import { ensureFormGroupConfig } from '@bp/shared/typings';
import { Validators } from '@bp/shared/features/validation';

import type { IResetPasswordOtpVerificationApiRequest } from '@bp/admins-shared/domains/identity';

@Component({
	selector: 'bp-otp-verification-form',
	templateUrl: './otp-verification-form.component.html',
	styleUrls: [ './otp-verification-form.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OtpVerificationFormComponent extends FormBaseComponent<IResetPasswordOtpVerificationApiRequest> {

	@Output() readonly tryAnotherWay = new EventEmitter<void>();

	override onSubmitShowInvalidInputsToast = false;

	constructor(
		fb: UntypedFormBuilder,
		cdr: ChangeDetectorRef,
		toaster: ToastrService,
	) {
		super(fb, cdr, toaster);

		this.form = this._formBuilder.group(
			ensureFormGroupConfig<IResetPasswordOtpVerificationApiRequest>({
				code: [ null, [ Validators.required ]],
			}),
		);
	}

}
