
import type { MetadataEntity } from '../metadata-entity';

import { Property } from './property-metadata.decorator';

/**
 * Such property is hidden from telemetry
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export function Secret() {
	return function(model: MetadataEntity, property: string): void {
		Property({ isSecret: true })(model, property);
	};
}
