import { ChangeDetectionStrategy, Component } from '@angular/core';

import { FADE } from '@bp/shared/animations';

import { NotificationsHubFacade } from '../../state';

@Component({
	selector: 'bp-notifications-hub-bell-btn',
	templateUrl: './notifications-hub-bell-btn.component.html',
	styleUrls: [ './notifications-hub-bell-btn.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE ],
})
export class NotificationsHubBellBtnComponent {

	constructor(public notificationsHubFacade: NotificationsHubFacade) { }

}
