import type { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { BpError } from '@bp/shared/models/core';

export function reportJsErrorIfAny<T>(source$: Observable<T>): Observable<T> {
	return source$.pipe(
		catchError((error: unknown) => {
			if (error instanceof Error && !(error instanceof BpError))
				console.error(error);

			// eslint-disable-next-line rxjs/throw-error
			return throwError(() => error);
		}),
	);
}
