/* eslint-disable max-classes-per-file */
import { isEmpty, isNumber, uniq, without } from 'lodash-es';

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
	ChangeDetectionStrategy, Component, ContentChild, Directive, ElementRef, Input, ViewChild
} from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';

import { FADE } from '@bp/shared/animations';
import { FormFieldControlComponent } from '@bp/shared/components/core';
import { InputTextMaskConfig, NumberMaskConfig, TextMaskDirective } from '@bp/shared/features/text-mask';
import { bpQueueMicrotask } from '@bp/shared/utilities';

import { InputComponent } from '../input';

/**
 * Allows the user to customize the label.
 */
@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: 'bp-input-chips-label',
})
export class InputChipsLabelDirective { }

/**
 * Allows the user to customize the hint.
 */
@Directive({
	selector: 'bp-input-chips-hint, [bpInputChipsHint]',
})
export class InputChipsHintDirective { }

/**
 * Allows the user to add prefix.
 */
@Directive({
	selector: 'bp-input-chips-prefix, [bpInputChipsPrefix]',
})
export class InputChipsPrefixDirective { }

@Component({
	selector: 'bp-input-chips',
	templateUrl: './input-chips-control.component.html',
	styleUrls: [ './input-chips-control.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE ],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: InputChipsControlComponent,
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: InputChipsControlComponent,
			multi: true,
		},
	],
})
export class InputChipsControlComponent
	extends FormFieldControlComponent<number[] | string[] | null> {

	@Input() number!: boolean;

	@Input() mask!: InputTextMaskConfig;

	/** User-supplied override of the label element. */
	@ContentChild(InputChipsLabelDirective) customLabel?: InputChipsLabelDirective;

	@ContentChild(InputChipsHintDirective) customHint?: InputChipsHintDirective;

	@ContentChild(InputChipsPrefixDirective) prefix?: InputChipsPrefixDirective;

	@ViewChild(TextMaskDirective) maskDirective?: TextMaskDirective;

	@ViewChild('input', { static: true }) inputRef!: ElementRef<HTMLInputElement>;

	get $input(): HTMLInputElement {
		return this.inputRef.nativeElement;
	}

	separatorKeysCodes: number[] = [ ENTER, COMMA ];

	numberMask = InputComponent.numberMask;

	// #region Implementation of the ControlValueAccessor interface
	override writeValue(value: string[] | null): void {
		bpQueueMicrotask(() => void this.setValue(value, { emitChange: false }));
	}
	// #endregion Implementation of the ControlValueAccessor interface

	protected override _onInternalControlValueChange(_value: number[] | string[] | null) {

		/*
		 * Intentionally left blank, since we don't want to update the external control on input change
		 * only on a chip submit handled by add function
		 */
	}

	add(value: string): void {
		if (value.trim())
			this.setValue([ ...this.getSelectedChips(), value ]);
	}

	remove(item: number | string): void {
		this.setValue(without(this.getSelectedChips(), item));
	}

	focus(): void {
		this.$input.focus();
	}

	override setValue(chips: (number | string)[] | null, options?: { emitChange: boolean }) {
		this._resetInput();

		const value = this.maskDirective?.config instanceof NumberMaskConfig
			&& !this.maskDirective.config.allowLeadingZeroes
			&& !isEmpty(chips)
			? (<string[]> chips)!.map(v => isNumber(v) ? v : Number(v.replace(/\s/ug, '')))
			: chips;

		super.setValue(isEmpty(value) ? null : uniq(<any[]> value), options);
	}

	getSelectedChips(): (number | string)[] {
		return this.value || [];
	}

	private _resetInput() {
		this.$input.value = '';

		this.internalControl.setValue(null, { emitEvent: false });
	}

}
