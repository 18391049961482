<div class="mat-calendar-header">
	<div class="mat-calendar-controls">
		<button
			mat-button
			type="button"
			class="mat-calendar-period-button"
			cdkAriaLive="polite"
			[attr.aria-label]="periodButtonLabel"
			(click)="currentPeriodClicked()"
		>
			{{ periodButtonText | bpTranslate | async }}
			<div class="mat-calendar-arrow" [class.mat-calendar-invert]="calendar.currentView !== 'month'"></div>
		</button>

		<div class="mat-calendar-spacer"></div>

		<button
			mat-icon-button
			type="button"
			class="mat-calendar-clear-button"
			[bpTooltip]="'Clear the field' | bpTranslate | async"
			[disabled]="!this.pickerContent._getSelected()"
			(click)="clear()"
		>
			<mat-icon>delete_outline</mat-icon>
		</button>

		<button
			mat-icon-button
			type="button"
			class="mat-calendar-previous-button"
			[disabled]="!previousEnabled()"
			(click)="previousClicked()"
		></button>

		<button
			mat-icon-button
			[bpTooltip]="'Show the pane with today' | bpTranslate | async"
			type="button"
			class="mat-calendar-previous-button mat-calendar-today-button"
			(click)="moveToToday()"
		></button>

		<button
			mat-icon-button
			type="button"
			class="mat-calendar-next-button"
			[disabled]="!nextEnabled()"
			(click)="nextClicked()"
		></button>
	</div>
</div>
