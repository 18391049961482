<ng-container *ngIf="hasSeparator">
	<hr
		*bpDelayedRender="0; stagger: renderStagger; allStagger: true"
		@fadeInSemiSlow
		[style.margin-bottom.rem]="heading ? 1.75 : 2.5"
	/>
</ng-container>

<ng-container *ngIf="heading">
	<h2 *bpDelayedRender="0; stagger: renderStagger; allStagger: true" @fadeInSemiSlow class="mat-h2 mb-4">
		{{ heading }}
	</h2>
</ng-container>

<ng-container *ngIf="form">
	<ng-container *ngIf="propertiesMetadata; else sectionSchemeTpl">
		<ng-container *ngFor="let rowPropsMd of propertiesMetadata | chunk: (oneColumn ? 1 : 2)">
			<div
				*bpDelayedRender="0; stagger: renderStagger; allStagger: true"
				@fadeInSemiSlow
				(@fadeInSemiSlow.done)="isFadeInAnimationComplete = true"
				class="row"
			>
				<ng-container *ngFor="let propertyMetadata of rowPropsMd | takePresent">
					<ng-template
						[ngTemplateOutlet]="propertyMetadataControlTpl"
						[ngTemplateOutletContext]="{ $implicit: propertyMetadata }"
					>
					</ng-template>
				</ng-container>
			</div>
		</ng-container>
	</ng-container>

	<ng-template #sectionSchemeTpl>
		<ng-container *ngFor="let rowCellsDefs of sectionScheme!">
			<div
				*bpDelayedRender="0; stagger: renderStagger; allStagger: true"
				@fadeInSemiSlow
				(@fadeInSemiSlow.done)="isFadeInAnimationComplete = true"
				class="row"
			>
				<ng-container *ngFor="let rowCellDef of rowCellsDefs | takePresent">
					<div *ngIf="rowCellDef === ROW_EMPTY_CELL; else controlTpl"></div>
					<ng-template #controlTpl>
						<ng-container
							*ngTemplateOutlet="
								propertyMetadataControlTpl;
								context: { $implicit: getPropertyMetadata($any(rowCellDef)) }
							"
						>
						</ng-container>
					</ng-template>
				</ng-container>
			</div>
		</ng-container>
	</ng-template>
</ng-container>

<ng-template #propertyMetadataControlTpl let-propertyMetadata>
	<ng-container
		*ngIf="customControlPerProperty.has(propertyMetadata.property); else defaultPropertyMetadataControlTpl"
	>
		<ng-template
			[ngTemplateOutlet]="customControlPerProperty.get(propertyMetadata.property)!"
			[ngTemplateOutletContext]="{ $implicit: propertyMetadata }"
		>
		</ng-template>
	</ng-container>

	<ng-template #defaultPropertyMetadataControlTpl>
		<!-- eslint-disable-next-line @angular-eslint/template/no-call-expression -->

		<bp-property-metadata-control
			*ngIf="getCustomContext$($any(propertyMetadata.property)) | async as context"
			class="w-100"
			[metadata]="propertyMetadata"
			[control]="getFormControl(propertyMetadata.property)"
			[floatLabel]="
				!isFadeInAnimationComplete && !isNil(getFormControl($any(propertyMetadata.property)).value)
					? 'always'
					: undefined
			"
			[appearance]="appearance"
			[color]="color"
			[disabled]="context.disabled"
			[bpTooltip]="context.disabledDueTo"
			[pending]="context.pending"
			[items]="context.items"
			[textMask]="context.textMask"
		>
		</bp-property-metadata-control>
	</ng-template>
</ng-template>
