import { TelemetryService } from '@bp/shared/services';

import { ILoginQueryParams } from '@bp/shared-domains-identity';

import { Identity } from '../models';

export function tryCreateIdentityBasedOnLoginQueryParams(): Identity | null {
	const identityQueryParams = <Partial<ILoginQueryParams>>(
		Object.fromEntries(new URL(window.location.toString()).searchParams.entries())
	);

	if (identityQueryParams.jwt) {
		try {
			return new Identity(identityQueryParams.jwt, identityQueryParams.refreshToken);
		} catch (error: unknown) {
			TelemetryService.captureError(error, 'app', identityQueryParams.jwt);

			return null;
		}
	}

	return null;
}
