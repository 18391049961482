<button
	*ngIf="showResendOtpButton$ | async"
	@slide
	type="button"
	class="link interactive"
	[disabled]="(canResendOtp$ | async) === false"
	(click)="
		resourceName === 'login'
			? identityFacade.generateLoginOtp()
			: identityFacade.generateResourceAccessOtp(resourceName)
	"
>
	Send again

	<span *ngIf="(canResendOtp$ | async) === false" @fade> in {{ secondsUntilOtpExpires$ | async }} seconds</span>
</button>
