import { createReducer, on } from '@ngrx/store';

import type { Notification } from '../models';

import {
	close, open, setHasImportantItems, setHasUnreadItems, updateItems
} from './notifications-hub.actions';

export const FEATURE_KEY = '[feature]:notifications-hub';

export interface IState {

	notifications: Notification[];

	hasUnread: boolean;

	hasImportant: boolean;

	hasNotImportant: boolean;

	hasRemovable: boolean;

	hasNonRemovable: boolean;

	hasPendings: boolean;

	isOpened: boolean;

}

export const initialState: IState = {
	notifications: [],
	hasUnread: false,
	hasImportant: false,
	hasNotImportant: false,
	hasRemovable: false,
	hasNonRemovable: false,
	hasPendings: false,
	isOpened: false,
};

export const reducer = createReducer(
	initialState,

	on(open, close, (state, { type }): IState => ({
		...state,
		isOpened: type === open.type,
	})),

	on(setHasUnreadItems, (state, { hasUnread }): IState => ({
		...state,
		hasUnread,
	})),

	on(setHasImportantItems, (state, { hasImportant }): IState => ({
		...state,
		hasImportant,
	})),

	on(updateItems, (state, { notifications }): IState => ({
		...state,
		notifications: [ ...notifications ]
			.sort((a, b) => a.createdAt.isBefore(b.createdAt) ? 1 : -1),
		hasImportant: notifications.some(v => v.isImportant),
		hasNotImportant: notifications.some(v => !v.isImportant),
		hasNonRemovable: notifications.some(v => v.isNonRemovable),
		hasRemovable: notifications.some(v => !v.isNonRemovable),
		hasUnread: notifications.some(v => !v.isRead),
		hasPendings: notifications.some(v => v.isPending),
	})),

);
