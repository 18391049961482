
import { Injectable } from '@angular/core';

import { MomentTimezoneService } from '@bp/shared/services';

@Injectable({ providedIn: 'root' })
export class MomentBasePipe {

	constructor(protected _momentTimezoneService: MomentTimezoneService) {

	}

}
