import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IdentityFacade } from '@bp/admins-shared/domains/identity';

@Component({
	selector: 'bp-accept-invite-page',
	templateUrl: './accept-invite-page.component.html',
	styleUrls: [ './accept-invite-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcceptInvitePageComponent {

	constructor(
		public readonly identityFacade: IdentityFacade,
	) {
		this.identityFacade.acceptInvite();
	 }

}
