import { omitBy, isNil } from 'lodash-es';

import { HttpParams } from '@angular/common/http';

import { BooleanString } from '@bp/shared/typings';

export class QueryParamsBase<QueryParamsDTO> {

	protected _dto!: QueryParamsDTO;

	constructor(queryParamsDTO: unknown) {
		Object.defineProperty(this, '_dto', {
			enumerable: false, // By doing this the property becomes unserializable by JSON.stringify()
			value: queryParamsDTO ?? {},
		});
	}

	toHttpParams(): HttpParams {
		return new HttpParams({
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			fromObject: <any> omitBy(this, isNil),
		});
	}
}

export type StringifyQuery<Target extends Object> = {
	[ PropertyKey in keyof Target ]?:
	Target[ PropertyKey ] extends never
		? never
		: Target[ PropertyKey ] extends boolean
			? BooleanString | boolean
			: Target[PropertyKey] | string
};
