import { PaymentOptionType } from '@bp/shared/models/business';
import type { ClassMetadata, DTO } from '@bp/shared/models/metadata';
import { isPresent } from '@bp/shared/utilities';

import { BridgerPspPaymentOptionApm } from './bridger-psp-payment-option-apm';
import { BridgerPspPaymentOptionCreditCard } from './bridger-psp-payment-option-credit-card';
import { BridgerPspPaymentOptionCrypto } from './bridger-psp-payment-option-crypto';
import { BridgerPspPaymentOptionVoucher } from './bridger-psp-payment-option-voucher';

export type BridgerPspPaymentOptions = BridgerPspPaymentOptionApm
| BridgerPspPaymentOptionCreditCard
| BridgerPspPaymentOptionCrypto
| BridgerPspPaymentOptionVoucher;

const bridgerPspPaymentOptionsConstructors = [
	BridgerPspPaymentOptionCreditCard,
	BridgerPspPaymentOptionApm,
	BridgerPspPaymentOptionVoucher,
	BridgerPspPaymentOptionCrypto,
];

export function bridgerPspPaymentOptionsFactory(dtos: DTO<BridgerPspPaymentOptions>[]): BridgerPspPaymentOptions[] {
	return dtos
		.map(dto => dto.type && bridgerPspPaymentOptionFactory(dto.type, <any> dto))
		.filter(isPresent);
}

export function bridgerPspPaymentOptionFactory<T extends BridgerPspPaymentOptions = BridgerPspPaymentOptions>(
	type: PaymentOptionType | string,
	dto?: DTO<T>,
): T {
	const paymentOptionType = PaymentOptionType.parseStrict(type);

	const targetPaymentOptionConstructor = bridgerPspPaymentOptionsConstructors.find(
		paymentOption => paymentOption.type === paymentOptionType,
	);

	if (targetPaymentOptionConstructor)
		return (<T> new targetPaymentOptionConstructor(<any> dto));

	throw new Error(`No appropriate constructor for ${ paymentOptionType }`);

}

export function getBridgerPspPaymentOptionClassMetadata(
	type: PaymentOptionType,
): ClassMetadata<BridgerPspPaymentOptions> {
	const targetPaymentOptionConstructor = bridgerPspPaymentOptionsConstructors.find(
		paymentOption => paymentOption.type === type,
	);

	if (targetPaymentOptionConstructor)
		return targetPaymentOptionConstructor.getClassMetadata();

	throw new Error(`No appropriate class metadata for ${ type }`);
}
