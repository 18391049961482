import { Injectable } from '@angular/core';

import { MomentService } from './moment.service';
import { ObsoleteUrlsRerouterService } from './obsolete-urls-rerouter.service';
import { TitleService } from './title.service';

/**
 * The service for starting and managing app-wide different services and their intercommuncation at one place
 */
@Injectable({
	providedIn: 'root',
})
export class SharedStartupService {

	constructor(
		private readonly _obsoleteUrlsRerouter: ObsoleteUrlsRerouterService,
		private readonly _title: TitleService,
		private readonly _moment: MomentService,
	) { }

	init(): void {
		this._moment.init();

		this._obsoleteUrlsRerouter.init();

		this._title.init();
	}

}
