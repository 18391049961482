import type { Observable } from 'rxjs';
import { defer } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import type { DTO } from '@bp/shared/models/metadata';
import { filterPresent, filterTruthy } from '@bp/shared/rxjs';
import { bpQueueMicrotask } from '@bp/shared/utilities';

import type { IIdentity } from '../models';

import type { IdentitySelectors } from './compose-identity-selectors';
import type { IdentityActions } from './identity.actions';

@Injectable({ providedIn: 'root' })
export abstract class IdentityFacade<TIdentity extends IIdentity, TLoginPayload = undefined> {
	abstract readonly actions: IdentityActions<TIdentity, TLoginPayload>;

	abstract readonly selectors: IdentitySelectors<TIdentity>;

	readonly user$: Observable<TIdentity | null> = defer(() => this._store$.select(this.selectors.user));

	user!: TIdentity | null;

	readonly userPresent$: Observable<TIdentity> = this.user$.pipe(filterPresent);

	readonly userIsLoggedIn$: Observable<boolean> = this.user$.pipe(
		map(v => !!v),
		distinctUntilChanged(),
	);

	readonly userIsLoggedOut$: Observable<boolean> = this.userIsLoggedIn$.pipe(map(v => !v));

	readonly userLoggedIn$: Observable<TIdentity> = defer(() => this._actions$.pipe(
		ofType(this.actions.api.loginSuccess),
		map(({ result }) => result),
	));

	readonly userLoggedOut$: Observable<null> = defer(() => this._actions$.pipe(
		ofType(this.actions.logout),
		map(() => null),
	));

	readonly pending$ = defer(() => this._store$.select(this.selectors.pending));

	readonly urlForRedirectionAfterLogin$ = defer(() => this._store$.select(this.selectors.urlForRedirectionAfterLogin));

	readonly reset$ = defer(() => this._actions$.pipe(ofType(this.actions.resetState)));

	readonly error$ = defer(() => this._store$.select(this.selectors.error));

	constructor(protected readonly _store$: Store, protected readonly _actions$: Actions) {
		// at the end of the event loop to be sure the selectors and actions are set
		bpQueueMicrotask(() => {
			this._updateUserPropertyOnStateChange();
		});
	}

	abstract factory(v?: DTO<TIdentity>): TIdentity;

	setIdentity(identity: TIdentity): void {
		this._store$.dispatch(this.actions.setIdentity({ identity }));
	}

	removeIdentity(): void {
		this._store$.dispatch(this.actions.removeIdentity());
	}

	login(payload: TLoginPayload): void {
		this._store$.dispatch(this.actions.login({ payload }));
	}

	confirmLogout(): void {
		this._store$.dispatch(this.actions.confirmLogout());
	}

	logout(): void {
		this._store$.dispatch(this.actions.logout());
	}

	saveUrlForRedirectionAfterLogin(url: string): void {
		this._store$.dispatch(this.actions.saveUrlForRedirectionAfterLogin({ url }));
	}

	resetState(): void {
		this._store$.dispatch(this.actions.resetState());
	}

	resetError(): void {
		this._store$.dispatch(this.actions.resetError());
	}

	/**
	 * Happens on user's login and when we recover the user from local storage
	 */
	onIdentityFirstSet(action: (identity: TIdentity) => void): void {
		this.userIsLoggedIn$.pipe(filterTruthy).subscribe(() => void action(this.user!));
	}

	onIdentityLogout(action: () => void): void {
		this.userLoggedOut$.subscribe(action);
	}

	private _updateUserPropertyOnStateChange(): void {
		this.user$.subscribe(user => (this.user = user ?? null));
	}
}
