import { ChangeDetectionStrategy, Component } from '@angular/core';

import { FADE_IN } from '@bp/shared/animations';

import { NotificationsHubFacade } from '../../state';

@Component({
	selector: 'bp-notifications-hub-header',
	templateUrl: './notifications-hub-header.component.html',
	styleUrls: [ './notifications-hub-header.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE_IN ],
})
export class NotificationsHubHeaderComponent {

	constructor(public notificationsHubFacade: NotificationsHubFacade) {
	}

}
