import { createAction, props } from '@ngrx/store';

import type { ErrorActionPayload, RecordsPageResultActionPayload } from '@bp/shared/models/common';
import { BridgerPsp } from '@bp/shared/domains/bridger-psps/core';

import { ACTIONS_SCOPE } from './bridger-psps-shared.actions';

const scope = `[API]${ ACTIONS_SCOPE }`;

export const loadAllSuccess = createAction(
	`${ scope } Load All Success`,
	props<RecordsPageResultActionPayload<BridgerPsp>>(),
);

export const loadAllFailure = createAction(
	`${ scope } Load All Failure`,
	props<ErrorActionPayload>(),
);
