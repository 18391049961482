import { PspPaymentOptionType } from '@bp/shared/models/business';
import type { DTO } from '@bp/shared/models/metadata';
import { Default } from '@bp/shared/models/metadata';

import { BridgerPspPaymentOptionBaseSummary } from './bridger-psp-payment-option-base-summary';

export class BridgerPspPaymentOptionApmSummary extends BridgerPspPaymentOptionBaseSummary {

	static override readonly type = PspPaymentOptionType.apm;

	readonly type = BridgerPspPaymentOptionApmSummary.type;

	@Default(false)
	supportsBnpl!: boolean;

	constructor(dto: DTO<BridgerPspPaymentOptionApmSummary>) {
		super(dto);
	}

}
