import { PaymentCardBrand, PspPaymentOptionType } from '@bp/shared/models/business';
import { Currency } from '@bp/shared/models/currencies';
import type { DTO } from '@bp/shared/models/metadata';
import { Control, Default, FieldControlType, Mapper, Required } from '@bp/shared/models/metadata';

import { FirebasePspPaymentOptionBase } from './firebase-psp-payment-option-base';

export class FirebasePspPaymentOptionCreditCard extends FirebasePspPaymentOptionBase {

	static override readonly type = PspPaymentOptionType.creditCard;

	readonly type = FirebasePspPaymentOptionCreditCard.type;

	@Control(
		FieldControlType.brandChips,
		{
			list: PaymentCardBrand.getList(),
		},
	)
	@Mapper(PaymentCardBrand)
	@Default([])
	@Required()
	brands!: PaymentCardBrand[];

	@Control(
		FieldControlType.chip,
		{
			list: Currency.list,
			placeholder: 'Add currency...',
		},
	)
	@Mapper(Currency)
	@Default([])
	@Required()
	currencies!: Currency[];

	constructor(dto: DTO<FirebasePspPaymentOptionCreditCard>) {
		super(dto);
	}

}
