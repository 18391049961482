<ng-container *ngIf="environment.isNotProduction || environment.isBackoffice">
	<small class="mr-3">
		<button class="link interactive use-current-color" (click)="LocalBackendState.toggleAndReloadPage()">
			Turn
			<span class="turn-state" [class.off]="LocalBackendState.isActive">
				{{ LocalBackendState.isActive ? 'off' : 'on' }}
			</span>
			local backend
		</button>
	</small>

	<small *ngIf="environment.isStagingOrLocal" class="mr-3">
		<button class="link interactive use-current-color" (click)="MockedBackendState.toggleAndReloadPage()">
			Turn
			<span class="turn-state" [class.off]="MockedBackendState.isActive">
				{{ MockedBackendState.isActive ? 'off' : 'on' }}
			</span>
			mocked backend
		</button>
	</small>

	<small class="mr-3">
		<button class="link interactive use-current-color" (click)="ConsoleLoggingState.toggleAndReloadPage()">
			Turn
			<span class="turn-state" [class.off]="ConsoleLoggingState.isActive">
				{{ ConsoleLoggingState.isActive ? 'off' : 'on' }}
			</span>
			console logging
		</button>
	</small>

	<small *ngIf="telemetryService.userSessionRecordingUrl$ | async as userSessionRecordingUrl" class="mr-3">
		<a class="link use-current-color" [href]="userSessionRecordingUrl" bpTargetBlank> Recording </a>
	</small>
</ng-container>
