import { ChangeDetectionStrategy, Component } from '@angular/core';

import { EnvironmentService } from '@bp/shared/services';
import { IAppVersion } from '@bp/shared/typings';

@Component({
	selector: 'bp-app-version',
	templateUrl: './app-version.component.html',
	styleUrls: [ './app-version.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppVersionComponent {

	get appVersion(): IAppVersion {
		return this.environmentService.appVersion;
	}

	constructor(public environmentService: EnvironmentService) {
		this._setAppFrontendVersionAttributeToDocument();

		this._outputAppFrontendVersionToConsole();
	 }

	private _setAppFrontendVersionAttributeToDocument(): void {
		document.documentElement.setAttribute(
			'app-fe-version',
			this.environmentService.appVersion.releaseAndShortSHA,
		);
	}

	private _outputAppFrontendVersionToConsole(): void {
		console.log(
			`%c✨ ${ this.appVersion.release } ✨ ${ this.appVersion.shortSHA } ✨`,
			 'background-color: #2578ea; color: white; font-family: monospace; padding: 1em 1.5em; font-size: 4.5em; line-height: 1;',
		);
	}
}
