import { isArray, isBoolean, isNil, isNumber, isObject, isString, last, mapValues, pickBy } from 'lodash-es';

import type { Type } from '@angular/core';
import type { ActivatedRoute, Params, Router, UrlSegmentGroup } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';

import type { Dictionary } from '@bp/shared/typings';

import { isPresent } from './is-present';

export class UrlHelper {

	static parseRouteParam(value: string): string[] | string | null {
		const values = UrlHelper.parseRouteParamToArray(value);

		if (values.length === 0)
			return null;

		return values.length > 1 ? values : values[0] ?? null;
	}

	static parseRouteParamToArray(value: string): string[] {
		return isString(value)
			? value
				.split(',')
				.filter(isPresent)
			: [];
	}

	static parseRouteParamToNumberArray(value: string): number[] {
		return UrlHelper.parseRouteParamToArray(value)
			.map(Number)
			.filter(id => !Number.isNaN(id));
	}

	static toRouteParamString(value: unknown): string | null {
		if (isArray(value)) {
			return value.length > 0
				? value
					.map(valueToString)
					.filter(isPresent)
					.join(',')
				: null;
		}

		return valueToString(value);
	}

	static toRouteParams(value: Record<string, unknown>): Record<string, string | null> {
		return mapValues(value, v => UrlHelper.toRouteParamString(v));
	}

	static getLastPrimaryRouteNonNilParams(route: ActivatedRoute): Params {
		return UrlHelper.getRouteSnapshotNonNilParams(UrlHelper.getLastPrimaryRoute(route));
	}

	static mergeLastPrimaryRouteSnapshotParamsWithSourceParams(
		route: ActivatedRoute,
		sourceParameters: Params,
	): Params {
		return UrlHelper._mergeRouteParamsAndDeleteNilAndEmptyValues(
			UrlHelper.getLastPrimaryRouteNonNilParams(route),
			sourceParameters,
		);
	}

	static mergeRouteSnapshotParamsWithSourceParams(
		routeOrRouteSnapshot: ActivatedRoute | ActivatedRouteSnapshot,
		sourceParameters: Params,
	): Params {
		return UrlHelper._mergeRouteParamsAndDeleteNilAndEmptyValues(
			UrlHelper.getRouteSnapshotNonNilParams(routeOrRouteSnapshot),
			sourceParameters,
		);
	}

	static getRouteSnapshotNonNilParams(routeOrRouteSnapshot: ActivatedRoute | ActivatedRouteSnapshot): Params {
		const snapshot = routeOrRouteSnapshot instanceof ActivatedRouteSnapshot
			? routeOrRouteSnapshot
			: routeOrRouteSnapshot.snapshot;
		const parameters = snapshot.url.length > 0 ? last(snapshot.url)!.parameters : snapshot.params;

		return pickBy(parameters, v => !isNil(v));
	}

	private static _mergeRouteParamsAndDeleteNilAndEmptyValues(target: Params, source: Params): Params {
		return pickBy(
			mapValues(
				{
					...target,
					...source,
				},
				// eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
				value => isObject(value) ? value.toString() : value,
			),
			isPresent,
		);
	}

	static getLastPrimaryRouteQueryParams(route: ActivatedRoute): Params {
		const snapshot = UrlHelper.getLastPrimaryRoute(route.snapshot);

		return pickBy(snapshot.queryParams, v => !isNil(v));
	}

	static getComponentActivatedRoute(route: ActivatedRoute | ActivatedRouteSnapshot, targetRouteCmpt: Type<any>): ActivatedRoute | ActivatedRouteSnapshot | null {
		if (route.routeConfig?.component === targetRouteCmpt)
			return route;

		for (const childRoute of route.children) {
			const cmptRoute = UrlHelper.getComponentActivatedRoute(childRoute, targetRouteCmpt);

			if (cmptRoute)
				return cmptRoute;
		}

		return null;
	}

	static getPrimaryRoutes<T extends ActivatedRoute | ActivatedRouteSnapshot>(route: T): T[] {
		const results = [ route ];

		while (route.firstChild) {
			route = <T> route.firstChild;

			results.push(route);
		}

		return results;
	}

	static getLastPrimaryRoute<T extends ActivatedRoute | ActivatedRouteSnapshot>(route: T): T {
		while (route.firstChild)
			route = <T> route.firstChild;

		return route;
	}

	static buildUrlExcludingOutlet(outlet: string, router: Router): string {
		const currentUrlTree = router.parseUrl(router.url);

		UrlHelper._deleteOutletRecursivelyFromSegments(outlet, currentUrlTree.root.children);

		return currentUrlTree.toString();
	}

	static appendQueryParams(url: string, params: Dictionary<number | string>): string {
		if (isNil(url))
			return url;

		const queryParams = Object.keys(params)
			.filter(k => !isNil(params[k]) && !Number.isNaN(<number> params[k]))
			.map(k => `${ encodeURIComponent(k) }=${ encodeURIComponent(params[k].toString()) }`)
			.join('&');

		return `${ url }${ url.includes('?') ? '&' : '?' }${ queryParams }`;
	}

	private static _deleteOutletRecursivelyFromSegments(
		outlet: string,
		dictionary: Dictionary<UrlSegmentGroup>,
	): void {
		for (const property of Object.keys(dictionary)) {
			if (property === outlet) {
				// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
				delete dictionary[property];

				return;
			}

			if (isObject(dictionary[property]))
				UrlHelper._deleteOutletRecursivelyFromSegments(outlet, dictionary[property].children);
		}
	}
}

function valueToString(value: unknown): string | null {
	if (isNil(value))
		return null;

	if (isBoolean(value))
		return value ? 'true' : null;

	if (isNumber(value))
		return value.toString();

	if (isString(value))
		return value === '' ? null : value;

	if (isObject(value))
		// Some of our classes (e.g., DateRange) may return nullish values in valueOf
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		return value.valueOf()?.toString() ?? null;

	return null;
}
