import type { Type } from '@angular/core';

export function isInstanceOf<T>(type: Type<T>) {
	return (value: unknown): value is T => value instanceof type;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function isInstanceOfAbstract<T>(type: any) {
	return (value: unknown): value is T => value instanceof type;
}
