<bp-login-form
	class="h-100"
	[pending]="introFacade.pending$ | async"
	[error]="introFacade.error$ | async"
	[showOAuthProviders]="environment.isNotProduction"
	(submitted)="introFacade.login($event)"
	(googleIdentityChange)="introFacade.login($event)"
	(sendResetPasswordLink)="introFacade.gotoSendResetPasswordLinkPage()"
>
</bp-login-form>
