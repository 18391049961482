import { camelCase, isNumber, isObject, isString, startCase, upperFirst } from 'lodash-es';
import type { Moment, MomentInput } from 'moment';
import moment from 'moment';

import type { Enumeration } from '@bp/shared/models/core/enum';
import { isEmpty } from '@bp/shared/utilities';

export function booleanMapper(v: boolean | string): boolean {
	return isString(v)
		? v.toLowerCase() === 'true'
		: v;
}

export function pascalCase(v: Enumeration | string | undefined): string {
	return upperFirst(camelCase(isObject(v) ? v.toString() : v));
}

export function titleCase(v: Enumeration | string | undefined): string {
	return startCase(isObject(v) ? v.toString() : v);
}

export function lowerCase(v: string): string {
	return v.toLowerCase();
}

export function trimMapper(v: string): string {
	return v.trim();
}

export function uriComponentObjectMapper(v: unknown): Record<string, unknown> {
	return isString(v) ? JSON.parse(decodeURIComponent(v)) : v;
}

export function numberMapper(v: unknown): number {
	return isNumber(v) && !Number.isNaN(v) ? v : 0;
}

export function momentMapper(v: MomentInput): Moment {
	if (isNumber(v) || isString(v) && !Number.isNaN(Number(v)))
		return moment.unix(Number(v));

	return moment(v);
}

export function hostNameFromUrlMapper(v: string): string {
	if (isEmpty(v))
		return '';

	// we need 'https://' at the start otherwise 'new URL(v)' will not parse it
	v = v.replace(/^(https?:\/\/)?/ui, 'https://');

	try {
		return new URL(v).hostname;
	} catch {
		return '';
	}
}
