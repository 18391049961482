import type { NonFunctionProperties } from '@bp/shared/typings';
import { PrimitiveConstructable } from '@bp/shared/models/core';

import type { CountryCode } from './country-codes';
import { COUNTRY_CODES } from './country-codes';
import { State } from './state';
import statesDTO from './states';

const STATES_BY_COUNTRY = <{[ country in CountryCode ]: State[]; }> Object.fromEntries(
	Object
		.entries(statesDTO)
		.map(([ country, states ]) => [ country, states.map(state => new State(state)) ]),
);

export class Country extends PrimitiveConstructable<CountryCode> {

	readonly name!: string;

	readonly displayName!: string;

	readonly code!: CountryCode;

	readonly dialCode!: string;

	/**
	 * `+${dialCode}`
	 */
	readonly internationalDialCodePrefix!: string;

	readonly currency!: string;

	readonly lowerCaseName?: string;

	readonly lowerCaseCode?: string;

	readonly states?: State[];

	constructor(dtoOrCode: CountryCode | (Partial<NonFunctionProperties<Country>> & { code: CountryCode })) {
		super(typeof dtoOrCode === 'string'
			? <CountryCode> dtoOrCode.toUpperCase()
			: dtoOrCode.code);

		if (this._isCached())
			return this;

		if (typeof dtoOrCode === 'object')
			Object.assign(this, dtoOrCode);

		this._whenCountryCodeInvalidThrowNotFoundError();

		this.displayName = this.displayName || this.name;

		this.internationalDialCodePrefix = `+${ this.dialCode }`;

		this.lowerCaseName = this.name.toLowerCase();

		this.lowerCaseCode = this.code.toLowerCase();

		this.states = STATES_BY_COUNTRY[this.code];

		this._cacheAndFreezeInstance();
	}

	protected _getScope(): string {
		return 'country';
	}

	private _whenCountryCodeInvalidThrowNotFoundError(): void {
		if (!COUNTRY_CODES.includes(this._id))
			throw new Error(`Such country doesn't exist - ${ this._id }`);
	}
}
