import { ToastrService } from 'ngx-toastr';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

import { FormBaseComponent } from '@bp/shared/components/core';
import { Validators } from '@bp/shared/features/validation';
import { EnvironmentService, MockedBackendState } from '@bp/shared/services';
import { ensureFormGroupConfig } from '@bp/shared/typings';
import { IGoogleIdentity } from '@bp/shared/features/oauth';

import type { ILoginApiRequest } from '@bp/admins-shared/domains/identity';

@Component({
	selector: 'bp-login-form',
	templateUrl: './login-form.component.html',
	styleUrls: [ './login-form.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginFormComponent extends FormBaseComponent<ILoginApiRequest> {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	MockedBackendState = MockedBackendState;

	@Input() showOAuthProviders = true;

	@Output() readonly sendResetPasswordLink = new EventEmitter();

	@Output() readonly googleIdentityChange = new EventEmitter<IGoogleIdentity>();

	override onSubmitShowInvalidInputsToast = false;

	constructor(
		public environment: EnvironmentService,
		fb: UntypedFormBuilder,
		cdr: ChangeDetectorRef,
		toaster: ToastrService,
	) {
		super(fb, cdr, toaster);

		this.form = this._formBuilder.group(
			ensureFormGroupConfig<ILoginApiRequest>({
				userName: [ '', [ Validators.email ]],
				password: [ '', []],
			}),
		);
	}
}
