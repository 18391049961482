import type { Data, Params } from '@angular/router';

import * as fromRouter from '@ngrx/router-store';
import type { RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectRouter = createFeatureSelector<RouterReducerState<any>>(fromRouter.DEFAULT_ROUTER_FEATURENAME);

const {
	selectQueryParams, // select the current route query params
	selectRouteParams, // select the current route params
	selectRouteData, // select the current route data
	selectUrl, // select the current url
} = fromRouter.getSelectors(selectRouter);

export const getPrimaryQueryParams = createSelector(
	selectQueryParams,
	(v: Params | undefined) => v ?? {},
);

export const getPrimaryRouteParams = createSelector(
	selectRouteParams,
	(v: Params | undefined) => v ?? {},
);

export const getRouteData = createSelector(
	selectRouteData,
	(v: Data | undefined) => v ?? {},
);

export const getPrimaryRouteUrl = selectUrl;
