import type { FieldControlType } from '../enums';
import type { MetadataEntity } from '../metadata-entity';
import type { PropertyMetadataControl } from '../property-metadata-control';

import { $$classMetadataPropertyMetadataControlInjector } from './class-metadata-property-metadata-control-injector';

// eslint-disable-next-line @typescript-eslint/naming-convention
export function Control<TControlOptions>(
	type: FieldControlType<TControlOptions>,
	config?: Omit<Partial<PropertyMetadataControl<TControlOptions>>, 'type'>,
) {
	return function(model: MetadataEntity, property: string): void {
		$$classMetadataPropertyMetadataControlInjector(model, property, {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			type: <any> type,
			...config,
		});
	};
}
