import { Alias, DTO, MapFromDTO, MetadataEntity } from '@bp/shared/models/metadata';

export type IdentityJwtPayloadDTO = DTO<IdentityJwtPayload>;

export class IdentityJwtPayload extends MetadataEntity {
	@MapFromDTO()
	userFullName?: string;

	@MapFromDTO()
	userEmail!: string;

	@MapFromDTO()
	userIdentityId?: string;

	@Alias('id')
	merchantId?: string;

	@MapFromDTO()
	otpProvider?: string;

	@MapFromDTO()
	rol?: string;

	@MapFromDTO()
	permissions!: string[];

	@MapFromDTO()
	exp!: number;

	constructor(dto?: IdentityJwtPayloadDTO) {
		super(dto);
	}
}
