import { Default, Mapper } from './decorators';
import { booleanMapper } from './mappers';
import { MetadataEntity } from './metadata-entity';

export abstract class EntityBase extends MetadataEntity {

	@Default(null)
	id!: string | null;

	@Default(null)
	name!: string | null;

	@Mapper(booleanMapper)
	@Default(null)
	isEnabled!: boolean | null;

}
