import { debounce, isNil, isObject, mapValues, omitBy, snakeCase } from 'lodash-es';
import { lastValueFrom } from 'rxjs';

import { Inject, Injectable, Optional } from '@angular/core';

import { AsyncFlashSubject, ZoneService } from '@bp/shared/rxjs';
import { $, isEmpty, isEqual, toPlainObject } from '@bp/shared/utilities';
import { Dictionary } from '@bp/shared/typings';
import { TelemetryService } from '@bp/shared/services';

import { IZohoSyncEvent } from '@bp/firebase-functions';

import { IGoogleTagGlobalVariables, Gtag, GtagEvents, IDispatchEventObserver, DISPATCH_EVENT_OBSERVERS } from '../models';

interface IInitConfig {

	analyticsId: string;

	transportUrl: string;

	environment: IZohoSyncEvent['environment'];

	platform: IZohoSyncEvent['platform'];

	// eslint-disable-next-line @typescript-eslint/naming-convention
	debug_mode?: boolean;

}

@Injectable({ providedIn: 'root' })
export class GoogleTagService {

	/**
	 * Not to clash with google optimize
	 */
	private static readonly _dataLayerPropertyName = 'gtagDataLayer';

	private static get _dataLayer(): unknown[] {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
		return (<any>window)[this._dataLayerPropertyName] ?? ((<any>window)[this._dataLayerPropertyName] = []);
	}

	private static readonly _gtag: Gtag.IGtagFunction = function() {
		// eslint-disable-next-line prefer-rest-params
		ZoneService.runOutsideAngular(() => GoogleTagService._dataLayer.push(arguments));
	};

	private _inited = false;

	private readonly _gtagInitConfig$ = new AsyncFlashSubject<Gtag.IConfig>();

	private readonly _debouncedDispatchConfigUpdatedEvent = debounce(
		() => void this.dispatchEvent('global_config_updated'),
		500,
	);

	private _gtagConfig?: Gtag.IConfig;

	constructor(
		private readonly _telemetry: TelemetryService,
		@Inject(DISPATCH_EVENT_OBSERVERS)
		@Optional()
		private readonly _dispatchEventObservers?: IDispatchEventObserver[],
	) {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/unbound-method
		(<any>window).gtag = GoogleTagService._gtag;
	}

	init(config: IInitConfig): void {
		if (this._inited)
			return;

		this._inited = true;

		this._setInitConfig(config);

		this._injectScriptIntoHead(config);
	}

	setGlobalVariables(params: IGoogleTagGlobalVariables): void {
		void this._setGlobalVariables(params);
	}

	setGlobalEventPayloads(params: GtagEvents.EventPayloads): void {
		void this._setGlobalVariables(params);
	}

	dispatchEvent<TEventName extends GtagEvents.List>(
		event: TEventName,
		payload?: GtagEvents.IEventPayloadMap[TEventName],
	): void {
		payload = payload && toPlainObject(payload);

		this._dispatchEventObservers?.forEach(observer => void observer.dispatchEvent(event, payload));

		this._telemetry.track(event, payload);

		void this._dispatchEvent(event, payload);
	}

	private async _dispatchEvent<TEventName extends GtagEvents.List>(
		event: TEventName,
		payload?: GtagEvents.IEventPayloadMap[TEventName],
	): Promise<void> {
		await lastValueFrom(this._gtagInitConfig$);

		this._debouncedDispatchConfigUpdatedEvent.cancel(); // no need to dispatch if we are already dispatching

		GoogleTagService._gtag(
			'event',
			<TEventName>snakeCase(event),
			payload && <any> this._normalizeGtagPayload(payload),
		);
	}

	private _setInitConfig(config: IInitConfig): void {
		const gtagInitConfig: Gtag.IConfig = {
			analyticsId: config.analyticsId,
			environment: config.environment,
			platform: config.platform,
			/* eslint-disable @typescript-eslint/naming-convention */
			transport_url: config.transportUrl,
			first_party_collection: true,
			debug_mode: config.debug_mode,
			/* eslint-enable @typescript-eslint/naming-convention */
		};

		this._gtagInitConfig$.complete(gtagInitConfig);

		GoogleTagService._gtag('js', new Date());

		GoogleTagService._gtag('config', config.analyticsId, gtagInitConfig);
	}

	private async _setGlobalVariables(params: Dictionary<any>): Promise<void> {
		const gtagInitConfig = await lastValueFrom(this._gtagInitConfig$);

		if (isEmpty(params))
			return;

		const newGtagConfig = this._normalizeGtagPayload({
			...this._gtagConfig,
			...params,
			...gtagInitConfig,
			/* eslint-disable @typescript-eslint/naming-convention */
			send_page_view: false,
			/* eslint-enable @typescript-eslint/naming-convention */
		});

		if (isEqual(newGtagConfig, this._gtagConfig))
			return;

		this._gtagConfig = newGtagConfig;

		GoogleTagService._gtag(
			'config',
			gtagInitConfig.analyticsId,
			this._gtagConfig,
		);

		this._debouncedDispatchConfigUpdatedEvent();
	}

	private _injectScriptIntoHead(config: IInitConfig): void {
		document.head.append($.buildAsyncScriptElement({
			src: `${ config.transportUrl }/gtag/js?id=${ config.analyticsId }&l=${ GoogleTagService._dataLayerPropertyName }`,
		}));
	}

	private _normalizeGtagPayload<T extends Object>(object: T): T {
		object = <T>mapValues(object, value => isObject(value) ? JSON.stringify(value) : value);

		object = <T>omitBy(object, isNil);

		return object;
	}
}
