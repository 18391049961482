import { Component, ChangeDetectionStrategy } from '@angular/core';

import { ConsoleLoggingState, EnvironmentService, HttpConfigService, LocalBackendState, MockedBackendState, TelemetryService } from '@bp/shared/services';

@Component({
	selector: 'bp-footer-mock-links',
	templateUrl: './footer-mock-links.component.html',
	styleUrls: [ './footer-mock-links.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterMockLinksComponent {

	// eslint-disable-next-line @typescript-eslint/naming-convention
	ConsoleLoggingState = ConsoleLoggingState;

	// eslint-disable-next-line @typescript-eslint/naming-convention
	LocalBackendState = LocalBackendState;

	// eslint-disable-next-line @typescript-eslint/naming-convention
	MockedBackendState = MockedBackendState;

	constructor(
		public httpConfig: HttpConfigService,
		public environment: EnvironmentService,
		public telemetryService: TelemetryService,
	) { }

}
