import { Injectable } from '@angular/core';

import { CloudflareAccessService, MomentTimezoneService, TelemetryService, UserIdleService } from '@bp/shared/services';

import { AdminSharedAppStartupService } from '@bp/admins-shared/core/services';
import type { Identity } from '@bp/admins-shared/domains/identity';
import { IdentityFacade } from '@bp/admins-shared/domains/identity';
import { BridgerPspsSharedFacade } from '@bp/admins-shared/domains/bridger-psps';

@Injectable({
	providedIn: 'root',
})
export class AppStartupService {

	constructor(
		private readonly _adminSharedAppStartup: AdminSharedAppStartupService,
		private readonly _identityFacade: IdentityFacade,
		private readonly _telemetry: TelemetryService,
		private readonly _userIdleService: UserIdleService,
		private readonly _momentTimezoneService: MomentTimezoneService,
		private readonly _cloudflareAccessService: CloudflareAccessService,
		private readonly _bridgerPspsSharedFacade: BridgerPspsSharedFacade,
	) { }

	init(): void {
		this._adminSharedAppStartup.init();

		this._cloudflareAccessService.whenUserUnauthorizedByCloudflareRedirectToCloudflareLoginPage();

		this._momentTimezoneService.setGuessedTimezone();

		this._userIdleService.init();

		this._identityFacade.onIdentityFirstSet(identity => {
			this._bridgerPspsSharedFacade.loadAll();

			void this._associateUserWithTelemetry(identity);
		});
	}

	private _associateUserWithTelemetry(user: Identity): void {
		this._telemetry.identifyUser(user.email);
	}

}
