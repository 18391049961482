import { InjectionToken } from '@angular/core';

import { GtagEvents } from './gtag-events.interfaces';

export const DISPATCH_EVENT_OBSERVERS = new InjectionToken<ReadonlyArray<IDispatchEventObserver>>('DispatchEventObservers');

export interface IDispatchEventObserver {

	dispatchEvent: <TEventName extends GtagEvents.List>(
		event: TEventName,
		payload?: GtagEvents.IEventPayloadMap[TEventName],
	) => void;

}
