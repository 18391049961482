import type { DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { createSelector } from '@ngrx/store';

import type { IIdentity } from '../models';

import type { IdentityState } from './compose-identity-reducer';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export function composeIdentitySelectors<TIdentity extends IIdentity, TState extends IdentityState<TIdentity>>(
	featureStateSelector: MemoizedSelector<Object, TState, DefaultProjectorFn<TState>>,
) {
	return {
		user: createSelector(featureStateSelector, state => state.user),

		pending: createSelector(featureStateSelector, state => state.pending),

		error: createSelector(featureStateSelector, state => state.error),

		urlForRedirectionAfterLogin: createSelector(featureStateSelector, state => state.urlForRedirectionAfterLogin),
	};
}

export type IdentitySelectors<TIdentity extends IIdentity> = Omit<
ReturnType<typeof composeIdentitySelectors>,
'user'
> & {
	user: MemoizedSelector<Object, TIdentity | null, DefaultProjectorFn<TIdentity | null>>;
};
