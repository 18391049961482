import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconComponent } from './svg-icon.component';
import { SvgSharedIconsDefinitionsComponent } from './svg-shared-icons-definitions.component';
import { SvgSharedFiltersDefinitionsComponent } from './svg-shared-filters-definitions.component';

const EXPOSED = [
	SvgSharedFiltersDefinitionsComponent,
	SvgSharedIconsDefinitionsComponent,
	SvgIconComponent,
];

@NgModule({
	imports: [ CommonModule ],
	declarations: EXPOSED,
	exports: EXPOSED,
})
export class SharedFeaturesSvgIconsModule { }
