import { Injectable } from '@angular/core';
import type { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Router } from '@angular/router';

import type { IIdentity } from '../models';
import { IdentityFacade } from '../state';

@Injectable({
	providedIn: 'root',
})
export class IdentityLoggedOutGuard implements CanActivate {
	constructor(private readonly _router: Router, private readonly _identityFacade: IdentityFacade<IIdentity, any>) {}

	canActivate(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean {
		if (this._identityFacade.user) {
			void this._router.navigate([ '/' ]);

			return false;
		}

		return true;
	}
}
