import { firstValueFrom, Observable, BehaviorSubject, fromEvent, of, race, throwError } from 'rxjs';
import { concatMap, first } from 'rxjs/operators';
import { isEmpty } from 'lodash-es';

import {
	HostBinding, OnChanges, SimpleChanges, Directive, ContentChild, ChangeDetectionStrategy, Component, Input
} from '@angular/core';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';

import { Destroyable } from '@bp/shared/models/common';

/**
 * Allows to handle image load error
 */
@Directive({
	selector: 'bp-img-error, [bpImgError]',
})
export class ImgErrorDirective { }

@Component({
	selector: 'bp-img',
	templateUrl: './img.component.html',
	styleUrls: [ './img.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImgComponent extends Destroyable implements OnChanges {

	@Input() url?: string | null;

	/**
	 * An array of img urls the first successfully loaded is gonna be shown
	 */
	@Input() urls?: string[] | null;

	@Input() size: number | null = null;

	@Input()
	get withPlaceholder(): boolean {
		return this._withPlaceholder;
	}

	set withPlaceholder(value: BooleanInput) {
		this._withPlaceholder = coerceBooleanProperty(value);
	}

	private _withPlaceholder = false;

	@Input()
	@HostBinding('class.thumbnail-image')
	get thumbnail(): boolean {
		return this._thumbnail;
	}

	set thumbnail(value: BooleanInput) {
		this._thumbnail = coerceBooleanProperty(value);
	}

	private _thumbnail = false;

	get hasPlaceholder(): boolean {
		return this._thumbnail || this.withPlaceholder;
	}

	@ContentChild(ImgErrorDirective) imgErrorDirective?: ImgErrorDirective;

	isDownloading$ = new BehaviorSubject(true);

	src$ = new BehaviorSubject<string | null>(null);

	loadingFailed$ = new BehaviorSubject(false);

	ngOnChanges({ url, urls }: Partial<SimpleChanges>): void {
		if (url && this.url)
			void this._setFirstSuccessfullyLoadedImage([ this.url ]);
		else if (urls && !isEmpty(this.urls))
			void this._setFirstSuccessfullyLoadedImage(this.urls!);
		else {
			this.src$.next(null);

			this.isDownloading$.next(false);
		}
	}

	private async _setFirstSuccessfullyLoadedImage(sourceUrls: string[]): Promise<void> {
		this.isDownloading$.next(true);

		const firstLoadedImageUrl = await this._loadImagesAndGetFirstUrlFromAllSuccessfullyLoadedUrls(sourceUrls);

		if (!firstLoadedImageUrl) {
			this.loadingFailed$.next(true);

			this.isDownloading$.next(false);

			return;
		}

		this.src$.next(firstLoadedImageUrl);

		// Wait til the img is rendered then show it to apply smooth animation
		setTimeout(() => void this.isDownloading$.next(false), 100);

	}

	private async _loadImagesAndGetFirstUrlFromAllSuccessfullyLoadedUrls(sourceUrls: string[]): Promise<string | null> {
		const imageLoadResults = await Promise.allSettled(
			sourceUrls.map(async url => firstValueFrom(this._loadImage(url))),
		);

		return imageLoadResults
			.find((result): result is PromiseFulfilledResult<string> => result.status === 'fulfilled')
			?.value ?? null;
	}

	private _loadImage(sourceUrl: string): Observable<string> {
		const img = new Image();

		img.src = sourceUrl;

		if (img.complete)
			return of(sourceUrl);

		const error$ = fromEvent(img, 'error')
			.pipe(
				first(),
				concatMap(() => throwError(new Error('Image load error'))),
			);

		const load$ = fromEvent(img, 'load')
			.pipe(
				first(),
				concatMap(() => img.height === 0
					? throwError(new Error('Broken image'))
					: of(sourceUrl)),
			);

		return race(
			load$,
			error$,
		);
	}
}
