import { isNil } from 'lodash-es';
import type { Observable } from 'rxjs';
import { of } from 'rxjs';

import type { AfterContentInit, TemplateRef } from '@angular/core';
import { ChangeDetectionStrategy, Component, ContentChildren, Input, QueryList } from '@angular/core';
import type { UntypedFormControl } from '@angular/forms';
import { UntypedFormGroup } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { FloatLabelType, MatFormFieldAppearance } from '@angular/material/form-field';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';

import { FADE_IN_SEMI_SLOW } from '@bp/shared/animations';
import { Destroyable, takeUntilDestroyed } from '@bp/shared/models/common';
import type { MetadataEntity, PropertyMetadata } from '@bp/shared/models/metadata';
import { ClassMetadata } from '@bp/shared/models/metadata';
import type { Dictionary, NonFunctionPropertyNames } from '@bp/shared/typings';
import { listenToQueryListChanges } from '@bp/shared/utilities';

import { ROW_EMPTY_CELL, ROW_RENDER_STAGGER } from '../constants';
import { PropertyMetadataControlContextDirective, PropertyMetadataControlCustomDirective } from '../property-metadata-control';

export type ControlsSectionScheme<T> = ([
	firstRowCellDef: NonFunctionPropertyNames<T>,
	secondRowCellDef?: NonFunctionPropertyNames<T> | typeof ROW_EMPTY_CELL,
] | undefined)[];

export function ensureControlSectionScheme<T>(scheme: ControlsSectionScheme<T>): ControlsSectionScheme<T> {
	return scheme;
}

@Component({
	selector: 'bp-property-metadata-controls-section',
	templateUrl: './property-metadata-controls-section.component.html',
	styleUrls: [ './property-metadata-controls-section.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE_IN_SEMI_SLOW ],
})
export class PropertyMetadataControlsSectionComponent<TEntity extends MetadataEntity> extends Destroyable implements AfterContentInit {

	isNil = isNil;

	// eslint-disable-next-line @typescript-eslint/naming-convention
	ROW_EMPTY_CELL = ROW_EMPTY_CELL;

	@Input() form!: UntypedFormGroup;

	@Input() metadata?: ClassMetadata<TEntity>;

	@Input() propertiesMetadata?: PropertyMetadata[];

	@Input() sectionScheme?: ControlsSectionScheme<TEntity> | null;

	@Input() heading?: string | null;

	@Input() hasSeparator!: boolean;

	@Input() appearance?: MatFormFieldAppearance;

	@Input() floatLabel?: FloatLabelType;

	@Input() color?: ThemePalette;

	@Input() staggerRendering = true;

	@Input()
	get oneColumn(): boolean {
		return this._oneColumn;
	}

	set oneColumn(value: BooleanInput) {
		this._oneColumn = coerceBooleanProperty(value);
	}

	private _oneColumn = false;

	get controls(): Dictionary<UntypedFormControl> {
		return <Dictionary<UntypedFormControl>> this.form.controls;
	}

	get renderStagger(): number {
		return this.staggerRendering ? ROW_RENDER_STAGGER : 0;
	}

	isFadeInAnimationComplete = false;

	@ContentChildren(PropertyMetadataControlContextDirective)
	private readonly _customContextQueryList!: QueryList<PropertyMetadataControlContextDirective<TEntity>>;

	private readonly _customContextPerProperty = new Map<
	PropertyMetadataControlContextDirective<TEntity>['propertyName'],
	PropertyMetadataControlContextDirective<TEntity>
	>();

	@ContentChildren(PropertyMetadataControlCustomDirective)
	private readonly _customControlQueryList!: QueryList<PropertyMetadataControlCustomDirective<TEntity>>;

	customControlPerProperty = new Map<NonFunctionPropertyNames<TEntity>, TemplateRef<any>>();

	getPropertyMetadata(property: NonFunctionPropertyNames<TEntity>): PropertyMetadata {
		if (!property)
			throw new Error('The property name must be provided');

		const md = this.metadata!.get(property);

		this._assertPropertyMetadata(md, property);

		return md;
	}

	ngAfterContentInit(): void {
		this._listenToAndSetCustomContextPerProperty();

		this._listenToAndSetCustomControlPerProperty();
	}

	getFormControl(
		propertyName: PropertyMetadataControlContextDirective<TEntity>['propertyName'],
	): UntypedFormControl {
		return this._customContextPerProperty.get(propertyName)?.formControl ?? this.controls[<string>propertyName];
	}

	getCustomContext$(
		propertyName: PropertyMetadataControlContextDirective<TEntity>['propertyName'],
	): Observable<Partial<PropertyMetadataControlContextDirective<TEntity>>> {
		return this._customContextPerProperty.get(propertyName)?.changed$ ?? of({});
	}

	private _listenToAndSetCustomContextPerProperty(): void {
		listenToQueryListChanges(this._customContextQueryList)
			.pipe(takeUntilDestroyed(this))
			.subscribe(contexts => void contexts.forEach(
				contextDirective => this._customContextPerProperty.set(contextDirective.propertyName, contextDirective),
			));
	}

	private _listenToAndSetCustomControlPerProperty(): void {
		listenToQueryListChanges(this._customControlQueryList)
			.pipe(takeUntilDestroyed(this))
			.subscribe(query => void query.forEach(
				customValueControlDirective => this.customControlPerProperty.set(
					this._assertCustomControlPropertyName(customValueControlDirective.propertyName),
					customValueControlDirective.tpl,
				),
			));
	}

	private _assertCustomControlPropertyName(
		name: NonFunctionPropertyNames<TEntity>,
	): NonFunctionPropertyNames<TEntity> {
		if (this.sectionScheme?.some(properties => properties?.some(property => property === name)))
			this._assertPropertyMetadata(this.metadata!.get(name), name);

		return name;
	}

	private _assertPropertyMetadata(
		md: PropertyMetadata | null | undefined,
		name: NonFunctionPropertyNames<TEntity>,
	): asserts md {
		if (!md)
			throw new Error(`${ String(name) } doesn't have metadata`);
	}
}
