import { createAction } from '@ngrx/store';

export const ACTIONS_SCOPE = '[Domain][Shared Bridger Psps]';

export const loadAll = createAction(
	`${ ACTIONS_SCOPE } Load All`,
);

export const resetState = createAction(
	`${ ACTIONS_SCOPE } Reset State`,
);
