import { has } from '@bp/shared/utilities';

import type { IPageQueryParams } from './query-params-mixins';

export class RecordsPage<TEntity = any, TDto = any> {

	readonly lastSyncTimestamp?: number | null = null;

	readonly nextSyncTimestamp?: number | null = null;

	readonly nextPageCursor: string | null = null;

	readonly firstPage: boolean | null = null;

	readonly records!: TEntity[];

	constructor(data: RecordsPage<TEntity>);
	constructor(
		query: IPageQueryParams | undefined,
		data: RecordsPage<TDto>,
		factory: (dto: TDto) => TEntity
	);
	constructor(
		queryOrData?: IPageQueryParams | RecordsPage<TEntity>,
		data?: RecordsPage<TDto>,
		factory?: (dto: TDto) => TEntity,
	) {
		if (has(<RecordsPage<TEntity>>queryOrData, 'records')) {
			Object.assign(this, queryOrData);

			return;
		}

		this.nextPageCursor = data!.nextPageCursor;

		this.firstPage = !(<IPageQueryParams | undefined> queryOrData)?.page;

		this.records = data!.records.map(factory!);

		this.lastSyncTimestamp = data!.lastSyncTimestamp;

		this.nextSyncTimestamp = data!.nextSyncTimestamp;
	}

}
