import { ToastrService } from 'ngx-toastr';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

import { Validators } from '@bp/shared/features/validation';
import { takeUntilDestroyed } from '@bp/shared/models/common';
import { FormMetadataEntityBaseComponent } from '@bp/shared/components/metadata';
import { DTO } from '@bp/shared/models/metadata';

import { CreateAccountApiRequest } from '@bp/admins-shared/domains/identity';

@Component({
	selector: 'bp-create-password-form',
	templateUrl: './create-password-form.component.html',
	styleUrls: [ './create-password-form.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreatePasswordFormComponent extends FormMetadataEntityBaseComponent<CreateAccountApiRequest> {

	@Input() email!: string;

	@Output() readonly login = new EventEmitter();

	override metadata = CreateAccountApiRequest.getClassMetadata();

	override onSubmitShowInvalidInputsToast = false;

	constructor(
		fb: UntypedFormBuilder,
		cdr: ChangeDetectorRef,
		toaster: ToastrService,
	) {
		super(fb, cdr, toaster);

	   this._setupForm({
			password: [
				Validators.required,
				Validators.password(),
			],
			confirmPassword: [
				Validators.required,
				Validators.password(),
				Validators.confirmPassword(),
			],
		});

		this._onPasswordChangeRevalidateConfirmPassword();
	}

	override factory = (dto?: DTO<CreateAccountApiRequest>): CreateAccountApiRequest => new CreateAccountApiRequest(dto);

	private _onPasswordChangeRevalidateConfirmPassword(): void {
		this.controls!.password!.valueChanges
			.pipe(takeUntilDestroyed(this))
			.subscribe(() => void this.controls!.confirmPassword!.updateValueAndValidity());
	}
}
