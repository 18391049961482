import { SortDirection } from '@angular/material/sort';

import { Constructor, ISortQueryParams, QueryParamsBase } from '@bp/shared/models/common';

import { ClassMetadata } from '../class-metadata';
import { MetadataEntity } from '../metadata-entity';

export interface ISortQueryParamsDTO<TSortField = string> extends ISortQueryParams<TSortField> { }

interface ISortQueryParamsMixin extends ISortQueryParams{

	setDefaultSortParamsBasedOnMetadata: (classMetadata: ClassMetadata<any>) => void;

}

export type SortQueryParamsCtor = Constructor<ISortQueryParamsMixin>;

type SortQueryParamsBaseCtor = Constructor<QueryParamsBase<ISortQueryParamsDTO>>;

export function mixinSortQueryParams<T extends SortQueryParamsBaseCtor>(
	base: T,
	metadataEntity: typeof MetadataEntity | null,
): SortQueryParamsCtor & T {
	return class extends base implements ISortQueryParamsMixin {

		sortField?: string;

		sortDir?: SortDirection;

		constructor(...args: any[]) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
			super(...args);

			if (this._dto.sortField) {
				this.sortField = this._dto.sortField;

				this.sortDir = this._dto.sortDir ?? 'desc';
			}

			if (metadataEntity)
				this.setDefaultSortParamsBasedOnMetadata(metadataEntity.getClassMetadata());
		}

		setDefaultSortParamsBasedOnMetadata(classMetadata: ClassMetadata<any>): void {
			if (this.sortField || !classMetadata.defaultSortProperty)
				return;

			this.sortDir = classMetadata.defaultSortProperty.table!.defaultSortDir;

			this.sortField = classMetadata.defaultSortProperty.property;
		}
	};
}
