import moment from 'moment';

import { Timezone, Timezones } from '@bp/shared/models/common';
import type { DTO } from '@bp/shared/models/metadata';
import {
	MapFromDTO, Control, Default, FieldControlType, Mapper, MetadataEntity, Label, FieldViewType, View
} from '@bp/shared/models/metadata';

export class IdentityPreferences extends MetadataEntity {

	@Mapper(Timezone)
	@Control(
		FieldControlType.autocomplete,
		{
			list: Timezones.list,
			typeControlOptions: {
				suggestedItem: Timezones.guessed,
				suggestedItemButtonTextPrefix: 'Use your local timezone: ',
			},
		},
	)
	@View(
		FieldViewType.text,
		(timezone: Timezone) => `${ timezone.displayName }${ timezone === Timezones.guessed ? ' (Your local timezone)' : '' }`,
	)
	@Default(Timezone.UTC)
	@Label('Default Timezone')
	timezone!: Timezone;

	@MapFromDTO()
	readonly timestamp!: number;

	constructor(dto?: DTO<IdentityPreferences>) {
		super(dto);

		this.timestamp = this.timestamp || moment().unix();
	}

}
