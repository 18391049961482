<mat-form-field
	[color]="color"
	[appearance]="matAppearance"
	[hideRequiredMarker]="defaultOptions?.hideRequiredMarker"
	[floatLabel]="$$floatLabel"
	[class.error-hidden]="hideErrorText"
>
	<mat-label *ngIf="label">{{ label | bpTranslate | async }}</mat-label>

	<mat-select
		[formControl]="internalControl"
		[placeholder]="$any(placeholder | bpTranslate | async)"
		[required]="!!required"
		[multiple]="multiple"
		[panelClass]="panelClass"
		[disabled]="!!disabled"
		[disableOptionCentering]="disableOptionCentering"
		(selectionChange)="selectionChange.emit($event)"
	>
		<mat-select-trigger>
			{{ internalControl.value | bpDisplayValue: itemDisplayValuePropertyName | bpTranslate | async }}
		</mat-select-trigger>

		<mat-option *ngIf="!required && !multiple" [ngClass]="optionClass!">
			{{ resetOptionText | bpTranslate | async }}
		</mat-option>

		<mat-option
			*ngFor="let item of $any(items)"
			[value]="useItemValueOf ? (item | bpValueOf) : item"
			[ngClass]="optionClass!"
		>
			<ng-template
				*ngIf="customSelectOption; else defaultSelectOptionViewTpl"
				[ngTemplateOutlet]="customSelectOption.tpl"
				[ngTemplateOutletContext]="{ $implicit: item }"
			></ng-template>

			<ng-template #defaultSelectOptionViewTpl>
				{{ item | bpDisplayValue: itemDisplayValuePropertyName | bpTranslate | async }}
			</ng-template>
		</mat-option>
	</mat-select>

	<mat-hint *ngIf="hint">{{ hint | bpTranslate | async }}</mat-hint>

	<mat-error [bpFieldError]="name" [bpFieldErrorSuppress]="hideErrorText"></mat-error>
</mat-form-field>
