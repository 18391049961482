<mat-sidenav
	#sidenav
	position="end"
	class="right-drawer-sidenav"
	[class.fullscreen]="fullscreen$ | async"
	[class.with-navigation-level]="(drawerRouteConfig$ | async)?.drawerWithNavigationLevel"
	[class.occupies-most-of-space]="(drawerRouteConfig$ | async)?.drawerOccupiesMostOfSpace"
	[class.compact]="(drawerRouteConfig$ | async)?.drawerCompact"
	[class.headless]="(drawerRouteConfig$ | async)?.headlessDrawer"
	[opened]="outlet.isActivated"
	[mode]="(drawerRouteConfig$ | async)?.drawerMode ?? defaultDrawerMode"
	[autoFocus]="true"
	[fixedInViewport]="true"
	[fixedTopGap]="55"
	[disableClose]="true"
	(openedChange)="onOpenedChange($event)"
	(transitionend)="onTransitionEnd($event)"
	(closed)="closed.emit()"
>
	<div *ngIf="hasBackdrop$ | async" @fade class="backdrop" (click)="backdropClick.emit()"></div>

	<button class="btn-icon-close" bpTooltip="Close (ESC)" mat-icon-button (click)="close()" tabindex="-1">
		<bp-svg-icon name="close"></bp-svg-icon>
	</button>

	<bp-dynamic-outlet #outlet="outlet" [name]="name"> </bp-dynamic-outlet>
</mat-sidenav>
