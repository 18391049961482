<form *ngIf="form" [formGroup]="form" (ngSubmit)="submit()">
	<bp-alert [errors]="errors" type="danger"> </bp-alert>

	<div>
		Please fill in the email that you used to register. You will be sent an email with instructions on how to reset
		your password.
	</div>

	<bp-input class="mt-4" label="Email" formControlName="userName" nativeAutocomplete="email" required></bp-input>

	<button
		class="bp-round-button mt-auto"
		type="submit"
		mat-flat-button
		[disabled]="(canSave$ | async) === false"
		color="primary"
	>
		Send Email
	</button>

	<div class="aux-buttons">
		<button class="link" type="button" (click)="login.emit()">Login</button>
	</div>
</form>
