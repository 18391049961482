<mat-toolbar color="primary">
	<div class="left">
		<bp-burger-btn
			class="btn-menu"
			[showCross]="(layoutFacade.showSidenav$ | async) === true"
			(click)="layoutFacade.showSidenav ? layoutFacade.closeSidenav() : layoutFacade.openSidenav()"
		>
		</bp-burger-btn>

		<a mat-button class="btn-logo" routerLink="/" (click)="layoutFacade.showSidenav && layoutFacade.closeSidenav()">
			<bp-svg-icon name="logo-short-text"></bp-svg-icon>
		</a>

		<ng-content select=".ng-content-toolbar-left-section"></ng-content>
	</div>

	<div class="right">
		<button
			*ngIf="!MockedBackendState.isDemoMode && MockedBackendState.isActive"
			mat-flat-button
			color="warn"
			class="mr-4 align-self-center bp-round-button bp-button-md"
			(click)="MockedBackendState.toggleAndReloadPage()"
		>
			Turn Off Mocked Backend
		</button>

		<button
			*ngIf="LocalBackendState.isActive"
			mat-flat-button
			color="warn"
			class="mr-4 align-self-center bp-round-button bp-button-md"
			(click)="LocalBackendState.toggleAndReloadPage()"
		>
			Turn Off Local Backend
		</button>

		<button
			*ngIf="pwaService.newVersionIsAvailable$ | async"
			@fadeIn
			mat-flat-button
			color="accent"
			class="mr-4 align-self-center bp-round-button bp-button-md"
			(click)="pwaService.reloadApp()"
		>
			Update Portal
		</button>

		<ng-content select=".ng-content-toolbar-right-section"></ng-content>

		<mat-menu
			#userMenu="matMenu"
			class="user-menu"
			routerLinkActive="active-link"
			[routerLinkActiveOptions]="{ exact: true }"
		>
			<a mat-menu-item [routerLink]="[{ outlets: { '[right-drawer]': ['identity', 'change-password'] } }]">
				Change Password
			</a>

			<ng-content select=".ng-content-toolbar-user-menu-items"></ng-content>

			<button mat-menu-item (click)="logout.emit()">Log Out</button>
		</mat-menu>

		<button class="user" mat-button [matMenuTriggerFor]="userMenu">
			{{ userName }}
		</button>
	</div>
</mat-toolbar>
