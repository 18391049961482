import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';

import { SharedComponentsCoreModule } from '@bp/shared/components/core';
import { SharedDirectivesModule } from '@bp/shared/directives';
import { SharedFeaturesTextMaskModule } from '@bp/shared/features/text-mask';
import { SharedFeaturesTooltipModule } from '@bp/shared/features/tooltip';
import { SharedFeaturesValidationModule } from '@bp/shared/features/validation';
import { SharedPipesModule } from '@bp/shared/pipes';

import { AutocompleteComponent } from './autocomplete';
import { ButtonToggleComponent } from './button-toggle';
import { ChipsControlComponent } from './chips';
import { CountrySelectorComponent } from './country-selector';
import { DateRangeComponent } from './date-range';
import { DateRangeShortcutsComponent } from './date-range-shortcuts';
import { DatePickerComponent } from './datepicker';
import { ImgUploadBtnComponent as ImgUploadButtonComponent } from './img-upload-btn';
import { ImgUploadImageBtnComponent } from './img-upload-image-btn';
import {
	InputComponent, InputHintDirective, InputLabelDirective, InputPrefixDirective, InputSuffixDirective
} from './input';
import {
	InputChipsControlComponent, InputChipsHintDirective, InputChipsLabelDirective, InputChipsPrefixDirective
} from './input-chips';
import { IpInputComponent } from './ip-input';
import { PhoneInputComponent } from './phone-input';
import { SelectComponent, SelectOptionDirective } from './select-field';
import { FileUploadBtnComponent } from './file-upload-btn';
import { PasswordInputComponent } from './password-input';

const EXPOSED_COMPONENTS = [
	DateRangeComponent,
	DateRangeShortcutsComponent,
	InputComponent,
	InputHintDirective,
	InputLabelDirective,
	InputPrefixDirective,
	InputSuffixDirective,
	CountrySelectorComponent,
	IpInputComponent,
	DatePickerComponent,
	AutocompleteComponent,
	SelectComponent,
	SelectOptionDirective,
	ButtonToggleComponent,
	ChipsControlComponent,
	FileUploadBtnComponent,
	ImgUploadButtonComponent,
	ImgUploadImageBtnComponent,
	InputChipsControlComponent,
	InputChipsHintDirective,
	InputChipsLabelDirective,
	InputChipsPrefixDirective,
	PhoneInputComponent,
	PasswordInputComponent,
];

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		MatIconModule,
		MatAutocompleteModule,
		MatButtonModule,
		MatButtonToggleModule,
		MatFormFieldModule,
		MatInputModule,
		MatChipsModule,
		MatDatepickerModule,
		MatProgressBarModule,
		MatSelectModule,
		ScrollingModule,

		SharedPipesModule,
		SharedDirectivesModule,
		SharedComponentsCoreModule,
		SharedFeaturesTooltipModule,
		SharedFeaturesValidationModule,
		SharedFeaturesTextMaskModule,
	],
	exports: EXPOSED_COMPONENTS,
	declarations: EXPOSED_COMPONENTS,
})
export class SharedComponentsControlsModule { }
