<form *ngIf="form" [formGroup]="form" (ngSubmit)="submit()">
	<bp-alert [errors]="errors" type="danger"> </bp-alert>

	<div class="mb-3" formArrayName="answers">
		<ng-container *ngFor="let answerForm of answersFormArray?.controls; let index = index">
			<div *ngIf="answerForm | bpEnsureFormGroup" class="security-question" [formGroup]="answerForm">
				<bp-select-field
					formControlName="id"
					label="Security Question {{ index + 1 }}"
					required
					itemDisplayValuePropertyName="text"
					[items]="availableSecurityQuestionsPerAnswer[index]"
					(selectionChange)="updateAvailableSecurityQuestionsPerAnswer()"
				>
					<span *bpSelectOption="let securityQuestion"> {{ securityQuestion.text }} </span>
				</bp-select-field>

				<bp-input
					class="mt-n3 mb-2"
					label="Answer {{ index + 1 }}"
					formControlName="answer"
					required
					[isSecret]="true"
				></bp-input>
			</div>
		</ng-container>
	</div>

	<button class="bp-round-button mt-auto" type="submit" mat-flat-button color="primary" [disabled]="pending$ | async">
		Set Answers
	</button>

	<div class="aux-buttons">
		<button class="link" type="button" (click)="login.emit()">Login</button>
	</div>
</form>
