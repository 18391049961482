/* eslint-disable @typescript-eslint/naming-convention */
export default {
	CA: [
		{
			code: 'AB',
			name: 'Alberta',
		},
		{
			code: 'BC',
			name: 'British Columbia',
		},
		{
			code: 'MB',
			name: 'Manitoba',
		},
		{
			code: 'NB',
			name: 'New Brunswick',
		},
		{
			code: 'NL',
			name: 'Newfoundland and Labrador',
		},
		{
			code: 'NS',
			name: 'Nova Scotia',
		},
		{
			code: 'NT',
			name: 'Northwest Territories',
		},
		{
			code: 'NU',
			name: 'Nunavut',
		},
		{
			code: 'ON',
			name: 'Ontario',
		},
		{
			code: 'PE',
			name: 'Prince Edward Island',
		},
		{
			code: 'QC',
			name: 'Quebec',
		},
		{
			code: 'SK',
			name: 'Saskatchewan',
		},
		{
			code: 'YT',
			name: 'Yukon',
		},
	],
	US: [
		{
			code: 'AL',
			name: 'Alabama',
		},
		{
			code: 'AK',
			name: 'Alaska',
		},
		{
			code: 'AZ',
			name: 'Arizona',
		},
		{
			code: 'AR',
			name: 'Arkansas',
		},
		{
			code: 'CA',
			name: 'California',
		},
		{
			code: 'CO',
			name: 'Colorado',
		},
		{
			code: 'CT',
			name: 'Connecticut',
		},
		{
			code: 'DE',
			name: 'Delaware',
		},
		{
			code: 'DC',
			name: 'District Of Columbia',
		},
		{
			code: 'FL',
			name: 'Florida',
		},
		{
			code: 'GA',
			name: 'Georgia',
		},
		{
			code: 'HI',
			name: 'Hawaii',
		},
		{
			code: 'ID',
			name: 'Idaho',
		},
		{
			code: 'IL',
			name: 'Illinois',
		},
		{
			code: 'IN',
			name: 'Indiana',
		},
		{
			code: 'IA',
			name: 'Iowa',
		},
		{
			code: 'KS',
			name: 'Kansas',
		},
		{
			code: 'KY',
			name: 'Kentucky',
		},
		{
			code: 'LA',
			name: 'Louisiana',
		},
		{
			code: 'ME',
			name: 'Maine',
		},
		{
			code: 'MD',
			name: 'Maryland',
		},
		{
			code: 'MA',
			name: 'Massachusetts',
		},
		{
			code: 'MI',
			name: 'Michigan',
		},
		{
			code: 'MN',
			name: 'Minnesota',
		},
		{
			code: 'MS',
			name: 'Mississippi',
		},
		{
			code: 'MO',
			name: 'Missouri',
		},
		{
			code: 'MT',
			name: 'Montana',
		},
		{
			code: 'NE',
			name: 'Nebraska',
		},
		{
			code: 'NV',
			name: 'Nevada',
		},
		{
			code: 'NH',
			name: 'New Hampshire',
		},
		{
			code: 'NJ',
			name: 'New Jersey',
		},
		{
			code: 'NM',
			name: 'New Mexico',
		},
		{
			code: 'NY',
			name: 'New York',
		},
		{
			code: 'NC',
			name: 'North Carolina',
		},
		{
			code: 'ND',
			name: 'North Dakota',
		},
		{
			code: 'OH',
			name: 'Ohio',
		},
		{
			code: 'OK',
			name: 'Oklahoma',
		},
		{
			code: 'OR',
			name: 'Oregon',
		},
		{
			code: 'PA',
			name: 'Pennsylvania',
		},
		{
			code: 'RI',
			name: 'Rhode Island',
		},
		{
			code: 'SC',
			name: 'South Carolina',
		},
		{
			code: 'SD',
			name: 'South Dakota',
		},
		{
			code: 'TN',
			name: 'Tennessee',
		},
		{
			code: 'TX',
			name: 'Texas',
		},
		{
			code: 'UT',
			name: 'Utah',
		},
		{
			code: 'VT',
			name: 'Vermont',
		},
		{
			code: 'VA',
			name: 'Virginia',
		},
		{
			code: 'WA',
			name: 'Washington',
		},
		{
			code: 'WV',
			name: 'West Virginia',
		},
		{
			code: 'WI',
			name: 'Wisconsin',
		},
		{
			code: 'WY',
			name: 'Wyoming',
		},
	],
	AU: [
		{
			name: 'Australian Capital Territory',
			code: 'ACT',
		},
		{
			name: 'New South Wales',
			code: 'NSW',
		},
		{
			name: 'Northern Territory',
			code: 'NT',
		},
		{
			name: 'Queensland',
			code: 'QLD',
		},
		{
			name: 'South Australia',
			code: 'SA',
		},
		{
			name: 'Tasmania',
			code: 'TAS',
		},
		{
			name: 'Victoria',
			code: 'VIC',
		},
		{
			name: 'Western Australia',
			code: 'WA',
		},
	],
	IN: [
		{
			code: 'AN',
			name: 'Andaman and Nicobar Islands',
		},
		{
			code: 'AP',
			name: 'Andhra Pradesh',
		},
		{
			code: 'AR',
			name: 'Arunachal Pradesh',
		},
		{
			code: 'AS',
			name: 'Assam',
		},
		{
			code: 'BR',
			name: 'Bihar',
		},
		{
			code: 'CG',
			name: 'Chandigarh',
		},
		{
			code: 'CH',
			name: 'Chhattisgarh',
		},
		{
			code: 'DH',
			name: 'Dadra and Nagar Haveli',
		},
		{
			code: 'DD',
			name: 'Daman and Diu',
		},
		{
			code: 'DL',
			name: 'Delhi',
		},
		{
			code: 'GA',
			name: 'Goa',
		},
		{
			code: 'GJ',
			name: 'Gujarat',
		},
		{
			code: 'HR',
			name: 'Haryana',
		},
		{
			code: 'HP',
			name: 'Himachal Pradesh',
		},
		{
			code: 'JK',
			name: 'Jammu and Kashmir',
		},
		{
			code: 'JH',
			name: 'Jharkhand',
		},
		{
			code: 'KA',
			name: 'Karnataka',
		},
		{
			code: 'KL',
			name: 'Kerala',
		},
		{
			code: 'LA',
			name: 'Ladakh',
		},
		{
			code: 'LD',
			name: 'Lakshadweep',
		},
		{
			code: 'MP',
			name: 'Madhya Pradesh',
		},
		{
			code: 'MH',
			name: 'Maharashtra',
		},
		{
			code: 'MN',
			name: 'Manipur',
		},
		{
			code: 'ML',
			name: 'Meghalaya',
		},
		{
			code: 'MZ',
			name: 'Mizoram',
		},
		{
			code: 'NL',
			name: 'Nagaland',
		},
		{
			code: 'OR',
			name: 'Odisha',
		},
		{
			code: 'PY',
			name: 'Puducherry',
		},
		{
			code: 'PB',
			name: 'Punjab',
		},
		{
			code: 'RJ',
			name: 'Rajasthan',
		},
		{
			code: 'SK',
			name: 'Sikkim',
		},
		{
			code: 'TN',
			name: 'Tamil Nadu',
		},
		{
			code: 'TS',
			name: 'Telangana',
		},
		{
			code: 'TR',
			name: 'Tripura',
		},
		{
			code: 'UP',
			name: 'Uttar Pradesh',
		},
		{
			code: 'UK',
			name: 'Uttarakhand',
		},
		{
			code: 'WB',
			name: 'West Bengal',
		},
	],
};
