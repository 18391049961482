
import { map, shareReplay, switchMap, timer } from 'rxjs';
import moment from 'moment';

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { isMomentReached$ } from '@bp/shared/rxjs';
import { FADE, SLIDE } from '@bp/shared/animations';

import { IdentityFacade } from '../../state';

@Component({
	selector: 'bp-otp-generate-code-button[resourceName]',
	templateUrl: './otp-generate-code-button.component.html',
	styleUrls: [ './otp-generate-code-button.component.scss' ],
	animations: [ SLIDE, FADE ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OtpGenerateCodeButtonComponent {

	@Input() resourceName!: string | 'login';

	showResendOtpButton$ = this.identityFacade.otpExpiresAt$.pipe(
		switchMap(otpExpiresAt => isMomentReached$(
			moment(otpExpiresAt).subtract('1', 'minute'),
		)),
	);

	canResendOtp$ = this.identityFacade.otpExpiresAt$.pipe(
		switchMap(otpExpiresAt => isMomentReached$(otpExpiresAt)),
		shareReplay({ bufferSize: 1, refCount: true }),
	);

	secondsUntilOtpExpires$ = this.identityFacade.otpExpiresAt$.pipe(
		switchMap(otpExpiresAt => timer(0, 1000).pipe(
			map(() => otpExpiresAt.diff(moment(), 'seconds')),
		)),
	);

	constructor(public readonly identityFacade: IdentityFacade) { }

}
