import { fromEvent } from 'rxjs';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { MomentTimezoneService, StorageService } from '@bp/shared/services';
import type { DTO } from '@bp/shared/models/metadata';

import { IdentityPreferences } from '../../models';

import { IdentityPreferencesFacade } from './identity-preferences.facade';
import { FEATURE_STATE_KEY, IDENTITY_PREFERENCES_STATE_KEY } from './identity-preferences.reducer';
import { localStorageIdentityPreferencesChanged, updateIdentityPreferences } from './identity-preferences.actions';

const IDENTITY_PREFERENCES_PATH_IN_STATE = `${ FEATURE_STATE_KEY }.${ IDENTITY_PREFERENCES_STATE_KEY }`;

@Injectable()
export class IdentityPreferencesEffects {

	setMomentTimezoneOnUserPreferenceChange = this._identityPreferencesFacade.identityPreferences$
		.pipe(
			map(({ timezone }) => timezone),
			distinctUntilChanged(),
		)
		.subscribe(timezone => void this._momentTimezoneService.setTimezone(timezone));

	storeInLocalStorageOnIdentityPreferencesChange$ = createEffect(
		() => this._actions$.pipe(
			ofType(updateIdentityPreferences),
			tap(({ identityPreferences }) => void this._storageService.setIfDifferentFromStored(
				identityPreferences,
				IDENTITY_PREFERENCES_PATH_IN_STATE,
			)),
		), { dispatch: false },
	);

	/**
	 * When on one tab the user preferences changes on
	 * all the other tabs the user preferences will get updated accordingly
	 */
	reflectLocalStorageIdentityPreferencesChange$ = createEffect(() => fromEvent<StorageEvent>(window, 'storage')
		.pipe(

			filter(event => event.key === this._storageService.deriveKey(IDENTITY_PREFERENCES_PATH_IN_STATE)),
			map(event => <DTO<IdentityPreferences> | null>(event.newValue && JSON.parse(event.newValue)) ?? undefined),
			map(identityPreferencesFromStorageDTO => new IdentityPreferences(identityPreferencesFromStorageDTO)),
			filter(identityPreferencesFromStorage => identityPreferencesFromStorage.timestamp !== this._identityPreferencesFacade.identityPreferences.timestamp),
			map(identityPreferencesFromStorage => localStorageIdentityPreferencesChanged({
				 identityPreferences: identityPreferencesFromStorage,
			})),
		));

	constructor(
		private readonly _actions$: Actions,
		private readonly _storageService: StorageService,
		private readonly _identityPreferencesFacade: IdentityPreferencesFacade,
		private readonly _momentTimezoneService: MomentTimezoneService,
	) { }
}
