/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { isEmpty, mapValues } from 'lodash-es';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';

import type { Dictionary } from '@bp/shared/typings';
import { BpError } from '@bp/shared/models/core';

import { createSentryMetaReducer } from './ngrx/create-sentry.meta-reducer';
import type { IReporter } from './reporter.interface';

const TELEMETRY_CATEGORY = 'telemetry';

export class SentryReporter implements IReporter {

	readonly logMetaReducer = createSentryMetaReducer();

	constructor(
		private readonly _options: {
			appId: string;
			environment: string;
			release: string;
		},
	) {
		this._init();
	}

	private _init(): void {
		 void Sentry.init({
			dsn: this._options.appId,
			environment: this._options.environment,
			release: this._options.release,

			/*
			 * This enables included instrumentation (highly recommended), but is not
			 * necessary for purely manual usage
			 */
			integrations: [
				new Integrations.BrowserTracing({
					routingInstrumentation: Sentry.routingInstrumentation,
					shouldCreateSpanForRequest: url => url.includes(`${ location.host }/api`),
				}),
				new CaptureConsoleIntegration({
					levels: [ 'error' ],
				}),
			],

			// To set a uniform sample rate
			tracesSampleRate: 0.2,
			attachStacktrace: true,
			normalizeDepth: 0,
			autoSessionTracking: true,
			maxBreadcrumbs: 50,
			ignoreErrors: [
				'Non-Error promise rejection captured',
				'ChunkLoadError',
				'Detected an update time that is in the future',
			],
			beforeSend: (event, _hint) => {
				this._groupLogEventsByMessage(event);

				return event;
			},
		});
	}

	identifyUser(
		userId: string,
		userTraits?: Dictionary<boolean | number | string | null | undefined> | { email?: string } | undefined,
	): void {
		void Sentry.setUser({
			id: userId,
			email: <string | null> userTraits?.email ?? undefined,
			...userTraits,
		});
	}

	captureError(error: any, source: string): void {
		const bpError = error instanceof BpError ? error : new BpError(JSON.stringify(error));

		Sentry.captureException(
			bpError,
			{ tags: {
				source,
				...bpError.requestUrl ? { requestUrl: bpError.requestUrl } : {},
			 } },
		);
	}

	captureMessage(message: string): void {
		Sentry.captureMessage(message, {
			level: 'info',
			tags: { source: 'code' },
		});
	}

	warn(message?: any, ...payload: any[]): void {
		void Sentry.addBreadcrumb({
			message,
			category: TELEMETRY_CATEGORY,
			level: 'warning',
			data: this._convertOptionalParamsToContext(payload),
		});
	}

	log(message?: any, ...payload: any[]): void {
		void Sentry.addBreadcrumb({
			message,
			category: TELEMETRY_CATEGORY,
			level: 'log',
			data: this._convertOptionalParamsToContext(payload),
		});
	}

	track(_key: string, _value: any): void {
		// sentry doesn't need to track user actions
	}

	private _groupLogEventsByMessage(event: Sentry.Event): void {
		if (!event.message?.includes('[log]'))
			return;

		event.fingerprint = [ event.message ];
	}

	private _convertOptionalParamsToContext(payload: any[]): Record<string, Record<string, unknown>> | undefined {
		if (isEmpty(payload))
			return;

		return payload.length === 1
			? { payload: payload[0] }
			: mapValues(payload);
	}

}
