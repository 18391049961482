import { Enumeration } from '@bp/shared/models/core/enum';

export type CashierDepositResult = 'approved' | 'declined' | 'pending';

export class CashierEvent<T> extends Enumeration {

	static init = new CashierEvent<{ type: 'failure' | 'success' }>();

	static clickOnPaymentMethod = new CashierEvent<{ type: string }>();

	static processingDeposit = new CashierEvent<{ value: boolean }>();

	static loadingPSP = new CashierEvent<{ value: boolean; type: 'first-psp' | 'next-psp'; provider: string }>();

	static processingPSP = new CashierEvent<{ value: boolean; provider: string }>();

	static deposit = new CashierEvent<{ type: CashierDepositResult }>();

	static error = new CashierEvent<{ error: string }>();

	static contentRendered = new CashierEvent();

	static redirect = new CashierEvent<{ url: string }>();

	static reload = new CashierEvent();

	static closeModal = new CashierEvent();

	static logrocketSessionUrl = new CashierEvent<{ url: string }>();

	static requestNewOrderId = new CashierEvent();

	payloadScheme!: T;
}
