import type { Moment } from 'moment';

import { Enumeration } from '@bp/shared/models/core/enum';
import { InputTextMaskConfig } from '@bp/shared/features/text-mask';
import { Country } from '@bp/shared/models/countries';

type InputBasedControlOptions = { maxLength?: number; textMask?: InputTextMaskConfig };

export class FieldControlType<T = unknown> extends Enumeration {
	// General
	static input = new FieldControlType<InputBasedControlOptions>();

	static number = new FieldControlType<InputBasedControlOptions>();

	static email = new FieldControlType<InputBasedControlOptions>();

	static phone = new FieldControlType<{
		initialDialCodeCountry: Country;
	}>();

	static password = new FieldControlType<InputBasedControlOptions>();

	static textarea = new FieldControlType<InputBasedControlOptions>();

	static inputChips = new FieldControlType();

	static numberChips = new FieldControlType();

	static switch = new FieldControlType();

	static checkbox = new FieldControlType();

	static datetime = new FieldControlType();

	static date = new FieldControlType<{
		min?: Moment;
		max?: Moment;
		startAt?: Moment;
		startView?: 'month' | 'multi-year' | 'year';
	}>();

	static time = new FieldControlType();

	static select = new FieldControlType();

	static autocomplete = new FieldControlType<{
		suggestedItem?: any;
		suggestedItemTooltip?: string;
		suggestedItemButtonTextPrefix?: string;
	}>();

	static buttonToggle = new FieldControlType();

	static chip = new FieldControlType();

	static currency = new FieldControlType();

	static country = new FieldControlType<{ hasWorldwide: boolean }>();

	static countryChips = new FieldControlType();

	static brand = new FieldControlType();

	static brandChips = new FieldControlType();

	static inputLike = [
		FieldControlType.input,
		FieldControlType.textarea,
		FieldControlType.number,
		FieldControlType.email,
	];

	static chipLike = [
		FieldControlType.chip,
		FieldControlType.brandChips,
		FieldControlType.countryChips,
	];

	static inputChipLike = [
		FieldControlType.inputChips,
		FieldControlType.numberChips,
	];

	options!: T;
}
