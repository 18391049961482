import moment from 'moment';

import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class MomentService {

	init(): void {
		moment.fn.toJSON = function() {
			return <string> <any> this.unix();
		};
	}

}
