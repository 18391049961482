export class State {

	readonly code!: string;

	readonly name!: string;

	constructor(data: Partial<State>) {
		Object.assign(this, data);

		Object.freeze(this);
	}

	toString(): string {
		return this.name;
	}

	valueOf(): any {
		return this.code;
	}

	toJSON(): any {
		return this.code;
	}
}
