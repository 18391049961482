import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedComponentsCoreModule } from '@bp/shared/components/core';
import { SharedDirectivesModule } from '@bp/shared/directives';
import { SharedFeaturesModalModule } from '@bp/shared/features/modal';
import { SharedFeaturesSvgIconsModule } from '@bp/shared/features/svg-icons';
import { SharedFeaturesSvgPaymentCardBrandsIconsModule } from '@bp/shared/features/svg-payment-card-brands-icons';

import { AdminsSharedCoreComponentsModule } from '@bp/admins-shared/core/components';
import { AdminsSharedFeaturesLayoutModule } from '@bp/admins-shared/features/layout';
import { AdminsSharedFeaturesNotificationsHubModule } from '@bp/admins-shared/features/notifications-hub';
import { AdminsSharedDomainsIdentityModule } from '@bp/admins-shared/domains/identity';

import { MenuComponent, RootComponent } from './components';

export const COMPONENTS = [
	RootComponent,
	MenuComponent,
];

@NgModule({
	imports: [
		CommonModule,
		RouterModule,

		SharedComponentsCoreModule,
		SharedDirectivesModule,
		SharedFeaturesSvgIconsModule,
		SharedFeaturesSvgPaymentCardBrandsIconsModule,
		SharedFeaturesModalModule,
		AdminsSharedDomainsIdentityModule,
		AdminsSharedCoreComponentsModule,
		AdminsSharedFeaturesLayoutModule,
		AdminsSharedFeaturesNotificationsHubModule,
	],
	declarations: COMPONENTS,
	exports: COMPONENTS,
})
export class CoreModule { }
