import { ɵa as LinkHandler, ɵɵLinkDirective, ɵd as LinkHandlerStrategy } from 'ngx-quicklink';

import { Directive, Input, Inject, OnChanges, Optional, SimpleChanges, ElementRef } from '@angular/core';
import { RouterLink, RouterLinkWithHref } from '@angular/router';

import { RouterLinkInput } from './router-link-input-type';

@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: '[routerLink], [routerLinkNoOutlets]',
})
export class BpQuicklinkDirective extends ɵɵLinkDirective implements OnChanges {

	@Input() routerLinkNoOutlets: RouterLinkInput;

	constructor(
	@Inject(LinkHandler)
		linkHandlers: LinkHandlerStrategy[],
		element: ElementRef,
		@Optional()
		link: RouterLink,
		@Optional()
		linkWithHref: RouterLinkWithHref,
	) {
	  super(linkHandlers, element, link, linkWithHref);
	}

	override ngOnChanges(changes: Partial<SimpleChanges>): void {
		if (changes['routerLinkNoOutlets'])
			changes['routerLink'] = changes['routerLinkNoOutlets'];

		super.ngOnChanges(<SimpleChanges>changes);
	}
}
