<mat-form-field
	[color]="color"
	[appearance]="matAppearance"
	[hideRequiredMarker]="defaultOptions?.hideRequiredMarker"
	[floatLabel]="$$floatLabel"
	[class.form-field-pending]="pending"
>
	<mat-label *ngIf="label || customLabel">
		<span [ngSwitch]="!!customLabel">
			<span *ngSwitchDefault>{{ label }}</span>
			<ng-content *ngSwitchCase="true" select="bp-input-label"> </ng-content>
		</span>

		<span *ngIf="required" class="mat-placeholder-required mat-form-field-required-marker"> *</span>
	</mat-label>

	<mat-chip-list #chipList [formControl]="externalControl!">
		<mat-chip *ngFor="let v of getSelectedChips()" @fade [color]="color" [removable]="true" (removed)="remove(v)">
			{{ v }}
			<mat-icon matChipRemove>cancel</mat-icon>
		</mat-chip>

		<input
			#input
			[autocomplete]="$$attrAutocompleteValue"
			[name]="$$name"
			[formControl]="internalControl"
			[placeholder]="placeholder"
			[required]="!!required"
			[bpTextMask]="number ? numberMask : mask"
			[matChipInputFor]="chipList"
			[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
			[matChipInputAddOnBlur]="true"
			(matChipInputTokenEnd)="add($event.value)"
		/>
	</mat-chip-list>

	<mat-hint *ngIf="hint || customHint">
		<span [ngSwitch]="!!customHint">
			<span *ngSwitchDefault>{{ hint }}</span>
			<ng-content *ngSwitchCase="true" select="bp-input-chips-hint, [bpInputChipsHint]"> </ng-content>
		</span>
	</mat-hint>

	<span *ngIf="prefix" matPrefix>
		<ng-content select="bp-input-chips-prefix, [bpInputChipsPrefix]"></ng-content>
	</span>

	<mat-error [bpFieldError]="name" [bpFieldErrorSuppress]="hideErrorText"> </mat-error>
</mat-form-field>
