import { isString } from 'lodash-es';

import { Enumeration } from '@bp/shared/models/core/enum';

export type PaymentCardBrandRangePattern = [startRange: number, endRange: number];

export type PaymentCardBrandPattern = (PaymentCardBrandRangePattern | number);

type PaymentCardBrandScheme = {
	patterns: PaymentCardBrandPattern[];

	lengths: number[];

	code: { displayName: string; lengths: number[] };

	skipLuhn?: boolean;
};

export class PaymentCardBrand extends Enumeration {

	static maestro = new PaymentCardBrand('Maestro', {
		 patterns: [
			493_698,
			[ 500_000, 504_174 ],
			[ 504_176, 506_698 ],
			[ 506_779, 508_999 ],
			[ 56, 59 ],
			63,
			67,
			6,
		],
		lengths: [ 12, 13, 14, 15, 16, 17, 18, 19 ],
		code: {
			displayName: 'CVC',
			lengths: [ 3 ],
		},
	});

	static visa = new PaymentCardBrand('Visa', {
		patterns: [ 4 ],
		lengths: [ 16, 18, 19 ],
		code: {
			displayName: 'CVV',
			lengths: [ 3 ],
		},
	});

	static masterCard = new PaymentCardBrand('MasterCard', {
		patterns: [[ 51, 55 ], [ 2221, 2229 ], [ 223, 229 ], [ 23, 26 ], [ 270, 271 ], 2720 ],
		lengths: [ 16 ],
		code: {
			displayName: 'CVC',
			lengths: [ 3 ],
		},
	});

	static amex = new PaymentCardBrand('American Express', {
		patterns: [ 34, 37 ],
		lengths: [ 15 ],
		code: {
			displayName: 'CID',
			lengths: [ 4 ],
		},
	});

	static hiper = new PaymentCardBrand('Hiper', {
		patterns: [ 637_095, 63_737_423, 63_743_358, 637_568, 637_599, 637_609, 637_612 ],
		lengths: [ 16 ],
		code: {
			displayName: 'CVC',
			lengths: [ 3 ],
		},
	});

	static hipercard = new PaymentCardBrand('Hipercard', {
		patterns: [ 606_282 ],
		lengths: [ 16 ],
		code: {
			displayName: 'CVC',
			lengths: [ 3 ],
		},
	});

	static elo = new PaymentCardBrand('Elo', {
		patterns: [
			401_178,
			401_179,
			438_935,
			457_631,
			457_632,
			431_274,
			451_416,
			457_393,
			504_175,
			[ 506_699, 506_778 ],
			[ 509_000, 509_999 ],
			627_780,
			636_297,
			636_368,
			[ 650_031, 650_033 ],
			[ 650_035, 650_051 ],
			[ 650_405, 650_439 ],
			[ 650_485, 650_538 ],
			[ 650_541, 650_598 ],
			[ 650_700, 650_718 ],
			[ 650_720, 650_727 ],
			[ 650_901, 650_978 ],
			[ 651_652, 651_679 ],
			[ 655_000, 655_019 ],
			[ 655_021, 655_058 ],
		],
		lengths: [ 16 ],
		code: {
			displayName: 'CVE',
			lengths: [ 3 ],
		},
		skipLuhn: true,
	});

	static forbrugsforeningen = new PaymentCardBrand('Forbrugsforeningen', {
		patterns: [ 600 ],
		lengths: [ 16 ],
		code: {
			displayName: 'CVV',
			lengths: [ 3 ],
		},
	});

	static dankort = new PaymentCardBrand('Dankort', {
		patterns: [ 5019 ],
		lengths: [ 16 ],
		code: {
			displayName: 'CVV',
			lengths: [ 3 ],
		},
	});

	static dinersclub = new PaymentCardBrand('Diners Club', {
		patterns: [[ 300, 305 ], 36, 38, 39 ],
		lengths: [ 14, 16, 19 ],
		code: {
			displayName: 'CVV',
			lengths: [ 3 ],
		},
	});

	static discover = new PaymentCardBrand('Discover', {
		patterns: [ 6011, [ 644, 649 ], 65 ],
		lengths: [ 16, 19 ],
		code: {
			displayName: 'CID',
			lengths: [ 3 ],
		},
	});

	static jcb = new PaymentCardBrand('JCB', {
		patterns: [ 2131, 1800, [ 3528, 3589 ]],
		lengths: [ 16, 17, 18, 19 ],
		code: {
			displayName: 'CVV',
			lengths: [ 3 ],
		},
	});

	static mir = new PaymentCardBrand('Mir', {
		  patterns: [[ 2200, 2204 ]],
		lengths: [ 16, 17, 18, 19 ],
		code: {
			displayName: 'CVP2',
			lengths: [ 3 ],
		},
	});

	static astroPay = new PaymentCardBrand('AstroPay', {
		patterns: [],
		lengths: [],
		code: {
			displayName: 'CVV',
			lengths: [ 4 ],
		},
	});

	static unionpay = new PaymentCardBrand('UnionPay', {
		  patterns: [
			620,
			[ 624, 626 ],
			[ 62_100, 62_182 ],
			[ 62_184, 62_187 ],
			[ 62_185, 62_197 ],
			[ 62_200, 62_205 ],
			[ 622_010, 622_999 ],
			622_018,
			[ 622_019, 622_999 ],
			[ 62_207, 62_209 ],
			[ 622_126, 622_925 ],
			[ 623, 626 ],
			6270,
			6272,
			6276,
			[ 627_700, 627_779 ],
			[ 627_781, 627_799 ],
			[ 6282, 6289 ],
			6291,
			6292,
			810,
			[ 8110, 8131 ],
			[ 8132, 8151 ],
			[ 8152, 8163 ],
			[ 8164, 8171 ],
		],
		lengths: [ 14, 15, 16, 17, 18, 19 ],
		code: {
			displayName: 'CVN',
			lengths: [ 3 ],
		},
		skipLuhn: true,
	});

	static override parseHook = (value: unknown): PaymentCardBrand | null => {
		if (isString(value) && value
			.toLowerCase()
			.startsWith('master'))
			return PaymentCardBrand.masterCard;

		return null;
	};

	constructor(displayName: string | undefined, public scheme: PaymentCardBrandScheme) {
		super(displayName);
	}
}
