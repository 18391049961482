import { forEach, get, isArray, isEmpty, isEqual, isFunction, isPlainObject, mapValues, pickBy, isNil } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, EMPTY } from 'rxjs';
import { auditTime, filter, map, skipWhile, startWith, switchMap } from 'rxjs/operators';

import { Inject, OnInit, Optional, ChangeDetectorRef, Directive, Input, Output } from '@angular/core';
import type { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { UntypedFormBuilder } from '@angular/forms';

import { ClassMetadata, FormSchemeAbstractControlOptions, MetadataEntityFormControls } from '@bp/shared/models/metadata';
import type { DTO, MetadataEntity, PropertyMetadata, FormSchemeArray, FormScheme } from '@bp/shared/models/metadata';
import { has } from '@bp/shared/utilities';
import { takeUntilDestroyed } from '@bp/shared/models/common';
import { FormBaseComponent, FormOptions, FORM_DEFAULT_OPTIONS } from '@bp/shared/components/core';
import { OnChanges, SimpleChanges } from '@bp/shared/models/core';
import { NonFunctionProperties, NonFunctionPropertyNames } from '@bp/shared/typings';

@Directive()
// eslint-disable-next-line @typescript-eslint/ban-types
export abstract class FormMetadataEntityBaseComponent<TMetadataEntity extends MetadataEntity>
	extends FormBaseComponent<TMetadataEntity, MetadataEntityFormControls<TMetadataEntity>>
	implements OnChanges, OnInit {

	protected readonly _entity$ = new BehaviorSubject<TMetadataEntity | null>(null);

	@Input() metadata!: ClassMetadata<TMetadataEntity>;

	@Output('entityChange') readonly entityChange$ = this._entity$
		.pipe(
			auditTime(50),
			skipWhile((entity, index) => entity === null && index === 0 || entity === this.entitySetExternally),
		);

	@Input() entity!: TMetadataEntity | null;

	@Input() factory!: (v?: DTO<TMetadataEntity>) => TMetadataEntity;

	@Input() formScheme?: FormScheme<TMetadataEntity> | null;

	entitySetExternally: TMetadataEntity | null = null;

	constructor(
		formBuilder: UntypedFormBuilder,
		cdr: ChangeDetectorRef,
		toaster: ToastrService,
		@Optional() @Inject(FORM_DEFAULT_OPTIONS) formDefaultOptions?: FormOptions,
	) {
		super(formBuilder, cdr, toaster, formDefaultOptions);

		this._onFormGroupChangeBuildEntityAndEmitChange();
	}

	override ngOnChanges(changes: SimpleChanges<this>): void {
		super.ngOnChanges(changes);

		if (changes.entity) {
			this.entitySetExternally = changes.entity.currentValue ?? null;

			this._setEntity(changes.entity.currentValue ?? null);

			this._updateFormOnEntityChangedExternally();
		}
	}

	ngOnInit(): void {
		this._assertFactoryIsProvided();
	}

	private _updateFormOnEntityChangedExternally(): void {
		if (this.formScheme === null)
			return;

		this.entity && this.form && this.formScheme
			? this._repopulateFormBasedOnScheme(
				this.form,
				this.formScheme,
				this.entity,
			)
			: this._setupForm(this.formScheme!);
	}

	setFormScheme(scheme: FormScheme<TMetadataEntity>): void {
		this.formScheme = scheme;
	}

	getFormScheme(): FormScheme<TMetadataEntity> {
		this._assertFormSchemeIsProvided(this.formScheme!);

		return this.formScheme!;
	}

	getControlByPropertyMetadata(md: PropertyMetadata): UntypedFormControl | null {
		return <UntypedFormControl | undefined> this.form!.controls[md.property] ?? null;
	}

	ensurePropertyName(
		this: void,
		property: NonFunctionPropertyNames<TMetadataEntity>,
	): string {
		return <string>property;
	}

	ensurePropertyNames(
		this: void,
		propertyNames: NonFunctionPropertyNames<TMetadataEntity>[],
	): NonFunctionPropertyNames<TMetadataEntity>[] {
		return propertyNames;
	}

	patchForm(properties: Partial<NonFunctionProperties<TMetadataEntity>>): void {
		this.form!.patchValue(properties);
	}

	getFormGroupFormControl(formGroup: UntypedFormGroup, property: string): UntypedFormControl | null {
		return <UntypedFormControl | null> formGroup.get(property);
	}

	protected _setEntity(entity: TMetadataEntity | null): void {
		if (isEqual(this._entity$.value, entity))
			return;

		this.entity = entity;

		this._entity$.next(entity);
	}

	protected _rollbackEntity(): void {
		if (this.entitySetExternally === this.entity)
			return;

		this.entity = this.entitySetExternally;

		if (this.form) {
			this._repopulateFormBasedOnScheme(
				this.form,
				this.formScheme!,
				this.entity,
			);
		}
	}

	protected _setupForm(formScheme: FormScheme<TMetadataEntity>): void {
		this.setFormScheme(formScheme);

		this.form = this._buildFormGroupBasedOnFormScheme(
			formScheme,
			this.entity ?? this.factory(),
		);
	}

	protected _buildFormGroupBasedOnFormScheme<TFormSeedEntity extends MetadataEntity>(
		formScheme: FormScheme<TFormSeedEntity>,
		formSeedEntity: TFormSeedEntity,
	): UntypedFormGroup {
		this._assertFormSchemeIsProvided(formScheme);

		return this._formBuilder.group(
			this._buildFormGroupConfigBasedOnScheme(formScheme, formSeedEntity),
			this._formDefaultOptions,
		);
	}

	private _buildFormGroupConfigBasedOnScheme<TFormSeedEntity extends MetadataEntity>(
		formScheme: FormScheme<TFormSeedEntity>,
		formSeedEntity: TFormSeedEntity,
	): Record<string, any> {
		this._assertFormSchemeIsProvided(formScheme);

		return mapValues(
			formScheme,
			(propertySchemeDefinition, propertyName) => this._buildControlConfigBasedOnPropertySchemeDefinition(
				get(formSeedEntity, propertyName),
				propertySchemeDefinition,
			),
		);
	}

	protected _buildControlConfigBasedOnPropertySchemeDefinition(
		// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
		propertyValue: any,
		propertySchemeDefinition: FormScheme<any>[ string ],
	): UntypedFormArray | UntypedFormGroup | [ formState: any, opts?: FormSchemeAbstractControlOptions ] {

		if (isNil(propertySchemeDefinition))
			return [ propertyValue ];

		if (this._isAbstractControlOptions(propertySchemeDefinition)) {
			return [
				isNil(propertyValue) && !isNil(propertySchemeDefinition.defaultValue)
					? propertySchemeDefinition.defaultValue
					: propertyValue,
				propertySchemeDefinition,
			];
		}

		if (this._isArrayFormScheme(propertySchemeDefinition)) {
			return this._formBuilder.array(
				(<any[]> propertyValue).map(propertyArrayItem => this._buildControlConfigBasedOnPropertySchemeDefinition(
					propertyArrayItem,
					propertySchemeDefinition[1],
				)),
			);
		}

		if (isFunction(propertySchemeDefinition) || isArray(propertySchemeDefinition))
			return [ propertyValue, { validators: propertySchemeDefinition }];

		if (isPlainObject(propertySchemeDefinition))
			return this._buildFormGroupBasedOnFormScheme(propertySchemeDefinition, propertyValue);

		throw this._buildError('Wrong property form scheme definition was provided');
	}

	private _isAbstractControlOptions(
		schemePropertyDefinition: any,
	): schemePropertyDefinition is FormSchemeAbstractControlOptions {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const definition: FormSchemeAbstractControlOptions = schemePropertyDefinition;

		return has(definition, 'validators')
			|| has(definition, 'asyncValidators')
			|| has(definition, 'updateOn')
			|| has(definition, 'defaultValue');
	}

	protected _repopulateFormBasedOnScheme<TFormEntity extends MetadataEntity>(
		form: UntypedFormGroup,
		formScheme: FormScheme<TFormEntity>,
		entity: TFormEntity | null,
	): void {
		this._assertFormSchemeIsProvided(formScheme);

		forEach(
			formScheme,
			(propertySchemeDefinition, propertyName) => void this._repopulatePropertyControlBasedOnPropertySchemeDefinition(
				form,
				propertyName,
				propertySchemeDefinition,
				entity,
			),
		);
	}

	protected _buildFormSchemeBasedOnPropertiesMetadata(propertiesMetadata: PropertyMetadata[]): FormScheme<any> {
		return Object.fromEntries(propertiesMetadata.map<[string, FormSchemeAbstractControlOptions]>(
			propertyMetadata => [
				propertyMetadata.property,
				{
					defaultValue: propertyMetadata.defaultPropertyValue,
				},
			],
		));
	}

	private _assertFormSchemeIsProvided(formScheme?: FormScheme<any>): void {
		if (isNil(formScheme))
			throw this._buildError('The default behavior of the form entity base class requires the form scheme to be set on the constructor');
	}

	private _repopulatePropertyControlBasedOnPropertySchemeDefinition<TFormEntity extends MetadataEntity>(
		form: UntypedFormGroup,
		propertyName: string,
		propertySchemeDefinition: FormScheme<any>[ string ],
		entity: TFormEntity | null,
	): void {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const propertyValue = get(entity, propertyName);

		if (isPlainObject(propertySchemeDefinition)) {
			this._repopulateFormBasedOnScheme(
				<UntypedFormGroup> form.controls[propertyName],
				<FormScheme<any>> propertySchemeDefinition,
				propertyValue,
			);

			return;
		}

		if (propertySchemeDefinition === 'array') {
			form.setControl(propertyName, this._formBuilder.array(
				(<any[]> propertyValue).map(() => this._formBuilder.group({}, this._formDefaultOptions)),
			));

			return;
		}

		(<UntypedFormControl> form.controls[propertyName])
			.setValue(propertyValue, { emitEvent: false, emitModelToViewChange: true });
	}

	private _onFormGroupChangeBuildEntityAndEmitChange(): void {
		this.form$
			.pipe(
				switchMap(form => form
					? form.valueChanges
						.pipe(
							startWith(form.value),
							filter(() => form.enabled),
						)
					: EMPTY),
				map(this._filterOutInternalControlsProperties()),
				filter(formValue => !isEmpty(formValue)),
				map(formValue => this.factory({ ...this.entity, ...formValue })),
				filter(formValueEntity => !isEqual(formValueEntity, this.entity)),
				takeUntilDestroyed(this),
			)
			.subscribe(v => void this._setEntity(v));
	}

	private _filterOutInternalControlsProperties() {
		return (formValue: Record<string, any>) => pickBy(formValue, (_value, key) => !key.startsWith('__'));
	}

	private _assertFactoryIsProvided(): void {
		if (isNil(this.factory))
			throw this._buildError('Entity factory must be provided');
	}

	private _isArrayFormScheme(formScheme: unknown): formScheme is FormSchemeArray<any> {
		return isArray(formScheme) && formScheme.includes('array');
	}

	private _buildError(error: string): Error {
		return new Error(`${ this.constructor.name }: ${ error }`);
	}
}
