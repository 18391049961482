/* eslint-disable */
/// <reference path="../../../../../typings.d.ts" />

import { Injectable, NgZone } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class ZoneService {

	static ngZone: NgZone;

	static rootZone: Zone = Zone.root;

	static isInAngularZone() {
		return Zone.current.name === 'angular';
	}

	static runInAngularZone<T>(func: (...args: any[]) => T, applyThis?: any, applyArgs?: any[]): T {
		return ZoneService.ngZone.run(func, applyThis, applyArgs);
	}

	static runOutsideAngular<T>(func: (...args: any[]) => T, applyThis?: any, applyArgs?: any[]): T {
		return ZoneService.rootZone.run(func, applyThis, applyArgs);
	}

	constructor(private readonly _ngZone: NgZone) {
		ZoneService.ngZone = this._ngZone;

		// this.monitorStuckPendingMacrotasksInDev();
	}

	runInAngularZone<T>(func: (...args: any[]) => T, applyThis?: any, applyArgs?: any[]): T {
		return ZoneService.runInAngularZone(func, applyThis, applyArgs);
	}

	runOutsideAngular<T>(func: (...args: any[]) => T, applyThis?: any, applyArgs?: any[]): T {
		return ZoneService.runOutsideAngular(func, applyThis, applyArgs);
	}

	// monitorStuckPendingMacrotasksInDev(): void {
	// 	if (!isDevMode())
	// 		return;
	//
	// 	const taskTrackingZone = (<any> this._ngZone)._inner.getZoneWith('TaskTrackingZone');
	//
	// 	let prevTickTasks: any[] = [];
	// 	this._ngZone.runOutsideAngular(() => setInterval(
	// 		() => {
	// 			const tasks: any[] = taskTrackingZone._properties.TaskTrackingZone.getTasksFor('macroTask');
	// 			const stuckTasks = intersection(prevTickTasks, tasks);
	// 			prevTickTasks = tasks;
	// 			if (stuckTasks.length > 0 && this._ngZone.hasPendingMacrotasks)
	// 				console.warn(`ZONE pending tasks`, tasks.slice());
	// 		},
	// 		1000
	// 	));
	// }

}
