import { PspPaymentOptionType } from '@bp/shared/models/business';

import { BridgerPspPaymentOptionBaseSummary } from './bridger-psp-payment-option-base-summary';

export class BridgerPspPaymentOptionVoucherSummary extends BridgerPspPaymentOptionBaseSummary {

	static override readonly type = PspPaymentOptionType.voucher;

	readonly type = BridgerPspPaymentOptionVoucherSummary.type;

}
