// import { ɵresetCompiledComponents } from '@angular/core';

declare let module: {
	id: string;
	hot?: {
		accept: () => void;
		dispose: (callback: () => void) => void;
	};
};

export function resetCompiledComponentsOnHMR(): void {
	if (!module.hot)
		// eslint-disable-next-line no-useless-return
		return;

	//  module.hot.accept();

	//  module.hot.dispose(ɵresetCompiledComponents);
}
