import type { Provider } from '@angular/core';

import { FILTER_CONTROL_OPTIONS_TOKEN, FORM_FIELD_DEFAULT_OPTIONS, IFormFieldDefaultOptions } from '@bp/shared/components/core';

export const CONTROL_DEFAULT_OPTIONS_PROVIDERS: Provider[] = [
	{
		provide: FORM_FIELD_DEFAULT_OPTIONS,

		useValue: <IFormFieldDefaultOptions>{
			appearance: 'round-lg',
			floatLabel: 'auto',
		},
	},
	{
		provide: FILTER_CONTROL_OPTIONS_TOKEN,
		useValue: <IFormFieldDefaultOptions>{
			appearance: 'round',
			hideErrorText: true,
		},
	},
];
