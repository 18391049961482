import { ROOT_EFFECTS_INIT } from '@ngrx/effects';
import type { ActionReducer } from '@ngrx/store';

import { IdentityPreferences } from '../../models';

import type { IState } from './identity-preferences.reducer';
import { IDENTITY_PREFERENCES_STATE_KEY } from './identity-preferences.reducer';

export function identityPreferencesStateRehydratorMetaReducer(reducer: ActionReducer<IState>): ActionReducer<IState> {
	return function(state, action) {
		const newState = reducer(state, action);

		return action.type === ROOT_EFFECTS_INIT
			? {
				...newState,
				[IDENTITY_PREFERENCES_STATE_KEY]: new IdentityPreferences(
					newState[IDENTITY_PREFERENCES_STATE_KEY],
				),
			}
			: newState;
	};
}
