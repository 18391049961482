import { isEmpty } from 'lodash-es';
import type { Instance as Tippy, Props as TippyConfig } from 'tippy.js';
import tippy, { animateFill, Placement as TippyPlacement } from 'tippy.js';
import * as Popper from '@popperjs/core';

import type { OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Directive, ElementRef, Input, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { ZoneService } from '@bp/shared/rxjs';
import { cancelIdleCallback, requestIdleCallback } from '@bp/shared/utilities';

type RequiredTippyConfig = Pick<TippyConfig, 'content' | 'hideOnClick' | 'placement'>;

// Default theme. Note that animateFill plugin is used with this theme, and it doesnt support arrow, so
// related prop will have no effect if this plugin is enabled.
const MATERIAL_THEME = 'material';

/**
 * Tooltip directive based on Tippy.js
 *
 * @link https://atomiks.github.io/tippyjs/v6/getting-started/
 */
@Directive({
	selector: '[bpTooltip]',
})
export class TooltipDirective implements OnChanges, OnDestroy {

	@Input('bpTooltip') content?: string | '' | null = null;

	@Input('bpTooltipPlacement') placement: TippyPlacement = 'top';

	@Input('bpTooltipHideOnClick') hideOnClick = true;

	// Always disabled with material theme.
	@Input('bpTooltipArrow') arrow = false;

	@Input('bpTooltipTheme') theme = MATERIAL_THEME;

	/*
	 * @link https://atomiks.github.io/tippyjs/v6/faq/#my-tooltip-appears-cut-off-or-is-not-showing-at-all
	 * @link https://popper.js.org/docs/v2/constructors/#strategy
	 */
	@Input('bpPositioningStrategy') positioningStrategy: Popper.PositioningStrategy = 'absolute';

	private _tippy: Tippy | null = null;

	private _destroyed = false;

	private _awaitingTaskId!: number;

	constructor(
		private readonly _host: ElementRef,
		private readonly _zoneService: ZoneService,
		private readonly _sanitizer: DomSanitizer,
	) {
	}

	ngOnChanges(_changes: Partial<SimpleChanges>): void {
		this._handleTooltipConfigChange();
	}

	ngOnDestroy(): void {
		this._destroyed = true;

		this._destroyTippy();
	}

	private _handleTooltipConfigChange(): void {
		this._runOutsideWhenIdle(() => {
			if (isEmpty(this.content)) {
				this._destroyTippy();

				return;
			}

			const config: RequiredTippyConfig = {
				content: this._getSanitizedContent(),
				placement: this.placement,
				hideOnClick: this.hideOnClick,
			};

			if (this._tippy)
				this._updateTippy(config);
			else
				this._createTippy(config);
		});
	}

	private _getSanitizedContent(): string {
		return this.content
			? this._sanitizer.sanitize(SecurityContext.HTML, this.content)!
			: '';
	}

	private _createTippy(config: Partial<TippyConfig> & RequiredTippyConfig): void {
		if (this._destroyed)
			return;

		this._tippy = tippy(
			<HTMLElement> this._host.nativeElement,
			{
				ignoreAttributes: true,
				arrow: this.arrow,
				theme: this.theme,
				animateFill: this.theme === MATERIAL_THEME,
				plugins: [ animateFill ],
				allowHTML: true,
				popperOptions: {
					strategy: this.positioningStrategy,
				},
				...config,
			},
		);
	}

	private _updateTippy(config: Partial<TippyConfig>): void {
		if (this._destroyed)
			return;

		this._tippy?.setProps(config);
	}

	private _destroyTippy(): void {
		this._tippy?.destroy();

		this._tippy = null;
	}

	private _runOutsideWhenIdle(task: () => void): void {

		/*
		 * If a new task came but the previous hasn't started or been finished we cancel it since we don't need
		 * the task done anymore
		 */
		cancelIdleCallback(this._awaitingTaskId);

		this._awaitingTaskId = this._zoneService.runOutsideAngular(
			() => requestIdleCallback(task, { timeout: 500 }),
		);
	}
}
