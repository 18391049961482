
/// <reference path="../../../../../../../../node_modules/@types/google-one-tap/index.d.ts" />

import { CredentialResponse } from 'google-one-tap';

import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, NgZone, OnDestroy, Output, ViewContainerRef } from '@angular/core';

import { $, JwtPayload, JwtToken } from '@bp/shared/utilities';
import { EnvironmentService } from '@bp/shared/services';
import { Destroyable } from '@bp/shared/models/common';

@Component({
	selector: 'bp-google-oauth-button',
	templateUrl: './google-oauth-button.component.html',
	styleUrls: [ './google-oauth-button.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GoogleOauthButtonComponent extends Destroyable implements OnDestroy {

	@Output() readonly identityChange = new EventEmitter<IGoogleIdentity>();

	readonly enabled = !!this._environmentService.oauthGoogleClientId;

	private readonly _$host = this._host.nativeElement;

	private readonly _$googleClientScript?: HTMLScriptElement;

	private _isGoogleClientInited = false;

	constructor(
		public viewContainerRef: ViewContainerRef,
		private readonly _host: ElementRef<HTMLElement>,
		private readonly _environmentService: EnvironmentService,
		private readonly _zone: NgZone,
	) {
		super();

		if (!this.enabled)
			return;

		this._$googleClientScript = this._injectGoogleClientScript();

		this._$googleClientScript.addEventListener('load', this._handleGoogleClientLoad);
	}

	override ngOnDestroy(): void {
		super.ngOnDestroy();

		this._$googleClientScript?.removeEventListener('load', this._handleGoogleClientLoad);

		this._$googleClientScript?.remove();

		if (this._isGoogleClientInited)
			window.google.accounts.id.cancel();
	}

	private readonly _handleGoogleClientLoad = (): void => {
		this._initializeGoogleOAuth();

		this._renderGoogleOAuthButton();

		window.google.accounts.id.prompt();

		this._isGoogleClientInited = true;
	};

	private _initializeGoogleOAuth(): void {
		window.google.accounts.id.initialize({
			/* eslint-disable @typescript-eslint/naming-convention */
			client_id: this._environmentService.oauthGoogleClientId!,
			auto_select: false,
			/* eslint-enable @typescript-eslint/naming-convention */
			context: 'use',
			callback: credentialResponse => void this._handleCredentialResponse(credentialResponse),
		});
	}

	private _renderGoogleOAuthButton(): void {
		window.google.accounts.id.renderButton(
			this._$host,
			{
				text: 'continue_with',
				theme: 'outline',
				size: 'large',
				shape: 'pill',
				locale: 'en',
				width: this._$host.offsetWidth,
			},
		);
	}

	private _handleCredentialResponse({ credential }: CredentialResponse): void {
		const parsed = JwtToken.decode<IGoogleCredentialJwtPayload>(credential);

		this._zone.run(() => void this.identityChange.emit({
			firstName: parsed.givenName,
			lastName: parsed.familyName,
			email: parsed.email,
			avatar: parsed.picture,
			oauthIdToken: credential,
			oauthProvider: 'google',
		}));
	}

	private _injectGoogleClientScript(): HTMLScriptElement {
		const $script = $.buildAsyncScriptElement({ src: 'https://accounts.google.com/gsi/client' });

		document.body.append($script);

		return $script;
	}
}

export interface IGoogleIdentity {

	oauthIdToken: string;

	oauthProvider: string;

	email: string;

	avatar: string;

	firstName: string;

	lastName: string;

}

interface IGoogleCredentialJwtPayload extends JwtPayload {
	email: string;
	picture: string;
	givenName: string;
	familyName: string;
}
