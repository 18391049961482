import type { IBaseEnvironment, IEnvironment } from '@bp/shared/typings';

import { constants } from './constants';
import { environment as appEnvironment } from './environment.app';

export function environmentExtender(environment: IBaseEnvironment): IEnvironment {
	return {
		...environment,

		/*
		* By putting this always after the main environment config this allows overriding
		* of any environment specific property by the app
		*/
		...appEnvironment,

		...constants,

		isLocalServer: environment.name === 'local',

		isRemoteServer: environment.name !== 'local',
	};
}
