import { isString } from 'lodash-es';

import { StringifyQuery } from '@bp/shared/models/common';
import { booleanMapper } from '@bp/shared/models/metadata';

import { BridgerPspScope } from './bridger-psp-scope';
import { BridgerPspSummaryQueryParams, BridgerPspSummaryQueryParamsDTO } from './summaries';

interface IBridgerPspsQueryParams {

	scopes?: BridgerPspScope[];

	popular?: boolean;

}

export type BridgerPspQueryParamsDTO = BridgerPspSummaryQueryParamsDTO & StringifyQuery<IBridgerPspsQueryParams>;

export class BridgerPspsQueryParams extends BridgerPspSummaryQueryParams {

	scopes: BridgerPspScope[];

	popular?: boolean;

	constructor(dto?: BridgerPspQueryParamsDTO) {
		super(dto);

		this.scopes = isString(dto?.scopes)
			? dto?.scopes
				.split(',')
				.map(v => BridgerPspScope.parse(v))
				.filter((v): v is BridgerPspScope => !!v) ?? []
			: dto?.scopes ?? [];

		if (dto?.popular !== undefined)
			this.popular = booleanMapper(dto.popular);
	}

}
