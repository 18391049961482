import { merge, Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';

@Injectable({
	providedIn: 'root',
})
export class ModalService {

	hasOpenedDialogs$ = merge(
		this._dialog.afterOpened.pipe(map(() => true)),
		this._dialog.afterAllClosed.pipe(map(() => false)),
	);

	constructor(private readonly _dialog: MatDialog) { }

	getOpenDialogById$(id: string): Observable<MatDialogRef<any> | undefined> {
		return this.hasOpenedDialogs$.pipe(
			map(() => this._dialog.getDialogById(id)),
			map(dialog => dialog?.getState() === MatDialogState.OPEN ? dialog : undefined),
			distinctUntilChanged(),
			shareReplay({ bufferSize: 1, refCount: true }),
		);
	}

	closeAll(): void {
		this._dialog.closeAll();
	}

}
