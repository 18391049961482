import { isNil } from 'lodash-es';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { HttpParams, HttpResponse, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponseBase } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { retryOn5XXOrUnknownErrorWithScalingDelay } from '@bp/shared/models/common';
import { BpError, IApiResponse, ResponseStatusCode } from '@bp/shared/models/core';

import { EnvironmentService } from '../environment.service';
import { TelemetryService } from '../telemetry';

import { CORRELATION_ID_HEADER, HttpConfigService } from './http-config.service';

@Injectable()
export class HttpResponseInterceptorService implements HttpInterceptor {

	constructor(
		private readonly _httpConfig: HttpConfigService,
		private readonly _telemetry: TelemetryService,
		private readonly _environment: EnvironmentService,
	) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
		return next
			.handle(request.clone({
				params: this._removeFromRequestHttpParamsEmptyValues(request.params),
			}))
			.pipe(
				catchError(async (error: unknown) => {
					void this._setHeaderBackendVersionToEnvironment(error);

					this._redirectOn3XX(error);

					return this._rethrowErrorAsBpError(error);
				}),
				retryOn5XXOrUnknownErrorWithScalingDelay(),
				tap(httpEvent => {
					void this._saveCorrelationIdFromResponseForNextRequests(httpEvent);

					void this._setHeaderBackendVersionToEnvironment(httpEvent);
				}),
				map(httpEvent => this._normalizeApiResponse(httpEvent)),
				catchError((error: unknown) => {
					if (error instanceof BpError) {
						this._whenRateLimitedOnProductionCaptureAsMessage(error);

						this._when5xxOnRemoteCaptureAsError(error);
					}

					// eslint-disable-next-line rxjs/throw-error
					throw error;
				}),

			);
	}

	private _redirectOn3XX(error: unknown): void {
		if (!(error instanceof HttpErrorResponse))
			return;

		const is3XX = error.status >= 300 && error.status < 400;
		const newLocation = error.headers.get('Location');

		if (is3XX && newLocation)
			window.location.href = newLocation;
	}

	private _saveCorrelationIdFromResponseForNextRequests(httpEvent: HttpEvent<any>): void {
		if (httpEvent instanceof HttpResponse && httpEvent.headers.has(CORRELATION_ID_HEADER))
			this._httpConfig.setHttpHeader(CORRELATION_ID_HEADER, httpEvent.headers.get(CORRELATION_ID_HEADER)!);
	}

	private _setHeaderBackendVersionToEnvironment(httpEvent: unknown): void {
		if (!(httpEvent instanceof HttpResponseBase))
			return;

		for (const apiHeader of [ 'api-version', 'x-api-version', 'api-supported-versions' ]) {
			if (httpEvent.headers.has(apiHeader)) {
				this._environment.setBackendVersion(httpEvent.headers.get(apiHeader)!);

				return;
			}
		}
	}

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
	private _normalizeApiResponse(
		httpEvent: HttpEvent<IApiResponse<any>>,
	) {
		return httpEvent instanceof HttpResponse && httpEvent.body?.result
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			? httpEvent.clone({ body: httpEvent.body.result })
			: httpEvent;
	}

	private async _rethrowErrorAsBpError(error: unknown): Promise<never> {
		let bpError: BpError;

		if (error instanceof HttpErrorResponse && error.error instanceof Blob) {
			try {
				bpError = new BpError(JSON.parse(
					await (new Response(error.error)).text(),
				));
			} catch {
				bpError = new BpError(error);
			}
		} else
			bpError = new BpError(error);

		throw bpError;
	}

	private _removeFromRequestHttpParamsEmptyValues(params: HttpParams): HttpParams {
		const httpParamsNames = params instanceof HttpParams ? params.keys() : Object.keys(params);
		const getValueByKey = (k: string): string | null => params instanceof HttpParams ? params.get(k) : params[k];

		return new HttpParams({
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			fromObject: Object.fromEntries(
				httpParamsNames
					.map(httpParamName => [
						httpParamName,
						getValueByKey(httpParamName),
					])
					.map(([ httpParamName, httpParamValue ]) => [
						httpParamName,
						isNil(httpParamValue) ? httpParamValue : httpParamValue.toString(),
					])
					.filter(([ , httpParamValue ]) => httpParamValue !== ''
						&& httpParamValue !== 'NaN'
						&& !isNil(httpParamValue)),
			),
		});
	}

	private _whenRateLimitedOnProductionCaptureAsMessage(error: BpError): void {
		if (this._environment.isProduction && error.status === ResponseStatusCode.RateLimited)
			this._telemetry.captureMessage(error.statusText!);
	}

	private _when5xxOnRemoteCaptureAsError(error: BpError): void {
		if (error.status! >= 500) {
			this._telemetry.captureError(new BpError({
				...error,
				message: `${ error.status } Internal Server Error`,
			}));
		}
	}
}
