import { ChangeDetectionStrategy, Component, Directive, EventEmitter, Input, TemplateRef, ContentChild, ViewChild, Output, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelect, MatSelectChange } from '@angular/material/select';

import { FormFieldControlComponent } from '@bp/shared/components/core';
import { attrBoolValue, bpQueueMicrotask } from '@bp/shared/utilities';

@Directive({
	selector: '[bpSelectOption]',
})
export class SelectOptionDirective {

	constructor(public tpl: TemplateRef<any>) { }

}

@Component({
	selector: 'bp-select-field[items]',
	templateUrl: './select-field.component.html',
	styleUrls: [ './select-field.component.scss' ],
	host: {
		'(focusin)': 'onTouched()',
	},
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: SelectComponent,
		multi: true,
	}],
})
export class SelectComponent<TSelectItem> extends FormFieldControlComponent<TSelectItem> implements OnInit {

	@Input() items!: Set<TSelectItem> | TSelectItem[] | null | undefined;

	@Input() multiple?: boolean | '';

	@Input() optionClass?: string;

	@Input() resetOptionText = 'None';

	@Input() useItemValueOf?: boolean | '';

	@Input() disableOptionCentering?: boolean | '';

	@Input() itemDisplayValuePropertyName?: string;

	@Input() panelClass = 'mat-select-panel';

	@Output() readonly selectionChange = new EventEmitter<MatSelectChange>();

	@ViewChild(MatSelect, { static: true }) select!: MatSelect;

	@ContentChild(SelectOptionDirective) customSelectOption?: SelectOptionDirective;

	override ngOnInit(): void {
		super.ngOnInit();

		this.multiple = attrBoolValue(this.multiple);

		this.useItemValueOf = attrBoolValue(this.useItemValueOf);

		this.disableOptionCentering = attrBoolValue(this.disableOptionCentering);

	}

	override writeValue(value: TSelectItem | null): void {
		bpQueueMicrotask(() => {
			value = (this.items ? [ ...this.items ].find(item => (<Object>item).valueOf() === value) : value) ?? null;

			super.writeValue(value);
		});
	}

	focus(): void {
		this.select.focus();
	}
}
