import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { FADE_IN } from '@bp/shared/animations';

import { LayoutFacade } from '../../state';

@Component({
	selector: 'bp-nav-item',
	templateUrl: './nav-item.component.html',
	styleUrls: [ './nav-item.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE_IN ],
})
export class NavItemComponent {

	@Input() icon?: string;

	@Input() path!: any[] | string;

	@Output() readonly navigate = new EventEmitter();

	constructor(public layoutFacade: LayoutFacade) { }

}
