import moment, { Moment } from 'moment';
import { isNil } from 'lodash-es';

import { IToastButton, ToastType } from '@bp/shared/components/core';
import { DTO, Default, MapFromDTO, Mapper, MetadataEntity, momentMapper } from '@bp/shared/models/metadata';
import { uuid } from '@bp/shared/utilities';

class NotificationButton extends MetadataEntity implements IToastButton {

	@MapFromDTO()
	text!: string;

	@Default(null)
	href?: string | null;

	@Default(null)
	routerLink?: any[] | null;

	@Default(null)
	onClick!: (() => void) | null;
}

export class Notification extends MetadataEntity {

	@MapFromDTO()
	id!: string;

	@MapFromDTO()
	text!: string;

	@Default(null)
	logoUrl!: string | null;

	@Mapper(momentMapper)
	createdAt!: Moment;

	@Mapper(NotificationButton)
	@Default(null)
	button?: NotificationButton | null;

	@Default(null)
	onRead!: (() => void) | null;

	@Default(null)
	onUnRead!: (() => void) | null;

	@Default(false)
	isRead!: boolean;

	@Default(false)
	isPending!: boolean;

	@Default(false)
	duplicateAsToast!: boolean;

	@Mapper(ToastType)
	@Default(ToastType.info)
	toastType!: ToastType;

	/**
	 * Notification can't be removed or marked as read, to always draw attention
	 */
	@Default(false)
	isImportant!: boolean;

	@Default(false)
	isNonRemovable!: boolean;

	constructor(dto: DTO<Notification>) {
		super(dto);

		this.id = this.id || uuid();

		this.createdAt = isNil(this.createdAt) ? moment() : this.createdAt;
	}

}
