import LogRocket from 'logrocket';

import { MetaReducer } from '@ngrx/store';

import { actionSanitizer, stateSanitizer } from './ngrx-sanitizers';

export function createLogrocketMetaReducer(): MetaReducer {
	 // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	 const reduxMiddleware = LogRocket.reduxMiddleware({
		actionSanitizer,
		stateSanitizer,
	 });

	let currentState: any;
	let store: any;

	return reducer => (state, action) => {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const newState = reducer(state, action);

		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		currentState = state;

		if (!store) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
			store = reduxMiddleware({
				getState: () => currentState,
			});
		}

		// eslint-disable-next-line @typescript-eslint/no-unsafe-call
		store(() => newState)(action);

		return newState;
	};
}
