import { Observable, lastValueFrom, from, concatAll, catchError, of } from 'rxjs';
import { isEmpty, isEqual, isObject, transform, uniq } from 'lodash-es';
import moment from 'moment';

import { inject, Injectable } from '@angular/core';

import { FirebaseService } from '@bp/shared/services';
import { FirebasePsp } from '@bp/shared/models/firebase-psps';
import { BridgerPsp, bridgerPspPaymentOptionsFactory, BridgerPspScope, BridgerPspsQueryParams } from '@bp/shared/domains/bridger-psps/core';

import { BridgerPspsApiService } from './bridger-psps-api.service';

@Injectable({ providedIn: 'root' })
export class BridgerPspsImportService {

	readonly collectionPath = 'admin-payment-providers';

	private readonly _bridgerPspsApiService = inject(BridgerPspsApiService);

	private readonly _firebaseService = inject(FirebaseService);

	async deleteDuplications(): Promise<void> {
		const { records: bridgerPsps } = await lastValueFrom(this._bridgerPspsApiService.getRecordsPage(
			new BridgerPspsQueryParams({ limit: 9999 }),
		));

		const duplicates = bridgerPsps.filter(psp => psp.modifiedBy === 'importer' && !psp.internalName && psp.created!.isAfter(moment().subtract(2, 'hour')));

		console.log('Duplicates found! Count:', duplicates.length);

		await lastValueFrom(this._deleteBridgerPsps(duplicates));

		console.log('Finished!');
	}

	async importFirebasePsps(): Promise<void> {
		const firebasePsps = await lastValueFrom(this._getAllFirebasePsps());

		console.log('Count of Firebase PSPs to process', firebasePsps.length);

		const { records: bridgerPsps } = await lastValueFrom(this._bridgerPspsApiService.getRecordsPage(
			new BridgerPspsQueryParams({ limit: 9999 }),
		));

		const convertedBridgerPsps = firebasePsps.map(firebasePsp => {
			console.group(`Processing Firebase PSP ${ firebasePsp.name }`);

			console.log('Original Firebase PSP', firebasePsp);

			const matchBridgerPsp = bridgerPsps.find(
				bridgerPsp => bridgerPsp.internalName && firebasePsp.internalName && bridgerPsp.internalName.toLocaleLowerCase() === firebasePsp.internalName.toLocaleLowerCase(),
			) ?? null;

			if (matchBridgerPsp) {
				console.log(`Found match Bridger PSP ${ matchBridgerPsp.name }`, matchBridgerPsp);

				if (matchBridgerPsp.internalName !== firebasePsp.internalName)
					console.log('Internal name matches but is different', matchBridgerPsp.internalName, firebasePsp.internalName);

			} else
				console.log('No match Bridger PSP found');

			const convertedBridgerPsp = this._convertFirebasePspToBridgerPsp(firebasePsp, matchBridgerPsp);

			console.log('Resulting Bridger PSP', convertedBridgerPsp);

			if (matchBridgerPsp)
				console.log('Difference:', Object.keys(this._difference(convertedBridgerPsp, matchBridgerPsp)));

			console.groupEnd();

			return convertedBridgerPsp;
		});

		await lastValueFrom(this._saveBridgerPsps(convertedBridgerPsps));

		console.log('Finished!');
	}

	private _getAllFirebasePsps(): Observable<FirebasePsp[]> {
		return this._firebaseService
			.getCollection<FirebasePsp>(this.collectionPath, v => new FirebasePsp(v));
	}

	private _convertFirebasePspToBridgerPsp(
		firebasePsp: FirebasePsp,
		matchBridgerPsp: BridgerPsp | null,
	): BridgerPsp {
		return new BridgerPsp({
			...matchBridgerPsp,
			description: isEmpty(matchBridgerPsp?.description) ? firebasePsp.description : matchBridgerPsp?.description,
			fullLogoUrl: isEmpty(matchBridgerPsp?.fullLogoUrl) ? firebasePsp.fullLogoUrl : matchBridgerPsp?.fullLogoUrl,
			industries: isEmpty(matchBridgerPsp?.industries) ? firebasePsp.industries : matchBridgerPsp?.industries,
			locations: isEmpty(matchBridgerPsp?.locations) ? firebasePsp.locations : matchBridgerPsp?.locations,
			logoUrl: isEmpty(matchBridgerPsp?.logoUrl) ? firebasePsp.logoUrl : matchBridgerPsp?.logoUrl,
			name: isEmpty(matchBridgerPsp?.name) ? firebasePsp.name : matchBridgerPsp?.name,
			websiteUrl: isEmpty(matchBridgerPsp?.websiteUrl) ? firebasePsp.websiteUrl : matchBridgerPsp?.websiteUrl,
			popular: matchBridgerPsp?.popular ?? firebasePsp.popular,

			modified: matchBridgerPsp?.modified ?? firebasePsp.updatedAt,
			modifiedBy: 'importer',
			paymentOptions: isEmpty(matchBridgerPsp?.paymentOptions)
				? bridgerPspPaymentOptionsFactory(firebasePsp.paymentOptions)
				: matchBridgerPsp!.paymentOptions,
			scopes: uniq([
				...matchBridgerPsp?.scopes ?? [],
				...(firebasePsp.publiclyVisible ? [ BridgerPspScope.promo ] : []),
			]),
		});
	}

	private _saveBridgerPsps(bridgerPsps: BridgerPsp[]): Observable<any> {
		return from(bridgerPsps.map(
			bridgerPsp => this._bridgerPspsApiService.save(bridgerPsp)
				.pipe(
					catchError((error: unknown) => {
						console.error(`Error on ${ bridgerPsp.name } PSP saving`, error);

						return of(null);
					}),
				),
		))
			.pipe(concatAll());
	}

	private _deleteBridgerPsps(bridgerPsps: BridgerPsp[]): Observable<any> {
		return from(bridgerPsps.map(
			bridgerPsp => this._bridgerPspsApiService.delete(bridgerPsp)
				.pipe(
					catchError((error: unknown) => {
						console.error(`Error on ${ bridgerPsp.name } PSP saving`, error);

						return of(null);
					}),
				),
		))
			.pipe(concatAll());
	}

	// @link https://gist.github.com/Yimiprod/7ee176597fef230d1451
	private _difference(object: any, base: any): object {
		// eslint-disable-next-line @typescript-eslint/no-shadow
		function changes(object: any, base: any): object {
			return transform(object, (result: any, value: any, key: any) => {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
				if (!isEqual(value, base[key]))
					// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
					result[key] = (isObject(value) && isObject(base[key])) ? changes(value, base[key]) : value;

			});
		}

		return changes(object, base);
	}

}
