import type { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { isPresent } from '@bp/shared/utilities';

export function filterPresent<T>(source$: Observable<T | null | undefined>): Observable<NonNullable<T>> {
	return <Observable<NonNullable<T>>>source$.pipe(
		filter(isPresent),
	);
}
