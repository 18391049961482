import { isEmpty } from 'lodash-es';

import type { Type } from '@angular/core';

import type { ActionReducer } from '@ngrx/store';
import { ROOT_EFFECTS_INIT } from '@ngrx/effects';

import type { DTO } from '@bp/shared/models/metadata';

import type { IIdentity } from '../models';

import type { IdentityState } from './compose-identity-reducer';
import { IDENTITY_STATE_KEY } from './compose-identity-reducer';

export function composeIdentityStateRehydratorMetaReducer<
	TIdentity extends IIdentity,
	TState extends IdentityState<TIdentity>
>(identityConstructor: Type<TIdentity>) {
	return function(reducer: ActionReducer<TState>): ActionReducer<TState> {
		return function(state, action) {
			const newState = reducer(state, action);

			if (action.type === ROOT_EFFECTS_INIT) {
				const identityDTO = <DTO<TIdentity> | null>newState[IDENTITY_STATE_KEY];

				return {
					...newState,
					[IDENTITY_STATE_KEY]: isEmpty(identityDTO) ? null : new identityConstructor(identityDTO),
				};
			}

			return newState;
		};
	};
}
