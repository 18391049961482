import { without } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { distinct, filter, mergeMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { ToastConfig, ToastType } from '@bp/shared/components/core';
import { UserIdleService } from '@bp/shared/services';
import type { DTO } from '@bp/shared/models/metadata';

import { Notification } from '../models';
import { NotificationsHubFacade } from '../state';

@Injectable({
	providedIn: 'root',
})
export class NotificationsHubService {

	private readonly _list$ = new BehaviorSubject<Notification[]>([]);

	list$ = this._list$.asObservable();

	get list(): Notification[] {
		return this._list$.value;
	}

	constructor(
		private readonly _toaster: ToastrService,
		private readonly _notificationsHubFacade: NotificationsHubFacade,
		private readonly _userIdleService: UserIdleService,
	) {
		this._duplicateEmittedNotificationsAsToastsIfRequired();
	}

	next(
		...[ notificationOrConfig ]: [ config: DTO<Notification> ] | [ notification: Notification ]
	): Notification {
		const notification = notificationOrConfig instanceof Notification
			? notificationOrConfig
			: new Notification(notificationOrConfig);

		if (this.list.some(v => v.id === notification.id))
			return this.list.find(v => v.id === notification.id)!;

		this._list$.next([ notification, ...this.list ]);

		return notification;
	}

	markAsRead(notification: Notification): void {
		this._setReadState(notification, true);
	}

	markAsUnRead(notification: Notification): void {
		this._setReadState(notification, false);
	}

	markAllAsRead(): void {
		this._list$.next(this.list.map(
			notification => this._getNotificationCopyWithUpdatedReadState(notification, true),
		));
	}

	remove(notification: Notification): void {
		this._list$.next(without(this.list, notification));
	}

	removeAll(): void {
		this._list$.next(this.list.filter(notification => notification.isImportant && notification.isNonRemovable));
	}

	async showToast(notification: Notification): Promise<void> {
		await firstValueFrom(this._userIdleService.onActive$);

		const toastConfig: Partial<ToastConfig> = {
			closeButton: true,
			button: notification.button,
		};

		switch (notification.toastType) {
			case ToastType.info:
				this._toaster.info(
					notification.text,
					undefined,
					toastConfig,
				);
				break;

			case ToastType.success:
				this._toaster.success(
					notification.text,
					undefined,
					toastConfig,
				);
				break;

			case ToastType.warning:
				this._toaster.warning(
					notification.text,
					undefined,
					toastConfig,
				);
				break;

			case ToastType.error:
				this._toaster.error(
					notification.text,
					undefined,
					toastConfig,
				);
				break;

			default:
				throw new Error(`Unknown toast type: ${ notification.toastType }`);
		}
	}

	private _setReadState(notification: Notification, isRead: boolean): void {
		if (!this.list.includes(notification))
			return;

		const shallowCopy = [ ...this.list ];

		shallowCopy.splice(
			this.list.indexOf(notification),
			1,
			this._getNotificationCopyWithUpdatedReadState(notification, isRead),
		);

		this._list$.next(shallowCopy);
	}

	private _getNotificationCopyWithUpdatedReadState(notification: Notification, isRead: boolean): Notification {
		if (isRead === notification.isRead)
			return notification;

		if (isRead)
			void notification.onRead?.();
		else
			void notification.onUnRead?.();

		return new Notification({
			...notification,
			isRead,
		});
	}

	private _duplicateEmittedNotificationsAsToastsIfRequired(): void {
		this._list$.pipe(
			mergeMap(list => list),
			distinct(v => v.id),
			filter(notification => notification.duplicateAsToast && !this._notificationsHubFacade.isOpened),
		)
			.subscribe(notification => void this.showToast(notification));
	}

}
