import { InjectionToken } from '@angular/core';

import { Permission } from '../models';

export const IDENTITY_GET_ACCESS_DIALOG_GUARDS = new InjectionToken<IIdentityGetAccessDialogGuard[]>(
	'identity-has-access-guard-interceptors',
);

export interface IIdentityGetAccessDialogGuard {
	getAccess: (permission: Permission) => Promise<boolean>;
}
