import moment, { Moment } from 'moment';
import { isArray, isNumber, isString } from 'lodash-es';

import { DTO, Secret, Entity } from '@bp/shared/models/metadata';
import { PickBy } from '@bp/shared/typings';
import { isEmpty, JwtToken } from '@bp/shared/utilities';

import {
	MerchantAdminFeature,
	BridgerAdminFeature,
	FeaturePermission,
	FeatureAction,
	featurePermissionsMapFactory,
	IIdentity,
	IdentityJwtPayload,
	IdentityJwtPayloadDTO
} from '@bp/shared-domains-identity';

import { OtpProvider } from './otp-provider.enum';

interface IDeprecatedIdentityDTO {
	session: {
		token: string;
	};
}

export class Identity extends Entity implements IIdentity {
	private static _convertToDto(dtoOrJWT: DTO<Identity> | IDeprecatedIdentityDTO | string): DTO<Identity> {
		return isString(dtoOrJWT) ? { jwt: dtoOrJWT } : Identity._tryConvertToNewInterfaceIfDtoDeprecated(dtoOrJWT);
	}

	private static _tryConvertToNewInterfaceIfDtoDeprecated(
		dto: DTO<Identity> | IDeprecatedIdentityDTO,
	): DTO<Identity> {
		return 'session' in dto ? { jwt: dto.session.token } : dto;
	}

	@Secret()
	readonly jwt!: string;

	@Secret()
	readonly refreshToken?: string | null;

	/**
	 * Full name of the user.
	 */
	override readonly name: string | null;

	readonly email: string;

	readonly role: string | null;

	readonly sessionExpiresAt: Moment;

	readonly otpExpiresAt: Moment | null;

	readonly featurePermissions = new Map<
	BridgerAdminFeature | FeatureAction | MerchantAdminFeature,
	FeaturePermission<BridgerAdminFeature | MerchantAdminFeature>
	>();

	/**
	 * An identity which cannot proceed to the portal and must finish some steps before logging in
	 */
	readonly isIncomplete: boolean;

	readonly otpProvider: OtpProvider | null;

	readonly merchantId: string | null;

	private readonly _jwtPayload: IdentityJwtPayload;

	constructor(
		dtoOrJWT: Parameters<typeof Identity._convertToDto>[0],
		refreshToken?: string | null,
		otpExpiresAt?: number | null,
	) {
		super(Identity._convertToDto(dtoOrJWT));

		this._jwtPayload = this._decodeJWT();

		this.refreshToken = refreshToken ?? this.refreshToken;

		this.sessionExpiresAt = moment.unix(this._jwtPayload.exp).subtract('10', 'minute'); // ten mins earlier to refresh token while it's still valid

		this.otpExpiresAt = otpExpiresAt ? moment.unix(otpExpiresAt) : null;

		this.email = this._jwtPayload.userEmail;

		this.id = this._jwtPayload.userIdentityId!;

		this.merchantId = this._jwtPayload.merchantId ?? null;

		this.role = this._jwtPayload.rol ?? null;

		this.name = this._jwtPayload.userFullName ?? null;

		this.otpProvider = this._jwtPayload.otpProvider ? OtpProvider.parseStrict(this._jwtPayload.otpProvider) : null;

		this.featurePermissions = this._parseJWTPermissions();

		this.modified = this.modified ?? moment();

		this.isIncomplete = this._isIncomplete();
	}

	hasPermission(featureOrAction: BridgerAdminFeature | FeatureAction | MerchantAdminFeature): boolean {
		return this.featurePermissions.has(featureOrAction);
	}

	private _decodeJWT(): IdentityJwtPayload {
		const decoded = JwtToken.decode<IdentityJwtPayloadDTO>(this.jwt);

		if (isEmpty(decoded.permissions))
			throw new Error('The user doesn\'t have any permissions');

		if (!isNumber(decoded.exp))
			throw new Error('The user session JWT must contain expiration timestamp');

		return new IdentityJwtPayload({
			...decoded,
			permissions: isArray(decoded.permissions) ? decoded.permissions : [ decoded.permissions ],
		});
	}

	private _parseJWTPermissions(): PickBy<Identity, 'featurePermissions'> {
		return featurePermissionsMapFactory(this._jwtPayload.permissions, featureName => {
			const feature = BridgerAdminFeature.parse(featureName) ?? MerchantAdminFeature.parse(featureName);

			if (!feature)
				throw new Error(`Unknown feature permission: ${ featureName }`);

			return feature;
		});
	}

	private _isIncomplete(): boolean {
		return [
			MerchantAdminFeature.acceptInvite,
			MerchantAdminFeature.createAccount,
			MerchantAdminFeature.confirmationEmail,
			MerchantAdminFeature.setPasswordOnLogin,
			MerchantAdminFeature.setSecurityQuestionsAnswers,
			MerchantAdminFeature.registerAuthenticator,
			MerchantAdminFeature.resetPassword,
			MerchantAdminFeature.resetExpiredPassword,
			MerchantAdminFeature.resetAuthenticator,
			MerchantAdminFeature.otpVerify,
		].some(featurePermission => this.hasPermission(featurePermission));
	}
}
