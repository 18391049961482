import { InjectionToken } from '@angular/core';
import { FloatLabelType, MatFormFieldAppearance } from '@angular/material/form-field';

export type FormFieldAppearance = MatFormFieldAppearance | 'custom' | 'round-lg' | 'round';

export interface IFormFieldDefaultOptions {

	appearance?: FormFieldAppearance;

	hideRequiredMarker?: boolean;

	hideErrorText?: boolean;

	/**
     * Whether the label for form-fields should by default float `always`,
     * `never`, or `auto` (only when necessary).
     */
	floatLabel?: FloatLabelType;

	/**
	 * Makes internal form field margins smaller
	 */
	compact?: boolean;

}

/**
 * Injection token that can be used to configure the
 * default options for all form field within an app.
 */
export const FORM_FIELD_DEFAULT_OPTIONS = new InjectionToken<IFormFieldDefaultOptions | null>(
	'FORM_FIELD_DEFAULT_OPTIONS',
	{
		// TODO: Seems that for some reason InjectFlags.Optional doesn't work with `inject` function:
		// TODO: when token is not provided, it fails with NullInjector error.
		// TODO: So simple factory is provided to handle the case. Remove when bug will be fixed.
		factory: () => null,
	},
);
