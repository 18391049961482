import type { Provider } from '@angular/core';

import { META_REDUCERS } from '@ngrx/store';

import { StorageService, TelemetryService } from '@bp/shared/services';

import {
	consoleLoggerMetaReducer, hmrAppStateRestorerMetaReducer, immutabilityCheckMetaReducer, appStateRehydratorMetaReducerFactory
} from './meta-reducers';

const SHARED_META_REDUCERS_PROVIDERS: Provider[] = [
	{
		provide: META_REDUCERS,
		useFactory: appStateRehydratorMetaReducerFactory,
		multi: true,
		deps: [ StorageService ],
	},
	{
		provide: META_REDUCERS,
		useValue: consoleLoggerMetaReducer,
		multi: true,
	},
	(TelemetryService.logToReporterMetaReducer
		? [{
			provide: META_REDUCERS,
			useValue: TelemetryService.logToReporterMetaReducer,
			multi: true,
		}]
		: []
	),
];

export const LOCAL_SERVER_META_REDUCERS_PROVIDERS: Provider[] = [
	SHARED_META_REDUCERS_PROVIDERS,
	{
		provide: META_REDUCERS,
		useValue: hmrAppStateRestorerMetaReducer,
		multi: true,
	},
	{
		provide: META_REDUCERS,
		useValue: immutabilityCheckMetaReducer,
		multi: true,
	},

];

export const REMOTE_SERVER_META_REDUCERS_PROVIDERS: Provider[] = [
	SHARED_META_REDUCERS_PROVIDERS,
];
