import type { MonoTypeOperatorFunction, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import type { OnDestroy } from '@angular/core';
import { Directive } from '@angular/core';

import { AsyncVoidSubject } from '@bp/shared/rxjs';

export interface IDestroyable {

	destroyed$: Observable<void>;

}

export function takeUntilDestroyed<T>(destroyable: IDestroyable): MonoTypeOperatorFunction<T> {
	return (source$: Observable<T>) => source$
		.pipe(takeUntil(destroyable.destroyed$));
}

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class Destroyable implements OnDestroy, IDestroyable {

	protected readonly _destroyed$ = new AsyncVoidSubject();

	destroyed$ = this._destroyed$.asObservable();

	ngOnDestroy(): void {
		this._destroyed$.complete();
	}

}
