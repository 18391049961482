import type { Constructor } from './constructor';
import type { QueryParamsBase } from './query-params-base';

export const PAGE_SIZE = 24;

export interface IPageQueryParamsDTO {

	pageSize?: string;

	page?: string | null;

	limit?: number;

}

export interface IPageQueryParams {

	page?: string | null;

	limit: number;

}

export type PageQueryParamsCtor = Constructor<IPageQueryParams>;

type PageQueryParamsBaseCtor = Constructor<QueryParamsBase<IPageQueryParamsDTO>>;

export function mixinPageQueryParams<T extends PageQueryParamsBaseCtor>(base: T, defaultPageSize = PAGE_SIZE): PageQueryParamsCtor & T {
	return class extends base implements IPageQueryParams {

		page?: string;

		limit: number;

		constructor(...args: any[]) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
			super(...args);

			this.limit = Number(this._dto.limit ?? this._dto.pageSize ?? defaultPageSize);

			this.page = this._dto.page ?? undefined;
		}
	};
}
