import type { MetadataEntity } from '../metadata-entity';

import { Property } from './property-metadata.decorator';

// eslint-disable-next-line @typescript-eslint/naming-convention
export function Hint(hint: string, longHint: string = hint) {
	return function(model: MetadataEntity, property: string): void {
		Property({ hint, longHint })(model, property);
	};
}
