import { PspPaymentOptionType } from '@bp/shared/models/business';
import { CryptoCurrency } from '@bp/shared/models/currencies';
import type { DTO } from '@bp/shared/models/metadata';
import { Control, FieldControlType, Label, Mapper, Required } from '@bp/shared/models/metadata';

import { FirebasePspPaymentOptionBase } from './firebase-psp-payment-option-base';

export class FirebasePspPaymentOptionCrypto extends FirebasePspPaymentOptionBase {

	static override readonly type = PspPaymentOptionType.crypto;

	readonly type = FirebasePspPaymentOptionCrypto.type;

	@Control(
		FieldControlType.chip,
		{
			list: CryptoCurrency.list,
			placeholder: 'Add crypto currency...',
		},
	)
	@Mapper(CryptoCurrency)
	@Label('Crypto Currencies')
	@Required()
	currencies!: CryptoCurrency[];

	constructor(dto: DTO<FirebasePspPaymentOptionCrypto>) {
		super(dto);
	}

}
