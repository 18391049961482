<bp-input
	[name]="name"
	[label]="label"
	[hint]="hint"
	[nativeAutocomplete]="false"
	[color]="color"
	[required]="!!required"
	[formControl]="internalControl"
	[placeholder]="placeholder"
	[autocomplete]="countryAutocomplete"
	[appearance]="appearance"
	[floatLabel]="floatLabel"
	[throttle]="throttle"
	[debounce]="debounce"
	[hideErrorText]="hideErrorText"
>
	<bp-input-prefix>
		<bp-country compact [src]="value || worldwide"> </bp-country>
	</bp-input-prefix>
</bp-input>

<mat-autocomplete #countryAutocomplete="matAutocomplete" [class]="panelClass">
	<div *ngIf="countryAutocomplete.isOpen" @fadeInListStaggered>
		<div *ngFor="let country of filtered | take: 20">
			<mat-option *bpDelayedRender="0; allStagger: true" [value]="country.name" [bpTooltip]="country.displayName">
				<bp-country class="ml-1" [src]="country"></bp-country>
			</mat-option>
		</div>
	</div>
	<!-- the mat option below is a trick not to render all the options on render of this component, mat-autocomplete needs options to be present to start render them in dom -->
	<mat-option style="display: none" disabled></mat-option>
</mat-autocomplete>
