import { createFeatureSelector, createSelector } from '@ngrx/store';

import type { IState } from './notifications-hub.reducer';
import { FEATURE_KEY } from './notifications-hub.reducer';

export const selectFeatureState = createFeatureSelector<IState>(FEATURE_KEY);

export const getIsOpened = createSelector(
	selectFeatureState,
	s => s.isOpened,
);

export const getNotifications = createSelector(
	selectFeatureState,
	s => s.notifications,
);

export const getHasPendingNotifications = createSelector(
	selectFeatureState,
	s => s.hasPendings,
);

export const getHasUnreadNotifications = createSelector(
	selectFeatureState,
	s => s.hasUnread,
);

export const getHasImportantNotifications = createSelector(
	selectFeatureState,
	s => s.hasImportant,
);

export const getHasNotImportantNotifications = createSelector(
	selectFeatureState,
	s => s.hasNotImportant,
);

export const getHasRemovableNotifications = createSelector(
	selectFeatureState,
	s => s.hasRemovable,
);

export const getHasNonRemovableNotifications = createSelector(
	selectFeatureState,
	s => s.hasNonRemovable,
);
