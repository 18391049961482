import { isFunction, isObjectLike } from 'lodash-es';
import m from 'moment';

import type { Action, ActionReducer } from '@ngrx/store';

import { Enumeration } from '@bp/shared/models/core/enum';

export function immutabilityCheckMetaReducer<T, V extends Action = Action>(
	reducer: ActionReducer<T, V>,
): ActionReducer<any, any> {
	return (state, action) => deepFreeze(reducer(state, deepFreeze(action)));
}

const hasOwnProperty = (o: any, p: number | string | symbol) => Object.prototype.hasOwnProperty.call(o, p);

function deepFreeze(object: any): any {
	const targetIsFunction = isFunction(object);
	const propertyNames = Object.getOwnPropertyNames(object);

	for (const propertyName of propertyNames) {
		if (
			hasOwnProperty(object, propertyName)
				&& (targetIsFunction
					? propertyName !== 'caller' && propertyName !== 'callee' && propertyName !== 'arguments'
					: true
				)
		) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
			const propertyValue = object[propertyName];

			if (isObjectLike(propertyValue)
					&& !isFunction(propertyValue)
					&& !Object.isFrozen(propertyValue)
					&& !m.isMoment(propertyValue)
					&& !(propertyValue instanceof Enumeration)
			)
				deepFreeze(propertyValue);
		}
	}

	return Object.freeze(object);
}
