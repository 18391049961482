import { ToastrService } from 'ngx-toastr';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

import { FormBaseComponent } from '@bp/shared/components/core';
import { ensureFormGroupConfig } from '@bp/shared/typings';
import { Validators } from '@bp/shared/features/validation';

import { ILoginOtpVerificationApiRequest } from '@bp/admins-shared/domains/identity';

@Component({
	selector: 'bp-otp-on-login-form',
	templateUrl: './otp-on-login-form.component.html',
	styleUrls: [ './otp-on-login-form.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OtpOnLoginFormComponent extends FormBaseComponent<ILoginOtpVerificationApiRequest> {

	@Output() readonly login = new EventEmitter<void>();

	@Output() readonly resetAuthApp = new EventEmitter<void>();

	override onSubmitShowInvalidInputsToast = false;

	constructor(
		fb: UntypedFormBuilder,
		cdr: ChangeDetectorRef,
		toaster: ToastrService,
	) {
		super(fb, cdr, toaster);

		this.form = this._formBuilder.group(ensureFormGroupConfig<ILoginOtpVerificationApiRequest>({
			code: [ null, [ Validators.required ]],
		}));

	}

}
