import { omit, pick } from 'lodash-es';
import type { Observable } from 'rxjs';

import type { OnChanges } from '@angular/core';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { SLIDE } from '@bp/shared/animations';
import { Destroyable, takeUntilDestroyed } from '@bp/shared/models/common';

import type { IValidationErrors } from '../models';
import { ErrorsTextsProviderService } from '../errors-texts-provider';

@Component({
	selector: 'bp-validation-error',
	templateUrl: './validation-error.component.html',
	styleUrls: [ './validation-error.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ SLIDE ],
})
export class ValidationErrorComponent extends Destroyable implements OnChanges {

	@Input('errors') errors!: IValidationErrors | null;

	@Input() animate = true;

	@Input() formControlName?: number | string | null;

	@HostBinding('class.mat-error') matError = true;

	errorText$!: Observable<string> | null;

	constructor(private readonly _errorTextsProvider: ErrorsTextsProviderService) {
		super();
	}

	ngOnChanges(): void {
		if (this.errors) {
			this.errorText$ = this._errorTextsProvider
				.renderFirstErrorTextAndObserveChanges(
					this._moveRequiredValidationErrorToBottom(this.errors),
					this.formControlName,
				)
				.pipe(takeUntilDestroyed(this));
		} else
			this.errorText$ = null;
	}

	private _moveRequiredValidationErrorToBottom(errors: IValidationErrors): IValidationErrors {
		const requiredValidationRuleName = 'required';

		return <IValidationErrors> {
			...omit(errors, requiredValidationRuleName),
			...pick(errors, requiredValidationRuleName),
		};
	}
}
