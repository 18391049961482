import { findLast, get, isArray, isEmpty, isEqual, isFunction, isNil, isNull, noop, repeat } from 'lodash-es';
import type { Observable } from 'rxjs';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { filter, map, skip } from 'rxjs/operators';
import { createTextMaskInputElement } from 'text-mask-core/dist/textMaskCore';

import { BACKSPACE, DOWN_ARROW, END, HOME, LEFT_ARROW, PAGE_DOWN, PAGE_UP, RIGHT_ARROW, UP_ARROW } from '@angular/cdk/keycodes';
import type { AfterViewInit, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import type { ControlValueAccessor } from '@angular/forms';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { AsyncVoidSubject } from '@bp/shared/rxjs';

import type { MaskPipe } from './mask-pipe';
import { NumberMaskPipe } from './number-mask-pipe';
import { TextMaskPipe } from './text-mask-pipe';
import type { TextMask, TextMaskFunc as TextMaskFunction } from './text-mask.config';
import { NumberMaskConfig, TextMaskConfig } from './text-mask.config';

const IS_ANDROID = typeof navigator !== 'undefined' && (/android/iu).test(navigator.userAgent);
const DEFER = typeof requestAnimationFrame === 'undefined' ? setTimeout : requestAnimationFrame;

export type InputTextMaskConfig = Partial<NumberMaskConfig | TextMaskConfig> | TextMask | TextMaskFunction;

enum ValueSource {
	Ui = 'Ui',
	Write = 'Write',
}

@Directive({
	selector: '[bpTextMask]',
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: TextMaskDirective,
		multi: true,
	}],
})
export class TextMaskDirective implements OnInit, AfterViewInit, OnChanges, ControlValueAccessor {

	@Input()
	set bpTextMask(value: InputTextMaskConfig | null | undefined) {
		if (isFunction(value) || isArray(value))
			this.config.mask = value;
		else if (value instanceof TextMaskConfig)
			this.config = value;
		else if (value)
			this.config.assign(value);
		else
			this.config = new TextMaskConfig();
	}

	@Input() bpTextMaskPlaceholderFromMask = true;

	config = new TextMaskConfig();

	valueChange$: Observable<number | string | null>;

	get value() {
		return this._value$.value.value;
	}

	private readonly _value$ = new BehaviorSubject<{
		value: number | string | null;
		source: ValueSource | undefined;
	}>({ value: null, source: undefined });

	private _activeConfig!: TextMaskConfig | null;

	private get _$host(): HTMLElement {
		return this._host.nativeElement;
	}

	private _$input!: HTMLInputElement;

	private get _hasValue() {
		return !isEmpty(this._$input.value)
			&& this._activeConfig
			&& this._$input.value !== this._activeConfig.placeholder;
	}

	private get _isInputSelectable() {
		return [ 'text', 'search', 'url', 'tel', 'password' ].includes(this._$input.type);
	}

	private get _placeholderFromMask() {
		return this.bpTextMaskPlaceholderFromMask && this._activeConfig?.placeholderFromMask;
	}

	private _textMaskInputManager!: {
		state: {
			previousConformedValue: string;
			previousOnRejectRawValue: string;
		};
		update: (value: string) => void;
	} | null;

	private _firstMaskCharIndex = -1;

	private _lastMaskCharIndex = -1;

	private _maskPipe!: MaskPipe | NumberMaskPipe;

	private _isEmptyPlaceholderOnInit!: boolean;

	private readonly _viewInit$ = new AsyncVoidSubject();

	private readonly _ready$ = new AsyncVoidSubject();

	private _onChange: (v: any) => void = noop;

	private _onTouched: () => void = noop;

	constructor(
		private readonly _host: ElementRef,
		private readonly _renderer: Renderer2,
	) {
		this.valueChange$ = this._value$
			.pipe(
				skip(1), // Initial value
				filter(({ source }) => source === ValueSource.Ui),
				map(({ value }) => value),
			);
	}

	async ngOnChanges({ bpTextMask }: Partial<SimpleChanges>): Promise<void> {
		await lastValueFrom(this._viewInit$);

		this._updateDirectiveState();

		if (bpTextMask && this._textMaskInputManager && this._$input.value) {
			this._updateInputAndControlOnConfigChange(
				!bpTextMask.firstChange && !isEqual(bpTextMask.previousValue, bpTextMask.currentValue),
			);
		}

		this._ready$.complete();
	}

	ngOnInit() {
		// `textMask` directive is used directly on an input element
		this._$input = this._$host.tagName === 'INPUT'
			? <HTMLInputElement> this._$host
			// `textMask` directive is used on an abstracted input element, `ion-input`, `md-input`, etc
			: <HTMLInputElement> this._$host.querySelectorAll('INPUT')[0];

		if (isNil(this._$input))
			throw new Error(`bpTextMask hasn't found the input element among descendants of the ${ this._$host.constructor.name }`);
	}

	ngAfterViewInit(): void {
		this._isEmptyPlaceholderOnInit = !this._$input.placeholder;

		this._viewInit$.complete();
	}

	// Begin of ControlValueAccessor
	async writeValue(outerValue?: number | string | null) {
		await lastValueFrom(this._ready$);

		if (this.value === outerValue)
			return;

		let value: number | string | null = isNil(outerValue) ? '' : outerValue.toLocaleString();

		if (this._textMaskInputManager) {
			value = this._activeConfig instanceof NumberMaskConfig ? this._formatDecimalValue(value) : value;

			value = this._applyMaskAndConvertToControlValue(value);

			this._setCaretToValidPosition(this._$input.value.length);

			this._tryActivatePlaceholder();
		} else
			this._$input.value = value;

		this._value$.next({ value, source: ValueSource.Write });
	}

	registerOnChange(func: (value: any) => void) {
		this._onChange = func;
	}

	registerOnTouched(func: () => void) {
		this._onTouched = func;
	}

	setDisabledState(isDisabled: boolean) {
		this._renderer.setProperty(this._$host, 'disabled', isDisabled);
	}
	// End of ControlValueAccessor

	@HostListener('input', [ '$event.target.value' ])
	onInput(userInput: string) {
		let value: number | string | null = userInput;

		if (this._textMaskInputManager) {
			value = this._applyMaskAndConvertToControlValue(userInput);

			if (!this._hasValue
				&& this._activeConfig
				&& this._activeConfig.maskOnFocus
				&& document.activeElement === this._$input
			)
				this._$input.value = this._activeConfig.placeholder;

			if (this._activeConfig instanceof NumberMaskConfig)
				this._tryActivatePlaceholder();

			this.updateCaretPosition();
		}

		this._emitChange(value);
	}

	@HostListener('paste', [ '$event' ])
	onPaste(clipboardEvent: ClipboardEvent) {
		if (!this._textMaskInputManager)
			return;

		if (isEmpty(this._textMaskInputManager.state.previousConformedValue)) {
			clipboardEvent.preventDefault();

			clipboardEvent.clipboardData && this.onInput(clipboardEvent.clipboardData.getData('text/plain'));
		}
	}

	@HostListener('blur')
	onBlur() {
		void this._onTouched();

		if (!this._textMaskInputManager)
			return;

		this._activeConfig instanceof NumberMaskConfig && this._applyMaskAndUpdateInput(
			this._formatDecimalValue(this._$input.value),
		);

		this._tryActivatePlaceholder();
	}

	@HostListener('focus')
	onFocus() {
		if (!this._textMaskInputManager)
			return;

		if (!this._hasValue && this._activeConfig && this._activeConfig.maskOnFocus)
			this._$input.value = this._activeConfig.placeholder;

		this.updateCaretPosition();
	}

	@HostListener('mouseup')
	@HostListener('mousedown')
	updateCaretPosition() {
		if (!this._textMaskInputManager)
			return;

		this._setCaretToValidPosition();

		setTimeout(() => void this._setCaretToValidPosition());
	}

	@HostListener('keydown', [ '$event' ])
	onKeyDown(keyboardEvent: KeyboardEvent) {
		if (!this._textMaskInputManager)
			return;

		if ([ BACKSPACE, PAGE_UP, PAGE_DOWN, END, HOME, LEFT_ARROW, UP_ARROW, RIGHT_ARROW, DOWN_ARROW ]
			.includes(keyboardEvent.keyCode)
		)
			setTimeout(() => void this._setCaretToValidPosition());
	}

	private _emitChange(value: number | string | null) {
		void this._onChange(value);

		this._value$.next({ value, source: ValueSource.Ui });
	}

	private _updateDirectiveState() {
		if (!this.config.mask && !this.config.prefix && !this.config.suffix && !(this.config instanceof NumberMaskConfig)) {
			this._textMaskInputManager = null;

			if (this._activeConfig) {
				if (this._isEmptyPlaceholderOnInit && this._placeholderFromMask)
					this._renderer.setAttribute(this._$input, 'placeholder', '');

				this._$input.value = this._$input.value
					.replace(this._activeConfig.prefixRegExp || '', '')
					.replace(this._activeConfig.suffixRegExp || '', '');

				this._activeConfig = null;
			}

			return;
		}

		this._activeConfig = this.config instanceof NumberMaskConfig
			? new NumberMaskConfig(this.config)
			: new TextMaskConfig(this.config);

		this._maskPipe = this._activeConfig instanceof NumberMaskConfig
			? new NumberMaskPipe(this._activeConfig)
			: new TextMaskPipe(this._activeConfig);

		this._activeConfig.inputElement = this._$input;

		this._activeConfig.placeholder = this._convertMaskToPlaceholder();

		const renderedMask = this._maskPipe.transform('');

		if (isEmpty(renderedMask) && !isFunction(this._activeConfig.mask))
			return;

		this._recalculateFirstLastMaskIndexes();

		if (this._isEmptyPlaceholderOnInit && this._placeholderFromMask)
			this._renderer.setAttribute(this._$input, 'placeholder', this._activeConfig.placeholder);

		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		this._textMaskInputManager = createTextMaskInputElement({
			...this._activeConfig,
			mask: this._maskPipe.transform.bind(this._maskPipe),
		});
	}

	private _setCaretToValidPosition(desiredPosition?: number) {
		if (!this._textMaskInputManager || !this._isInputSelectable)
			return;

		const { value } = this._$input;
		let { selectionStart, selectionEnd } = this._$input;

		selectionStart = selectionStart ?? 0;

		selectionEnd = selectionEnd ?? 0;

		let force = false;

		if (!isNil(desiredPosition)) {
			force = true;

			selectionStart = selectionEnd = desiredPosition;
		}

		if (this.config instanceof NumberMaskConfig && selectionStart === 0 && selectionEnd === value.length)
			this._setCaret(0, this._lastMaskCharIndex);
		else if (selectionStart === selectionEnd) {
			if (this._activeConfig?.maskOnFocus && value === this._activeConfig.placeholder)
				this._setCaret(this._firstMaskCharIndex);
			else if (this._firstMaskCharIndex > 0 && selectionStart < this._firstMaskCharIndex)
				this._setCaret(this._firstMaskCharIndex);
			else if (this._lastMaskCharIndex >= 0 && selectionStart > this._lastMaskCharIndex)
				this._setCaret(this._lastMaskCharIndex);
			else if (force)
				this._setCaret(selectionStart, selectionEnd);
		} else if (force)
			this._setCaret(selectionStart, selectionEnd);
	}

	private _applyMaskAndConvertToControlValue(userInput: string): number | string | null {
		if (this._isCursorWithinPrefix())

			/*
			 * UserInput it's a prefix minus one char (due to backspace);
			 * because of that the textMask lib isn't able to recognize the userInput as prefix
			 * and will just concat prefix + userInput, therefore we are resetting userInput
			 */
			// eslint-disable-next-line no-param-reassign
			this._$input.value = userInput = '';

		let maskedValue = this._applyMaskAndUpdateInput(userInput);

		if (this._activeConfig && !this._activeConfig.includeMaskInValue)
			maskedValue = this._cleanValueFromMask(maskedValue);

		if (this._activeConfig instanceof NumberMaskConfig) {
			maskedValue = this._formatDecimalValue(maskedValue)
				.replace(this._activeConfig.decimalSeparatorSymbol, '.')
				.replace(this._activeConfig.thousandsSeparatorSymbol, '')
				.replace(/\s/gu, '')
				.replace(/\.$/u, '');

			if (!this._activeConfig.allowLeadingZeroes)
				return maskedValue !== '' || this._activeConfig.emptyIsZero ? Number(maskedValue) : null;
		}

		return maskedValue;
	}

	private _applyMaskAndUpdateInput(userInput: string) {
		if (this._activeConfig instanceof NumberMaskConfig && !this._activeConfig.allowLeadingZeroes) {
			// Trim zeros
			const match = this._activeConfig.leadingZeroRegExp.exec(userInput);

			if (match)
				// eslint-disable-next-line no-param-reassign
				userInput = userInput.slice(match[1].length);
		}

		this._textMaskInputManager?.update(userInput);

		this._recalculateFirstLastMaskIndexes(this._$input.value);

		return this._$input.value;
	}

	private _updateInputAndControlOnConfigChange(emitOnChange: boolean) {
		this._setCaret(0); // Reset caret on config change
		const value = this._applyMaskAndConvertToControlValue(this.value?.toString() ?? '');

		emitOnChange && this._emitChange(value);
	}

	private _recalculateFirstLastMaskIndexes(value: string = '') {
		const renderedMask = (this._maskPipe.transform(value) || [])
			.filter(char => char !== this._maskPipe.caretTrap); // Remove caret traps for proper indexes calculation

		this._firstMaskCharIndex = renderedMask.findIndex(this._maskCharPredicate);

		this._lastMaskCharIndex = renderedMask.lastIndexOf(findLast(renderedMask, this._maskCharPredicate)!)
			+ (isEmpty(this._cleanValueFromMask(value)) && !this._activeConfig!.suffix ? 0 : 1);
	}

	private _formatDecimalValue(value: string): string {
		if (this._activeConfig instanceof NumberMaskConfig
			&& (this._activeConfig).decimalSeparatorRegExp.test(value)
		) {
			const { decimalMinimumLimit } = (<NumberMaskConfig> this.config);

			let fractionDigits = [ ...get(RegExp, '$\'') ];

			fractionDigits = fractionDigits
				.filter(v => (<NumberMaskPipe> this._maskPipe).digitRegExp.test(v));

			const fractionPart = this._activeConfig.decimalSeparatorSymbol + fractionDigits.join('');

			if (fractionDigits.every(v => v === '0'))
				return value.replace(fractionPart, '');

			if (fractionDigits.length < decimalMinimumLimit)
				return value.replace(fractionPart, fractionPart + repeat('0', decimalMinimumLimit - fractionDigits.length));
		}

		return value;
	}

	private _cleanValueFromMask(value: string = '') {
		return [
			...value
				.replace(this._activeConfig!.prefixRegExp ?? '', '')
				.replace(this._activeConfig!.suffixRegExp ?? '', ''),
		]
			.filter(char => !this._maskPipe.formatChars.includes(char))
			.join('');
	}

	private _setCaret(start: number, end = start) {
		if (IS_ANDROID)
			DEFER(() => void this._$input.setSelectionRange(start, end, 'none'));
		else
			this._$input.setSelectionRange(start, end, 'none');
	}

	private _convertMaskToPlaceholder() {
		const mask = this._maskPipe.transform('');

		if (!mask)
			return '';

		if (mask.includes(this._activeConfig!.placeholderChar)) {
			throw new Error(
				`Placeholder character must not be used as part of the mask. Please specify a character
				that is not present in your mask as your placeholder character.\n\n
				The placeholder character that was received is: ${ JSON.stringify(this._activeConfig!.placeholderChar) }\n\n
				The mask that was received is: ${ JSON.stringify(mask) }`,
			);
		}

		let { placeholderChar } = this._activeConfig!;

		if (this._activeConfig instanceof NumberMaskConfig && this._placeholderFromMask)
			placeholderChar = '0';

		return mask
			.map(char => char instanceof RegExp
				? placeholderChar
				: char)
			.join('');
	}

	private _tryActivatePlaceholder() {
		if ((this._activeConfig && this._$input.value === this._activeConfig.placeholder)
			|| (this._activeConfig instanceof NumberMaskConfig
				&& this._activeConfig.emptyIsZero
				&& Number(this._$input.value) === 0
			)
		)
			this._$input.value = '';
	}

	private _isCursorWithinPrefix() {
		return this._isInputSelectable
			&& this._firstMaskCharIndex > 0
			&& this._$input.selectionStart! > 1
			&& this._$input.selectionStart! < this._firstMaskCharIndex;
	}

	private _maskCharPredicate(this: void, char: any) {
		return char instanceof RegExp || isNull(char);
	}
}
