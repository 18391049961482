import { Observable, throwError, timer } from 'rxjs';
import { mergeMap, retryWhen } from 'rxjs/operators';

import { BpError, ResponseStatusCode } from '@bp/shared/models/core';

export const retryOn5XXOrUnknownErrorWithScalingDelay = ({
	maxRetryAttempts = 5,
	scalingDelayDuration = 1000,
}: {
	maxRetryAttempts?: number;
	scalingDelayDuration?: number;
} = {}) => <T>(source$: Observable<T>) => source$.pipe(
	retryWhen(attempts$ => attempts$.pipe(mergeMap((error: BpError, index) => {
		const retryAttempt = index + 1;
		const needRetry = [ ResponseStatusCode.InternalServerError, ResponseStatusCode.UnknownError ].includes(error.status!)
			&& retryAttempt <= maxRetryAttempts;

		return needRetry
			? timer(retryAttempt * scalingDelayDuration)
			: throwError(() => error);
	}))),
);
