import { PaymentOptionType } from '@bp/shared/models/business';
import type { ClassMetadata, DTO } from '@bp/shared/models/metadata';
import { isPresent } from '@bp/shared/utilities';

import { FirebasePspPaymentOptionApm } from './firebase-psp-payment-option-apm';
import { FirebasePspPaymentOptionCreditCard } from './firebase-psp-payment-option-credit-card';
import { FirebasePspPaymentOptionCrypto } from './firebase-psp-payment-option-crypto';
import { FirebasePspPaymentOptionVoucher } from './firebase-psp-payment-option-voucher';

export type FirebasePspPaymentOptions = FirebasePspPaymentOptionApm
| FirebasePspPaymentOptionCreditCard
| FirebasePspPaymentOptionCrypto
| FirebasePspPaymentOptionVoucher;

const firebasePspPaymentOptionsConstructors = [
	FirebasePspPaymentOptionCreditCard,
	FirebasePspPaymentOptionApm,
	FirebasePspPaymentOptionVoucher,
	FirebasePspPaymentOptionCrypto,
];

export function firebasePspPaymentOptionsFactory(dtos: DTO<FirebasePspPaymentOptions>[]) {
	return dtos
		.map(dto => dto.type && firebasePspPaymentOptionFactory(dto.type, <any> dto))
		.filter(isPresent);
}

export function firebasePspPaymentOptionFactory<T extends FirebasePspPaymentOptions = FirebasePspPaymentOptions>(
	type: PaymentOptionType | string,
	dto?: DTO<T>,
): T {
	const paymentOptionType = PaymentOptionType.parseStrict(type);

	const targetPaymentOptionConstructor = firebasePspPaymentOptionsConstructors.find(
		paymentOption => paymentOption.type === paymentOptionType,
	);

	if (targetPaymentOptionConstructor)
		return (<T> new targetPaymentOptionConstructor(<any> dto));

	throw new Error(`No appropriate constructor for ${ paymentOptionType }`);

}

export function getFirebasePspPaymentOptionClassMetadata(
	type: PaymentOptionType,
): ClassMetadata<FirebasePspPaymentOptions> {
	const targetPaymentOptionConstructor = firebasePspPaymentOptionsConstructors.find(
		paymentOption => paymentOption.type === type,
	);

	if (targetPaymentOptionConstructor)
		return targetPaymentOptionConstructor.getClassMetadata();

	throw new Error(`No appropriate class metadata for ${ type }`);
}
