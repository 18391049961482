import { camelCase, forOwn, isEmpty, toPath } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, combineLatest, EMPTY, lastValueFrom, merge, of, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, skip, startWith, switchMap } from 'rxjs/operators';

import {
	ChangeDetectorRef, Directive, HostBinding, Inject, InjectionToken, Input, isDevMode, Optional, Output, ViewChild
} from '@angular/core';
import {
	AbstractControl, AsyncValidatorFn, ValidatorFn, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, FormGroupDirective
} from '@angular/forms';

import { Destroyable, takeUntilDestroyed } from '@bp/shared/models/common';
import { IErrorMessage, BpError, OnChanges, SimpleChanges } from '@bp/shared/models/core';
import { filterPresent, takeFirstTruthy } from '@bp/shared/rxjs';
import { FormControls, FormGroupConfig, NonFunctionProperties, Typify } from '@bp/shared/typings';

type FormControlsValidators<T> = Partial<Typify<NonFunctionProperties<T>, ValidatorFn[]>>;

type FormControlsAsyncValidators<T> = Partial<Typify<NonFunctionProperties<T>, AsyncValidatorFn[]>>;

export type FormOptions = {
	updateOn?: 'blur' | 'change' | 'submit';
};

export const FORM_DEFAULT_OPTIONS = new InjectionToken<FormOptions>('form-default-options');

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class FormBaseComponent<TEntity = any, TFormControls = FormControls<TEntity>> extends Destroyable implements OnChanges {

	@ViewChild(FormGroupDirective)
	private readonly _formGroupDirective?: FormGroupDirective;

	@Input()
	get form(): UntypedFormGroup | null {
		return this._form$.value;
	}

	set form(value: UntypedFormGroup | null) {
		this._form$.next(value);
	}

	private readonly _form$ = new BehaviorSubject<UntypedFormGroup | null>(null);

	readonly form$ = this._form$.pipe(skip(1));

	get controls(): TFormControls | null {
		return <any> this.form!.controls ?? null;
	}

	@Input() controlsValidators?: FormControlsValidators<TEntity> | null;

	@Input() controlsAsyncValidators?: FormControlsAsyncValidators<TEntity> | null;

	@HostBinding('class.pending')
	@Input()
	get pending(): boolean | null {
		return this._externalPending$.value || !!this.form?.pending;
	}

	set pending(value: boolean | null) {
		this._externalPending$.next(!!value);
	}

	private readonly _externalPending$ = new BehaviorSubject(false);

	private readonly _formPending$ = this.form$
		.pipe(
			switchMap(form => form
				? form.statusChanges
					.pipe(
						startWith(form.status),
						map(status => status === 'PENDING'),
					)
				: of(false)),
			distinctUntilChanged(),
			shareReplay({ refCount: false, bufferSize: 1 }),
		);

	pending$ = combineLatest(
		this._externalPending$,
		this._formPending$,
	)
		.pipe(
			map(([ externalPending, formPending ]) => externalPending || formPending),
		);

	@Input()
	get error(): BpError | null {
		return this._error;
	}

	set error(value: BpError | null) {
		this._error = value;

		if (!value) {
			this.errors = null;

			return;
		}

		void this._setGlobalAndControlErrors(value.messages);
	}

	private _error!: BpError | null;

	errors!: IErrorMessage[] | null;

	private readonly _submittedValidFormValue$ = new Subject<TEntity>();

	@Output('submitted')
	readonly submittedValidFormValue$ = this._submittedValidFormValue$.asObservable();

	private readonly _formEnabled$ = new BehaviorSubject(true);

	@Output('formEnabled')
	readonly formEnabled$ = this._formEnabled$.asObservable();

	private readonly _formDirtyAndValid$ = new BehaviorSubject(false);

	@Output('formDirtyAndValid')
	readonly formDirtyAndValid$ = this._formDirtyAndValid$.asObservable();

	private readonly _formInvalid$ = new BehaviorSubject(false);

	@Output('formInvalid')
	readonly formInvalid$ = this._formInvalid$.asObservable();

	readonly formValid$ = this._formInvalid$.pipe(map(v => !v));

	private readonly _formDirty$ = new BehaviorSubject(false);

	@Output('formDirty')
	readonly formDirty$ = this._formDirty$.asObservable();

	readonly formPristine$ = this._formDirty$.pipe(map(v => !v));

	readonly formDirtyAndPending$ = combineLatest([
		this.formDirty$,
		this.pending$,
	]);

	@Output('canSave')
	readonly canSave$ = combineLatest([
		this.formDirtyAndValid$,
		this.pending$,
	])
		.pipe(map(([ dirtyAndValid, pending ]) => dirtyAndValid && !pending));

	readonly canNotSave$ = this.canSave$
		.pipe(map(canSave => !canSave));

	private _canSave = false;

	get canSave(): boolean {
		return this._canSave;
	}

	@Output('canCreate')
	readonly canCreate$ = combineLatest([
		this.formValid$,
		this.pending$,
	])
		.pipe(map(([ valid, pending ]) => valid && !pending));

	private _canCreate = false;

	get canCreate(): boolean {
		return this._canCreate;
	}

	onSubmitShowInvalidInputsToast = true;

	submitIfPristineAndValid = false;

	omitFromGlobalErrorsNotFoundControls = false;

	private _updateFormControlsValidatorsSubscription = Subscription.EMPTY;

	private _updateFormControlsAsyncValidatorsSubscription = Subscription.EMPTY;

	constructor(
		protected _formBuilder: UntypedFormBuilder,
		protected _cdr: ChangeDetectorRef,
		protected _toaster: ToastrService,
		@Optional() @Inject(FORM_DEFAULT_OPTIONS) protected _formDefaultOptions?: FormOptions,
	) {
		super();

		this._disableFormOnExternalPending();

		this._observeFormEnabled();

		this._observeFormInvalid();

		this._observeFormDirty();

		this._observeFormDirtyAndValid();

		this._updateCanCreateOnStreamChange();

		this._updateCanSaveOnStreamChange();

		this._resetGlobalErrorsOnPending();
	}

	ngOnChanges(changes: SimpleChanges<this>): void {
		if (changes.controlsValidators) {
			this._updateFormControlsValidators(
				changes.controlsValidators.previousValue,
				changes.controlsValidators.currentValue,
			);
		}

		if (changes.controlsAsyncValidators) {
			this._updateFormControlsAsyncValidators(
				changes.controlsAsyncValidators.previousValue,
				changes.controlsAsyncValidators.currentValue,
			);
		}

	}

	submit(): void {
		isDevMode() && console.warn('submit', this.form);

		const formDirtyStateOnSubmit = this.form!.dirty;

		this._revalidateAndMarkAsDirtyAndMarkAsTouchedRecursivelyToShowAllControlErrors(this._getRootForm(this.form!));

		if (this.form!.valid) {
			if (this.submitIfPristineAndValid || formDirtyStateOnSubmit)
				this._submittedValidFormValue$.next(this._getSubmittedValidFormValue());
			else
				this._toaster.info('Nothing to save');
		} else if (this.onSubmitShowInvalidInputsToast && this.form!.invalid)
			this._toaster.error('Some inputs are invalid!');

		this._cdr.detectChanges();

		this._cdr.markForCheck();
	}

	markFormAsPristineAndUntouched(): void {
		this.markAsPristine();

		this.form!.markAsUntouched();

		this.form!.updateValueAndValidity(); // To invoke changes
	}

	resetForm(): void {
		this._formGroupDirective?.resetForm();
	}

	markAsDirty(): void {
		this.form!.markAsDirty();

		this.form?.updateValueAndValidity();// To invoke changes

		this._formDirty$.next(true);
	}

	markAsPristine(): void {
		this.form!.markAsPristine();

		this.form?.updateValueAndValidity();// To invoke changes

		this._formDirty$.next(false);
	}

	protected _getSubmittedValidFormValue(): TEntity {
		return this.form!.value;
	}

	protected _createFormGroup<U = TEntity>(config: FormGroupConfig<U>, options?: FormOptions): UntypedFormGroup {
		return this._formBuilder.group(config, {
			...this._formDefaultOptions,
			...options,
		});
	}

	private _getRootForm(form: UntypedFormArray | UntypedFormGroup): UntypedFormArray | UntypedFormGroup {
		let rootForm = form;

		while (rootForm.parent)
			rootForm = rootForm.parent;

		return rootForm;
	}

	private _revalidateAndMarkAsDirtyAndMarkAsTouchedRecursivelyToShowAllControlErrors(
		control: AbstractControl,
	): void {
		control.markAsTouched({ onlySelf: true });

		control.markAsDirty({ onlySelf: true });

		// Order is important, because some observables (e.g. formDirty$) relies on status change to update
		// its status. So it always must be the last one in state change statements list.
		// Also controls error displaying relies on their state, so validity should be updated after.
		control.updateValueAndValidity({ onlySelf: true });

		if (control instanceof UntypedFormGroup) {
			forOwn(
				control.controls,
				cntrl => void this._revalidateAndMarkAsDirtyAndMarkAsTouchedRecursivelyToShowAllControlErrors(cntrl),
			);
		} else if (control instanceof UntypedFormArray) {
			control.controls.forEach(
				cntrl => void this._revalidateAndMarkAsDirtyAndMarkAsTouchedRecursivelyToShowAllControlErrors(cntrl),
			);
		}
	}

	private _disableFormOnExternalPending(): void {
		merge(
			this._externalPending$,
			this.form$,
		)
			.pipe(takeUntilDestroyed(this))
			.subscribe(() => {
				if (!this.form)
					return;

				if (this.pending)
					this.form.disable({ emitEvent: false });
				else
					this.form.enable({ emitEvent: false });
			});
	}

	private _resetGlobalErrorsOnPending(): void {
		this.pending$
			.pipe(
				filter(pending => !!pending),
				takeUntilDestroyed(this),
			)
			.subscribe(() => (this.errors = null));
	}

	private _observeFormEnabled(): void {
		this.form$
			.pipe(
				switchMap(form => form
					? merge(form.statusChanges, this.pending$)
						.pipe(
							startWith(null),
							map(() => form.enabled),
						)
					: of(false)),
				distinctUntilChanged(),
				takeUntilDestroyed(this),
			)
			.subscribe(this._formEnabled$);
	}

	private _observeFormInvalid(): void {
		this.form$
			.pipe(
				switchMap(form => form
					? combineLatest(form.statusChanges, this.pending$)
						.pipe(
							startWith([ form.status ]),
							filter(() => !this.pending),
							map(([ status ]) => status === 'INVALID' || isEmpty(form.value)),
						)
					: of(false)),
				distinctUntilChanged(),
				takeUntilDestroyed(this),
			)
			.subscribe(this._formInvalid$);
	}

	private _observeFormDirty(): void {
		this.form$
			.pipe(
				switchMap(form => form
					? merge(form.statusChanges, this.pending$)
						.pipe(
							startWith(null),
							map(() => !!form.dirty),
						)
					: EMPTY),
				distinctUntilChanged(),
				takeUntilDestroyed(this),
			)
			.subscribe(this._formDirty$);
	}

	private _observeFormDirtyAndValid(): void {
		combineLatest([
			this.formValid$,
			this.formDirty$,
		])
			.pipe(
				map(([ valid, dirty ]) => valid && dirty),
				takeUntilDestroyed(this),
			)
			.subscribe(this._formDirtyAndValid$);
	}

	private async _setGlobalAndControlErrors(errors?: IErrorMessage[]): Promise<void> {
		await lastValueFrom(this.formEnabled$.pipe(takeFirstTruthy));

		if (errors && this.form) {
			const { errorAndControlPairs, errorsWithoutControls } = this._partitionErrorsWithControlsAndWithout(errors);

			errorAndControlPairs
				.forEach(([ error, control ]) => void control.setErrors({ server: error.message }));

			this.errors = [
				...errors.filter(it => !it.field),
				...this.omitFromGlobalErrorsNotFoundControls
					? []
					: errorsWithoutControls,
			];
		}

		if (isEmpty(errors))
			this._error = this.errors = null;

		this._cdr.detectChanges();
	}

	private _partitionErrorsWithControlsAndWithout(errors: IErrorMessage[]): {
		errorAndControlPairs: [IErrorMessage, AbstractControl][];
		errorsWithoutControls: IErrorMessage[];
	} {
		const errorAndControlPair = errors
			.filter(responseError => !!responseError.field)
			.map(responseError => <const>[
				responseError,
				this.form!.get(this._buildFormControlPath(responseError.field!)),
			]);

		return {
			errorAndControlPairs: errorAndControlPair
				.filter((pair): pair is [IErrorMessage, AbstractControl] => !!pair[1]),
			errorsWithoutControls: errorAndControlPair
				.filter(([ , control ]) => !control)
				.map(([ error ]) => error),
		};
	}

	private _buildFormControlPath(errorField: string): (number | string)[] {
		return toPath(errorField).map(camelCase);
	}

	private _updateCanCreateOnStreamChange(): void {
		this.canCreate$
			.pipe(takeUntilDestroyed(this))
			.subscribe(canCreate => (this._canCreate = canCreate));
	}

	private _updateCanSaveOnStreamChange(): void {
		this.canSave$
			.pipe(takeUntilDestroyed(this))
			.subscribe(canSave => (this._canSave = canSave));
	}

	private _updateFormControlsValidators(
		previousValidators?: FormControlsValidators<TEntity> | null,
		newValidators?: FormControlsValidators<TEntity> | null,
	): void {
		this._updateFormControlsValidatorsSubscription.unsubscribe();

		this._updateFormControlsValidatorsSubscription = this._form$
			.pipe(
				filterPresent,
				takeUntilDestroyed(this),
			)
			.subscribe(form => {
				let shouldUpdate = false;

				for (const [ controlName, validators ] of Object.entries(previousValidators ?? {})) {
					shouldUpdate = shouldUpdate || (<ValidatorFn[]>validators)
						.some(validator => form.get(controlName)?.hasValidator(validator));

					form.get(controlName)?.removeValidators(<ValidatorFn[]>validators);
				}

				for (const [ controlName, validators ] of Object.entries(newValidators ?? {})) {
					shouldUpdate = shouldUpdate || (<ValidatorFn[]>validators)
						.some(validator => !form.get(controlName)?.hasValidator(validator));

					form.get(controlName)?.addValidators(<ValidatorFn[]>validators);
				}

				shouldUpdate && form.updateValueAndValidity();
			});
	}

	private _updateFormControlsAsyncValidators(
		previousAsyncValidators?: FormControlsAsyncValidators<TEntity> | null,
		newAsyncValidators?: FormControlsAsyncValidators<TEntity> | null,
	): void {
		this._updateFormControlsAsyncValidatorsSubscription.unsubscribe();

		this._updateFormControlsAsyncValidatorsSubscription = this._form$
			.pipe(
				filterPresent,
				takeUntilDestroyed(this),
			)
			.subscribe(form => {
				let shouldUpdate = false;

				for (const [ controlName, validators ] of Object.entries(previousAsyncValidators ?? {})) {
					shouldUpdate = shouldUpdate || (<AsyncValidatorFn[]>validators)
						.some(validator => form.get(controlName)?.hasAsyncValidator(validator));

					form.get(controlName)?.removeAsyncValidators(<AsyncValidatorFn[]>validators);
				}

				for (const [ controlName, validators ] of Object.entries(newAsyncValidators ?? {})) {
					shouldUpdate = shouldUpdate || (<AsyncValidatorFn[]>validators)
						.some(validator => !form.get(controlName)?.hasAsyncValidator(validator));

					form.get(controlName)?.addAsyncValidators(<AsyncValidatorFn[]>validators);
				}

				shouldUpdate && form.updateValueAndValidity();
			});
	}

}
