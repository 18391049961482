import type m from 'moment';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

import { momentMapper } from '@bp/shared/models/metadata';

import { MomentBasePipe } from './moment-base.pipe';

@Pipe({
	name: 'momentFormat',
})
export class MomentFormatPipe extends MomentBasePipe implements PipeTransform {
	transform(momentInput: m.MomentInput, format: string): Observable<string> {
		return this._momentTimezoneService.timezone$.pipe(
			map(timezone => momentInput
				? momentMapper(momentInput)
					.tz(timezone.name)
					.format(format)
				: ''),
		);
	}
}
