import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedComponentsCoreModule } from '@bp/shared/components/core';
import { SharedDirectivesModule } from '@bp/shared/directives';
import { SharedFeaturesLottiePlayerModule } from '@bp/shared/features/lottie-player';

import { BoxedItemsComponent } from './boxed-items';
import { CashierComponent } from './cashier';
import { PaymentCardBrandsComponent } from './payment-card-brands';
import { SplashScreenComponent } from './splash-screen';

const EXPOSED_COMPONENTS = [
	CashierComponent,
	SplashScreenComponent,
	PaymentCardBrandsComponent,
	BoxedItemsComponent,
];

@NgModule({
	imports: [
		CommonModule,

		SharedFeaturesLottiePlayerModule,
		SharedComponentsCoreModule,
		SharedDirectivesModule,
	],
	declarations: EXPOSED_COMPONENTS,
	exports: EXPOSED_COMPONENTS,
})
export class AdminsSharedCoreComponentsModule { }
