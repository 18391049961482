import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgPaymentCardBrandsIconsDefinitionsComponent } from './svg-payment-card-brands-icons-definitions.component';

const EXPOSED = [
	SvgPaymentCardBrandsIconsDefinitionsComponent,
];

@NgModule({
	imports: [ CommonModule ],
	declarations: EXPOSED,
	exports: EXPOSED,
})
export class SharedFeaturesSvgPaymentCardBrandsIconsModule { }
