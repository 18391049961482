import { isNil } from 'lodash-es';

import type { OnChanges, SimpleChanges } from '@angular/core';
import { Directive, Input, Self } from '@angular/core';

import type { RecordsPage } from '@bp/shared/models/common';

import { PaginatorComponent } from './paginator.component';

@Directive({
	selector: 'bp-paginator[recordsPage]',
})
export class CursorPageAdaptorDirective implements OnChanges {

	@Input('recordsPage') recordsPage!: RecordsPage | null;

	pageCursors: Record<number, string | null> = { 1: '' }; // The first page doesn't have cursor

	constructor(@Self() private _paginator: PaginatorComponent) {
		this._paginator.hasBack = () => this._hasCursor(this._paginator.getBackPage());

		this._paginator.hasNext = () => this._hasCursor(this._paginator.getNextPage());

		this._paginator.onBack = () => {
			const backPage = this._paginator.getBackPage();

			this._paginator.page = this.pageCursors[backPage]!;

			this._paginator.progressBack = true;

			this._paginator.currentPage = backPage;
		};

		this._paginator.onNext = () => {
			const nextPage = this._paginator.getNextPage();

			this._paginator.page = this.pageCursors[nextPage]!;

			this._paginator.progressNext = true;

			this._paginator.currentPage = nextPage;
		};
	}

	ngOnChanges({ recordsPage }: Partial<SimpleChanges>): void {
		if (recordsPage && this.recordsPage) {
			if (this.recordsPage.firstPage)
				this._paginator.currentPage = 1;

			this.pageCursors[this._paginator.getNextPage()] = this.recordsPage.nextPageCursor;

			this._paginator.progressBack = this._paginator.progressNext = false;

			this._paginator.cdr.detectChanges();
		}
	}

	private _hasCursor(page: number): boolean {
		return !isNil(this.pageCursors[page]);
	}
}
