import { Observable, EMPTY, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BpError, ResponseStatusCode } from '@bp/shared/models/core';
import {
	CloudflareAccessService, CLOUDFLARE_ACCESS_CHECK_PATHNAME, EnvironmentService
} from '@bp/shared/services';

import { IdentityFacade } from '../state';

@Injectable()
export class HttpResponseAccessGuardInterceptor implements HttpInterceptor {

	constructor(
		private readonly _identityFacade: IdentityFacade,
		private readonly _cfAccess: CloudflareAccessService,
		private readonly _environment: EnvironmentService,
		private readonly _toaster: ToastrService,
	) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
		return next
			.handle(request)
			.pipe(
				catchError((error: unknown) => {
					if (error instanceof BpError || error instanceof HttpErrorResponse) {
						this._checkCloudflareAuthorizationOn5XXandGotoCFLogin(error);

						if (this._checkIfUserUnauthorizedOnRemote(error)) {
							this._identityFacade.logout();

							this._toaster.info(
								'Your session has expired',
								undefined,
								{
									timeOut: 5000,
									progressBar: false,
								},
							);

							return EMPTY;
						}
					}

					// eslint-disable-next-line rxjs/throw-error
					return throwError(() => error);
				}),
			);
	}

	private _checkIfUserUnauthorizedOnRemote(error: BpError | HttpErrorResponse): boolean {
		return !!this._getRequestUrl(error)?.includes(this._environment.hostDomain)
			&& error.status === ResponseStatusCode.Unauthorized
			&& this._environment.isRemoteServer;
	}

	private _checkCloudflareAuthorizationOn5XXandGotoCFLogin(responseError: BpError | HttpErrorResponse): void {
		if ((responseError.status! >= ResponseStatusCode.InternalServerError || responseError.status === 0)
			&& this._environment.isRemoteServer
			&& !this._getRequestUrl(responseError)?.includes(CLOUDFLARE_ACCESS_CHECK_PATHNAME))
			void this._cfAccess.checkAccessAndTryRedirectToCFLogin();
	}

	private _getRequestUrl(error: BpError | HttpErrorResponse): string | null | undefined {
		return error instanceof BpError ? error.requestUrl : error.url;
	}

}
