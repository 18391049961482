import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import type { PaymentCardBrand } from '@bp/shared/models/business';

@Component({
	selector: 'bp-payment-card-brands',
	templateUrl: './payment-card-brands.component.html',
	styleUrls: [ './payment-card-brands.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentCardBrandsComponent {

	@Input() brands?: PaymentCardBrand[];

}
