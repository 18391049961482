import { firstValueFrom } from 'rxjs';

import { HttpClientModule } from '@angular/common/http';
import { ApplicationRef, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';

import { EffectsModule } from '@ngrx/effects';
import { NavigationActionTiming, RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';

import { SharedComponentsCoreModule } from '@bp/shared/components/core';
import { environment } from '@bp/shared/environments';
import { SharedFeaturesValidationModule } from '@bp/shared/features/validation';
import { SharedPipesModule } from '@bp/shared/pipes';
import { FirebaseAppConfig, PLATFORM, FIREBASE_APP_CONFIG, SharedServicesModule, TelemetryService } from '@bp/shared/services';
import { LOCAL_SERVER_META_REDUCERS_PROVIDERS, REMOTE_SERVER_META_REDUCERS_PROVIDERS, sharedReducer } from '@bp/shared/state';
import { ensureType } from '@bp/shared/utilities';
import { Platform } from '@bp/shared/typings';

import { AdminsSharedBridgerPspsRootModule } from '@bp/admins-shared/domains/bridger-psps';
import { AdminsSharedDomainsIdentityRootModule } from '@bp/admins-shared/domains/identity';
import { AdminsSharedFeaturesLayoutRootModule } from '@bp/admins-shared/features/layout';
import { AdminsSharedFeaturesNotificationsHubRootModule } from '@bp/admins-shared/features/notifications-hub';
import { AdminsSharedPagesErrorsModule } from '@bp/admins-shared/pages/errors';
import { AdminsSharedPagesIntroRootModule } from '@bp/admins-shared/pages/intro';
import { CONTROL_DEFAULT_OPTIONS_PROVIDERS } from '@bp/admins-shared/core/models';

import { AppRoutingModule } from './app-routing.module';
import { AppStartupService } from './app-startup.service';
import { CoreModule, RootComponent } from './core';

TelemetryService.log('App module execution begun');

@NgModule({
	declarations: [],
	imports: [
		HttpClientModule,
		BrowserAnimationsModule,
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.isRemoteServer,
			registrationStrategy: 'registerWhenStable:5000',
		}),

		// Redux related
		StoreModule.forRoot(sharedReducer, {
			runtimeChecks: {
				strictActionImmutability: false,
				// StrictActionSerializability: false,
				strictStateImmutability: false,

				/*
				 * StrictStateSerializability: false
				 * We dont use the built-in immutability check because
				 * it freezes the whole moment structure
				 * so we utilize custom immutabilityCheckMetaReducer
				 */
			},
		}),
		StoreRouterConnectingModule.forRoot({
			routerState: RouterState.Minimal,
			navigationActionTiming: NavigationActionTiming.PostActivation,
		}),

		EffectsModule.forRoot([]),

		SharedPipesModule.forRoot(),
		SharedServicesModule.forRoot(),
		SharedComponentsCoreModule.forRoot(),
		SharedFeaturesValidationModule.forRoot(),
		AdminsSharedDomainsIdentityRootModule,
		AdminsSharedBridgerPspsRootModule,
		AdminsSharedFeaturesLayoutRootModule,
		AdminsSharedFeaturesNotificationsHubRootModule,
		AdminsSharedPagesIntroRootModule,

		AppRoutingModule,
		CoreModule,
		AdminsSharedPagesErrorsModule, // Should be the last module with routes to properly catch all notfound routes
	],
	providers: [
		environment.isRemoteServer ? REMOTE_SERVER_META_REDUCERS_PROVIDERS : LOCAL_SERVER_META_REDUCERS_PROVIDERS,
		CONTROL_DEFAULT_OPTIONS_PROVIDERS,
		{
			provide: FIREBASE_APP_CONFIG,
			useValue: <FirebaseAppConfig> {
				appId: '1:977741303368:web:8ac31f76595976fa00927d',
			},
		},
		{
			provide: PLATFORM,
			useValue: ensureType<Platform>('bridger-admin'),
		},
	],
	bootstrap: [ RootComponent ],
})
export class AppModule {

	constructor(
		private readonly _appStartupService: AppStartupService,
		private readonly _app: ApplicationRef,
	) {
		void this._whenAppIsStableInitStartupLogic();
	}

	private async _whenAppIsStableInitStartupLogic(): Promise<void> {
		await firstValueFrom(this._app.isStable);

		this._appStartupService.init();
	}

}
