import m from 'moment';

import { DateRange } from './date-range';
import { DescribableEnumeration } from './enums';

export class DateRangeShortcut extends DescribableEnumeration {

	static today = new DateRangeShortcut();

	static last7days = new DateRangeShortcut();

	static week = new DateRangeShortcut();

	static currentMonth = new DateRangeShortcut();

	static month = new DateRangeShortcut();

	static quarter = new DateRangeShortcut();

	static year = new DateRangeShortcut();

	static currentRangeShortcuts = [
		DateRangeShortcut.week,
		DateRangeShortcut.month,
		DateRangeShortcut.quarter,
		DateRangeShortcut.year,
	];

	dateRange!: DateRange;

	constructor() {
		super();

		void Promise
			.resolve()
			.then(() => {
				this.dateRange = this._getDateRange()!;

				if (DateRangeShortcut.currentRangeShortcuts.includes(this))
					// @ts-expect-error should be readonly
					this.description = `From the beginning of the current ${ this.displayName.toLowerCase() } to this day`;

				setInterval(() => (this.dateRange = this._getDateRange()), 24 * 60 * 60 * 1000);
			});
	}

	private _getDateRange() {
		const format = 'D MMM YYYY';
		const to = m().endOf('day');

		// eslint-disable-next-line default-case
		switch (this) {
			case DateRangeShortcut.today:
				return new DateRange(
					{
						to,
						from: m().startOf('day'),
					},
					format,
				);

			case DateRangeShortcut.last7days:
				return new DateRange(
					{
						to,
						from: m().subtract(6, 'days'),
					},
					format,
				);

			case DateRangeShortcut.week:
				return new DateRange(
					{
						to,
						from: m().startOf('week'),
					},
					format,
				);

			case DateRangeShortcut.currentMonth:

			case DateRangeShortcut.month:
				return new DateRange(
					{
						to,
						from: m().startOf('month'),
					},
					format,
				);

			case DateRangeShortcut.quarter:
				return new DateRange(
					{
						to,
						from: m().startOf('quarter'),
					},
					format,
				);

			case DateRangeShortcut.year:
				return new DateRange(
					{
						to,
						from: m().startOf('year'),
					},
					format,
				);
		}

		throw new Error('DateRange for the DateRangeShortcut is not implemented');
	}
}
