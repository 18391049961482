import { ɵɵLinkDirective } from 'ngx-quicklink';

import { NgModule } from '@angular/core';
import { MAT_SORT_DEFAULT_OPTIONS } from '@angular/material/sort';

import { DelayedRenderStructuralDirective } from './delayed-render.structural.directive';
import { DisabledDirective } from './disabled.directive';
import { DynamicOutletDirective } from './dynamic-outlet.directive';
import { ElementRefDirective } from './element-ref.directive';
import { FeatureUnderDevelopmentStructuralDirective } from './feature-under-development.structural.directive';
import { HoverDirective } from './hover.directive';
import { RenderWhenIdleStructuralDirective } from './render-when-idle.structural.directive';
import {
	BpQuicklinkDirective, OutletLinkRelativeToTargetDirective, OutletLinkRelativeToTargetWithHrefDirective,
	RouterLinkNoOutletsWithHrefDirective
} from './router-links';
import { SortDirective } from './sort.directive';
import { TargetBlankDirective } from './target-blank.directive';
import { RouterLinkActiveOnIdInUrlDirective } from './router-link-active-on-id-in-url.directive';
import { TrimOnPasteDirective } from './trim-on-paste.directive';
import { IfEmptyNgForDirective } from './if-empty-ng-for.directive';

const EXPOSED_DIRECTIVES = [
	IfEmptyNgForDirective,
	RouterLinkNoOutletsWithHrefDirective,
	OutletLinkRelativeToTargetDirective,
	OutletLinkRelativeToTargetWithHrefDirective,
	TargetBlankDirective,
	SortDirective,
	DelayedRenderStructuralDirective,
	DynamicOutletDirective,
	DisabledDirective,
	HoverDirective,
	FeatureUnderDevelopmentStructuralDirective,
	RenderWhenIdleStructuralDirective,
	ElementRefDirective,
	BpQuicklinkDirective,
	TrimOnPasteDirective,
	RouterLinkActiveOnIdInUrlDirective,
];

@NgModule({
	exports: EXPOSED_DIRECTIVES,
	declarations: EXPOSED_DIRECTIVES,
	providers: [
		{ provide: MAT_SORT_DEFAULT_OPTIONS, useValue: { disableClear: true } },
		{ provide: ɵɵLinkDirective, useClass: BpQuicklinkDirective },
	],
})
export class SharedDirectivesModule { }
