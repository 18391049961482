import { PspPaymentOptionType } from '@bp/shared/models/business';
import { Currency } from '@bp/shared/models/currencies';
import type { DTO } from '@bp/shared/models/metadata';
import { Control, FieldControlType, Mapper, Required } from '@bp/shared/models/metadata';

import { FirebasePspPaymentOptionBase } from './firebase-psp-payment-option-base';

export class FirebasePspPaymentOptionVoucher extends FirebasePspPaymentOptionBase {

	static override readonly type = PspPaymentOptionType.voucher;

	readonly type = FirebasePspPaymentOptionVoucher.type;

	@Control(
		FieldControlType.chip,
		{
			list: Currency.list,
			placeholder: 'Add currency...',
		},
	)
	@Mapper(Currency)
	@Required()
	currencies!: Currency[];

	constructor(dto: DTO<FirebasePspPaymentOptionVoucher>) {
		super(dto);
	}
}
