
import type { OnInit } from '@angular/core';
import { Directive, Input } from '@angular/core';

import type { ClassMetadata } from '@bp/shared/models/metadata';
import { SortDirective } from '@bp/shared/directives';

@Directive({
	selector: '[bpClassMetadataSort][matSort]',
})
export class ClassMetadataSortDirective extends SortDirective implements OnInit {

	@Input('bpClassMetadataSort') classMetadata!: ClassMetadata<any>;

	ngOnInit(): void {
		this._trySetDefaultSortFieldFromClassMetadata();
	}

	private _trySetDefaultSortFieldFromClassMetadata(): void {
		if (!this.classMetadata.defaultSortProperty)
			return;

		const { property, table } = this.classMetadata.defaultSortProperty;

		this._matSort.active = property;

		this._matSort.direction = table!.defaultSortDir;

		this._matSort.start = table!.defaultSortDir === '' ? 'desc' : table!.defaultSortDir;
	}

}
