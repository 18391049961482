<div
	class="downloading"
	[class.downloading]="isDownloading$ | async"
	[style.width.px]="size"
	[style.height.px]="size"
	[style.border-radius.px]="size"
>
	<div *ngIf="hasPlaceholder && (loadingFailed$ | async) === false" class="placeholder">
		<bp-svg-icon name="image"></bp-svg-icon>
		<ng-content></ng-content>
	</div>

	<div *ngIf="(loadingFailed$ | async) === true" class="loading-failed">
		<ng-content
			*ngIf="imgErrorDirective; else defaultImgErrorTpl"
			select="bp-img-error, [bpImageError]"
		></ng-content>
		<ng-template #defaultImgErrorTpl>
			<bp-svg-icon name="broken-image"></bp-svg-icon>
		</ng-template>
	</div>

	<img *ngIf="src$ | async as src" [src]="src" />
</div>
