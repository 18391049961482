// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { environment } from 'libs/shared/environments/src/environment';

import { ConsoleLoggingState, LocalBackendState, EnvironmentService, TelemetryService, MockedBackendState } from '@bp/shared/services';

/**
 * Logic that should be kicked in before execution of any app module file
 */
EnvironmentService.init(environment);

if (environment.name === 'local' || MockedBackendState.isActive || LocalBackendState.isActive)
	ConsoleLoggingState.set(true);

if (MockedBackendState.isActive)
	LocalBackendState.set(false);

if (LocalBackendState.isActive)
	MockedBackendState.set(false);

TelemetryService.init(environment);

TelemetryService.log('Environment has been setup');
