<bp-input
	class="w-100"
	[nativeAutocomplete]="false"
	[label]="label ?? '6-digit code'"
	[hint]="hint"
	[color]="color"
	[formControl]="internalControl"
	[appearance]="appearance"
	[placeholder]="placeholder"
	[floatLabel]="floatLabel"
	[mask]="mask"
	[required]="!!required"
>
</bp-input>
