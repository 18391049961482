<bp-notification-hub-notification-item
	*ngFor="let notification of notificationsHubFacade.notifications$ | async; trackBy: trackById"
	@hubItemFlyInOut
	(@hubItemFlyInOut.start)="showNoNotificationsMsg$.next(false)"
	(@hubItemFlyInOut.done)="showNoNotificationsMsg$.next(!notificationsHubFacade.notifications.length)"
	[notification]="notification"
>
</bp-notification-hub-notification-item>

<h1 *ngIf="showNoNotificationsMsg$ | async" @fadeIn class="text-center">No notifications</h1>
