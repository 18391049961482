import { BehaviorSubject, Observable } from 'rxjs';

import { ChangeDetectorRef, EnvironmentInjector, OnDestroy, OnInit, Directive, Input, ViewContainerRef } from '@angular/core';
import { ChildrenOutletContexts, Data, Params, RouterOutlet } from '@angular/router';

import { Destroyable, takeUntilDestroyed } from '@bp/shared/models/common';

@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: 'bp-dynamic-outlet',
	exportAs: 'outlet',
})
export class DynamicOutletDirective extends Destroyable implements OnInit, OnDestroy {

	@Input() name!: string;

	ngOutlet?: RouterOutlet;

	private readonly _activatedComponent$ = new BehaviorSubject<Object | null>(null);

	/**
	 * Observable of the instance of the activated component or null if the outlet is not activated.
	 */
	readonly activatedComponent$ = this._activatedComponent$.asObservable();

	get isActivated(): boolean {
		return !!this.ngOutlet?.isActivated;
	}

	get component(): Object | null {
		return this.ngOutlet?.isActivated
			? this.ngOutlet.component
			: null;
	}

	get activatedRouteData(): Data | undefined {
		return this.ngOutlet?.activatedRouteData;
	}

	get activatedRouteParams$(): Observable<Params> | undefined {
		return this.ngOutlet?.activatedRoute.params;
	}

	constructor(
		private readonly _parentContexts: ChildrenOutletContexts,
		private readonly _viewContainerRef: ViewContainerRef,
		private readonly _environmentInjector: EnvironmentInjector,
		private readonly _changeDetector: ChangeDetectorRef,
	) {
		super();
	}

	ngOnInit() {
		this.ngOutlet = new RouterOutlet(
			this._parentContexts,
			this._viewContainerRef,
			this.name,
			this._changeDetector,
			this._environmentInjector,
		);

		this._listenToOutletActivatedComponentChange();

		// eslint-disable-next-line @angular-eslint/no-lifecycle-call
		this.ngOutlet.ngOnInit();
	}

	private _listenToOutletActivatedComponentChange(): void {
		this.ngOutlet!.activateEvents
			.pipe(takeUntilDestroyed(this))
			.subscribe(activatedComponent => void this._activatedComponent$.next(activatedComponent));
	}

	override ngOnDestroy(): void {
		super.ngOnDestroy();

		// eslint-disable-next-line @angular-eslint/no-lifecycle-call
		this.ngOutlet?.ngOnDestroy();
	}
}
