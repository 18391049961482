import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CanEditGuardDirective, HasAccessStructuralDirective } from './directives';

const EXPOSED = [ CanEditGuardDirective, HasAccessStructuralDirective ];

@NgModule({
	imports: [ CommonModule ],
	exports: [ EXPOSED ],
	declarations: [ EXPOSED ],
})
export class SharedDomainsIdentityModule {}
