import { identity } from 'lodash-es';
import type { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import type { QueryList } from '@angular/core';

export function listenToQueryListChanges<T, U = T>(
	source: QueryList<T>,
	mapper: (source: T) => U = identity,
): Observable<U[]> {
	return source.changes
		.pipe(
			startWith<QueryList<T>>(source),
			map(queryList => queryList
				.toArray()
				.map(element => mapper(element))),
		);
}
