<mat-icon *ngIf="status === 'warning'" class="material-icons-round bp-icon-md">warning</mat-icon>

<bp-img
	class="img-white-background-eraser"
	thumbnail
	[bpTooltip]="showTooltip ? (name$ | async)! : null"
	[size]="size"
	[url]="pspImgUrl$ | async"
>
</bp-img>
