import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';

import { SharedComponentsControlsModule } from '@bp/shared/components/controls';
import { SharedComponentsCoreModule } from '@bp/shared/components/core';
import { SharedDirectivesModule } from '@bp/shared/directives';
import { SharedFeaturesTooltipModule } from '@bp/shared/features/tooltip';
import { SharedPipesModule } from '@bp/shared/pipes';
import { SharedFeaturesValidationModule } from '@bp/shared/features/validation';

import {
	PropertyMetadataControlComponent, PropertyMetadataControlContextDirective, PropertyMetadataControlCustomDirective
} from './property-metadata-control';
import { PropertyMetadataControlsSectionComponent } from './property-metadata-controls-section';
import { PropertyMetadataCustomValueViewDirective, PropertyMetadataViewComponent } from './property-metadata-view';
import { PropertyMetadataViewsSectionComponent } from './property-metadata-views-section';
import { ClassMetadataSortDirective } from './directives';

const EXPOSED_COMPONENTS = [
	PropertyMetadataControlComponent,
	PropertyMetadataControlsSectionComponent,
	PropertyMetadataControlContextDirective,
	PropertyMetadataControlCustomDirective,
	PropertyMetadataViewComponent,
	PropertyMetadataViewsSectionComponent,
	PropertyMetadataCustomValueViewDirective,
	ClassMetadataSortDirective,
];

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		MatSlideToggleModule,
		MatIconModule,
		MatChipsModule,
		MatCheckboxModule,
		MatFormFieldModule,
		PortalModule,

		SharedPipesModule,
		SharedComponentsCoreModule,
		SharedComponentsControlsModule,
		SharedDirectivesModule,
		SharedFeaturesTooltipModule,
		SharedFeaturesValidationModule,
	],
	exports: EXPOSED_COMPONENTS,
	declarations: EXPOSED_COMPONENTS,
})
export class SharedComponentsMetadataModule { }
