import { Feature } from './feature';
import { FeatureAction } from './feature-actions';
import { FeaturePermission } from './feature-permission';

export function featurePermissionsMapFactory<TFeature extends Feature>(
	permissionNames: string[],
	parser: (featureName: string) => TFeature,
): Map<FeatureAction | TFeature, FeaturePermission<TFeature>> {
	return new Map(
		permissionNames.map(permissionName => {
			const featurePermission = new FeaturePermission(permissionName, parser);

			return [ featurePermission.permission, featurePermission ];
		}),
	);
}
