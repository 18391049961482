import { PspPaymentOptionType } from '@bp/shared/models/business';
import { Currency } from '@bp/shared/models/currencies';
import type { DTO } from '@bp/shared/models/metadata';
import { Control, FieldControlType, Mapper, Required } from '@bp/shared/models/metadata';

import { BridgerPspPaymentOptionVoucherSummary } from '../summaries';

import { BridgerPspPaymentOptionBase } from './bridger-psp-payment-option-base';

export class BridgerPspPaymentOptionVoucher extends BridgerPspPaymentOptionBase implements BridgerPspPaymentOptionVoucherSummary {

	static override readonly type = PspPaymentOptionType.voucher;

	readonly type = BridgerPspPaymentOptionVoucher.type;

	@Control(FieldControlType.chip, {
		list: Currency.list,
		placeholder: 'Add currency...',
	})
	@Mapper(Currency)
	@Required()
	currencies!: Currency[];

	constructor(dto: DTO<BridgerPspPaymentOptionVoucher>) {
		super(dto);
	}

}
