import { switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { apiResult } from '@bp/shared/models/common';
import { BridgerPspsQueryParams } from '@bp/shared/domains/bridger-psps/core';

import { BridgerPspsApiService } from '../services';

import { loadAllFailure, loadAllSuccess } from './bridger-psps-shared-api.actions';
import { loadAll } from './bridger-psps-shared.actions';

@Injectable()
export class BridgerPspsEffects {

	onLoad$ = createEffect(() => this._actions$.pipe(
		ofType(loadAll),
		switchMap(() => this._bridgerPspsApiService
			.getRecordsPage(new BridgerPspsQueryParams({ limit: 99_999 }))
			.pipe(apiResult(loadAllSuccess, loadAllFailure))),
	));

	constructor(
		private readonly _actions$: Actions,
		private readonly _bridgerPspsApiService: BridgerPspsApiService,
	) {
	}

}
