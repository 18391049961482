<form *ngIf="form" cdkTrapFocus cdkTrapFocusAutoCapture [formGroup]="form" (ngSubmit)="submit()">
	<bp-alert [errors]="errors" type="danger"> </bp-alert>

	<!-- to kick in the browsers save password feature with upon login -->
	<input type="text" name="email" [value]="email" hidden />

	<div *ngIf="expiredPassword" class="mb-5 mt-n2 text-center">Your password has expired. Please enter a new one</div>

	<div class="inputs-wrapper" [class.mb-2]="expiredPassword">
		<bp-password-input
			required
			label="Password"
			nativeAutocomplete="new-password"
			formControlName="password"
		></bp-password-input>

		<bp-password-input
			required
			label="Confirm Password"
			nativeAutocomplete="new-password"
			formControlName="confirmPassword"
		></bp-password-input>
	</div>

	<button type="submit" class="bp-round-button mt-auto" mat-flat-button [disabled]="pending$ | async" color="primary">
		Reset Password
	</button>

	<div class="aux-buttons">
		<button type="button" class="link" (click)="login.emit()">Login</button>
	</div>
</form>
